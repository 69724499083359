import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/pro-regular-svg-icons/faBoxes";

// import axios from "axios";
import { useConfig } from "@peracto/peracto-config";
import { ProductCard } from "components/ProductCard";

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faBoxes} size="6x" />
    </div>
);

const ProductListComponent = ({ state: { skus = [""], heading = "" } }) => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [products, setProducts] = useState([]);
    const [loadingError, setLoadingError] = useState(false);

    const loadProductData = async (skus) => {
        setLoadingError(false);

        if (skus.length > 0) {
            try {
                const skuQueryString = skus
                    .map((sku) => `skus[]=${sku}`)
                    .join("&");

                const response = await fetch(
                    `${API_URL}/storefront/products-by-skus?${skuQueryString}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        credentials: "include",
                    }
                );

                const json = await response.json();

                const apiProducts = json?.["hydra:member"];

                const productsToShow = [];

                skus.forEach((sku) => {
                    const apiProduct = apiProducts?.find(
                        (product) => product.sku === sku
                    );

                    if (apiProduct) {
                        return productsToShow.push(apiProduct);
                    }
                });

                setProducts(productsToShow);
            } catch (e) {
                setLoadingError(true);
                console.error(e);
            }
        }
    };

    useEffect(() => {
        const filteredSkus = skus.filter((sku) => !!sku);

        loadProductData(filteredSkus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skus]);

    return (
        <article>
            <div className="flex flex-col justify-start content__product-list w-full">
                {heading.length > 0 ? (
                    <h1 className="text-center text-brand-primary mb-4 lg:mb-8 font-bold text-[40px] leading-[52px]">
                        {heading}
                    </h1>
                ) : null}
                {products?.length > 0 && !loadingError ? (
                    <div className="grid grid-cols-1 gap-4 mb-8 md:grid-cols-2 lg:grid-cols-4">
                        {products.map((product) => {
                            return (
                                <ProductCard
                                    showComparison={false}
                                    key={product["@id"]}
                                    showAddToFavourites={true}
                                    onAddToBasket={() => {}}
                                    orientation="vertical"
                                    product={{
                                        ...product,
                                        canAddToBasket: false,
                                        prices: {
                                            price: product.price,
                                        },
                                    }}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <Icon />
                )}
            </div>
        </article>
    );
};

export default ProductListComponent;
