import React from "react";
import { Route, Switch } from "react-router-dom";
import {
    GetPartsDetailForm,
    GetInvoicesForm,
    GetDocumentForm,
} from "./babbage-soap";
import {
    VrmLookupForm,
    VinLookupForm,
    XrefLookupForm,
    ExhaustLookupForm,
    PartDetailsForm,
    ApplicationGuideForm,
    ComponentsByVehicleAttributesForm,
    SearchByComponentTreeForm,
} from "./raindata";
import { WismoOrderRequestForm, WismoAccountRequestForm } from "./wismo";
import { WebServiceLogsForm } from "./web-service-logs";
import {
    HaynesAuthForm,
    GetIDByTecdocVRMForm,
    GetIDByTecdocVINForm,
    GetIDByTecdocMVLForm,
    GetMaintenanceSystemsV6Form,
    GetMaintenanceTasksV7Form,
    GetRepairTimeTypesV2Form,
    GetRepairTimeSubnodesByGroupV3Form,
    GetRepairTimeSubnodeTextSearchV4Form,
} from "./haynes-pro";
import { GetRepairTimeInfosV3Form } from "./haynes-pro/get-repair-time-infos-v3";

export default () => {
    return {
        routes: [
            {
                path: "/web-services",
                render: ({ match }) => (
                    <Switch>
                        {/* Babbage */}

                        <Route
                            path={`${match.url}/get-document`}
                            exact
                            component={GetDocumentForm}
                        />

                        <Route
                            path={`${match.url}/get-parts-detail`}
                            exact
                            component={GetPartsDetailForm}
                        />

                        <Route
                            path={`${match.url}/get-invoices`}
                            exact
                            component={GetInvoicesForm}
                        />

                        {/* Raindata */}

                        <Route
                            path={`${match.url}/vin`}
                            exact
                            component={VinLookupForm}
                        />

                        <Route
                            path={`${match.url}/vrm`}
                            exact
                            component={VrmLookupForm}
                        />

                        <Route
                            path={`${match.url}/xref`}
                            exact
                            component={XrefLookupForm}
                        />

                        <Route
                            path={`${match.url}/exhaust`}
                            exact
                            component={ExhaustLookupForm}
                        />

                        <Route
                            path={`${match.url}/part-details`}
                            exact
                            component={PartDetailsForm}
                        />

                        <Route
                            path={`${match.url}/application-guide`}
                            exact
                            component={ApplicationGuideForm}
                        />

                        <Route
                            path={`${match.url}/search-by-component-tree`}
                            exact
                            component={SearchByComponentTreeForm}
                        />

                        <Route
                            path={`${match.url}/component-tree`}
                            exact
                            component={ComponentsByVehicleAttributesForm}
                        />

                        {/* Wismo */}

                        <Route
                            path={`${match.url}/wismo/order-request`}
                            exact
                            component={WismoOrderRequestForm}
                        />

                        <Route
                            path={`${match.url}/wismo/details-request`}
                            exact
                            component={WismoAccountRequestForm}
                        />

                        {/* Web Service Logs  */}

                        <Route
                            path={`${match.url}/web-service-logs`}
                            exact
                            component={WebServiceLogsForm}
                        />

                        {/* Haynes Pro  */}
                        <Route
                            path={`${match.url}/get-haynes-auth`}
                            exact
                            component={HaynesAuthForm}
                        />

                        <Route
                            path={`${match.url}/get-haynes-tech-doc-vrm`}
                            exact
                            component={GetIDByTecdocVRMForm}
                        />

                        <Route
                            path={`${match.url}/get-haynes-tech-doc-vin`}
                            exact
                            component={GetIDByTecdocVINForm}
                        />
                        <Route
                            path={`${match.url}/get-haynes-tech-doc-mvl`}
                            exact
                            component={GetIDByTecdocMVLForm}
                        />

                        <Route
                            path={`${match.url}/haynes-pro-get-maintenance-systems-v6`}
                            exact
                            component={GetMaintenanceSystemsV6Form}
                        />

                        <Route
                            path={`${match.url}/haynes-pro-get-maintenance-tasks-v7`}
                            exact
                            component={GetMaintenanceTasksV7Form}
                        />

                        <Route
                            path={`${match.url}/haynes-get-repair-time-types-v2`}
                            exact
                            component={GetRepairTimeTypesV2Form}
                        />

                        <Route
                            path={`${match.url}/haynes-get-repair-time-subnodes-by-group-v3`}
                            exact
                            component={GetRepairTimeSubnodesByGroupV3Form}
                        />

                        <Route
                            path={`${match.url}/haynes-get-repair-time-subnode-text-search-v4`}
                            exact
                            component={GetRepairTimeSubnodeTextSearchV4Form}
                        />

                        <Route
                            path={`${match.url}/haynes-get-repair-time-infos-v3`}
                            exact
                            component={GetRepairTimeInfosV3Form}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
