import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import * as yup from "yup";
import { Helmet } from "react-helmet";
import * as qs from "query-string";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";

import { MODE_ADD } from "../CategoryForm";

const CategoryAdd = ({ CategoryForm }) => {
    const { client, getResource } = useClient();
    const [redirect, setRedirect] = useState();

    const queryString = qs.parse(window?.location?.search);

    const parent = queryString?.parent
        ? `/categories/${queryString?.parent}`
        : null;

    const tier3 = queryString?.tierNumber === "3";

    const schema = getSchemaFromResource(getResource("categories")).shape({
        type: yup.string().when("template", (template, schema) => {
            return template === "Product List"
                ? schema.required()
                : schema.notRequired();
        }),
        metaDescription: yup.string().nullable(),
        heading: yup.string().required(),
        identifier: yup.string().required(),
        metaTitle: yup.string().required(),
        sortOrder: yup
            .number()
            .required()
            .typeError("Sort Order must be a number"),
    });

    const onSubmit = async (data, actions) => {
        try {
            await client(CREATE, "categories", {
                data: {
                    // Set defaults to prevent error when not set
                    metaKeywords: [],
                    filters: data.filters || [],
                    isDeleted: false,
                    parent,
                    type: "standard",
                    template: "Product List",
                    ...data,
                    sortOrder: parseInt(data.sortOrder || 0),
                },
            });

            setRedirect("/categories");
            toast.success("Category successfully added!");
            actions.setSubmitting(false);
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );

            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }

            actions.setSubmitting(false);
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <Helmet>
                <title>Categories | Add | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Add Category">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link
                            className="btn btn-outline-primary"
                            to="/categories"
                            data-testid="back-to-categories"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Categories
                        </Link>
                    </div>
                </Heading>
                <CategoryForm
                    onSubmit={onSubmit}
                    mode={MODE_ADD}
                    schema={schema}
                    tier3={tier3}
                    values={{
                        tierNumber: queryString?.tierNumber || "0",
                    }}
                />
            </div>
        </>
    );
};

export default CategoryAdd;
