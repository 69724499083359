import React from "react";
import { Route, Switch } from "react-router-dom";

import { AccountsList } from "./accounts";

export default () => {
    return {
        routes: [
            {
                path: "/babbage-accounts",
                render: ({ match }) => (
                    <Switch>
                        {/* Babbage */}

                        <Route
                            path={`${match.url}`}
                            exact
                            component={AccountsList}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
