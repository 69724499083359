import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import CardsRenderer from "./Renderer/CardsRenderer";

const CardsForm = lazyLoad(() => import("./Form/CardsForm"));

const CardsPlugin = (settings) =>
    createPlugin({
        Renderer: CardsRenderer,
        Controls: CardsForm,
        ...settings,
    });

const Cards = CardsPlugin();
export default Cards;

export { CardsPlugin, CardsRenderer };
