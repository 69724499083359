import React from "react";
import PropTypes from "prop-types";

import {
    Form,
    Group,
    Input,
    Select,
    Textarea,
    File,
    FormGroup,
    RichText,
    Checkbox,
} from "@peracto/peracto-ui";
import { Field, getIn } from "formik";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const CategoryForm = ({
    mode = MODE_EDIT,
    values = {},
    tier3 = false,
    ...props
}) => {
    return (
        <Form
            autoComplete="off"
            values={{
                heading: "",
                description: "",
                identifier: "",
                thumbnail: "",
                sortOrder: 0,
                status: "active",
                webServiceCategory: false,
                adsCodes: "",
                emdmCodes: "",
                genericCodes: "",
                onlySoldInMultiples: false,
                comparisonToolEnabled: false,
                showPackSize: false,
                tooltipForMultiples: "",
                code: "",
                tierNumber: "0",
                ...values,
            }}
            {...props}
        >
            <div data-testid="category-information">
                <Group
                    key="category-information"
                    id="category-information"
                    name="Category Information"
                >
                    <Field name="heading">
                        {({ field, form }) => {
                            let error = getIn(form.errors, field.name);

                            if (error) {
                                error = error.replace(
                                    field.name,
                                    "Navigation Label / Title"
                                );
                            }
                            if (!field.value) field.value = "";

                            return (
                                <FormGroup
                                    name={field.name}
                                    label="Navigation Label / Title"
                                    help="This is what will be displayed on the front end."
                                    required
                                    error={error}
                                >
                                    <input
                                        type="text"
                                        className="form-control"
                                        data-testid="heading"
                                        {...field}
                                        disabled={form.isSubmitting}
                                        onBlur={() => {
                                            if (field.value.length > 0) {
                                                if (
                                                    !form.values.metaTitle ||
                                                    form.values.metaTitle
                                                        .length === 0
                                                ) {
                                                    form.setFieldValue(
                                                        "metaTitle",
                                                        field.value
                                                    );
                                                }

                                                if (
                                                    !form.values.identifier ||
                                                    form.values.identifier
                                                        .length === 0
                                                ) {
                                                    form.setFieldValue(
                                                        "identifier",
                                                        `${field.value
                                                            .split(" ")
                                                            .join("-")
                                                            .toLowerCase()}`
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                </FormGroup>
                            );
                        }}
                    </Field>

                    <RichText
                        name="description"
                        label="Description"
                        testId="description"
                        help="Category description displayed on the front end."
                    />

                    <Input
                        name="code"
                        label="Category Code"
                        testId="code"
                        help="Please enter the category code"
                    />

                    <File
                        name="thumbnail"
                        label="Thumbnail Image"
                        testId="thumbnail"
                        help="This image will be used for menu items"
                    />

                    <Input
                        name="sortOrder"
                        label="Sort Order"
                        testId="sortOrder"
                        help="Determines the placement of the category within its parent in the navigation"
                    />
                </Group>
            </div>

            <div data-testid="category-options">
                <Group
                    key="category-options"
                    id="category-options"
                    name="Category Options"
                >
                    <Select
                        name="status"
                        label="Status"
                        testId="status"
                        defaultValue={{ label: "Active", value: "active" }}
                        options={[
                            { label: "Active", value: "active" },
                            { label: "Inactive", value: "draft" },
                        ]}
                    />

                    {(values?.tierNumber === 3 || tier3) && (
                        <>
                            <Checkbox
                                name="webServiceCategory"
                                label="Web Service Category?"
                                help="Connect to ADS Webservices in order to retrieve category data?"
                            />

                            <Field name="genericCodes">
                                {({ field, form }) => {
                                    return !form.values?.webServiceCategory ? (
                                        <Input
                                            name={field.name}
                                            label="Associated Generic Categories"
                                            testId="genericCodes"
                                            help="Please enter in pipe separated format eg. 123|456|789. Note. Adding a category that doesnt currently exist will create it in the system."
                                        />
                                    ) : null;
                                }}
                            </Field>

                            <Field name="adsCodes">
                                {({ field, form }) => {
                                    return form.values?.webServiceCategory ? (
                                        <Input
                                            name={field.name}
                                            label="Associated ADS Categories"
                                            testId="adsCodes"
                                            help="Please enter in pipe separated format eg. 123|456|789. Note. Adding a category that doesnt currently exist will create it in the system."
                                        />
                                    ) : null;
                                }}
                            </Field>

                            <Field name="emdmCodes">
                                {({ field, form }) => {
                                    return form.values?.webServiceCategory ? (
                                        <Input
                                            name={field.name}
                                            label="Associated eMDM Categories"
                                            testId="emdmCodes"
                                            help="Please enter in pipe separated format eg. 123|456|789. Note. Adding a category that doesnt currently exist will create it in the system."
                                        />
                                    ) : null;
                                }}
                            </Field>
                        </>
                    )}

                    <Checkbox
                        name="onlySoldInMultiples"
                        label="Products that have a set quantity can only be bought in multiples?"
                        help="Set whether products in the category can only be bought in multiples"
                    />

                    <Checkbox
                        name="comparisonToolEnabled"
                        label="Enable the Product Comparison tool on this category"
                        help="Set whether the User is able to compare products within this category"
                    />

                    <Checkbox
                        name="showPackSize"
                        label="Show pack size on storefront?"
                    />

                    <Input
                        name="tooltipForMultiples"
                        label="Tooltip used for when buying products in multiples"
                        testId="tooltipForMultiples"
                        help="Set help icon text displayed when buying products in multiples"
                    />

                    <Input
                        name="tierNumber"
                        label="Category Tier Number"
                        testId="tierNumber"
                        help="This is the Category Tier Number. It is a non-editable field. Please be aware that this field will automatically update depending on the Tier Number of the Parent Category it is."
                        disabled
                        readOnly
                    />
                </Group>
            </div>

            <div data-testid="search-engine-data">
                <Group
                    key="search-engine-data"
                    id="search-engine-data"
                    name="Search Engine Data"
                >
                    <Input
                        name="identifier"
                        label="URL and Path"
                        prefix={`https://omnipart.eurocarparts.com/category/`}
                        testId="seo-path-slug"
                        required
                    />

                    <Input
                        name="metaTitle"
                        label="Title"
                        testId="seo-title"
                        required
                    />

                    <Textarea
                        name="metaDescription"
                        label="Description"
                        testId="seo-description"
                    />
                </Group>
            </div>
        </Form>
    );
};

CategoryForm.displayName = "CategoryForm";

CategoryForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CategoryForm;
