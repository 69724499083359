import React, { useEffect, useState } from "react";
import TextInput from "@peracto/peracto-editor-ui/dist/TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";

import styled from "@emotion/styled";

const SKURow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: between;

    .form-group {
        margin: 0;
        width: 100%;
    }

    button {
        width: 24px;
    }
`;

const ProductListForm = (props) => {
    const {
        onChange,
        state: { skus = [""], heading = "" },
        id,
    } = props;

    const [internalSkus, setInternalSkus] = useState(skus);

    useEffect(() => {
        setInternalSkus(skus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <TextInput
                label="Heading"
                placeholder=""
                onChange={(e) => {
                    onChange({
                        heading: e.target.value,
                    });
                }}
                value={heading}
            />

            <label className="mb-1">Product SKUs</label>

            {internalSkus.map((sku, idx) => {
                return (
                    <SKURow className={idx > 0 ? "mt-2" : ""}>
                        <TextInput
                            label=""
                            placeholder="Product SKU"
                            onChange={(e) => {
                                const skusClone = [...internalSkus];

                                skusClone[idx] = e.target.value;
                                setInternalSkus(skusClone);
                            }}
                            value={sku}
                        />

                        <button
                            className="btn btn-danger ml-2 p-0"
                            onClick={(e) => {
                                const skusClone = [...internalSkus];

                                skusClone.splice(idx, 1);

                                setInternalSkus(skusClone);
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </SKURow>
                );
            })}

            <hr className="my-2" />

            <button
                className="btn btn-sm btn-secondary w-100"
                onClick={(e) => {
                    e.preventDefault();

                    setInternalSkus([...internalSkus, ""]);
                }}
            >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add SKU
            </button>

            <hr className="my-2" />

            <button
                className="btn btn-sm btn-success w-100"
                onClick={(e) => {
                    e.preventDefault();

                    onChange({
                        skus: internalSkus,
                    });
                }}
            >
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> Apply
                SKUs
            </button>
        </>
    );
};

export default ProductListForm;
