/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";
import {
  GET_ONE,
  UPDATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";
import { formatDataForAPI } from "../util";

const AttributeEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Attribute">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link
            className="btn btn-outline-primary"
            to="/attributes"
            data-testid="back-to-attributes"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Attributes
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const AttributeEdit = ({ AttributeForm, location: { pathname } }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();

  const [attribute, setAttribute] = useState();

  const fetchAttribute = async () => {
    try {
      const { data, response } = await client(GET_ONE, "attributes", {
        id: pathname,
      });

      setAttribute(data);
      setLoading(false);

      if (response.status === 404) {
        setRedirect("/attributes");
      }
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setLoading(false);
      setRedirect("/attributes");
    }
  };

  useEffect(() => {
    fetchAttribute();
  }, []);

  const schema = getSchemaFromResource(getResource("attributes"));

  const onSubmit = async (data, actions) => {
    try {
      const response = await client(UPDATE, "attributes", {
        id: pathname,
        data: formatDataForAPI(data),
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        actions.setSubmitting(false);
        toast.success("Attribute successfully updated!");
      }
    } catch (e) {
      console.error(e);
      if (e?.error?.body?.violations && e.error.body.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <AttributeEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </AttributeEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/attributes"
        message="The attribute you're looking for could not be found"
        buttonLabel="Go to Attributes"
      />
    );
  }

  return (
    <AttributeEditContainer>
      {redirect && <Redirect to={redirect} />}

      <Helmet>
        <title>{attribute.label || "Attribute"} | Edit | Peracto</title>
      </Helmet>

      <AttributeForm values={attribute} onSubmit={onSubmit} schema={schema} />
    </AttributeEditContainer>
  );
};

export default AttributeEdit;
