import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Group, Heading, LoadingIcon } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

export const HaynesAuthForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState("");

    const onLoadAuthData = async () => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.get(
                `${API_URL}/test-harness/haynes-pro/get-authentication-vrid`,
                {
                    headers,
                }
            );

            setResponseData(JSON.stringify(data));
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error when retrieving Haynes authentication results."
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        onLoadAuthData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    getAuthenticationVrid | Haynes Pro | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="getAuthenticationVrid" />

                {loading && (
                    <div className="flex justify-center items-center py-5">
                        <LoadingIcon />
                    </div>
                )}

                {responseData?.length > 0 && !loading && (
                    <Group
                        key="response-details"
                        id="response-details"
                        name="Response Details"
                    >
                        <div className="flex items-center justify-between mt-2 mb-3">
                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                Haynes Authentication Results
                            </h4>{" "}
                            <div className="flex flex-row">
                                <CopyButton content={responseData} />
                            </div>
                        </div>

                        <div className="border rounded-[4px] p-2">
                            <CodeFormatter
                                code={responseData}
                                language="json"
                            />
                        </div>
                    </Group>
                )}
            </div>
        </>
    );
};

HaynesAuthForm.displayName = "HaynesAuthForm";
