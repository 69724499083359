const getCategoryResources = (resources) => {
    let categoryResources = {};

    resources.forEach((resource) => {
        const { type, title, description, destination, path } = resource;
        if (!categoryResources.hasOwnProperty(type)) {
            categoryResources[type] = [];
        }
        categoryResources[type].push({
            "@id": resource["@id"] || null,
            type,
            title,
            path,
            description,
            destination: destination || "",
        });
    });

    return categoryResources;
};

export const formatDataForForm = (data) => {
    data.categoryResources = getCategoryResources(data.resources);

    return {
        ...data,
    };
};

/*
 * API -> UI conversion
 --------------------------------------------------------*/
function prepareItemForTree(item) {
    if (item.heading) {
        item.title = item.heading;
    }

    if (item.identifier) {
        item.pathName = item.identifier;
    }

    if (item.children) {
        item.children = convertToTreeData(item.children);
    }

    if (item.id) {
        item.identifier = item.id;
    }

    item.expanded = true;

    return item;
}

export const convertToTreeData = (data) => {
    if (data?.map) {
        return data.map((item) => {
            return prepareItemForTree(item);
        });
    } else {
        return prepareItemForTree(data);
    }
};

/*
 * UI -> API conversion
 --------------------------------------------------------*/

export const convertToAPIData = (data) => {
    if (data?.length > 0) {
        return data.map((item) => {
            let id;

            if (typeof item.id === "string") {
                // Newly created items' ID is an IRI (which is a string) e.g. "/menu-item/1"
                // originID gives us the created item's ID as an int
                id = item.originId;
            } else {
                id = item.id;
            }

            const apiItem = {
                id,
                children: item.children || [],
            };
            if (apiItem?.children?.length > 0) {
                apiItem.children = convertToAPIData(apiItem.children);
            }
            return apiItem;
        });
    }
};
