/* eslint-disable */
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { useConfig } from "@peracto/peracto-config";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";
import { formatDataForAPI } from "../util";

import { MODE_ADD } from "../PromotionsForm";
import cloneDeep from "lodash/cloneDeep";

const PromotionsAdd = ({ PromotionsForm }) => {
  const { client, getResource } = useClient();
  const [redirect, setRedirect] = useState();

  const config = useConfig();
  const { promotions: promotionsConfig } = config.get("features", {});

  const schema = getSchemaFromResource(getResource("promotions")).shape({
    name: yup.string().required(),
    identifier: yup.string().required(),
    countryCode: yup
      .string()
      .matches(/^(gb|ie)$/)
      .required(),
    ...(promotionsConfig?.orderTransferCode && {
      orderTransferCode: yup.string().required(),
    }),
  });

  const onSubmit = async (data, actions) => {
    const dataClone = cloneDeep(data);

    try {
      const formattedData = formatDataForAPI(dataClone);
      const response = await client(CREATE, "promotions", {
        data: formattedData,
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        setRedirect(response.data.id);
        toast.success("Promotion successfully added!");
        actions.setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      if (e?.error?.body?.violations?.length > 0) {
        // Display errors for invalid fields
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      actions.setSubmitting(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const defaultFormData = {
    discounts: [
      {
        type: "percentage",
        value: 10.0,
      },
    ],
    status: "active",
    promotionCodes: [],
  };

  return (
    <>
      <Helmet>
        <title>Promotions | Add | Peracto</title>
      </Helmet>
      <div className="form-container">
        <Heading name="Add Promotion">
          <div className="flex-grow-1 d-flex align-items-center justify-content-end">
            <Link className="btn btn-outline-primary" to="/promotions">
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Promotions
            </Link>
          </div>
        </Heading>
        <PromotionsForm
          onSubmit={onSubmit}
          mode={MODE_ADD}
          schema={schema}
          values={defaultFormData}
        />
      </div>
    </>
  );
};

export default PromotionsAdd;
