/* eslint-disable */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { HydraTable, TimeAgo } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

import { GET_LIST, useClient } from "@peracto/client";

const AttributesImportList = () => {
  const { client } = useClient();
  const config = useConfig();
  const formatDate = config.get("user_format_date", config.get("format_date"));

  const columns = useMemo(() => [
    {
      Header: "Import Type",
      accessor: "importType",

      Cell: ({ row, cell: { value } }) => (
        <Link to={`/attributes/import/${row.original.originId}`}>
          Attribute
        </Link>
      ),
    },
    {
      Header: "Processed",
      accessor: "processedCount",
    },
    {
      Header: "Errors",
      accessor: "errorCount",
    },
    {
      Header: "Total",
      accessor: "totalCount",
    },
    {
      Header: "Created By",
      accessor: "user.email",
      id: "createdBy",
      Cell: ({ cell: { value } }) => value || "-",
    },
    {
      Header: "Created",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => {
        const date = new Date(value);
        const validDate = !!date.getDate() === true;

        return value && validDate ? (
          <TimeAgo title={formatDate(date)} date={date} />
        ) : (
          "-"
        );
      },
    },
    {
      Header: "% Complete",
      id: "percentComplete",
      Cell: ({ row }) =>
        row.original.totalCount > 0
          ? `${Math.round(
              (row.original.processedCount / row.original.totalCount) * 100
            )}%`
          : "-",
    },
  ]);

  const onFetchData = (options) =>
    client(GET_LIST, "imports", {
      ...options,
      filter: {
        importType: "attribute",
        ...options.filter,
      },
    });

  return (
    <HydraTable
      columns={columns}
      onFetchData={onFetchData}
      noResultsMessage="No attribute data has been imported"
      fixedRowCount={10}
      nestedTable={true}
    />
  );
};

export default AttributesImportList;
