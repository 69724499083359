import React, { useState, useEffect } from "react";
import SortableTree, { removeNodeAtPath } from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faEdit } from "@fortawesome/pro-regular-svg-icons/faEdit";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { DELETE, useClient } from "@peracto/client";
import { Modal } from "@peracto/peracto-ui";
import { toast } from "react-toastify";

import CategoryTreeRow from "./CategoryTreeRow";
import CategoryTreeEmpty from "./CategoryTreeEmpty";
import * as S from "./styled";

const CHILDESS_TYPES = ["directLink"];

const CategoryTree = ({ values }) => {
    const { client } = useClient();
    const [treeData, setTreeData] = useState(values);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const onDelete = async () => {
        try {
            await client(DELETE, "categories", {
                id: `/categories/${
                    modalData.node.originId || modalData.node.id
                }`,
            });

            setTreeData(
                removeNodeAtPath({
                    treeData,
                    path: modalData.path,
                    getNodeKey,
                })
            );

            toast.success(`Category successfully deleted!`);
            setModalData(null);
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    useEffect(() => {
        setTreeData(values);
    }, [values]);

    const getNodeKey = ({ treeIndex }) => treeIndex;

    if (treeData) {
        return (
            <>
                <S.CategoryTree>
                    <div className="d-flex justify-content-end align-items-center mb-2">
                        <Link to="/categories/add" className="btn btn-primary">
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="mr-2"
                            />
                            Add New Category
                        </Link>
                    </div>
                    {treeData.length > 0 ? (
                        <div style={{ height: "80vh", minHeight: "400px" }}>
                            <SortableTree
                                isVirtualized={false}
                                treeData={treeData}
                                onChange={(treeData) => setTreeData(treeData)}
                                nodeContentRenderer={CategoryTreeRow}
                                canNodeHaveChildren={(node) => {
                                    return (
                                        node &&
                                        !CHILDESS_TYPES.includes(node.itemType)
                                    );
                                }}
                                generateNodeProps={({ node, path }) => {
                                    let originId = node.identifier;

                                    if (typeof node.identifier === "string") {
                                        originId = originId.split("/");
                                    }

                                    const id =
                                        typeof node.identifier === "string"
                                            ? originId[originId.length - 1]
                                            : node.identifier;

                                    let buttons = [
                                        <Link
                                            to={`/categories/${id}`}
                                            className="btn btn-link text-info"
                                            title="Edit Menu Item"
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </Link>,

                                        <button
                                            className="btn btn-link text-danger"
                                            title="Remove Item"
                                            onClick={() => {
                                                setModalData({ node, path });
                                                setShowDeleteModal(true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>,
                                    ];

                                    // If the menu item supports children then add the 'Add Child'
                                    // button between the edit/delete buttons
                                    if (node?.tierNumber < 3) {
                                        const categoryFormUrl = `/categories/add?parent=${id}&tierNumber=${
                                            (node?.tierNumber || 0) + 1
                                        }`;

                                        buttons.splice(
                                            1,
                                            0,

                                            <Link
                                                to={categoryFormUrl}
                                                className="btn btn-link"
                                                title="Add Menu Item"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                />
                                            </Link>
                                        );
                                    }
                                    return {
                                        buttons,
                                    };
                                }}
                            />
                        </div>
                    ) : (
                        <CategoryTreeEmpty />
                    )}
                </S.CategoryTree>

                <Modal
                    isVisible={showDeleteModal}
                    title="Delete Category"
                    close={() => setShowDeleteModal(false)}
                    buttons={[
                        {
                            type: "btn-outline-secondary",
                            text: "Close",
                            action: () => {
                                setShowDeleteModal(false);
                            },
                        },
                        {
                            type: "btn-danger",
                            text: "Delete",
                            action: () => {
                                setShowDeleteModal(false);
                                onDelete();
                            },
                        },
                    ]}
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size="4x"
                        className="d-block mb-4"
                    />
                    {`Are you sure you would like to remove ${
                        modalData?.node?.title || "this category"
                    }?`}
                </Modal>
            </>
        );
    }

    return "Loading...";
};

export default CategoryTree;
