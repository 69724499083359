import React from 'react'
import { Select, Input } from '@peracto/peracto-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'

export const NUMERIC_CONDITIONS = ['basket.subtotal']
export const CURRENCY_CONDITIONS = ['basket.subtotal']

const CONDITION_TYPES = [
    { label: 'Basket Subtotal', value: 'basket.subtotal' },
    { label: 'Customer Group', value: 'basket.user_customer_group_name' },
    // { label: 'User Email', value: 'user.email' },
]

export const OPERATION_TYPES = [
    { label: 'Equals', value: '=' },
    { label: 'Greater than', value: '>' },
    { label: 'Less than', value: '<' },
    { label: 'Greater than or equal', value: '>=' },
    { label: 'Less than or equal', value: '<=' },
    { label: 'Not equal to', value: '!=' },
]

export const STRING_OPERATION_TYPES = [
    { label: 'Equals', value: '=' },
    { label: 'Not equal to', value: '!=' },
]

const MinimumRequirementsRow = ({ index, field, form, remove, customerGroups }) => {
    return (
        <div key={`condition_${index}`}>
            <div className="row mb-1">
                <div className="col-12">
                    <div className="form-row">
                        <div className="col-12 col-md-4">
                            <Select
                                name={`${field.name}.${index}.condition`}
                                options={CONDITION_TYPES}
                            />
                        </div>

                        <div className="col-12 col-md-4">
                            <Select
                                name={`${field.name}.${index}.operation`}
                                options={
                                    NUMERIC_CONDITIONS.includes(field.value[index]?.condition)
                                        ? OPERATION_TYPES
                                        : STRING_OPERATION_TYPES
                                }
                            />
                        </div>

                        <div className="col-12 col-md-3">
                            {field.value[index]?.condition === 'basket.user_customer_group_name' ? (
                                <Select
                                    name={`${field.name}.${index}.value`}
                                    options={customerGroups}
                                    defaultValue={
                                        field.value[index]?.value
                                            ? {
                                                  label: field.value[index]?.value,
                                                  value: field.value[index]?.value,
                                              }
                                            : null
                                    }
                                    value={
                                        field.value[index]?.value
                                            ? {
                                                  label: field.value[index]?.value,
                                                  value: field.value[index]?.value,
                                              }
                                            : null
                                    }
                                />
                            ) : (
                                <Input
                                    type={
                                        NUMERIC_CONDITIONS.includes(field.value[index]?.condition)
                                            ? 'number'
                                            : 'text'
                                    }
                                    name={`${field.name}.${index}.value`}
                                    min="0"
                                    step="any"
                                />
                            )}
                        </div>

                        <div className="col-12 col-md-1 text-right">
                            <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                    remove(index)
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MinimumRequirementsRow
