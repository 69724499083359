import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import {
    HydraTable,
    Heading,
    TextFilter,
    DateRangeFilter,
    StyledCheckbox,
    SelectFilter,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import { GET_LIST, useClient } from "@peracto/client";
import { formatAsCurrency } from "../../../peracto-catalogue/Orders/OrdersView";
import { toast } from "react-toastify";
import download from "downloadjs";
import axios from "axios";
import dayjs from "dayjs";
import classNames from "classnames";

import { ReprocessOrders } from "./ReprocessOrders";

export const FailedOrdersList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState();

    const { client } = useClient();

    const config = useConfig();

    const { orders } = config.get("features", {});
    const API_URL = config.get("api");
    const orderExports = orders?.exportOrderData || false;

    const exportOrderData = async () => {
        let filename = `order.csv`;

        const timeframe = dayjs()
            .subtract(6, "months")
            .format("YYYY-MM-DD HH:mm:ss");

        try {
            const { data } = await axios.get(
                `${API_URL}/export/${filename}?createdAt[operator]=gt&createdAt[value]=${timeframe}&status=error`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );

            download(data, `order_export.csv`);
        } catch (e) {
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const tableColumns = [
        {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <StyledCheckbox
                    {...row.getToggleRowSelectedProps()}
                    data-testid={`row-${row.index}-checkbox`}
                />
            ),
            selectable: false,
        },
        {
            Header: "Order Reference",
            accessor: "reference",
            sortType: "basic",
            Filter: (props) => (
                <TextFilter label="Order Reference" {...props} />
            ),
            Cell: (row) => (
                <Link to={`${row.row.original.id}?to=failed`}>
                    {row.cell.value || "-"}
                </Link>
            ),
            selectable: false,
        },
        {
            Header: "Email",
            accessor: "email",
            sortType: "basic",
            Filter: (props) => <TextFilter label="Email" {...props} />,
            selectable: false,
        },
        {
            Header: "Total (inc. tax)",
            accessor: "totalIncTaxAfterDiscount",
            sortType: "basic",
            Filter: (props) => (
                <TextFilter
                    type="number"
                    step={0.01}
                    label="Total (inc. tax)"
                    {...props}
                />
            ),
            Cell: (row) =>
                formatAsCurrency(
                    row.cell.value,
                    row?.cell?.row?.original?.currency
                ),
            selectable: false,
        },

        {
            Header: "Status",
            accessor: "status",
            sortType: "basic",
            Filter: (props) => (
                <SelectFilter
                    label="Status"
                    options={[
                        { value: "processing", label: "Processing" },
                        { value: "pending", label: "Pending" },
                        { value: "error", label: "Error" },
                    ]}
                    {...props}
                />
            ),
            Cell: ({ cell: { value } }) => (
                <span
                    // eslint-disable-next-line no-undef
                    className={classNames(
                        "badge",
                        "badge-pill",
                        "text-capitalize",
                        {
                            "badge-warning": value === "processing",
                            "badge-info": value === "pending",
                            "badge-danger": value === "error",
                        }
                    )}
                >
                    {value}
                </span>
            ),
            selectable: false,
        },

        {
            Header: "Order Created",
            accessor: "createdAt",
            Filter: (props) => (
                <DateRangeFilter label="Order Created" {...props} />
            ),
            Cell: ({ cell: { value } }) =>
                new Date(value) > 0
                    ? dayjs(value).format("DD MMM YYYY HH:mm:ss")
                    : "-",
            selectable: false,
        },
    ];

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(() => tableColumns, [tableColumns]);
    const onFetchData = (options) =>
        client(GET_LIST, "orders", {
            ...options,
            id: "babbage-accounts",
            filter: {
                ...options?.filter,
                status: options?.filter?.status || [
                    "error",
                    "processing",
                    "pending",
                ],
            },
        });

    return (
        <>
            <Helmet>
                <title>Failed Orders | Peracto</title>
            </Helmet>
            <Heading name="Failed Orders">
                <div className="d-flex align-items-center ml-2">
                    {orderExports === true && (
                        <button
                            className="btn btn-outline-primary mr-2"
                            onClick={() => exportOrderData()}
                            data-testid="export-order-data"
                        >
                            <FontAwesomeIcon
                                icon={faDownload}
                                className="mr-2"
                            />
                            Export Order Data
                        </button>
                    )}
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                actionPanelContent={ReprocessOrders}
                                defaultFilters={{
                                    reference: "",
                                    email: "",
                                    createdAt: "",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FailedOrdersList;
