import React from 'react'

import { Slider, Select } from '@peracto/peracto-editor-ui'

const TextForm = ({ onChange, state: { maxWidth = 100, blockAlignment = 'left' } }) => {
    const blockAlignmentOptions = [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' },
    ]

    return (
        <>
            <Slider
                label={`Max Width (${maxWidth}%)`}
                min={10}
                max={100}
                step={10}
                value={maxWidth}
                onChange={e => onChange({ maxWidth: e.target.value })}
            />

            {maxWidth < 100 && (
                <Select
                    label="Block Alignment"
                    value={blockAlignment}
                    onChange={e => onChange({ blockAlignment: e.value })}
                    options={blockAlignmentOptions}
                />
            )}
        </>
    )
}

export default TextForm
