import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import ProductsRenderer from "./Renderer/ProductsRenderer";

const ProductsForm = lazyLoad(() => import("./Form/ProductsForm"));

const ProductsPlugin = (settings) =>
    createPlugin({
        Renderer: ProductsRenderer,
        Controls: ProductsForm,
        ...settings,
    });

const Products = ProductsPlugin();
export default Products;

export { ProductsPlugin, ProductsRenderer };
