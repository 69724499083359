import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";

import { GET_ONE, useClient, getSchemaFromResource } from "@peracto/client";

const UserEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit User Application">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary"
                        to="/user-applications"
                    >
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to User Applications
                    </Link>
                </div>
            </Heading>

            {children}
        </div>
    );
};

export const UserApplicationEdit = ({
    UserApplicationForm,
    location: { pathname },
}) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();

    const [formData, setFormData] = useState();

    const fetchUser = async () => {
        try {
            const { data: userData, response: userResponse } = await client(
                GET_ONE,
                "users",
                {
                    id: pathname,
                }
            );

            const customerGroup = userData.customerGroup
                ? userData.customerGroup["@id"]
                : null;
            const defaultBranch = userData?.defaultBranch
                ? userData?.defaultBranch?.["@id"]
                : null;

            let createdByName = "";

            if (
                userData?.createdBy?.firstName ||
                userData?.createdBy?.lastName
            ) {
                createdByName = `${userData?.createdBy?.firstName || ""} ${
                    userData?.createdBy?.lastName || ""
                }`;
            }

            setFormData({
                ...userData,
                customerGroup,
                defaultBranch,
                createdByName,
            });

            if (userResponse.status === 404) {
                setRedirect("/user-applications");
            }
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/user-applications");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = getSchemaFromResource(getResource("users")).shape({
        firstName: yup.string().required("First Name is required."),
        lastName: yup.string().required("Last Name is required."),
        email: yup.string().required("Email is required."),
        tradingCountry: yup.string().required("Trading Country is required."),
        title: yup.string().required("Title is required."),
        telephone: yup.string().required("Telephone Number is required."),
    });

    if (loading) {
        return (
            <UserEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </UserEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/user-applications"
                message="The user you're looking for could not be found"
                buttonLabel="Go to User Applications"
            />
        );
    }

    return (
        <UserEditContainer>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>
                    {`${formData.firstName} ${formData.lastName}`} | Edit |
                    Peracto
                </title>
            </Helmet>

            <UserApplicationForm
                values={{
                    title: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    telephone: "",
                    companyName: "",
                    type: null,
                    hasCreditTerms: true,
                    businessTelephone: "",
                    splitDateOfBirth: {
                        day: "",
                        month: "",
                        year: "",
                    },
                    status: "active",
                    roles: ["ROLE_USER"],
                    canShowPrices: true,
                    canManagePrice: true,
                    canEditAccount: true,
                    allowOrdersStraightToPrint: true,
                    allowAlternateDeliveryAddress: false,
                    hasHaynesPro: false,
                    hasFifm: false,
                    isExcludedFromOmnirewards: false,
                    excludedFromMaintenanceMode: false,
                    hasWismo: true,
                    babbageAccounts: [],
                    userGroup: "trade",
                    tradingCountry: "gb",
                    ...formData,
                }}
                schema={schema}
                setFormData={setFormData}
                testId="edit"
            />
        </UserEditContainer>
    );
};
