import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "@peracto/peracto-auth";

import {
    Form,
    Group,
    Input,
    Tags,
    Select,
    Modal,
    Checkbox,
    FormActions,
} from "@peracto/peracto-ui";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";
import dayjs from "dayjs";
import startCase from "lodash/startCase";
import classNames from "classnames";

import { CREATE, DELETE, GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";

import { roles, titles } from "./util";
import { BabbageAccounts } from "./BabbageAccounts";
import { SuspendUser } from "./SuspendUser";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

export const LKQ_OPTIONAL_USER_ID_GROUPS = ["admin"];
export const LKQ_REQUIRED_USER_ID_GROUPS = [
    "branch_admin",
    "customer_service",
    "marketing_publisher",
    "marketing_executive",
];
export const LKQ_USER_ID_GROUPS = [
    ...LKQ_OPTIONAL_USER_ID_GROUPS,
    ...LKQ_REQUIRED_USER_ID_GROUPS,
];

export const UserForm = ({
    mode = MODE_EDIT,
    values,
    setFormData = () => {},
    onSaveAddress,
    testId,
    ...props
}) => {
    const [showUserDialog, setShowUserDialog] = useState(false);

    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [sendingPasswordReset, setSendingPasswordReset] = useState(false);

    const [branches, setBranches] = useState([]);
    const [userGroups, setUserGroups] = useState([]);

    const [redirect, setRedirect] = useState();
    const { client } = useClient();

    const { user: authUser } = useAuth();

    const userGroup = authUser?.profile?.user_group || [];

    const config = useConfig();
    const { user } = config.get("features", {});
    const defaultBranch = user?.defaultBranch ?? false;

    const fetchUserGroups = async (inputValue = "") => {
        const { data } = await client(GET_LIST, "user-groups", {
            id: "user-groups",
            label: inputValue,
        });

        const values = data.map((val) => ({
            label: val.name,
            value: val.identifier,
        }));

        setUserGroups(values);

        return values;
    };
    const onDelete = async () => {
        try {
            await client(DELETE, "users", {
                id: values.id,
            });

            toast.success("User deleted successfully!");
            setRedirect("/users");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const onResetPassword = async () => {
        setSendingPasswordReset(true);
        try {
            await client(CREATE, "users/reset-password", {
                data: {
                    email: values.email,
                },
            });

            setShowPasswordReset(false);
            setSendingPasswordReset(false);
            toast.success("Password reset email sent!");
        } catch (e) {
            console.error(e);
            setSendingPasswordReset(false);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    const fetchBranches = async (inputValue = "") => {
        const { data } = await client(
            GET_LIST,
            "locations?locationTypes.type=branch",
            {
                id: "locations",
                label: inputValue,
            }
        );

        const values = data.map((val) => ({
            label: val.name,
            value: val.id,
        }));

        setBranches(values);

        return values;
    };

    useEffect(() => {
        fetchUserGroups();

        defaultBranch && fetchBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <>
            <div data-testid={testId}>
                {redirect && <Redirect to={redirect} />}

                {mode === MODE_EDIT && (
                    <FormActions>
                        <>
                            <button
                                className="btn btn-link px-0"
                                onClick={() => setShowPasswordReset(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="mr-2"
                                />
                                Reset Password
                            </button>

                            <button
                                className="btn btn-link text-danger"
                                onClick={() => setShowUserDialog(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="mr-2"
                                />
                                Delete User
                            </button>
                        </>
                    </FormActions>
                )}

                <Form autoComplete="off" values={values} {...props}>
                    <Group
                        key="account-information"
                        id="account-information"
                        name="Account Information"
                    >
                        <Input
                            name="email"
                            label="Email"
                            required
                            autoComplete="email"
                            testId="email"
                            help="The email address is used to login and recover their account if they forget their password. Order confirmation emails will be sent to this email address."
                        />
                    </Group>
                    <Group
                        key="personal-information"
                        id="personal-information"
                        name="Personal Information"
                    >
                        <Field name="lkqUserId">
                            {({ field, form }) => {
                                return (
                                    <>
                                        {LKQ_USER_ID_GROUPS.includes(
                                            form.values.userGroup
                                        ) ? (
                                            <Input
                                                name={field.name}
                                                label="LKQ User ID"
                                                required={LKQ_REQUIRED_USER_ID_GROUPS.includes(
                                                    form.values.userGroup
                                                )}
                                                maxLength={20}
                                                disabled={
                                                    mode === MODE_EDIT &&
                                                    userGroup !== "Admin"
                                                }
                                                testId="lkq-user-id"
                                            />
                                        ) : null}
                                    </>
                                );
                            }}
                        </Field>

                        <Select
                            name="title"
                            label="Title"
                            options={titles}
                            placeholder="Select a title"
                            testId="title"
                            testIdItems="title__item"
                            testIdIndex={0}
                        />
                        <Input
                            name="firstName"
                            label="First name"
                            required
                            autoComplete="first-name"
                            testId="firstname"
                        />
                        <Input
                            name="lastName"
                            label="Last name"
                            required
                            autoComplete="last-name"
                            testId="lastname"
                        />
                    </Group>

                    <Group
                        key="contact-information"
                        id="contact-information"
                        name="Contact Information"
                    >
                        <Input
                            name="telephone"
                            label="Telephone"
                            testId="telephone"
                        />
                    </Group>

                    {mode === MODE_EDIT && (
                        <Group
                            key="system-information"
                            id="system-information"
                            name="System Information"
                        >
                            <Input
                                name="createdByName"
                                label="Created By"
                                testId="createdBy"
                                readonly
                                disabled
                            />
                        </Group>
                    )}

                    <Group
                        key="user-settings"
                        id="user-settings"
                        name="User Settings"
                    >
                        {defaultBranch && branches?.length > 0 && (
                            <Field name="defaultBranch">
                                {({ field, form }) => (
                                    <div className="form-group">
                                        <label>Default Branch</label>
                                        <Select
                                            name={field.name}
                                            className="w-100"
                                            isSearchable={true}
                                            onChange={(option) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                );
                                            }}
                                            options={branches}
                                            placeholder="Search for Branches..."
                                            testId="branches"
                                            testIdItems="branches__item"
                                            testIdIndex={1}
                                            allowClear={true}
                                        />
                                    </div>
                                )}
                            </Field>
                        )}

                        <Tags
                            name="roles"
                            label="Roles"
                            options={roles}
                            testId="roles"
                            testIdItems="roles__item"
                            help="The user's role will determine their level of access."
                        />

                        <Field name="userGroup">
                            {({ field, form }) => (
                                <div className="form-group">
                                    <label>User Group</label>
                                    <Select
                                        name={field.name}
                                        className="w-100"
                                        isSearchable={true}
                                        handleChange={() => {
                                            form.setErrors({});
                                        }}
                                        options={userGroups}
                                        defaultValue={{
                                            label: "Search for User Groups...",
                                            value: null,
                                        }}
                                        placeholder="Search for User Groups..."
                                        testId="user-groups"
                                        testIdItems="user-groups__item"
                                        testIdIndex={1}
                                        allowClear={true}
                                    />
                                </div>
                            )}
                        </Field>

                        <Select
                            name="status"
                            label="Status"
                            options={[
                                {
                                    label: "Active",
                                    value: "active",
                                },
                                {
                                    label: "Suspended",
                                    value: "suspended",
                                },
                            ]}
                            placeholder="Select a status"
                            testId="status"
                            testIdItems="status__item"
                            testIdIndex={0}
                            help="Active = the customer can log in and use the website. 
                            Suspended = the customer will not be able to login. 
                            Use the suspended status only when you want to stop a customer from using the website."
                        />

                        {mode === MODE_EDIT && (
                            <Field name="enabled">
                                {({ form }) => <SuspendUser form={form} />}
                            </Field>
                        )}

                        <Checkbox
                            name="isExcludedFromOmnirewards"
                            label="Exclude user from Omnirewards?"
                        />

                        <Checkbox
                            name="hasWismo"
                            label="Set Wismo access at user level"
                        />

                        <Checkbox
                            name="excludedFromMaintenanceMode"
                            label="Bypass maintenance mode?"
                        />
                    </Group>

                    <Group
                        key="tws-user-settings"
                        id="tws-user-settings"
                        name="TWS User Settings"
                    >
                        <Select
                            name="tradingCountry"
                            label="Trading Country"
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "gb",
                                },
                                {
                                    label: "Ireland",
                                    value: "ie",
                                },
                            ]}
                            placeholder="Select a trading country"
                            testId="tradingCountry"
                            testIdItems="tradingCountry__item"
                            testIdIndex={0}
                            required
                        />

                        <Checkbox
                            name="hasCreditTerms"
                            label="User has credit terms?"
                        />

                        <Checkbox
                            name="canShowPrices"
                            label="Show prices on website front-end?"
                            help="If unchecked, prices will be hidden from the user"
                        />

                        <Checkbox
                            name="canEditAccount"
                            label="Allow user to edit account on the front-end?"
                            help="If unchecked, user will be able to see account page but not edit the details"
                        />

                        <Checkbox
                            name="canManagePrice"
                            label="Allow price management"
                            help="If checked, this Price Management option will allow the user to also display RRP or ECP price or show their price plus a percentage"
                        />

                        <Checkbox
                            name="allowOrdersStraightToPrint"
                            label="Allow orders straight to print"
                        />

                        <Checkbox
                            name="allowAlternateDeliveryAddress"
                            label="Allow delivery to alternate delivery locations"
                        />
                    </Group>

                    <Group
                        key="babbage-accounts"
                        id="babbage-accounts"
                        name="Babbage Accounts"
                    >
                        <BabbageAccounts />
                    </Group>

                    <Group
                        key="haynes-pro-settings"
                        id="haynes-pro-settings"
                        name="Haynes Pro Settings"
                    >
                        <Checkbox
                            name="hasHaynesPro"
                            label="Set Haynes Pro access at user level"
                            {...(mode === MODE_EDIT && {
                                help: !!values?.haynesProAgreedAt
                                    ? "EULA has been accepted by user"
                                    : "EULA not yet accepted by user",
                            })}
                        />
                    </Group>

                    <Group
                        key="fifm-settings"
                        id="fifm-settings"
                        name="FiFM Settings"
                    >
                        <Checkbox
                            name="hasFifm"
                            label="Set FiFM Access At User Level"
                        />
                    </Group>

                    {mode === MODE_EDIT && (
                        <Group
                            key="user-status-history"
                            id="user-status-history"
                            name="User Status History"
                        >
                            <Field name="userStatusLogs">
                                {({ field }) =>
                                    field.value.length > 0 ? (
                                        <table className="table table-sm table-fixed">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>By</th>
                                                    <th>Type</th>
                                                    <th>Explanation</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {field.value.map((log, idx) => (
                                                    <tr key={`user-log-${idx}`}>
                                                        <td>
                                                            {dayjs(
                                                                log.createdAt
                                                            ).format(
                                                                "DD-MM-YYYY HH:mm:ss"
                                                            )}
                                                        </td>
                                                        <td>
                                                            {log.updatedBy
                                                                .email || "-"}
                                                        </td>
                                                        <td>
                                                            <span
                                                                className={classNames(
                                                                    "badge",
                                                                    "badge-pill",
                                                                    {
                                                                        "badge-success":
                                                                            log.type ===
                                                                            "active",
                                                                        "badge-danger":
                                                                            log.type ===
                                                                            "suspended",
                                                                    }
                                                                )}
                                                            >
                                                                {startCase(
                                                                    log.type
                                                                )}
                                                            </span>
                                                        </td>
                                                        <td>{log.reason}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <p className="py-4 text-center mb-0">
                                            No User Status History for this User
                                        </p>
                                    )
                                }
                            </Field>
                        </Group>
                    )}
                </Form>

                {mode === MODE_EDIT && (
                    <>
                        <Modal
                            isVisible={showUserDialog}
                            title="Delete User"
                            close={() => setShowUserDialog(false)}
                            buttons={[
                                {
                                    type: "btn-outline-secondary",
                                    text: "Close",
                                    action: () => setShowUserDialog(false),
                                },
                                {
                                    type: "btn-danger",
                                    text: "Delete User",
                                    action: () => onDelete(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Are you sure you would like to permanently delete
                            the account of {values.email}? Deleted users cannot
                            be recovered.
                        </Modal>

                        <Modal
                            isVisible={showPasswordReset}
                            title="Reset Password"
                            close={() => setShowPasswordReset(false)}
                            buttons={[
                                {
                                    type: "btn-outline-secondary",
                                    text: "Cancel",
                                    action: () => setShowPasswordReset(false),
                                },
                                {
                                    type: "btn-success",
                                    text: sendingPasswordReset
                                        ? "Sending Email..."
                                        : "Send Email",
                                    disabled: sendingPasswordReset,
                                    action: () => onResetPassword(),
                                },
                            ]}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                size="4x"
                                className="mb-4 d-block"
                            />
                            Are you sure you would like to generate a reset
                            password email for {values.email}?
                        </Modal>
                    </>
                )}
            </div>
        </>
    );
};

UserForm.displayName = "UserForm";
UserForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setFormData: PropTypes.func,
};
