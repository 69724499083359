import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectanglePortrait } from "@fortawesome/pro-regular-svg-icons/faRectanglePortrait";
import { CardHorizontal } from "./components/CardHorizontal";
import { CardVertical } from "./components/CardVertical";

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faRectanglePortrait} size="6x" />
    </div>
);

const CardsComponent = ({
    state: { cards = [], direction = "vertical", selectedCardIndex = null },
    isEditMode,
    onChange,
    focused,
}) => {
    return (
        <>
            {cards?.length > 0 ? (
                <div className="flex flex-col md:flex-row content__cards gap-4">
                    {cards.map((card, idx) => {
                        return (
                            <div
                                key={`card_${idx}`}
                                style={{
                                    boxShadow:
                                        selectedCardIndex === idx &&
                                        focused &&
                                        "inset 0 0 0 3px red",
                                }}
                                className="flex-1"
                                onClick={(e) => {
                                    if (isEditMode) {
                                        e.preventDefault();
                                        onChange({ selectedCardIndex: idx });
                                    }
                                }}
                            >
                                {direction === "horizontal" ? (
                                    <CardHorizontal {...card} />
                                ) : (
                                    <CardVertical {...card} />
                                )}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Icon />
            )}
        </>
    );
};

export default CardsComponent;
