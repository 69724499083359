import React from "react";
import ReactSelect, { components } from "react-select";

import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons/faChevronDown";

type SelectProps = {
    options: {
        value: string;
        label: string;
        selected: boolean;
        disabled: boolean;
    }[];
    name: string;
    label?: string;
    required?: boolean;
};

const Select = (props: SelectProps) => {
    const { name, options, label, required } = props;

    return (
        <Field name={name}>
            {({ field, form }) => {
                const value =
                    field?.value?.length > 0 &&
                    options?.find((option) => option.label === field.value);

                return (
                    <>
                        {label?.length > 0 && (
                            <label
                                htmlFor={name}
                                className={`mb-[10px] text-sm block font-bold`}
                            >
                                {label}{" "}
                                {required ? (
                                    <span className="text-brand-danger">*</span>
                                ) : (
                                    <span className="italic text-[12px] font-normal text-brand-dark-grey">
                                        {" "}
                                        &#8212; optional
                                    </span>
                                )}
                            </label>
                        )}

                        <div className="flex flex-col mb-[15px] justify-center">
                            <ReactSelect
                                onChange={(item) => {
                                    form.setFieldValue(field.name, item.value);
                                }}
                                value={value}
                                options={options}
                                className="react-select"
                                styles={{
                                    control: (
                                        provided,
                                        { isFocused, menuIsOpen }
                                    ) => ({
                                        ...provided,
                                        padding: "0 5px",
                                        height: "45px",
                                        fontWeight: "bold",
                                        backgroundColor: "#fff",
                                        borderColor: "#DDDDDD",
                                        borderWidth: "1px",
                                        borderBottom: menuIsOpen
                                            ? "none"
                                            : "1px solid #DDDDDD",
                                        borderRadius: menuIsOpen
                                            ? "23px 23px 0 0"
                                            : "23px",
                                        color: "#101820",
                                        boxShadow: isFocused ? "none" : "none",
                                        fontSize: "14px",
                                        "&:hover": {
                                            borderColor: "#DDDDDD",
                                        },
                                        transition: "none",
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: "45px",
                                        lineHeight: "30px",
                                    }),
                                    indicatorsContainer: (provided) => ({
                                        ...provided,
                                        position: "relative",
                                        top: "-2px",
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        padding: "0",
                                        color: "#101820",
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        width: "calc(100% - 2px)",
                                        margin: "0",
                                        left: "1px",
                                        right: "1px",
                                        padding: "0px",
                                        color: "#101820",
                                        backgroundColor: "#fff",
                                        border: "none",
                                        borderWidth: "1px",
                                        borderRadius: "0px 0px 23px 23px",
                                        overflow: "hidden",
                                    }),
                                    option: (
                                        provided,
                                        { isDisabled, isFocused }
                                    ) => ({
                                        ...provided,
                                        padding: "10px 15px",
                                        fontSize: "14px",
                                        color: "#101820",
                                        opacity: isDisabled ? 0.7 : 1,
                                        fontWeight: "normal",
                                        backgroundColor: isFocused
                                            ? "#F0F0F0"
                                            : "F0F0F0",
                                        "&:active": {
                                            backgroundColor: "#F0F0F0",
                                        },
                                    }),
                                }}
                                components={{
                                    DropdownIndicator,
                                    IndicatorSeparator: () => null,
                                }}
                                data-testid="select-input"
                            />
                        </div>
                    </>
                );
            }}
        </Field>
    );
};

const CaretDownIcon = () => {
    return (
        <p>
            <FontAwesomeIcon
                icon={faChevronDown as any}
                className="h-[14px] w-[14px] text-brand-black"
            />
        </p>
    );
};

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};

export default Select;
