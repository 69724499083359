export const formatDataForAPI = (data) => {
    const babbageAccounts = [];

    if (data?.babbageAccounts?.length > 0) {
        data.babbageAccounts.forEach((account) => {
            if (account?.babbageAccount?.["@id"]) {
                babbageAccounts.push({
                    "@id": account.babbageAccount["@id"],
                    isDefault: !!account.isDefault,
                });
            } else {
                babbageAccounts.push({
                    accountNumber: account.babbageAccount.accountNumber,
                    branch: `/locations/${account.babbageAccount.branch.id}`,
                    isDefault: !!account.isDefault,
                });
            }
        });
    }

    let status_reason = null;
    const enabled = data?.status === "active";

    if (!enabled) {
        if (
            data.status_reason === "Other" &&
            data.status_reason_other?.length > 0
        ) {
            status_reason = data.status_reason_other;
        } else if (
            data.status_reason.length > 0 &&
            data.status_reason !== "Other"
        ) {
            status_reason = data.status_reason;
        }
    }

    return {
        ...data,
        babbageAccounts,
        defaultBranch: data?.defaultBranch || null,
        enabled,
        status_reason,
        lkqUserId: data?.userGroup === "trade" ? null : data.lkqUserId,
    };
};
