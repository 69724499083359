import React from "react";
import styled from "@emotion/styled";

import type { HTMLAttributes } from "react";

import { labelSizeDict } from "./variants";
import { twMerge } from "tailwind-merge";
import { CheckedIcon, UncheckedIcon } from "./icons";

const ToggleContainer = styled.div`
    input[type="checkbox"]:checked ~ div > *,
    input[type="checkbox"][aria-checked="mixed"] ~ div > * {
        left: calc(100% - 18px);

        &.slim {
            left: calc(100% - 14px);
        }
    }
`;

export type ToggleProps = HTMLAttributes<HTMLDivElement> & {
    id: string;
    disabled?: boolean;
    label?: string;
    isOn?: boolean;
    slim?: boolean;
    inputSize?: "md" | "lg";
    setIsOn?: any;
    showIcon?: boolean;
};

export const Toggle: React.FC<ToggleProps> = (props) => {
    const {
        id,
        disabled,
        label,
        slim,
        isOn = false,
        setIsOn,
        inputSize = "md",
        showIcon = false,
        ...restProps
    } = props;

    return (
        <ToggleContainer
            className="flex flex-row items-center gap-[8px]"
            data-testid="toggle"
            {...restProps}
        >
            <span
                className={`flex flex-col justify-center ${
                    disabled ? "opacity-30" : ""
                }`}
            >
                <label
                    className={`flex align-middle items-center mb-0 ${
                        disabled ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                >
                    <input
                        type="checkbox"
                        id={id}
                        className="border-0 h-[1px] w-[1px] -m-[1px] p-0 overflow-hidden whitespace-nowrap absolute"
                        style={{
                            clip: "rect(0px,0px,0px,0px)",
                        }}
                        disabled={disabled}
                        checked={isOn}
                        {...(setIsOn && {
                            onChange: function () {
                                if (!disabled) {
                                    setIsOn(!isOn);
                                }
                            },
                        })}
                        data-testid="toggle__checkbox"
                    />
                    <div
                        aria-hidden="true"
                        className={`relative inline-flex flex-start w-[36px] box-content items-center transition-all shrink-0 rounded-full border ${
                            isOn ? "bg-brand-success" : "bg-brand-grey"
                        } ${slim ? "h-[12px]" : "h-[20px]"}`}
                        data-testid="toggle__indicator"
                    >
                        <div
                            className={`absolute flex items-center justify-center bg-brand-white w-[16px] h-[16px] rounded-full transition-all  ${
                                slim ? "slim left-0" : "left-[2px]"
                            }`}
                            style={{
                                ...(slim && {
                                    boxShadow:
                                        "0px 1px 1px 0px rgba(48, 49, 51, 0.1), 0px 0px 1px 0px rgba(48, 49, 51, 0.05)",
                                }),
                            }}
                            data-testid="toggle__indicator--circle"
                        >
                            {showIcon ? (
                                <>
                                    {isOn ? <CheckedIcon /> : <UncheckedIcon />}
                                </>
                            ) : null}
                        </div>
                    </div>
                </label>
            </span>

            {label && (
                <label
                    htmlFor={id}
                    className={twMerge(
                        `text-left align-middle inline-block text-brand-black select-none cursor-pointer ${
                            disabled ? "cursor-not-allowed" : "cursor-pointer"
                        }`,
                        labelSizeDict[inputSize]
                    )}
                    data-testid="toggle__label"
                >
                    {label}
                </label>
            )}
        </ToggleContainer>
    );
};
