import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Checkbox,
    Heading,
    DateRange,
    Select,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

import dayjs from "dayjs";

const schema = yup.object().shape({
    babbageAccountNumber: yup.string().required(),
    babbageBranchId: yup
        .number()
        .required()
        .typeError("Branch ID must be a number"),
    dateFrom: yup.string().required(),
    dateTo: yup.string().required(),
    country: yup.string().required("Trading Country is required."),
});

export const GetInvoicesForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const [showTableData, setShowTableData] = useState(true);

    const [tableData, setTableData] = useState([]);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/get-invoices`,
                {
                    ...formData,
                    dateFrom: dayjs(formData.dateFrom).format("YYYY-MM-DD"),
                    dateTo: dayjs(formData.dateTo).format("YYYY-MM-DD"),
                    completed: formData?.completed ? "true" : "false",
                    outstanding: formData?.outstanding ? "true" : "false",
                },
                {
                    headers,
                }
            );

            setRequestData(data?.request);
            setResponseData(data?.rawResponse);
            setTableData(data?.response);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Get Invoices | Babbage Soap | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Get Invoices" />

                <Form
                    autoComplete="on"
                    values={{
                        babbageAccountNumber: "",
                        babbageBranchId: "",
                        dateFrom: new Date(
                            dayjs().subtract(30, "days").format("YYYY-MM-DD")
                        ),
                        country: "gb",
                        dateTo: new Date(),
                        maxRecs: 100,
                        completed: true,
                        outstanding: true,
                        download: false,
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                    enableReinitialize={false}
                >
                    <Group
                        key="get-invoices"
                        id="get-invoices"
                        name="Get Invoices"
                    >
                        <Select
                            name="country"
                            label="Trading Country"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "gb",
                                },
                                { label: "Ireland", value: "ie" },
                            ]}
                            testId="country"
                            required={true}
                        />

                        <Input
                            name="babbageAccountNumber"
                            label="Babbage Account Number"
                            help="Example: A0001"
                            testId="babbageAccountNumber"
                            required
                        />

                        <Input
                            name="babbageBranchId"
                            label="Branch ID"
                            help="246 for Wembley, 1401 for Dublin"
                            testId="babbageBranchId"
                            required
                        />
                        <DateRange
                            fromName="dateFrom"
                            fromLabel="Date From"
                            toName="dateTo"
                            toLabel="Date To"
                            required
                        />

                        <Input
                            name="maxRecs"
                            label="Max. Records"
                            testId="maxRecs"
                            type="number"
                        />

                        <Checkbox
                            name="completed"
                            label="Completed Invoices"
                            testId="completed-invoices"
                        />

                        <Checkbox
                            name="outstanding"
                            label="Outstanding Invoices"
                            testId="outstanding-invoices"
                        />

                        {/* <Checkbox
                            name="download"
                            label="Download as Zip"
                            help="Check to request a zip of the returned invoices"
                            testId="download"
                        /> */}
                        <button
                            className="btn btn-primary mt-2"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData?.length > 0 && !loading && (
                        <Group
                            key="request-details"
                            id="request-details"
                            name="Request Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Request to Babbage
                                </h4>{" "}
                                <CopyButton content={requestData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter code={requestData} />
                            </div>
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Response from Babbage
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter code={responseData} />
                            </div>
                        </Group>
                    )}

                    {tableData?.length > 0 && !loading && (
                        <Group
                            key="invoice-data"
                            id="invoice-data"
                            name="Invoice Data"
                        >
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Invoice Data
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton
                                        content={JSON.stringify(tableData)}
                                    />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTableData(!showTableData);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showTableData ? faMinus : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showTableData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showTableData && (
                                <table className="table table-sm table-fixed mt-3">
                                    <thead>
                                        <tr>
                                            <th>Document Number</th>
                                            <th>Document Date</th>
                                            <th>Due Date</th>
                                            <th>Value</th>
                                            <th>Outstanding</th>
                                            <th>Magic</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tableData.map((row, idx) => {
                                            return (
                                                <tr
                                                    key={
                                                        row?.documentnumber ||
                                                        idx
                                                    }
                                                >
                                                    <td>
                                                        {row?.documentnumber ||
                                                            "-"}
                                                    </td>
                                                    <td>
                                                        {row?.documentdate ||
                                                            "-"}
                                                    </td>

                                                    <td>
                                                        {row?.duedate || "-"}
                                                    </td>

                                                    <td>{row?.value || "-"}</td>

                                                    <td>
                                                        {row?.outstanding ||
                                                            "-"}
                                                    </td>

                                                    <td>{row?.magic || "-"}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

GetInvoicesForm.displayName = "GetInvoicesForm";
