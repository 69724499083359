import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";

import { GET_LIST, UPDATE, useClient } from "@peracto/client";
import { Link } from "react-router-dom";
import { HydraTable, Heading, Modal } from "@peracto/peracto-ui";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

export const BranchReassignmentList = () => {
    const { client } = useClient();

    const [cancelReassignment, setCancelReassignment] = useState();
    const [loading, setLoading] = useState(false);

    const onReassign = async () => {
        setCancelReassignment(null);
        setLoading(true);

        try {
            await client(UPDATE, "locations", {
                id: cancelReassignment,
                data: {
                    branchOverride: null,
                },
            });
            toast.success("Reassignment successfully removed!");
            setCancelReassignment(null);
        } catch (e) {
            console.error(e);
            toast.error("Unable to remove branch reassignment");
        } finally {
            setLoading(false);
        }
    };

    const fields = [
        {
            header: "Reassigned From",
            accessor: "name",
            show: true,
            selectable: false,
        },

        {
            header: "Reassigned to",
            accessor: "branchOverride",
            show: true,
            selectable: false,

            Cell: (row) => row.cell.value?.name || "-",
        },

        {
            header: (
                <div className="flex justify-end">
                    <span>Cancel Reassignment</span>
                </div>
            ),
            accessor: "id",
            show: true,
            selectable: false,
            Cell: (row) => {
                return (
                    <div className="flex justify-end">
                        <button
                            className="btn btn-danger"
                            onClick={() => {
                                setCancelReassignment(row.cell.value);
                            }}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                );
            },
        },
    ];

    const columns = [];

    fields.forEach(
        ({ header, accessor, selectable, show, id = null, Cell, Filter }) =>
            columns.push({
                Header: header,
                accessor: accessor,
                sortType: "basic",
                Filter: Filter ? Filter : null,
                Cell: Cell
                    ? Cell
                    : ({ cell: { value } }) => (value ? value : "-"),
                selectable: selectable,
                show: show,
                id,
            })
    );

    const onFetchData = (options) => client(GET_LIST, "locations", options);

    return (
        <>
            <Helmet>
                <title>Temporary Branch Reassignment | Peracto</title>
            </Helmet>
            <Heading name="Temporary Branch Reassignment">
                <div className="d-flex align-items-center ml-2">
                    <Link
                        className="btn btn-primary"
                        to="/branch-reassignment/add"
                        data-testid="add-reassignment"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Reassignment
                    </Link>
                </div>
            </Heading>

            {!loading && (
                <div className="row">
                    <div className="col">
                        <div className="card card--transparent">
                            <div className="card-body">
                                <HydraTable
                                    columns={columns}
                                    onFetchData={onFetchData}
                                    alwaysFilter={{
                                        "exists[branchOverride]": true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal
                isVisible={!!cancelReassignment}
                title="Cancel Reassignment"
                close={() => setCancelReassignment(null)}
                buttons={[
                    {
                        type: "btn-outline-secondary",
                        text: "Close",
                        action: () => setCancelReassignment(null),
                    },
                    {
                        type: "btn-danger",
                        text: "Cancel Reassignment",
                        action: () => onReassign(),
                    },
                ]}
            >
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    size="4x"
                    className="d-block mb-4"
                />
                Are you sure you would like to cancel this reassignment?
            </Modal>
        </>
    );
};
