/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import {
  Form,
  Group,
  Input,
  Textarea,
  Checkbox,
  Select,
  File,
} from "@peracto/peracto-ui";

import AttributeOptionList from "./AttributeOptionList";
import { inputOptions } from "../util";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const AttributeForm = ({ mode = MODE_EDIT, values, ...props }) => {
  const [inputType, setInputType] = useState("text");
  const initialInputType = values ? values.input : "text";

  useEffect(() => {
    setInputType(values?.input || initialInputType);
  }, [values]);

  const defaultValueInputTypes = ["text", "integer"];
  const optionListInputTypes = ["select", "multiselect", "swatch"];

  const dataTypes = {
    text: "string",
    textarea: "string",
    select: "string",
    textarea_rte: "string",
    swatch: "string",
    yesno: "string",
    multiselect: "array",
    integer: "integer",
  };

  return (
    <Form
      autoComplete="off"
      values={{
        label: "",
        code: "",
        input: "text",
        productSpecSortOrder: 10,
        filteringOperator: "or",
        defaultValue: "",
        ...values,
      }}
      onReset={(values) => {
        setInputType(values.input || "text");
      }}
      {...props}
    >
      <div data-testid="attribute-properties">
        <Group
          key="attribute-properties"
          id="attribute-properties"
          name="Attribute Properties"
        >
          <Field name="label">
            {({ form, field }) => (
              <Input
                name={field.name}
                testId="attribute-label"
                label="Label"
                help="Displayed when editing and viewing product on the front end."
                required
                autoComplete={field.name}
                onBlur={() => {
                  if (field.value.length > 0) {
                    const code = field.value
                      .split(" ")
                      .join("_")
                      .replace(/[`~!@#$£€%^&*|+=?;:'",.()<>\{\}\[\]\\\/]/gi, "")
                      .replace(/-+/g, "-")
                      .replace(/_+/g, "_")
                      .toLowerCase();

                    if (!form.values.code) {
                      form.setFieldValue("code", code);
                    }
                  }
                }}
              />
            )}
          </Field>
          <Input
            name="code"
            testId="attribute-code"
            label="Code"
            help="Uniquely identifies the attribute within Peracto. Alphanumeric lowercase string e.g. 'width_mm'"
            required
          />

          <Textarea
            name="description"
            testId="attribute-description"
            label="Description"
            help="Description of the attribute displayed to assist the Peracto user when adding/editing product."
          />

          <File
            label="Image"
            name={`icon`}
            placeholder={`Please select an image for this attribute`}
            help="Makes an image available to the Storefront for this Attribute."
            testId="attribute-image"
          />
        </Group>
      </div>

      <div data-testid="attribute-options">
        <Group
          key="attribute-options"
          id="attribute-options"
          name="Attribute Options"
        >
          <Select
            name="input"
            label="Input Type"
            testId="attribute-input-type"
            options={inputOptions.inputTypes}
            help="Type of input displayed to the Peracto user when adding/editing a product."
            handleChange={(value) => setInputType(value)}
            required
          />

          {dataTypes[initialInputType] !== dataTypes[inputType] &&
            mode === MODE_EDIT && (
              <div
                className="alert alert-danger"
                data-testid="option-change-warning"
              >
                This Attribute Input Type change modifies the way data is
                stored. To prevent data issues all product values for this
                attribute will need re-importing/updating after the change. Only
                proceed if you have a backup of data available.
              </div>
            )}

          {defaultValueInputTypes.includes(inputType) && (
            <Input
              name="defaultValue"
              testId="attribute-default-value"
              label="Default Value"
              help="Displayed when editing and viewing a product on the storefront."
              autoComplete="label"
            />
          )}

          {optionListInputTypes.includes(inputType) && (
            <AttributeOptionList name="options" inputType={inputType} />
          )}
        </Group>
      </div>

      <div data-testid="attribute-settings">
        <Group
          key="attribute-settings"
          id="attribute-settings"
          name="Attribute Settings"
        >
          <Checkbox
            name="public"
            label="Make this attribute available to your storefront"
          />
          <Checkbox
            name="availableInProductSearch"
            label="Make this attribute available to the storefront product search"
          />
          <Checkbox
            name="required"
            label="Require a value for this attribute"
          />

          <Input
            name="googleFeedName"
            label="Google Feed Name"
            testId="google-feed-name"
            tooltip="The value provided will be used in the Google product feed for products that have a value. Consult the Google Product data specification to ensure their requirements are met."
          />

          <Checkbox
            name="allowedInPromotions"
            label="Allow this attribute to be used as a promotion condition"
          />
          <Checkbox
            name="allowedInShipping"
            label="Allow this attribute to be used as a shipping restriction"
          />
          <Checkbox
            name="includeInPartDetails"
            label="Include in part details"
          />
          <Input name="adsCode" label="Ads Code" testId="ads-code" />
        </Group>
      </div>

      <div data-testid="attribute-display">
        <Group
          key="attribute-display"
          id="attribute-display"
          name="Attribute Display"
        >
          <Checkbox
            name="includeInProductSpec"
            label="Display within the product specification"
          />
          <Input
            showWhen="includeInProductSpec"
            name="productSpecSortOrder"
            label="Specification display order"
            testId="spec-display-order"
            help="Used when attributes are displayed within the product specification. Attributes are ordered by ascending display order."
            required
          />

          <Checkbox
            name="filterable"
            label="Allow product filtering by this attribute"
          />

          <Select
            showWhen="filterable"
            name="filteringOperator"
            label="Filtering Operator"
            testId="filter-operator"
            options={inputOptions.filteringOperators}
            help="Determines whether the attribute is returned on the editing form for products of these types.
                        'OR' (default) will expand the product result set when values are checked e.g. Colour = 'Blue' OR 'Red'
                        'AND' will reduce the result set when values are checked e.g. Colour = 'Blue' AND 'Red'"
            required
          />
        </Group>
      </div>
    </Form>
  );
};

AttributeForm.displayName = "AttributeForm";

AttributeForm.propTypes = {
  values: PropTypes.object,
  mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AttributeForm;
