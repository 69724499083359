import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Field } from "formik";
import { toast } from "react-toastify";
import { Modal, Select } from "@peracto/peracto-ui";
import axios from "axios";

import { useConfig } from "@peracto/peracto-config";

import { UPDATE, useClient } from "@peracto/client";

const REASSIGNMENT_REASONS = ["Incorrect Branch ID", "Other"];

export const ReassignApplication = (props) => {
    const { canReassignToCustomerService, canReassignBranch, branches } = props;
    const config = useConfig();

    const API_URL = config.get("api");

    const { id } = useParams();
    const history = useHistory();

    const [showReassignmentModal, setShowReassignmentModal] = useState(false);
    const [reassignmentReason, setReassignmentReason] = useState("");
    const [reassignmentReasonMessage, setReassignmentReasonMessage] =
        useState("");

    const [reassignmentBranch, setReassignmentBranch] = useState("");

    const { client } = useClient();

    const onReassignBranch = async (data, actions) => {
        try {
            const response = await client(UPDATE, "user-applications", {
                id: data["@id"],
                data: { branch: reassignmentBranch },
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(
                        `${error.propertyPath}`,
                        error.message
                    );
                });
            } else {
                toast.success("User application branch reassigned!");
                setTimeout(() => {
                    history.go(0);
                }, 2000);

                actions.setSubmitting(false);
            }
        } catch (e) {
            console.error(e);

            // eslint-disable-next-line no-unused-expressions
            e?.error?.body?.violations?.forEach((error) => {
                actions.setFieldError(`${error.propertyPath}`, error.message);
            });

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);
        }
    };

    const onReassignToCustomerService = async () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            await axios.put(
                `${API_URL}/user-applications/reassign_to_customer_service/${id}`,
                {
                    reason:
                        reassignmentReason === "Other"
                            ? reassignmentReasonMessage
                            : reassignmentReason,
                },
                {
                    headers,
                }
            );

            toast.success("User application reassigned!");

            setShowReassignmentModal(false);

            setTimeout(() => {
                history.go(0);
            }, 2000);
        } catch (e) {
            console.error(e);

            toast.error("Whoops, there was a problem...");
        }
    };

    return (
        <>
            <div className="flex flex-col items-start">
                <Field name="name">
                    {({ form }) => {
                        return (
                            <>
                                {(canReassignToCustomerService ||
                                    canReassignBranch) && (
                                    <p className="font-semibold text-danger mb-2">
                                        {`Please note that any edits or updates
                                        you have made to this application will
                                        be lost when reassigning it to ${
                                            canReassignToCustomerService
                                                ? "customer service"
                                                : ""
                                        } ${
                                            canReassignToCustomerService &&
                                            canReassignBranch
                                                ? "or to"
                                                : ""
                                        } ${
                                            canReassignBranch
                                                ? "a different branch"
                                                : ""
                                        }`}
                                    </p>
                                )}

                                {canReassignToCustomerService && (
                                    <>
                                        <h6>Reassign to Customer Service</h6>

                                        <button
                                            className="btn btn-success mb-2"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowReassignmentModal(true);
                                            }}
                                        >
                                            Reassign To Customer Service
                                        </button>
                                    </>
                                )}
                                {canReassignBranch && (
                                    <>
                                        {canReassignToCustomerService && (
                                            <hr className="w-full my-2" />
                                        )}
                                        <h6>Reassign to New Branch</h6>
                                        <Select
                                            name="branch-reassign"
                                            className="w-1/2 text-left"
                                            options={branches}
                                            required
                                            placeholder="Select Branch"
                                            testId="reassignment-branch"
                                            testIdItems="reassignment-branch__item"
                                            testIdIndex={0}
                                            handleChange={(branch) => {
                                                setReassignmentBranch(branch);
                                            }}
                                        />

                                        <button
                                            className="btn btn-danger"
                                            disabled={!reassignmentBranch}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (reassignmentBranch) {
                                                    const {
                                                        values,
                                                        ...actions
                                                    } = form;

                                                    onReassignBranch(
                                                        values,
                                                        actions
                                                    );
                                                }
                                            }}
                                        >
                                            Reassign Branch
                                        </button>
                                    </>
                                )}
                            </>
                        );
                    }}
                </Field>
            </div>

            <Modal
                isVisible={showReassignmentModal}
                title="Reassign Application"
                close={() => setShowReassignmentModal(false)}
                buttons={[
                    {
                        type: "btn-outline-secondary",
                        text: "Cancel",
                        action: () => setShowReassignmentModal(false),
                    },
                    {
                        type: "btn-success",
                        text: "Reassign Application",
                        action: () => onReassignToCustomerService(),
                        disabled:
                            !reassignmentReason ||
                            (reassignmentReason === "Other" &&
                                !reassignmentReasonMessage),
                    },
                ]}
            >
                <p className="text-left">
                    Please select a reason for reassign this application to
                    Customer Service.
                </p>

                <Select
                    name="reason"
                    className="w-full text-left"
                    options={REASSIGNMENT_REASONS.map((reason) => {
                        return {
                            label: reason,
                            value: reason,
                        };
                    })}
                    required
                    placeholder="Select Reassignement Reason"
                    testId="reassignment-reason"
                    testIdItems="reassignment-reason__item"
                    testIdIndex={0}
                    handleChange={(reason) => {
                        setReassignmentReason(reason);
                    }}
                />

                {reassignmentReason === "Other" && (
                    <textarea
                        className="w-full form-control"
                        value={reassignmentReasonMessage}
                        onChange={(e) =>
                            setReassignmentReasonMessage(e.target.value)
                        }
                    />
                )}
            </Modal>
        </>
    );
};
