import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import * as yup from "yup";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";
import {
    GET_ONE,
    UPDATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";

import { formatDataForForm } from "../util";

const CategoryEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit Category">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary"
                        to="/categories"
                        data-testid="back-to-categories"
                    >
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Categories
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

const CategoryEdit = ({ CategoryForm, location: { pathname } }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();

    const [category, setCategory] = useState();

    const fetchCategory = async () => {
        try {
            const { data, response } = await client(GET_ONE, "categories", {
                id: pathname,
            });

            // To deal with the fixtures we're using...
            if (Array.isArray(data.options) || !data.options.attributes) {
                data.options = {
                    ...data.options,
                    attributes: [],
                };
            }

            const formattedData = formatDataForForm(data);

            setCategory(formattedData);
            setLoading(false);

            if (response.status === 404) {
                setRedirect("/categories");
            }
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setLoading(false);
            setRedirect("/categories");
        }
    };

    useEffect(() => {
        fetchCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = getSchemaFromResource(getResource("categories")).shape({
        type: yup.string().when("template", (template, schema) => {
            return template === "Product List"
                ? schema.required()
                : schema.notRequired();
        }),
        metaDescription: yup.string().nullable(),
        heading: yup.string().required(),
        identifier: yup.string().required(),
        metaTitle: yup.string().required(),
        sortOrder: yup
            .number()
            .required()
            .typeError("Sort Order must be a number"),
    });

    const onSubmit = async (data, actions) => {
        try {
            const response = await client(UPDATE, "categories", {
                id: pathname,
                data: {
                    ...data,
                    sortOrder: parseInt(data.sortOrder),
                },
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            } else {
                actions.setSubmitting(false);
                const formattedData = formatDataForForm(response.data);
                setCategory(formattedData);
                toast.success("Category successfully updated!");
            }
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }
            actions.setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <CategoryEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </CategoryEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/categories"
                message="The category you're looking for could not be found"
                buttonLabel="Go to Categories"
            />
        );
    }

    return (
        <CategoryEditContainer>
            {redirect && <Redirect to={redirect} />}
            <Helmet>
                <title>{category.name || "Category"} | Edit | Peracto</title>
            </Helmet>

            <CategoryForm
                values={category}
                onSubmit={onSubmit}
                schema={schema}
            />
        </CategoryEditContainer>
    );
};

export default CategoryEdit;
