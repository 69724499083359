import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Form, Group, Input, Heading, LoadingIcon } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    accountNumber: yup.string().required(),
    ref: yup.string().required(),
});

export const WismoOrderRequestForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState("");
    const [notFound, setNotFound] = useState(false);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);
        setNotFound(false);
        setResponseData("");

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/wismo-order`,
                formData,
                {
                    headers,
                }
            );

            setResponseData(JSON.stringify(data));
        } catch (e) {
            console.error(e);
            setNotFound(true);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Order Request | Wismo | Web Services | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Wismo Order Request" />

                <Form
                    autoComplete="on"
                    values={{
                        accountNumber: "",
                        ref: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="babbage-part-details"
                        id="babbage-part-details"
                        name="Babbage Part Details"
                    >
                        <Input
                            id="babbage_account_number"
                            name="accountNumber"
                            label="Babbage Account Number"
                            help="Example: A0001"
                            testId="babbageAccountNumber"
                            required
                        />

                        <Input
                            id="order_number"
                            name="ref"
                            label="Order Number"
                            testId="orderNumber"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="order-details"
                            id="order-details"
                            name="Order Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Order Details
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={responseData}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}

                    {notFound && !loading && (
                        <p className="text-center py-4">No order data found</p>
                    )}
                </Form>
            </div>
        </>
    );
};

WismoOrderRequestForm.displayName = "WismoOrderRequestForm";
