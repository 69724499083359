import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Form, Group, Input, Heading, LoadingIcon } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

import dayjs from "dayjs";

const schema = yup.object().shape({
    reference: yup.string().required(),
});

export const WebServiceLogsForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState({
        fileName: "",
        timestamp: "",
    });
    const [responseData, setResponseData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.get(
                `${API_URL}/test-harness/babbage-order-xml/${formData.reference}`,
                {
                    headers,
                }
            );

            setFileData({
                fileName: data?.fileName,
                timestamp: data?.timestamp,
            });
            setResponseData(data?.xml);
        } catch (e) {
            console.error(e);

            if (e.response.status === 404) {
                toast.error("Reference not found");
            } else {
                toast.error(
                    e?.error?.body?.hasOwnProperty("hydra:description")
                        ? e.error.body["hydra:description"]
                        : "There was an error submitting this form."
                );
            }
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Web Service Logs | Web Services | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Web Service Logs" />

                <Form
                    autoComplete="on"
                    values={{
                        reference: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group key="reference" id="reference" name="Reference">
                        <Input
                            name="reference"
                            label="Search by Reference"
                            testId="reference"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {(!!fileData?.fileName || !!fileData?.timestamp) &&
                        !loading && (
                            <Group
                                key="request-details"
                                id="request-details"
                                name="Request Details"
                            >
                                <table className="table table-striped table-sm table-fixed mt-3">
                                    <tbody>
                                        {fileData?.timestamp?.length > 0 && (
                                            <tr>
                                                <td>Request Timestamp</td>
                                                <td>
                                                    {dayjs(
                                                        fileData.timestamp
                                                    ).format(
                                                        "DD MMM YYYY HH:mm:ss"
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {fileData?.fileName?.length > 0 && (
                                            <tr>
                                                <td>Request Filename</td>
                                                <td>{fileData.fileName}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Group>
                        )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Web Service Log Data
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={responseData}
                                    language="xml"
                                />
                            </div>
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

WebServiceLogsForm.displayName = "WebServiceLogsForm";
