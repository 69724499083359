import React, { useEffect, useState } from "react";
import {
    IconSearch,
    IconCircleXFilled,
    IconCircleCheckFilled,
} from "@tabler/icons-react";

import Select from "react-select";

import { GET_LIST, GET_ONE, useClient } from "@peracto/client";

import { accountTypes } from ".";

export const AddBabbageAccount = (props) => {
    const { push, field } = props;

    const { client } = useClient();

    const [accountToSearch, setAccountToSearch] = useState("");
    const [accountToAdd, setAccountToAdd] = useState({
        accountNumber: "",
        branch: {},
    });

    const [accountNotFound, setAccountNotFound] = useState(false);

    const [branches, setBranches] = useState([]);

    const fetchBranches = async () => {
        const { data } = await client(GET_LIST, "locations", {
            id: "locations",
            filter: {
                itemsPerPage: 10000,
            },
        });

        const values = data.map((val) => ({
            label: `${val.name} - ${val.ecpBranchId}`,
            value: val,
        }));

        setBranches(values);
    };

    const fetchBabbageAccount = async () => {
        setAccountNotFound(false);
        setAccountToAdd({
            accountNumber: "",
            branch: {},
        });

        try {
            const { data } = await client(GET_ONE, `babbage-account-data`, {
                id: `babbage-account-data/${accountToSearch}`,
            });

            const branch = branches.find(
                (branch) => `${branch.value.ecpBranchId}` === data.branchCode
            )?.value;

            delete data["@id"];
            delete data["@type"];

            setAccountToAdd({
                ...data,
                accountNumber: data?.orderPointAccount,
                branch: {
                    ...branch,
                    id: parseInt(branch?.["@id"].replaceAll("/locations/", "")),
                },
            });
        } catch (e) {
            console.error(e);
            setAccountNotFound(true);
        }
    };

    const accountToAddExists = field?.value?.some((value) => {
        return (
            value?.babbageAccount?.accountNumber ===
                accountToAdd.accountNumber &&
            value?.babbageAccount?.branch?.id === accountToAdd?.branch?.id
        );
    });

    useEffect(() => {
        if (branches.length === 0) {
            fetchBranches();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [branches]);

    return (
        <>
            <div>
                <h3 className="text-[0.8rem] border-b border-b-[#f0f0f0] pb-2 mb-2 uppercase font-medium">
                    Add Account
                </h3>

                <p className="text-[#606060] mb-1">Search for an account</p>
                <p className="text-[#aaa] text-[14px] mb-1">
                    Please enter the account number you wish to add. Note: The
                    search is case sensitive. Maximum of 8 characters.
                </p>

                <div className="flex flex-row items-center">
                    <div className="form-group relative flex-1 mb-0 mr-4">
                        <IconSearch className="h-[20px] w-[20px] absolute left-2 top-2 text-[#aaa]" />
                        <input
                            type="text"
                            className="form-control pl-8"
                            value={accountToSearch}
                            onChange={(e) => {
                                setAccountToSearch(e.target.value);
                            }}
                            onKeyDown={(keyEvent) => {
                                if (
                                    (keyEvent.charCode || keyEvent.keyCode) ===
                                    13
                                ) {
                                    keyEvent.preventDefault();
                                    if (
                                        accountToSearch &&
                                        accountToSearch.length <= 8
                                    ) {
                                        fetchBabbageAccount();
                                    }
                                }
                            }}
                            onBlur={() => {
                                setAccountToSearch(
                                    accountToSearch
                                        .replace(/[^A-Za-z0-9]/g, "")
                                        .slice(0, 8)
                                );
                            }}
                        />

                        {accountToSearch.length > 0 && (
                            <button
                                className="absolute right-2 top-2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAccountToSearch("");
                                    setAccountToAdd({
                                        accountNumber: "",
                                        branch: {},
                                    });
                                }}
                            >
                                <IconCircleXFilled className="h-[20px] w-[20px]  text-[#aaa]" />
                            </button>
                        )}
                    </div>

                    <button
                        className="btn btn-primary"
                        disabled={
                            !accountToSearch || accountToSearch.length > 8
                        }
                        onClick={(e) => {
                            if (
                                accountToSearch &&
                                accountToSearch.length <= 8
                            ) {
                                e.preventDefault();
                                fetchBabbageAccount();
                            }
                        }}
                    >
                        Search
                    </button>
                </div>
            </div>

            {accountNotFound && (
                <p className="text-center py-4 mb-0">
                    Sorry, this Babbage account number does not exist
                </p>
            )}

            {accountToAdd?.accountNumber && (
                <div>
                    <div className="flex flex-row gap-4 p-3 border !border-b-0 border-[#f0f0f0] mt-4">
                        <div className="flex flex-row flex-grow gap-4">
                            <div className="form-group mb-0 flex-1">
                                <label className="flex">
                                    <IconCircleCheckFilled className="text-brand-success h-[20px] w-[20] mr-2" />
                                    Account Number
                                </label>

                                <input
                                    className="form-control"
                                    readOnly
                                    disabled
                                    value={accountToAdd.accountNumber}
                                />
                            </div>

                            <div className="flex-1">
                                <label>Branch</label>

                                <Select
                                    classNamePrefix="list"
                                    options={branches}
                                    isDisabled={!!accountToAdd?.restrictToOwner}
                                    isSearchable={true}
                                    onChange={(option) => {
                                        const branch = {
                                            ...option.value,
                                            id: option.value.originId,
                                        };

                                        setAccountToAdd({
                                            ...accountToAdd,
                                            branch,
                                        });
                                    }}
                                    value={
                                        accountToAdd?.branch?.name
                                            ? {
                                                  label: `${accountToAdd.branch.name} - ${accountToAdd.branch.ecpBranchId}`,
                                                  value: "",
                                              }
                                            : null
                                    }
                                    placeholder="Please select a branch..."
                                    noOptionsMessage={({ inputValue }) => {
                                        if (inputValue.length > 0) {
                                            return `No results found for '${inputValue}'.`;
                                        } else {
                                            return "Enter text to begin searching.";
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex-[115px] grow-0 self-end">
                            <button
                                className="btn btn-primary h-[38px]"
                                disabled={
                                    !accountToAdd.accountNumber ||
                                    !accountToAdd.branch?.id ||
                                    accountToAddExists
                                }
                                onClick={(e) => {
                                    e.preventDefault();

                                    if (
                                        accountToAdd.accountNumber &&
                                        accountToAdd.branch?.id &&
                                        !accountToAddExists
                                    ) {
                                        push({
                                            babbageAccount: accountToAdd,
                                            isDefault:
                                                field?.value?.length === 0,
                                        });

                                        setAccountToAdd({
                                            accountNumber: "",
                                            branch: {},
                                        });

                                        setAccountToSearch("");
                                    }
                                }}
                            >
                                Add Account
                            </button>
                        </div>
                    </div>

                    <table className="table table-sm table-fixed">
                        <tbody>
                            <tr className="border-x">
                                <td className="px-3">
                                    <strong>Account Level Type:</strong>
                                </td>
                                <td className="px-3">
                                    <span className="flex justify-between">
                                        <span>
                                            {accountToAdd?.accountLevelType ||
                                                "N/A"}
                                        </span>
                                        <span className="text-[#aaa] text-[14px] italic">
                                            I = Invoice, O = Order Point, S =
                                            Statement, M = Main
                                        </span>
                                    </span>
                                </td>
                            </tr>

                            <tr className="border-x">
                                <td className="px-3">
                                    <strong>Main Account Number:</strong>
                                </td>
                                <td className="px-3">
                                    {accountToAdd?.mainAccountNumber || "N/A"}
                                </td>
                            </tr>

                            <tr className="border-x">
                                <td className="px-3">
                                    <strong>Statement Account Number:</strong>
                                </td>
                                <td className="px-3">
                                    {accountToAdd?.statementAccountNumber ||
                                        "N/A"}
                                </td>
                            </tr>

                            <tr className="border-x">
                                <td className="px-3">
                                    <strong>Invoice Account Number:</strong>
                                </td>
                                <td className="px-3">
                                    {accountToAdd?.invoiceAccountNumber ||
                                        "N/A"}
                                </td>
                            </tr>

                            <tr className="border-x">
                                <td className="px-3">
                                    <strong>Customer Name:</strong>
                                </td>
                                <td className="px-3">
                                    {accountToAdd?.customerName || "N/A"}
                                </td>
                            </tr>

                            <tr className="border-x border-b">
                                <td className="px-3">
                                    <strong>Account Type:</strong>
                                </td>
                                <td className="px-3">
                                    {accountToAdd.accountType
                                        ? accountTypes[accountToAdd.accountType]
                                        : "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
