import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faCloudDownloadAlt } from "@fortawesome/pro-regular-svg-icons/faCloudDownloadAlt";
import { Helmet } from "react-helmet";
import axios from "axios";
import download from "downloadjs";
import { Heading, Group, Form, Checkbox } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

export const UserExport = () => {
    const config = useConfig();
    const API_URL = config.get("api");
    const { user } = config.get("features", {});

    const [redirect, setRedirect] = useState();

    useEffect(() => {
        if (!user?.exportUserData) {
            setRedirect("/users");
        }
    }, [user]);

    // Prevent enter key submitting form
    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    const handleSubmit = async (type, values = {}) => {
        const filteredValues = {};

        for (const [field, value] of Object.entries(values)) {
            if (!!value) {
                filteredValues[field] = value;
            }
        }

        let filename = `${type}.csv?${new URLSearchParams(
            filteredValues
        ).toString()}`;

        const { data } = await axios.get(`${API_URL}/export/${filename}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });

        const filenameDetails = Object.keys(filteredValues).join("_");

        download(
            data,
            `${type}_export${filenameDetails ? `_${filenameDetails}` : ""}.csv`
        );
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <Helmet>
                <title>Export User Data | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Export User Data">
                    <div className="d-flex align-items-center ml-2">
                        <Link
                            className="btn btn-outline-primary"
                            to="/users"
                            data-testid="export-user-data"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Users
                        </Link>
                    </div>
                </Heading>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <Group key="users" id="users" name="Users">
                            <Form
                                showActionPanel={false}
                                onSubmit={() => handleSubmit("user")}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-users"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Users
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>

                    <div className="col-12 col-lg-6">
                        <Group key="addresses" id="addresses" name="Addresses">
                            <Form
                                values={{
                                    default: false,
                                }}
                                showActionPanel={false}
                                onSubmit={(values) =>
                                    handleSubmit("address", values)
                                }
                                onKeyDown={onKeyDown}
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <Checkbox
                                            name="default"
                                            label="Default Addresses Only"
                                        />

                                        <button
                                            className="btn btn-outline-primary"
                                            type="submit"
                                            data-testid="export-addresses"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCloudDownloadAlt}
                                                className="mr-2"
                                            />
                                            Export Addresses
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Group>
                    </div>
                </div>
            </div>
        </>
    );
};
