/* eslint-disable */
import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import * as yup from "yup";

import { Heading } from "@peracto/peracto-ui";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";
import { formatDataForAPI } from "../util";

import { MODE_ADD } from "../AttributeForm";

const AttributeAdd = ({ AttributeForm }) => {
  const { client, getResource } = useClient();
  const [redirect, setRedirect] = useState();

  const schema = getSchemaFromResource(getResource("attributes")).shape({
    dataType: yup.string().notRequired(),
    locale: yup.string().notRequired(),
  });

  const onSubmit = async (data, actions) => {
    try {
      const response = await client(CREATE, "attributes", {
        data: formatDataForAPI(data),
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        setRedirect(response.data.id);
        toast.success("Attribute successfully added!");
        actions.setSubmitting(false);
      }
    } catch (e) {
      console.error(e);
      if (e?.error?.body?.violations && e.error.body.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      actions.setSubmitting(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <Helmet>
        <title>Attributes | Add | Peracto</title>
      </Helmet>
      <div className="form-container">
        <Heading name="Add Attribute">
          <div className="flex-grow-1 d-flex align-items-center justify-content-end">
            <Link
              className="btn btn-outline-primary"
              to="/attributes"
              data-testid="back-to-attributes"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Attributes
            </Link>
          </div>
        </Heading>
        <AttributeForm onSubmit={onSubmit} mode={MODE_ADD} schema={schema} />
      </div>
    </>
  );
};

export default AttributeAdd;
