import lazyLoad from '@loadable/component'

import createPlugin from './createPlugin'

import TextRenderer from './Renderer/TextRenderer'

const TextControls = lazyLoad(() => import('./Controls/TextControls'))

const textPlugin = settings =>
    createPlugin({
        Renderer: TextRenderer,
        Controls: TextControls,
        ...settings,
    })

const text = textPlugin()
export default text

export { textPlugin }
