export const menuData = {
    header: [
        {
            "@id": "/storefront-menu-items-output-dtos/bc7f7db5-0f56-402f-9e8d-9bb885617e08",
            "@type": "StorefrontMenuItemsOutputDto",
            categoryName: "Car Parts",
            categorySlug: "car-parts",
            children: [
                {
                    "@id": "/storefront-menu-items-output-dtos/a8217d23-f5e7-487e-8921-d7052c7b5d3e",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Air Conditioning",
                    categorySlug: "air-conditioning",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/58857504-9142-4820-b6aa-bc81629d2fe2",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Air Conditioning",
                            categorySlug: "air-conditioning-t3",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0c64a378-f38d-408f-b74c-a25cd121f85c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Actuators",
                                    categorySlug: "air-conditioning-actuator",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/60ab99be-35c6-4431-83cc-3034ee4ce82f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pipes & Hoses",
                                    categorySlug: "airconditiong-pipes-hoses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/06937df0-8153-4e39-97c6-21b38ae36477",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Accumulators & Receiver Driers",
                                    categorySlug:
                                        "accumulators-and-receiver-dryer",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/eb1b6db5-77d8-4ea5-9635-6800251223d4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Compressors",
                                    categorySlug: "car-compressor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aa4bdd1e-f1c9-4dae-863a-8c2995ee978d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Condensers",
                                    categorySlug: "air-conditioning-condenser",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9e3d39a2-2a23-433e-a3fd-585816ae15d9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Evaporators",
                                    categorySlug: "car-evaporator",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ef5c36e2-16a7-466f-934c-8990247d3839",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Switches & Sensors",
                                    categorySlug:
                                        "airconditioning-switch-sensors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c6c8336c-d42f-4023-a10e-edff03502605",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Expansion Valves",
                                    categorySlug: "expansion-valve",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/ac71e2d5-aa50-437c-b837-08a5d7855fd5",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Belts & Chains",
                    categorySlug: "belts-and-chains",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/cfd6188d-1000-440b-89e7-355cac9eef52",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Belts Chains & Tensioners",
                            categorySlug: "belts-chains-and-tensioners",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b73e1009-abd8-4e05-ba77-871056f24847",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drive Belts",
                                    categorySlug: "car-drive-belt",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/17963f52-12e9-4afa-9e92-7a2fc12aea4b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Belts",
                                    categorySlug: "timing-belts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/79081b94-ab19-4f21-86f3-5b4831368c2c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Chains",
                                    categorySlug: "timing-chain",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1302f10e-99e5-4e9f-9b62-70596317eea3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Tensioners Idlers & Dampers",
                                    categorySlug:
                                        "tensioners-idlers-and-dampers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5674ea4c-f132-497c-8c2a-466b5c86e877",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Belt Kits",
                                    categorySlug: "timing-belt-kit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5680b772-15a7-4e84-8ff4-eed56fe4057d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Chain and Tensioner Parts",
                                    categorySlug: "timing-chain-guide-rail",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b6ff2d05-3f92-4258-9846-5a82c9ac9f06",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Timing Belt Guide & Deflection Pulley",
                                    categorySlug:
                                        "timing-belt-guide-and-deflection-pulley",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0caf1272-8e95-4a71-b8b1-35c17ae62dd4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Timing Belt Kit & Water Pump",
                                    categorySlug:
                                        "timing-belt-kit-and-water-pump",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/5de7df47-55b2-45a6-9876-f53ec2a1062b",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Braking",
                    categorySlug: "braking",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/0b2767b5-d69c-41ab-992a-01182ba2141e",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Brake Friction",
                            categorySlug: "brake-friction",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c72b8473-b18a-4fe2-b940-2b47a8c9c89a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Handbrake Cables",
                                    categorySlug: "handbrake-cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/01105494-6ecd-47c3-a446-eafc9a975656",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Discs",
                                    categorySlug: "brake-disc",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8b710f0f-ef55-488c-abba-47896e5aa70d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Drums",
                                    categorySlug: "handbrake-drum",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/dd1e9596-dfb7-444e-a79e-372c7f2d3ffb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories & Fitting Kits",
                                    categorySlug:
                                        "brake-accessories-and-brake-fitting-kit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b7530780-86c2-456c-88c1-d1371861b4bd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Pad Wear Sensors",
                                    categorySlug: "brake-pad-sensor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b43e3635-e53b-46f6-a8d7-05de6c1242f2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Pads",
                                    categorySlug: "brake-pad",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8ec3cd99-a496-4bc3-aab2-0f066415906d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Shoes",
                                    categorySlug: "brake-shoes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/cc6cf98c-0833-4a5c-863e-9cc98bc01002",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Handbrake Shoes",
                                    categorySlug: "handbrake-shoes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/466010ae-1c48-4a2a-896b-67905379b9b8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "ABS Ring",
                                    categorySlug: "abs-rings",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/7e3b0ff0-d3f6-4175-9727-f64eeeacc43e",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Brake Hydraulics",
                            categorySlug: "brake-hydraulics",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b064d4b9-290c-48a6-9dfc-eead8a271e52",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "ABS Hydraulic Units",
                                    categorySlug: "abs-hydraulic-unit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/40508583-838b-44b7-9ebd-069d43eeb975",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Calipers",
                                    categorySlug: "brake-caliper",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ae17642b-8a7e-4c60-b0da-8c87a5230c06",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hoses & Pipes",
                                    categorySlug: "brake-hose-and-brake-pipe",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/19578843-f826-4b22-9abf-073e12b07578",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Servos",
                                    categorySlug: "brake-servo",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2451a795-0af3-45c8-b49d-2ce67c18dd65",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Master Cylinders",
                                    categorySlug: "master-cylinder",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d0694f8d-0ffc-406a-b74a-5f8bf15f4bbc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wheel Cylinders",
                                    categorySlug: "wheel-cylinder",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/541a6553-3912-4659-a020-47bd66f2705b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Brake Hydraulics",
                                    categorySlug: "other-brake-hydraulics",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bf8d0b6e-200c-4eaf-b040-9366041ee44f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Valves & Pushrods",
                                    categorySlug: "brake-valve-push-rod",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/918e2bf4-0315-4cc2-a050-b547398a03ec",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "ABS Controllers",
                                    categorySlug: "abs-controller",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9d7c2288-89de-4d22-9eac-39fda741d491",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "ABS Sensors",
                                    categorySlug: "abs-sensor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bf156306-0843-4204-a724-808217e6415f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Vacuum Pumps",
                                    categorySlug: "vacuum-pumps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/53d871b4-fc45-4199-a6ea-e7e06d26f942",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Fluid",
                                    categorySlug: "brake-fluid",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/0f423927-3369-4d91-b516-dd64b3cf5b3f",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Cables",
                    categorySlug: "cables",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/21a9662f-f93b-4713-942d-e8e10968464f",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Cables",
                            categorySlug: "vehicle-cables",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7b34f21a-8883-4a96-b3fd-994b3fe017ee",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accelerator Cable",
                                    categorySlug: "accelerator-cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ec9effc5-89e9-472b-a605-253d1665d5c0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bonnet Cables",
                                    categorySlug: "bonnet-cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c61c6de4-d3f3-4e28-9869-09429cb465ac",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch Cables",
                                    categorySlug: "clutch-cables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/73dcc0b4-bdf1-45ff-bceb-ddd9dac7b78b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gear Cables",
                                    categorySlug: "gear-cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/999bd353-0716-4bf1-ad06-8ca5586c3e01",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Handbrake Cables",
                                    categorySlug: "handbrake-cables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a3d7c676-931f-4511-a41c-191683cdd868",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Speedo Cables",
                                    categorySlug: "speedo-cables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/949002d2-b4d3-46d6-8d4f-6f81b4dbac43",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Cables",
                                    categorySlug: "car-cables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9a00ee67-9247-4783-aab5-9445c6fcad54",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Seat Adjustment Cable",
                                    categorySlug: "car-seat-adjustment-cables",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/99a2acce-77f9-4c54-b7a2-8c81d2a81866",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Clutch & Transmission",
                    categorySlug: "clutch-and-transmission",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/91f89241-dc54-49fa-8bde-5928caaa16c5",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Clutch & Associated Parts",
                            categorySlug: "clutch-and-associated-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1a585849-b518-4347-b925-705f174a94ed",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch Cables",
                                    categorySlug: "clutch-cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/80806fbd-2284-46d1-b740-26940dfe5550",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch Covers",
                                    categorySlug: "car-clutch-covers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/143a5076-d996-4fa8-b4f7-77bacdfcc63b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch Parts",
                                    categorySlug: "clutch-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c4ad9834-d9d7-4630-af0a-349298a9002f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch kits",
                                    categorySlug: "clutch-kit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/12dc4d6f-8cdf-4457-b3d4-8a1273005eaf",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Flywheels",
                                    categorySlug: "flywheel",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b4ebc16c-c222-46bb-a6cc-7fba252ff643",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Spigot Bearing & Freewheel Clutches",
                                    categorySlug: "spigot-bearing-clutch",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a3e2c231-fc09-43a1-a72d-a0e05f38e4d8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch Hydraulics",
                                    categorySlug: "hydraulic-clutches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/30508c87-2fc4-49bb-be1b-ed3999d7f77b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Solid Flywheel Conversions",
                                    categorySlug:
                                        "solid-flywheel-conversion-kit",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/a8fabb3b-dabf-43af-813d-6aa6914fead6",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Transmission Parts",
                            categorySlug: "transmission-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/343e1b53-1c23-4edb-8e6c-5d599903a920",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "CV Boot Kits",
                                    categorySlug: "cv-boot-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/00e7e513-4ef2-4738-b5a4-e06b282b57ed",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "CV Joint Kits",
                                    categorySlug: "cv-joint-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/77e80590-69d6-4697-89e2-7d52890cb94c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Driveshafts",
                                    categorySlug: "driveshaft",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f8976e84-fef1-44f2-b472-1bc9a71377b9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gearboxes & Linkages",
                                    categorySlug: "gearbox-linkage",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b8702007-f6f7-4f5f-8e34-88643b5a1e9b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Propshafts & Components",
                                    categorySlug: "propshafts-components",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d1395459-05a0-48df-b385-056ebc7abc4f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Stub Axels",
                                    categorySlug: "stub-axle",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5a46f5f8-8a3a-4839-bbbe-70f208d59604",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wheel Bearings & Hubs",
                                    categorySlug: "wheel-bearing-and-wheel-hub",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2eda58a7-32bf-4e5f-bb9f-1f97adc63419",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hub Nuts",
                                    categorySlug: "hub-nut",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/eafdcd87-bc35-44c2-8037-b1b5cf2b9e15",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wheel Bolts & Nuts",
                                    categorySlug: "wheel-bolt-and-wheel-nut",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/a08b4d17-5397-4a03-b4f5-78885d1eb5c0",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Cooling & Heating",
                    categorySlug: "cooling-and-heating",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/79f7d024-03c1-4dba-b3cf-d36ad1c7f87e",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Cooling",
                            categorySlug: "cooling",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ecd22985-f109-4381-9c77-0851e1edd16b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fans",
                                    categorySlug: "car-fans",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/558511d0-9cdc-4ffe-8970-6c82ceaeae76",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Expansion Tanks",
                                    categorySlug: "expansion-tank",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ea010d40-9f4f-4766-abd0-6d07f740748a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fans & Parts",
                                    categorySlug: "car-fans-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f34f1e0f-5639-46d6-8d23-c9d276dfee44",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Coolers & Oil Pipes",
                                    categorySlug: "oil-cooler-and-oil-pipe",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0d75c61e-5457-4a15-ad63-9837968b672e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Radiators",
                                    categorySlug: "car-radiator",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/69d62313-5b45-4c4a-8abd-59fb75c89ef3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Radiator Caps",
                                    categorySlug: "radiator-cap",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0077ae54-2dd9-4201-b824-e6783e94f775",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Thermostat - Housings & Gaskets",
                                    categorySlug: "thermostat-housings-gasket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4fe74b0d-7967-4e16-86e0-7d01d1c19c49",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Thermostats",
                                    categorySlug: "car-thermostat",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/538f6bb7-f2dc-4cb0-b632-08273f8da523",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Switch & Sensors",
                                    categorySlug: "car-switch-and-car-sensor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b4afbc6a-0b1b-45e0-b49b-e1f744ae7a1e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Water Hoses & Clips",
                                    categorySlug:
                                        "water-hose-and-water-hose-clip",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/67aa7bef-36d5-4854-90ab-70fb1dcae5de",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Water Pumps & Gaskets",
                                    categorySlug: "water-pump-gasket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5225e94b-b9ad-4bab-8c2d-ba2730a48d0b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Intercoolers",
                                    categorySlug: "intercooler",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/fb4b2d6a-be04-407f-8188-1b81bef98ffa",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Heating",
                            categorySlug: "heating",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f6308f95-9b8d-4995-a201-825eaa36fd33",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Heater Cables",
                                    categorySlug: "heater-cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/25a72728-6d03-4f3d-a965-89560fc9ceb1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Heater Matrix",
                                    categorySlug: "heater-matrix",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/41066071-038d-4e01-a67f-7cc9c1753b04",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Heater Motors",
                                    categorySlug: "heater-motor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a75b30ce-8ab2-4519-8f37-ab5f23596bc6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Heating Parts",
                                    categorySlug: "car-heating-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f703bbdd-72e8-4a4c-9489-4d0990a740fd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Heater Controls",
                                    categorySlug: "heater-controls",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/76fc2fc6-346f-46e7-ba50-e12d406e8768",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Electrical & Ignition",
                    categorySlug: "electrical-and-ignition",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/541825b4-8118-4e15-94f1-9d7b3c9d9232",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Electrical Car Parts",
                            categorySlug: "electrical",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/803b49c2-e7fe-4af6-947b-9edc1682dd66",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Alternators",
                                    categorySlug: "alternator",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6cdd5e8e-f99c-483d-b581-e7b4250b1b80",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Horns & Alarms",
                                    categorySlug: "car-alarm-and-car-horns",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a9381f14-681a-43fe-b589-3aa7790e4644",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Car Battery",
                                    categorySlug: "car-battery",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2c6f2c0f-9d32-47a0-8e9d-16267e2d9986",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Regulators Relays & Solenoids",
                                    categorySlug:
                                        "car-regulators-relays-and-solenoids",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/de7c34f1-a24a-41c0-b05f-f362ab9aa98d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Switches & Sensors",
                                    categorySlug: "car-switch-and-car-sensors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ac6a4221-c8f3-4590-8018-fbd33a2fd717",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Starter Motors",
                                    categorySlug: "starter-motor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/170cf5a0-814d-4d5c-a1c5-02b7fa498518",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Motorcycle Battery",
                                    categorySlug: "motorcycle-battery",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/12add4b9-b47f-4b09-8166-264f148fc3b7",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Ignition",
                            categorySlug: "ignition",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/979509e1-53e0-4c21-8d85-46ef190a0489",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Condensers",
                                    categorySlug: "ignition-condenser",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/32822084-46de-4882-801e-5453468b2069",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Distributor Caps",
                                    categorySlug: "distributorcap",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c9d7bc32-216b-498c-a85b-49f5f4cfc156",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Distributors",
                                    categorySlug: "ignition-distributor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1cbf42e5-53f8-4281-ac88-c3f9eab17996",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ignition Coils",
                                    categorySlug: "ignition-coil",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/be856963-d405-47f3-9c8b-013b13deb4d5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ignition Modules",
                                    categorySlug: "ignition-module",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c41a05e3-1006-4e94-b293-bc6794e2d566",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rotor Arms",
                                    categorySlug: "rotorarm",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4927b624-b3f1-4518-832c-036f7ba6245e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ignition Leads",
                                    categorySlug: "ignition-lead",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ddaa4266-d491-4267-9464-b89f228f780f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Glow Plugs",
                                    categorySlug: "glowplugs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3c5d5618-b616-4355-b49b-bafbe60f08bc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spark Plugs",
                                    categorySlug: "spark-plug",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/385cdfe9-1f05-4587-8a16-0d5e5d0d5cc3",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Engine Parts",
                    categorySlug: "engine-parts",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/dab3edaf-b3e5-4b20-acd3-900df8dea463",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Engine Parts",
                            categorySlug: "engine-component-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1d30aae0-249b-42de-a444-1d518aac2fc9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Engine Parts",
                                    categorySlug: "car-engine-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/cc1bf3b7-b18a-4a85-84bf-55efbbe86125",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sump Pans & Plugs",
                                    categorySlug: "sump-pans-and-sump-plug",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1d6b34f5-2a70-40df-b46d-0ba2b99a853f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Bearings",
                                    categorySlug: "engine-bearings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/802dc039-22ab-4a36-abc8-162a74ba159e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pistons liners & Rings",
                                    categorySlug:
                                        "pistons-liner-and-piston-ring",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/58e59cd0-d95e-40f1-8bd8-69acc7363daa",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Head Bolts",
                                    categorySlug: "head-bolts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/23423e14-b52e-46b8-b975-3e277cdd194f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Pump & Kits",
                                    categorySlug: "oil-pump-kit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f4673440-1b89-4e7d-ba3a-cfbc7585cf92",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Caps & Breather Pipes",
                                    categorySlug: "oil-cap-and-breather-pipe",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e7ca4eda-4c6a-4ca0-a510-d7049f94d5c8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gaskets & Seals",
                                    categorySlug: "car-gasket-seals",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4fc684c2-2e67-4896-9aac-953d021d51b6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cam & Valve Train",
                                    categorySlug: "valve-train",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/dbdfb106-118e-4068-97ff-0dc6f3efea13",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Core Plugs",
                                    categorySlug: "core-plug",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/abcfefea-4ca9-4578-a645-c3f7d4292bb4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cylinder Heads",
                                    categorySlug: "cylinder-heads",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6b4478d6-a810-40a0-a9ec-f033678f3cc7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Oil",
                                    categorySlug: "engine-oils",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5e27faa5-cf6d-42af-a7e2-220fca8eea62",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cold Start Valves",
                                    categorySlug: "cold-start-valve",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/838f2d2a-2f4e-4617-bc18-58222f0b7ff1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cam Covers",
                                    categorySlug: "heat-shield-rocker-cover",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/203827ac-8ea1-4799-9c37-da2b36eb9ebd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Inlet Manifold & Gasket",
                                    categorySlug: "inlet-manifold-and-gasket",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/408b1bc4-add5-4148-8132-f1416199d377",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Exhaust & Turbo",
                    categorySlug: "exhaust-and-turbo",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/ab8c4702-6b3d-4dad-a5e1-d355e94d5802",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Exhausts",
                            categorySlug: "exhausts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f950f890-fa0c-4d32-ad31-ed33f67e7df3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Systems",
                                    categorySlug: "exhaust-system",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c6ba8ea1-855e-4e3b-91a1-b4f8bbb65d53",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Accessories",
                                    categorySlug: "exhaust-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/801607af-90dc-4dd7-9186-cfd0d970572e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Lambda Sensors",
                                    categorySlug: "lambda-sensor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/43e0a12f-1490-4392-94f5-fcbab52ea187",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Catalytic Converters",
                                    categorySlug: "catalytic-converter",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a90c2e1c-4f56-405b-978d-9b0487c69f89",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Parts & Sensors",
                                    categorySlug: "exhaust-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2d4e2abe-9ae6-41de-abb7-30e5632fa48e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gaskets & Clamps",
                                    categorySlug:
                                        "exhaust-gasket-and-exhaust-clamp",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fedf4b0f-5d5d-450c-8c78-3dcddc44bf49",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Heat Exchangers",
                                    categorySlug: "heat-exchanger",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2be07259-dca0-42e9-9e57-90f2c9ce83ff",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Mounting & Bracket",
                                    categorySlug:
                                        "exhaust-mounting-and-bracket",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/b0f6f347-e9ff-4e06-804b-a368a917c8a9",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Turbos",
                            categorySlug: "turbos",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/dbfcbb49-faf6-42a0-a9ba-82405adea110",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Turbo Chargers",
                                    categorySlug: "turbocharger",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/96e7d27c-e9c3-4d49-bb40-7d26a5ef0336",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Intercoolers",
                                    categorySlug: "intercoolers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/98ac5722-39dd-41fe-a7b6-1d6d3bd688cf",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Dump Valves/ BOV",
                                    categorySlug: "dump-valve",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/19ece133-3eb5-4fae-830f-362832f2c25b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Turbo Hoses & Pipes",
                                    categorySlug: "turbo-hose",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/431ef328-32a5-4f15-9a0d-15c32efff5a3",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Fuel & Engine Management",
                    categorySlug: "fuel-and-engine-management",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/9bf1b86c-1221-4bc0-a87a-93fd5e49b31b",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Fuel & Engine Management",
                            categorySlug: "fuel-and-engine-management-t3",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7f8299bb-3b16-4624-a0d4-c6af09f224ab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Carburettors & Parts",
                                    categorySlug: "car-carburettors-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/562f464e-d940-4988-8ba6-c5d2e9366273",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Control Units (ECU)",
                                    categorySlug: "ecu-unit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1345f7fe-82ac-4d0b-aed5-2a3204f057af",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Engine Management",
                                    categorySlug: "car-engine-management-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8a86faa1-d32d-40b3-9ddc-97332142406c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Management Sensors",
                                    categorySlug: "engine-management-sensor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/21758f9e-480c-4ef8-a042-9918ccb57822",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Management Switches",
                                    categorySlug: "engine-management-switches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fdd5e10f-67f0-4dde-8f5e-22547f3ce4e3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Distributers & Pipes",
                                    categorySlug: "fuel-distributors-pipe",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c5b67661-6bf0-4ecd-b8e6-c7cdd760e8f8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Hoses",
                                    categorySlug: "fuel-hose",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6a60f12d-8144-4c16-9181-479e2f519f4c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Injectors",
                                    categorySlug: "fuel-injectors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5da04460-20fb-4089-ab30-b7b185b93e05",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Pumps & Sender Units",
                                    categorySlug: "fuel-pump-sending-unit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4a9c5264-bfa6-44e4-8929-48d030ad58b3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Throttle Bodies & Service Kits",
                                    categorySlug: "throttle-body-service-kit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a2512b2e-2daa-46e9-8461-2117caaedad6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Tank Sender Units",
                                    categorySlug: "fuel-tank-sending-unit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7fb6a676-18e1-4912-bedc-f62ad740f46f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Pumps & Parts",
                                    categorySlug: "fuel-pump",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f453fb70-4ab9-416f-8e68-5c3b548a97e1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Boost Pressure Valve / Sensor",
                                    categorySlug: "boost-pressure-sensor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/259b60da-e850-4d45-a592-28cc474f669c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Injection Pump",
                                    categorySlug: "injection-pump",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/342ac308-57a6-4989-b0ca-33867b78fff0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Injenction Control",
                                    categorySlug: "injenction-control",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/6469a812-9a07-4062-aa41-283703a555a8",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Bulbs",
                    categorySlug: "bulbs",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/d5c8538b-cb0d-46eb-995e-ecaaf93039e4",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Bulbs",
                            categorySlug: "lighting-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/99aeb0e4-c9c7-4140-848b-ea548d30d9c4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bulbs",
                                    categorySlug: "car-bulb",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/56579a3d-53c3-487d-8d8e-d83ffe4bcc97",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Lenses & Other Parts",
                                    categorySlug: "car-lens-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/485c12a3-9207-433a-b9a3-6408ccfcc5ed",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Indicators",
                                    categorySlug: "indicator",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/06735597-c529-4913-86c3-339a7a523fe0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Driving & Fog Lamps",
                                    categorySlug: "driving-lamps-and-fog-lamp",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4ff9c075-3d76-4248-91ef-9e5a36fa86a8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Headlights",
                                    categorySlug: "headlight",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/714ff280-da4f-45f0-a967-cc489c61f798",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Interior Lights",
                                    categorySlug: "car-interior-lights",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4ac9311f-e2f9-45c3-95c7-1006875d3ce7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Licence Plate Lights",
                                    categorySlug: "license-plate-lights",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/21976166-2f6e-4a21-84f0-03733960d018",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rear Lights",
                                    categorySlug: "rear-light",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ad8f8fe8-1201-4dc1-a57f-d3b7b1509a7c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Reflectors",
                                    categorySlug: "car-reflector",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6c0db83a-465c-4d3d-a4f5-2f375c419e03",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bulb Holder",
                                    categorySlug: "bulb-holder",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/bc5b9392-a615-480f-b46c-b68b11cbc1e2",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Lubricants & Fluids",
                    categorySlug: "lubricants-and-fluids",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/4535933d-50ff-489f-8653-71d329320566",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Lubricants",
                            categorySlug: "lubricants",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/554c4241-d755-4012-8859-f7ffce010534",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Anti Freeze",
                                    categorySlug: "anti-freeze2",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b988ad85-bf94-4249-86e5-86686719ba40",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Fluid",
                                    categorySlug: "brake-fluids",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/33c6d757-1578-4306-8dad-797f25d4a620",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Transmission Oil",
                                    categorySlug: "car-transmission-oils",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/10e19ab9-d11a-4ada-a4a6-0134b86a5ba7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Fluids",
                                    categorySlug: "car-fluids",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0ffeb7e0-5d7f-4381-ad70-18d5ff2187b9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Oil",
                                    categorySlug: "engineoil",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c3f3bfa1-c425-47d7-856b-4601b2a6e80a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Steering Fluid",
                                    categorySlug: "steering-fluid",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7081e347-ef27-45b3-857e-7d4a37f159b3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Suspension Fluid",
                                    categorySlug: "suspension-fluids",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/d25f9799-80c6-428f-ba5a-593ffce8729f",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Fluids",
                            categorySlug: "fluids-t3",
                            children: [],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/d9239016-fa30-49ea-825d-a62ba19775f6",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Service Parts",
                    categorySlug: "service-parts",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/91ca914d-4de6-42f0-bbe3-d0757cf9c8bb",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Regular Service",
                            categorySlug: "regular-service",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5f407c9d-742b-44ee-b126-a0dd9510bf66",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wiper Blades",
                                    categorySlug: "wiper-blades-t4",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9807f828-75fe-46e7-a64b-e639f9980902",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Discs",
                                    categorySlug: "brake-discs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8ce5d8ea-972d-47ee-9aa6-0088c51019ff",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Filters",
                                    categorySlug: "other-filters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ec730447-4c83-47db-b317-2d206880c728",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Filters",
                                    categorySlug: "air-filter",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3d35c380-5887-41a5-a501-69c79ae76bdc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Filters",
                                    categorySlug: "fuel-filter",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5bd179c3-4982-4947-bc78-eb83a7aae540",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Filters",
                                    categorySlug: "oil-filter",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/02dc0fe0-4b59-4c8d-b224-f1b8dc746cfe",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pollen Filters",
                                    categorySlug: "pollenfilter",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2e5741ce-ef1c-4c6c-9012-68abd17e2497",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Oils",
                                    categorySlug: "engine-oil",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b3f36410-14dd-45c4-a972-f9e5a6006561",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Pads",
                                    categorySlug: "brake-pads",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2956b568-a538-4924-84c9-0e409dbefb56",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Glow Plugs",
                                    categorySlug: "glow-plugs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ec2df011-5239-4c61-a9ee-7924eb63ce5f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spark Plugs",
                                    categorySlug: "spark-plugs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ab731c35-9bfa-42ab-b43d-84e6810e464c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Workshop Manuals",
                                    categorySlug: "workshop-manual",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c985e781-7840-428d-b7e7-170adba38b74",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Anti Freeze",
                                    categorySlug: "anti-freeze",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/f9a429f1-23fd-42f8-82c8-542d253967da",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Major Service",
                            categorySlug: "major-service",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b2c08c22-8bd2-4f7f-8832-c9ed58c4945b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Distributor Caps",
                                    categorySlug: "distributor-cap",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/91c97ea6-9569-492a-8fa6-cc1c918f4007",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rotor Arms",
                                    categorySlug: "rotor-arm",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5123b6ea-2a97-4d88-a6a6-94aa43c462fb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Belts",
                                    categorySlug: "timing-belt",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c077d4e4-b116-4859-bd1e-7eaa97499e6f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Belt Kits",
                                    categorySlug: "timing-belt-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a29cb200-62f0-47d4-a800-015f559d6828",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Tensioners Idlers & Dampers",
                                    categorySlug: "tensioners-idlers-dampers",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/2b6aede2-5403-4766-b5c5-396546f4a574",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Steering",
                    categorySlug: "steering",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/15ecc93c-3682-48c0-823a-4d626bc7656b",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Steering Parts",
                            categorySlug: "steering-part",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f06d47a9-c651-42b3-a0a4-10a51c79a491",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Boots & Gaskets",
                                    categorySlug:
                                        "steering-boot-and-steering-gasket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f87ec08d-252b-4e51-9052-2c830f26d5f2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Column Parts & Couplings",
                                    categorySlug:
                                        "column-parts-and-column-coupling",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/31005e57-2610-4b32-82e8-72d490187f08",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Dampers",
                                    categorySlug: "car-damper",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/13ab2705-af84-4d0c-b9b8-81e2bdbf65c4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Idler & Pitman Arms",
                                    categorySlug: "idler-arms-and-pitman-arm",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b5db6151-4438-4d31-a58a-c80aae5864e2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Track Rods & Ends",
                                    categorySlug: "track-rod-and-track-rod-end",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7a065de9-5ef6-4f26-a673-09439318fd59",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "PAS Pumps",
                                    categorySlug: "power-steering-pump",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4d51804a-aec8-486a-ab6d-28c8ad0d57a2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Steering Racks & Mounts",
                                    categorySlug:
                                        "steering-rack-and-steering-mount",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/86a81a34-e65c-4d12-a9fd-b28a41f4311c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Steering Boxes",
                                    categorySlug: "steering-box",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/740efeb5-8e42-4cba-9fbb-e700308e3c9e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Steering Parts",
                                    categorySlug: "steering-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5efae4ce-8a71-4b9c-8b4b-73f98f32d8c8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Steering Fluid",
                                    categorySlug: "steering-fluids",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f7d857f8-adfc-47e4-96ab-571a4a464902",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Steering Column",
                                    categorySlug: "steering-column",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/f75ddfb6-15fc-40ff-b982-16c1bac030f6",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Suspension",
                    categorySlug: "suspension",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/f5e3a1b4-e4e0-413f-9386-26118592a8ff",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Suspension Parts",
                            categorySlug: "suspension-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/855e81bb-d67e-4027-bfd0-aed1a8a714d1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Anti Roll Bar & Components",
                                    categorySlug: "anti-roll-bar-components",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/53f17928-f913-4c4e-a2d4-86fc7436a152",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Axle Mounting",
                                    categorySlug: "rear-axle-carriers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1dc82eed-9664-41ee-8733-8429a32a848d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bushes",
                                    categorySlug: "suspension-bushes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4daa1edb-ca21-43c3-b3f0-e726c652bb0a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Shock Absorbers",
                                    categorySlug: "shock-absorber",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bfbecc9b-f65f-4ed2-9b21-a40ec7bb11b9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Suspension Spheres",
                                    categorySlug: "suspension-sphere",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7672ee13-b96e-4b7d-9cd4-eac995e44571",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Springs",
                                    categorySlug: "coil-spring",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a737c3dd-a321-4628-bdf9-cbb27ca27694",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Suspension Arms & Joints",
                                    categorySlug:
                                        "suspension-arm-and-suspension-joints",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0a9a9248-bec8-4299-a1ab-6a0bf1b9b1a6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Other Suspension Parts",
                                    categorySlug: "car-suspension-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/62bb7ccc-dbbf-4dbe-aa40-947bab645f9f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Suspension Mountings",
                                    categorySlug: "suspension-mounting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fec61ae8-f116-407e-95a8-5bbc27626d1e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Mount",
                                    categorySlug: "engine-mount",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/26adb1ea-11d4-4613-a222-56ad0adaf279",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cross Member",
                                    categorySlug: "cross-members",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/07403a7a-0a8e-4cff-b03d-e289b17a105d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Subframes",
                                    categorySlug: "subframe",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/73b76f37-a4bd-494e-ac61-06c7527c5838",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rear Axle",
                                    categorySlug: "rear-axle",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/cb4f2c88-74ff-4d94-8650-494a7ce508f8",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Wipers",
                    categorySlug: "wiper-blades",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/d69a0ac7-0fc7-4f44-9ce8-c1d7d644b57d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Wipers",
                            categorySlug: "wipers",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/097608e8-04c0-4325-9559-f79db571c536",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wiper Arms & Motors",
                                    categorySlug: "wiper-arm-and-wiper-motor",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/972c5473-85f8-4487-8bc1-ae66d6c43f6d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wiper Blades",
                                    categorySlug: "wiper-blade",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fe2848fb-6d82-48ab-9fc5-7aed01ebc78d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Washer System",
                                    categorySlug: "car-washer-system",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2bdd0153-0165-49ed-bf75-6807f46997c2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "EGR Valves",
                                    categorySlug: "egr-valves",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            "@id": "/storefront-menu-items-output-dtos/ae429364-a8cc-4dbe-8155-2f9cc55ffb6a",
            "@type": "StorefrontMenuItemsOutputDto",
            categoryName: "Collision Parts",
            categorySlug: "collision-parts",
            children: [
                {
                    "@id": "/storefront-menu-items-output-dtos/1ce95df5-2cf8-416d-9dfb-0935d51e1450",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Engine Parts",
                    categorySlug: "engine-collision-parts",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/5ede89de-2004-4f55-ac60-6fe3a825aa47",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Engine Cooling Parts",
                            categorySlug: "engine-parts-t3",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/84a38102-3056-40bc-ab8b-a0473e78233d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Con & Parts",
                                    categorySlug: "air-con-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/99ad2f3b-20fb-4588-9c7d-1dff48e65efd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fan & Parts",
                                    categorySlug: "fan-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/46781c94-0d30-4393-9ecc-0b4432e39634",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "InterCooler & Parts",
                                    categorySlug: "intercooler-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f362bce6-31e1-4483-8fac-6772f20abc6d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Cooler & Parts",
                                    categorySlug: "oil-cooler-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6f1f061b-3809-4f6a-a8b2-d56837af78e4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Sump & Parts",
                                    categorySlug: "oil-sump-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f8656914-3088-460d-b7c9-5e30827a4a32",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Radiator & Parts",
                                    categorySlug: "radiator-and-parts",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/c7a4501b-6068-4857-babc-edc0cd7c0efb",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Lighting & Parts",
                    categorySlug: "lighting-and-parts",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/edb67d52-7f3f-4827-9aa8-f4e4266dad7a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Bulbs",
                            categorySlug: "bulbs-t3",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b191c714-bc91-4b6d-a5ab-af19281d38c2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bulbs Exterior",
                                    categorySlug: "bulbs-exterior",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/45e75922-f9ad-49e6-a325-14770c457958",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bulbs Interior",
                                    categorySlug: "bulbs-interior",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/621e947c-4d61-451c-9529-1137b4d2a3ae",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Lighting",
                            categorySlug: "lighting-collision",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/040dcf88-6e61-4144-ab9d-cd5beef7acfb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Corner,Indicator & Repeaters",
                                    categorySlug:
                                        "cornerindicator-and-repeaters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8bab92fb-0df4-4693-b0e6-9c2cd2296746",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Daytime Running lamps",
                                    categorySlug: "daytime-running-lamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c1554fd0-8954-4b70-b786-47a46b568ddb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Fog, Drive, Spot Lamps & Parts",
                                    categorySlug:
                                        "fog-drive-spot-lamps-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3d07d286-77f8-49c1-80ec-b864f48a7666",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Headlamp",
                                    categorySlug: "headlamp",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ed21f8cf-a083-4948-8f34-083c8f273e6a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Headlamp Parts",
                                    categorySlug: "headlamp-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8230c508-d96a-4a8a-91fb-853a565beb34",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Interior Lighting",
                                    categorySlug: "interior-lighting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e3558094-55d5-4530-825e-ac3ea69c3c69",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Licence Plate Light",
                                    categorySlug: "licence-plate-light",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a16e2d55-0b32-4047-b57a-77583477e242",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rear Lamp",
                                    categorySlug: "rear-lamp",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/64cab439-1bc9-465f-935f-eaf38f223bab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rear Lamp Parts",
                                    categorySlug: "rear-lamp-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e3b23900-51c1-4d45-9c5c-44a1cce0c706",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wiring Looms",
                                    categorySlug: "wiring-looms",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/b25ba77f-cd3a-427a-b881-14ff1952ab6f",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Mirrors & Parts",
                    categorySlug: "mirrors-and-parts",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/200d3662-1316-45a2-80f3-fd2e650af8a5",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Mirrors",
                            categorySlug: "mirrirs",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/381bf862-091a-4ad4-800a-e5dd417ad116",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Mirror Assembly",
                                    categorySlug: "mirror-assembly",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c75372de-5d4c-4a6b-82a7-be0e1a273b8a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Mirror Glass",
                                    categorySlug: "mirror-glass",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/65febf6e-f86d-4283-89c4-59da73d6fe44",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Mirror Parts",
                                    categorySlug: "mirror-parts",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/73e941ff-653f-402a-ad68-c47a6a7c1207",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Panels & Parts",
                    categorySlug: "panels-and-parts",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/8d425f76-c0de-4c7c-ab30-f0209374553f",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Corrosion Parts",
                            categorySlug: "corrosion-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/11090d21-0b93-4b43-af16-8b558a11e190",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Angle Sections",
                                    categorySlug: "angle-sections",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c4ea9fb9-761e-4c9b-bcd4-ae20615fdd14",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Chassis Section",
                                    categorySlug: "chassis-section",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3b36f397-aab7-4ed8-9e3f-be15786698cc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Repair Panel",
                                    categorySlug: "repair-panel",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4c24e831-3edc-4025-9c7b-673a1a24b37c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sheet Steel",
                                    categorySlug: "sheet-steel",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3c83778c-e253-4760-aa35-40a6f73c62c4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Universal Chassis Section",
                                    categorySlug: "universal-chassis-section",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d3b3386f-6967-4b71-996f-4a97c8291ad6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Universal Door Bottoms",
                                    categorySlug: "universal-door-bottoms",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fa602970-8333-4852-92cb-fdbebb150df1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Universal Wheel Arches",
                                    categorySlug: "universal-wheel-arches",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/2419e033-0e95-4f55-b88a-823cb9170356",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Exterior Parts",
                            categorySlug: "exterior-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/660e102e-8d71-4a05-966d-a37562347293",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Aerials",
                                    categorySlug: "aerials",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7dc722dc-33d1-489e-bc0c-c86d71de15cd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Badges & Emblems",
                                    categorySlug: "badges-and-emblems",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/90b65732-d80d-49d1-b171-6aaf971066c0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bumper",
                                    categorySlug: "bumper",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/40d311ec-b4e9-43c5-9ace-6e242cc54c95",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bumper Fittings",
                                    categorySlug: "bumper-fittings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0d8f5ae7-e2ed-43c8-b28d-73559a7a19ef",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Cap",
                                    categorySlug: "fuel-cap",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bc8d1cc1-600a-4d8d-937e-120e8e7a1419",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Tank & Parts",
                                    categorySlug: "fuel-tank-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/78cd4d3b-a2f6-451d-8eb4-6c7ce6731951",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gas Struts",
                                    categorySlug: "gas-struts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c5761131-78d8-475b-8624-29f40a2d687e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Grille & Fittings",
                                    categorySlug: "grille-and-fittings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f7188ab3-1bf0-4fd2-ba27-22445dd5dff0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Handles & Locks",
                                    categorySlug: "handles-and-locks",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/79231f8e-2193-442f-86df-a23fd59c19c4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Jacking Points",
                                    categorySlug: "jacking-points",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/eb80b2b5-ca14-4363-897c-f67ee0ce7341",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Moulding, Trim & Fittings",
                                    categorySlug: "moulding-trim-and-fittings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c77e1df1-0af4-4572-8e44-f3caa47f710d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sunroof Parts",
                                    categorySlug: "sunroof-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/dee61053-ced5-4684-b81b-5f0786c388a6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Windscreen",
                                    categorySlug: "windscreen",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/ede61e6e-96ba-453f-abec-2dbe1c78c6bd",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Front & Rear Parts",
                            categorySlug: "front_and_rear-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1b664335-310c-43c1-9401-8836f1b9c820",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Front End Parts",
                                    categorySlug: "front-end-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c4fe62b9-e10a-4e9e-af04-e4d1b81946b6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rear End Parts",
                                    categorySlug: "rear-end-parts",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/3e86b83a-d7b7-4975-894d-d9d7ae9e49b2",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Interior Parts",
                            categorySlug: "interior-parts",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/694a351c-185d-4096-8408-78582dd6a51e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Interior Trim",
                                    categorySlug: "interior-trim",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/27a1848e-4f08-425a-966c-2c489b9ecf97",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Parcel Shelf & Parts",
                                    categorySlug: "parcel-shelf-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/61c445b8-ac14-4d52-986f-99d74580ba6d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Seat & Parts",
                                    categorySlug: "seat-and-parts",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/fe695f99-eee4-4964-8f7e-9b84a0beb411",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Panels",
                            categorySlug: "panels",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/60a83e69-7189-46a9-9bb9-1ff78806544c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bonnet & Fittings",
                                    categorySlug: "bonnet-and-fittings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/47dcffa3-0365-4369-8dc6-a66c34b1da25",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Boot Floor Panel",
                                    categorySlug: "boot-floor-panel",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/baae393e-d64c-4dde-94fa-1241daf93246",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Door & Fittings",
                                    categorySlug: "door-and-fittings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2bdc369b-dd33-4ad7-8a5b-2fd933f6d95a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Panel & Parts",
                                    categorySlug: "engine-panel-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ffe7c203-87e0-40d2-b187-6f7751dc96e2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Front Panel & Parts",
                                    categorySlug: "front-panel-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/72bc3ad9-9b24-46f9-8ea1-9a4ee69dcb25",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rear Panel",
                                    categorySlug: "rear-panel",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/446f2111-cf7d-4484-8f28-48f077eeee5c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Side, Quarter Panel",
                                    categorySlug: "side-quarter-panel",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7d8c5fd4-3de6-47af-8538-34069831535d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Tailgate/Boot Lid & Parts",
                                    categorySlug: "tailgate-boot-lid-and-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/713489e2-2ec7-4d91-91be-032671d13230",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wings",
                                    categorySlug: "wings",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            "@id": "/storefront-menu-items-output-dtos/a153b485-161d-4448-9e92-cc65089e2b3c",
            "@type": "StorefrontMenuItemsOutputDto",
            categoryName: "Tools",
            categorySlug: "tools",
            children: [
                {
                    "@id": "/storefront-menu-items-output-dtos/a2bf54ca-c137-4c44-9b7e-de0571e03c8c",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Air Tools & Compressors",
                    categorySlug: "air-tools-compressor",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/162f3d77-1cfb-4630-bb54-8129e58c5d32",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Air Power Tools",
                            categorySlug: "air-power-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d5b91bf0-e2e8-4717-8821-488dc756fa23",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1 Drive Deep Impact Sockets",
                                    categorySlug:
                                        "one-inch-drive-deep-impact-socket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a657a576-e873-40c2-b35c-77543547fa16",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/2 Couplings",
                                    categorySlug: "half-inch-couplings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/41e730bd-77d6-4687-8a51-1783cf3b1c97",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/2 Drive Impact Sockets",
                                    categorySlug:
                                        "half-inch-drive-impact-socket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ac75098d-e0c9-4919-8c2c-80286669dbc6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "3/4 Drive Impact Sockets",
                                    categorySlug:
                                        "three-quarter-inch-impact-socket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/34d09322-a2b5-47bd-b033-0d1eb2489980",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "3/8 Drive Impact Sockets",
                                    categorySlug:
                                        "three-eighth-inch-impact-socket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b2b71951-a552-4214-8edb-9c592d46e1c3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Hammers",
                                    categorySlug: "air-hammers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/11c394e9-d45e-4a4d-ac28-939cb02ff28a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Hoses",
                                    categorySlug: "air-hoses-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5a5ce136-08cf-4578-808c-68449a2dc70b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Hose (Retracting)",
                                    categorySlug: "air-hose-retracting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/677fad31-b373-4940-bdd0-9e8ac42a0a6d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Line Fittings",
                                    categorySlug: "air-line-fittings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a98cd0c9-f806-4e4d-8606-fb022252def6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Ring Main System",
                                    categorySlug: "air-ring-main-system",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/148bb856-b0cd-4d8d-ba50-284c4abda40e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Tool Kits",
                                    categorySlug: "air-tool-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f0272e26-94c6-465c-916e-a8f14b4e061c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Blow Guns",
                                    categorySlug: "blow-guns",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/031e1941-f63a-4c02-8f00-a58da2ccd8ce",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Buffing & Punching",
                                    categorySlug: "buffing-and-punching",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e48e9288-8dbe-4e6b-b980-0c84c1a30b3d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Die Grinders",
                                    categorySlug: "die-grinders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4d74bf60-93e8-4353-b8d5-80e65b295977",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drills",
                                    categorySlug: "power-tools-drill",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a454f3c4-9932-415d-a18a-1a995ceb45e1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engraving",
                                    categorySlug: "engraving",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9ea77c21-33d0-4add-b1b5-44223b03cbdd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Extension Bars",
                                    categorySlug: "extension-bar-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b131bc4d-13ed-415b-9980-eaf29f77b15f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Filters & Regulators",
                                    categorySlug: "filters-and-regulators",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/054f9696-9719-466d-ac39-b45401f930ab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Impact Extension Bars",
                                    categorySlug: "impact-extension-bars",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0cdfb5cc-15a3-44b2-ba07-81ea5d187357",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Impact Socket Adaptors",
                                    categorySlug: "impact-socket-adaptors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/201542fe-fcbd-43a5-827f-12d629a71340",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Impact Wrenches",
                                    categorySlug: "impact-wrenches-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c6faca98-fe53-46d1-8567-d3d4040287b2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Key Sets",
                                    categorySlug: "key-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/54427ce9-069c-44d7-9df9-7c2d5aa01b01",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Nails",
                                    categorySlug: "nails",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a0a7ff6f-90b5-4e61-b80f-9c51e332aee8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil",
                                    categorySlug: "oil",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9d46431e-76f8-48a1-8896-6adb701cfc4b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Polisher Consumables",
                                    categorySlug: "polisher-consumables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/33c1756c-d9d7-4942-9126-e2a8999e981b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Polishers",
                                    categorySlug: "polisher-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4d2b053f-53ff-4ae1-8790-39bef178e2d7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ratchet Wrenches",
                                    categorySlug: "ratchet-wrenches-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/42c9ec1f-d4f6-4241-8dfc-7724293bc5cf",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Riveting",
                                    categorySlug: "riveting-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bad62c95-4d90-4fe5-843b-62727d790ee7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sander Consumables",
                                    categorySlug: "sander-consumables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/077f01ac-16b9-4530-a02e-6e427c4c985f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sanders",
                                    categorySlug: "sanders-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/10309ec9-dba6-4e61-b957-078d0960f7db",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Saws & Cutting",
                                    categorySlug: "saws-and-cutting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/025fbee4-45d6-4746-acc2-40b3116abb18",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Saws & Cutting Accessories",
                                    categorySlug:
                                        "saws-and-cutting-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/73c91435-e4ee-4b41-9c88-c241932f745e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Scalers",
                                    categorySlug: "scalers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/48778262-d9f1-40e7-ac46-fb6b682216ab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Screwdrivers",
                                    categorySlug: "screwdriver",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/98ee7188-e596-4d5f-b660-51f63b67e356",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Socket Sets",
                                    categorySlug: "socket-set",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/08478a29-1ef4-4a36-9f7c-e5a722845e71",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Staplers & Nailers",
                                    categorySlug: "staplers-and-nailers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5ba56033-8288-49b9-aa3c-9834a649125a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Staples",
                                    categorySlug: "staples",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ce72f685-2ee7-4748-b58a-6311b7d4de91",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Universal Joints",
                                    categorySlug: "universal-joints",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/0d167182-eace-445c-b100-d2ba72e65098",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Compressors",
                            categorySlug: "compressors1",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1653835c-9042-4ffa-9d05-25f477047334",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Screw Compressors",
                                    categorySlug: "screw-compressors",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/afa7d101-cac2-47ba-9363-43706f8bb10a",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Body Shop & Welding",
                    categorySlug: "body-shop-welding-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/50057b27-a0bb-4b9d-90c7-cd20afb3e2ce",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Body Shop",
                            categorySlug: "body-shop1",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/5f067bf1-073e-4650-9472-965f11765d14",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Chassis Alignment",
                            categorySlug: "chassis-alignment",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/29b7343b-89e1-483a-b40f-cba971b06d09",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Welding & Cutting",
                            categorySlug: "welding-and-cutting",
                            children: [],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/a8ae108e-f1c0-4414-978c-c69987d62fab",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Hand Tools",
                    categorySlug: "hand-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/66678bbb-eeb4-4514-915e-cf03e9ec0cf9",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Construction & Craft",
                            categorySlug: "construction-and-craft",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/6c67008a-83d8-4358-81c7-6b47081880ae",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Engineering Tools",
                            categorySlug: "engineering-tools1",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/3fd08eff-3867-4259-b6b7-1cc3716d2890",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "General Tools",
                            categorySlug: "general-tools1",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/bedbd854-71db-48a9-9343-1f03b57bc57d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Workshop Tools",
                            categorySlug: "workshop-tool",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6dab208e-d780-4cfc-8496-99620c78a93e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug: "workshop-tools-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ab023056-ede1-4946-85ac-8fcffddaba0d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Consumables",
                                    categorySlug: "workshop-tools-consumables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fc6f7815-ce85-4208-88a2-4b4e33a6188f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Engine Cleaning",
                                    categorySlug: "engine-cleaning-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/23eb9f04-641d-4223-8fa1-6ed840f63796",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Mechanics Mats",
                                    categorySlug: "mechanics-mats",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/553f2b49-30e6-4a7e-bc95-96041ee7f61b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "MOT",
                                    categorySlug: "mot",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7c9c98ce-91f1-4ddc-87e1-6abbd63957f0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pliers",
                                    categorySlug: "plier-workshop-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c1a3a893-3e07-4b70-aa78-ed3224dd1da1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Prybars",
                                    categorySlug: "prybars",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/93bf4d49-fc8a-4a12-bb3e-f18c689372f8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Scrapers",
                                    categorySlug: "scrapers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ce6cebe5-5b46-44f3-a0b1-911c02f6cc33",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tweezers",
                                    categorySlug: "tweezers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/283b6be0-cc75-4e9f-826e-6c6989a9e895",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wing Covers",
                                    categorySlug: "wing-cover-tools",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/e9a0f203-6432-4d33-bfcd-f67fa314cb50",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Lifting & Recovery",
                    categorySlug: "car-lift-recovery-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/03fca7ee-b1cb-47b9-bedc-8f902077b27e",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Jacking & Lifting",
                            categorySlug: "jacking-and-lifting",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/a3d1d6bb-57fc-4467-9a2b-0a5d8bbba47a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Material Handling",
                            categorySlug: "material-handling11",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5e6de6ee-33ca-416b-a21f-74df70c44980",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drum Trolleys",
                                    categorySlug: "drum-trolleys",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1547e38e-01d4-46b0-84e5-c26617aca28e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Folding Trucks",
                                    categorySlug: "folding-trucks",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9ac3a822-0dd0-410f-8174-ff464197add3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pallet Trucks",
                                    categorySlug: "pallet-trucks",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/70d39cf4-c5f3-4ec5-9b05-064e4ec4eb57",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Platform Trucks",
                                    categorySlug: "platform-trucks",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ce2e6c49-b1ba-4b57-956f-a694a0f80f73",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sack Trucks",
                                    categorySlug: "sack-trucks",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5d4525e0-4eab-455d-9774-da1be7c9fdc8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Workshop Trolleys",
                                    categorySlug: "workshop-trolley",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1bf6fbd6-e549-4934-9523-7c170e935fe5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cordless Screwdrivers",
                                    categorySlug: "cordless-screwdrivers",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/c6fefa88-08be-4631-8fb2-8073d9907a1d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Recovery & Towing",
                            categorySlug: "recovery-and-towing",
                            children: [],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/9b04bced-693d-4022-80d1-997edbcecb93",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Machine Shop",
                    categorySlug: "machine-shop-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/635f71c2-2572-4063-ba39-38ce5ed03d31",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Bench Grinders & Sanders",
                            categorySlug: "bench-grinders-and-sanders",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/12021c9b-f472-418b-bc5e-da58a4a51f0e",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Machine Shop",
                            categorySlug: "machine-shop",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/93589e58-2555-4a05-b46e-2b7dc9532a55",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Power Saws",
                            categorySlug: "power-saws",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/98ea5e5b-8163-45ae-ba96-85088e3c69ed",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Vices",
                            categorySlug: "vices1",
                            children: [],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/ef76ded4-4dd2-4bf2-8036-83d48cc4950f",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Mechanics Tools",
                    categorySlug: "car-mechanic-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/d4e515be-d581-476f-91ee-a6c2cb7c4822",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Body Shop & Sheet Tools",
                            categorySlug: "body-shop-and-sheet-tools",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/343d1cd6-e945-4f69-970a-5b34531fc93d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Electrical Tools",
                            categorySlug: "electrical1",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/b7055687-8ddf-4034-8dc3-a0fad20062c9",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Pliers",
                            categorySlug: "pliers1",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/bbc53416-e8c4-43b7-909d-ec229e4d59ac",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Socketry",
                            categorySlug: "socketry",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b4187001-232e-4294-83af-58832b809960",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/2 Drive Deep Sockets",
                                    categorySlug: "half-inch-deep-drive-socket",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f676c746-6522-4918-a4c6-a18b3ace1cc8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/2 Drive Socket Sets",
                                    categorySlug: "half-inch-drive-socket-set",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f1539f00-050b-4451-826f-98ca393a1913",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/4 Drive Deep Sockets",
                                    categorySlug:
                                        "quarter-inch-drive-deep-sockets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0163922a-cb58-4f0e-85be-4eda9015bb51",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/4 Drive Socket Sets",
                                    categorySlug:
                                        "quarter-inch-drive-socket-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0bcb9018-83e1-4738-9e36-042176d8a7a6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "3/4 Drive Extension Bars",
                                    categorySlug:
                                        "three-quarter-inch-drive-extension-bars",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b209a540-610b-4779-8fd3-245ac8792014",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "3/4 Drive Sockets",
                                    categorySlug:
                                        "three-quarter-inch-drive-sockets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/80112659-a1ff-4706-8b60-d215de62cd0d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "3/8 Drive Deep Sockets",
                                    categorySlug:
                                        "three-eighth-inch-drive-deep-sockets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/73c04b42-ad39-4fe1-8abe-c4aeb4af6a48",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "3/8 Drive Socket Sets",
                                    categorySlug:
                                        "three-eighth-inch-socket-set",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/151ca295-5d31-4a6b-a3a7-c6eba260edb8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug: "socket-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6ff6acf6-f26a-4273-85eb-8571317424d6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Power Adaptors",
                                    categorySlug: "power-adaptors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/846c7338-4a2f-4092-9c19-4d737d8c4be0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Allen Keys & Sets",
                                    categorySlug: "allen-keys-and-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/828666e3-6038-4d6d-8ca4-59310c6cdea5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Driver Bit Sets",
                                    categorySlug: "drive-bit-set",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/20157028-18d8-4340-a5ec-c25cb3d83e34",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Extension Bars",
                                    categorySlug: "extension-bars",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/43692fd4-f340-4d70-83bf-b87a859015ba",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Mixed Socket Sets",
                                    categorySlug: "mixed-socket-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1b40c479-ae05-4c57-9d5c-edb93ebd18c9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ribe Sets",
                                    categorySlug: "ribe-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9958a24c-1969-44eb-9ebe-cc90aa6bfe98",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Socket Sets",
                                    categorySlug: "socket-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3b37d5b8-3808-4c20-9191-2fe58c802c24",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spline Sets",
                                    categorySlug: "spline-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/748a131c-c92f-41ea-a5de-5ca3c478f4f1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "TRX Spline & Hex",
                                    categorySlug: "trx-spline-and-hex",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b7005bed-1b6f-444a-ad01-fbb1afd08597",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "TRX-Star Sets",
                                    categorySlug: "trx-star-sets",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/96880ea2-90a4-462c-a1b6-01252bb6cd93",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Torque Tools",
                            categorySlug: "torque-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1a97d48a-b418-4676-a0b6-f4d5beaa21e6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Torque Screwdriver",
                                    categorySlug: "torque-screwdriver",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c51654ee-27e4-4c86-a609-26942afa5b6f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Torque Wrenches",
                                    categorySlug: "torque-wrenches",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/d6207894-5b60-4f0d-bc75-0339c9dbcb4d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Wrenches",
                            categorySlug: "wrench",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b6e94522-015d-4493-a7bf-ba9326dbecef",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Adjustable Wrenches",
                                    categorySlug: "adjustable-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1f6bb8ea-b992-4009-8f17-3a6bb0aab930",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bit Driver Sets",
                                    categorySlug: "bit-driver-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bfb1d51b-010f-4387-91c0-60494ded149c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Breaker Bars",
                                    categorySlug: "breaker-bars",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aae29b55-142e-49b5-a603-c9ddbd964157",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Combination Wrenches",
                                    categorySlug: "combination-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/dff3b775-663a-4be3-a0d7-e9ccde6d18df",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Combination Wrench Sets",
                                    categorySlug: "combination-wrencheses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/df4374ba-8355-4afa-aae3-64742017c5fc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Crows Foot Wrenches",
                                    categorySlug: "crows-foot-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/28da9246-115c-40e3-9ecf-fb21da3606b3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Flare Nut Wrenches",
                                    categorySlug: "flare-nut-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/582cca38-844b-4de7-bbc8-b3cdb1ba3945",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Flex Head Wrenches",
                                    categorySlug: "flex-head-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a00d1eb4-f105-440e-89bd-41b89c0e2814",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ni Fe",
                                    categorySlug: "ni-fe",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a561f18e-23e8-497b-be4f-5cf9991a8644",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Nut Drivers",
                                    categorySlug: "nut-drivers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9541ace2-9100-4708-aa83-cdbf348f8a7f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ratchet Wrenches",
                                    categorySlug: "ratchet-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f3ffe28c-a043-4b55-a509-38203f589616",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wrench Sets",
                                    categorySlug: "wrench-sets",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/eb3241ac-311a-4ef6-8caa-e2d5691e97a4",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Power Tools",
                    categorySlug: "power-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/8d1e19c1-6e5c-4962-b603-b1b92d5b9750",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Cordless Power Tools",
                            categorySlug: "cordless-power-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e6dd9ddb-054f-4395-bc1b-ca170cd2a494",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Batteries & Chargers",
                                    categorySlug: "batteries-and-chargers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7a2b870b-3448-474d-bb2a-8c4d3bbc84c9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drills",
                                    categorySlug: "cordless-drills",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/096bccec-9cbd-45ad-a373-2a601e9967c0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Impact Wrenches",
                                    categorySlug: "impact-wrenches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6c88be39-4f91-468c-b405-fd63664154db",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Polishers",
                                    categorySlug: "polishers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e9cffd96-4b42-496b-81d3-dec79f08ef67",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Screwdrivers",
                                    categorySlug: "cordless-screwdriver",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/18bd0f4b-2d2f-47a6-a344-2a2a99fa27a7",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Electric Power Tools",
                            categorySlug: "electric-power-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/946ee282-0acf-4286-b707-d30e58268275",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Circular Saws",
                                    categorySlug: "circular-saws",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0e5040d8-b1ca-45ea-bbf3-dbbb8092c469",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drills",
                                    categorySlug: "electric-drills",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ef7acd85-7420-4682-bf66-0cb6711a5a34",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Grinders",
                                    categorySlug: "grinders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/478f2c29-8af3-477a-9d7c-2aa5664b4358",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hot Air Gun",
                                    categorySlug: "hot-air-gun",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/93ae82d3-01cc-4269-a90b-6fc11488efea",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Jig Saws",
                                    categorySlug: "jig-saws",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/06796e2a-2381-46f8-8ca5-df5f0e030e6e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Polishers",
                                    categorySlug: "electric-polishers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/afdad21b-53dc-49de-b85e-cb42e4ac3619",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Multi Tools",
                                    categorySlug: "multitool",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/ede2a6d8-fa56-4c6f-8d13-4a27b2d98f0d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Power Tool Accessories",
                            categorySlug: "power-tool-accessories",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fa7991cb-d8c6-438a-a607-76cf98e3d802",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug: "power-tools-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1fa77efc-df7d-4816-9649-48af6cd7ec41",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Backing Pads",
                                    categorySlug: "backing-pads",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/559c1f68-546d-4cd6-a6d6-09d495abf83e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Battery Chargers",
                                    categorySlug: "batteriescharger",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ad9d7bb1-91ee-451a-9eab-b709f8c8bb24",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Compounding Heads",
                                    categorySlug: "compounding-heads",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aa00f31b-669a-4114-b44e-a0d052541bed",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cutting Discs",
                                    categorySlug: "cutting-discs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7d8dac3a-7be8-4096-b3a3-b715b5796922",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drill Chucks & Keys",
                                    categorySlug: "drill-chucks-and-keys",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7d7dd5ce-b142-4c87-8dc7-6502647ae6a5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Flap Discs",
                                    categorySlug: "flap-discs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/271d1585-48b3-48d7-818c-9a04ddb140ab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Grinding Discs",
                                    categorySlug: "grinding-discs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e2d826c1-6b50-4896-aceb-90b9617831d2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wire Brushes",
                                    categorySlug: "wire-brushes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/cd87b504-6e92-43da-8758-79c9583196ae",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drill & Bit Sets",
                                    categorySlug: "drill-and-bit-set",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/e46f341e-0599-4247-ab50-90e1d8c865f5",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Soldering",
                            categorySlug: "soldering",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/999072bf-8a5a-45ac-b211-2b194405125d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug: "soldering-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c0109b00-64b4-4d8f-b51a-85c8e8017526",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Removal Tools",
                                    categorySlug: "removal-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aa3ded09-e8b4-4e14-a92e-037b276cf6d8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Soldering Guns",
                                    categorySlug: "soldering-guns",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/982eabe3-1a91-493b-81ac-a0332ba1033f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Soldering Irons",
                                    categorySlug: "soldering-irons",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8975a8ec-8613-4d0b-af7a-806d4c3745dc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Soldering Torches",
                                    categorySlug: "soldering-torches",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/5dac7fef-a715-419c-9ce4-e81e3ae84b92",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Vehicle Service",
                    categorySlug: "car-service-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/40456aca-d6a2-4b6b-a707-61484d97c75a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Air Conditioning Tool",
                            categorySlug: "air-conditioning-tool",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d4ed3209-bb16-4ad7-8161-7fe50b90d1c8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Conditioning Tools",
                                    categorySlug: "air-conditioning-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/14d1c31a-6f6d-41f0-aecd-f32fee441bf6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Leak Detection Kits",
                                    categorySlug: "leak-detection-kit",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/e20f0ed7-69d7-46a1-8cb1-df6e20b015b1",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Battery Maintenance",
                            categorySlug: "battery-maintenance2",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/15e2ca09-b473-4608-bdf2-e1c387efde31",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug:
                                        "battery-maintenance-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6206240a-5890-4e32-ab66-78d966a1a999",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Battery Service Tools",
                                    categorySlug: "battery-service-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e6e0cbaa-63f8-41e7-866e-68fab90b8040",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuses",
                                    categorySlug: "fuses-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/33e3bd8f-5685-4aef-8d09-98f549236c86",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Testers",
                                    categorySlug: "testers",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/ae8fff12-435a-45fe-9a60-aac093c7d85f",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Beam Setters",
                            categorySlug: "beam-setters",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e2af7e75-562b-4441-9c5f-325bca54a51d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Headlamp Beam Setters",
                                    categorySlug: "headlamp-beam-setters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/74086031-f710-4f83-b17c-34b787c39ac5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Laser Alignment Devices",
                                    categorySlug: "laser-alignment-devices",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/48a65b0c-9c36-43bc-a620-04396b3c38d0",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Brake Service Tools",
                            categorySlug: "brake-service-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/374e2cc9-a44f-4a78-89cc-c89fb177ca65",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Bleeders",
                                    categorySlug: "brake-bleeders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a169bf7b-827d-4f89-a069-33a5a87d6fbe",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake System Tools",
                                    categorySlug: "brake-system-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c1adf796-e9b5-4535-b0f6-910b64ab5f63",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cylinder Hones",
                                    categorySlug: "cylinder-hones",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9e8353a3-e546-43cb-9fea-161926e051d2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hose Pinch Tools",
                                    categorySlug: "hose-pinch-tools",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/1b0ea873-5f8b-401b-b644-a0fb1732c94e",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Cooling System Tools",
                            categorySlug: "cooling-system-tools1",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5b47a084-36ae-4101-ad09-353a32954392",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Anti Freeze Testers",
                                    categorySlug: "anti-freeze-testers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7943bbd7-4a7e-4cc5-91c7-195f8f0c6619",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cooling System Tools",
                                    categorySlug: "cooling-system-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c3d6b998-3bc6-4b5d-87ca-7250197a2610",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hose Clamps",
                                    categorySlug: "hose-clamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/40530e5f-63e1-4efe-8847-559a06366514",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Leak Detection Kits",
                                    categorySlug: "leak-detection-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6dd1661e-ef51-412f-a2de-964311c9d020",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Water Pump Pliers",
                                    categorySlug: "water-pump-pliers",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/e00cbe34-8311-4321-8aa6-f61b4b32c9af",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Diagnostic Tools",
                            categorySlug: "diagnostic-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c4fd7238-c39f-49f7-a8b1-4fd0f7144869",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Analysis Tools",
                                    categorySlug: "analysis-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/24ca060e-4a9f-4fbf-9c9d-8ffd2bbaf3ed",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Diagnostic",
                                    categorySlug: "diagnostic",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b29137c3-fce4-44e8-a6a9-4459271339e1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Test Probes",
                                    categorySlug: "test-probes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c67f321d-f8e7-4aa3-a1ef-02923814667a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Lights",
                                    categorySlug: "timing-lights",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/29acd2e8-c8af-468e-ac9e-3dadfe9ca135",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Gauged Tools",
                            categorySlug: "gauged-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d440c2de-cc1c-48ba-8661-ba8f93e42cb4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Compression",
                                    categorySlug: "compression",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/35ab961f-6a9c-47cf-a0eb-d0cb5dd57125",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cylinder Leakage",
                                    categorySlug: "cylinder-leakage",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/49fbdd0d-8627-43b6-b34c-7d97118a4c94",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Pressure",
                                    categorySlug: "fuel-pressure",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5df4bbdb-9b42-48e8-9f35-961b31bc9048",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Pressure",
                                    categorySlug: "oil-pressure",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/48b655fc-f70f-436b-ac08-b87cb0511297",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Vacuum & Fuel",
                                    categorySlug: "vacuum-and-fuel",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/3e4d80af-a5ce-40b1-8953-ef1c0096d9f5",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Engine Tools",
                            categorySlug: "general-engine-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/73eb5d6f-49be-4ad6-baa1-97350a30d050",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "1/2 Drive Head bolt kit",
                                    categorySlug:
                                        "half-inch-drive-head-bolt-kit",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6b21f32f-bd66-49ad-b7de-8f143925137a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clutch Tools",
                                    categorySlug: "clutch-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/db130c15-538f-4ead-8704-f0560adfb2ec",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Diagnostic",
                                    categorySlug: "diagnostictools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/965f5840-120f-40d3-b7b1-6ad13a9b69de",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Tools",
                                    categorySlug: "exhaust-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6a40d99d-8125-419d-8024-4fa6f0996141",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fan Tools",
                                    categorySlug: "fan-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/289bbf39-5e29-4a1e-a86e-7a5d819f4bc3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel & Injection Tools",
                                    categorySlug: "fuel-and-injection-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d1e1e397-a0ef-4a9f-92e4-a94fd40fe91a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hose Tools",
                                    categorySlug: "hose-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/022c2b57-9088-403a-933a-b5c38f50ee81",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hub Spanners",
                                    categorySlug: "hub-spanners",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fb642e68-242d-478d-bee9-50ffd902ec54",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pliers",
                                    categorySlug: "plier-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/267e91e9-5963-4e38-a9cb-493de84e7bb9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Plug & Piston Tools",
                                    categorySlug: "plug-and-piston-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/883a1fe4-ae0a-4588-91e3-551b868f2776",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pressure Testing",
                                    categorySlug: "pressure-testing",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/587a3eca-1775-48b4-bbd2-6db7238c0a2a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pulley Removal Tools",
                                    categorySlug: "pulley-removal-tool",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/86470f6d-a429-49f0-8b0a-4498045def41",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Punch Sets",
                                    categorySlug: "punch-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/92332f99-98c6-4243-81e0-d356103f5706",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spring Compressors",
                                    categorySlug: "spring-compressors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bc9fa020-572f-4088-b705-e1cadd55562f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Stands",
                                    categorySlug: "stands",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/27c5c37e-9a4f-4fa3-9f72-4bb34324d180",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Stethoscope",
                                    categorySlug: "stethoscope",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2cac942c-a1ba-44bc-8e8d-2ed37baa07a6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Thermometers",
                                    categorySlug: "thermometers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0367abf9-85c6-4cff-a8b7-fedb2b89d248",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Throttle Tools",
                                    categorySlug: "throttle-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d62e572d-d3fd-49cd-92ac-a5fc1b17a171",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Timing Belt Tools",
                                    categorySlug: "timing-belt-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1a1a2e62-d121-4533-8e52-cfb6be130a34",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Torque Gauges",
                                    categorySlug: "torque-gauges",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ba0a63cc-e3fb-45a6-a901-1249fe043b81",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "VAG Tools",
                                    categorySlug: "vag-tool",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5ea04aff-c425-42e8-989a-c042506ad5c3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wrenches",
                                    categorySlug: "wrenches",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/022563e7-b522-4b2c-8dd1-1b7c726d1bca",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Hub & Suspension Tools",
                            categorySlug: "hub-and-suspension-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7dbefcfc-ceb9-458c-bba9-1b43f7de2341",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug:
                                        "hub-and-suspension-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/dbd5398c-88c4-4308-9b47-09deff7524db",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ball Joint Tools",
                                    categorySlug: "ball-joint-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d72a7ccf-e0de-4cf3-ba99-e0b47497161e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bearing removal Tools",
                                    categorySlug: "bearing-removal-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/44c74fd6-335f-40c4-a84a-000ee6dc9353",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bearing Tools",
                                    categorySlug: "bearing-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1a3333df-8c1e-42bf-bc70-3f342e71d45e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bush Removal Tools",
                                    categorySlug: "bush-removal-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ac2917bd-975b-4954-9ee3-e4c527bc1655",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Coil Spring Compressors",
                                    categorySlug: "coil-spring-compressors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ff753b05-d2f9-4ca4-9bc6-6ea47da82a82",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "CV Joint Tools",
                                    categorySlug: "cv-joint-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6a35b599-a8af-4730-852f-0978c42efd1d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ford Tools",
                                    categorySlug: "ford-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/54b33b61-cc2a-4d97-bb92-6ad3b677c8d1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hub Tools",
                                    categorySlug: "hub-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aa6df846-46a6-4eb5-9275-feafc1a76214",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Shock Absorber Tools",
                                    categorySlug: "shock-absorber-tools",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/7b71ea16-d0e0-4a9b-aa07-0473c7ae3aa5",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Oil Service Tools",
                            categorySlug: "oil-service-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/241808a4-bfe4-4fdb-9b2c-a0481dc3ef61",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drain Plug Keys",
                                    categorySlug: "drain-plug-keys",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2cb5746b-361c-435c-9fad-616f0f721306",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Filter Crushers",
                                    categorySlug: "oil-filter-crushers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e570f102-4aae-480b-891b-c0d4e2534890",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Filter Sockets",
                                    categorySlug: "oil-filter-sockets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d6cad527-a222-48b3-8adb-555ff7d29806",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Filter Wrenches",
                                    categorySlug: "oil-filter-wrenches",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/ca51de80-5d36-4315-8644-bcf1044ec91a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Pullers & Slide Hammers",
                            categorySlug: "pullers-and-slide-hammers",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/abc5509d-a598-4586-a95b-76706b4b1051",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gear Pullers",
                                    categorySlug: "gear-pullers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ae78b535-298f-42bf-8c2e-d00bf70f6b92",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pulley Removal Tools",
                                    categorySlug: "pulley-removal-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/60d8b55b-2e06-4cbb-ac76-bd76f2c78ed6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Slide Hammers",
                                    categorySlug: "slide-hammers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/53a95ba5-07ad-446a-8625-2128298cbe82",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Alfa Tools",
                                    categorySlug: "alfa-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/deb7b343-c27d-491b-9a02-a4dc88458f87",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "BMW Tools",
                                    categorySlug: "bmw-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d6de5e7d-884d-48f0-8111-df631d2698da",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Camshaft Tools",
                                    categorySlug: "camshaft-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4c14b083-7e9c-43ba-895b-036bab179d80",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Chrysler Tools",
                                    categorySlug: "chrysler-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ac0963a6-1fe1-4d60-b0d3-dcf0101617a9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Citroen Tools",
                                    categorySlug: "citroen-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/37efe0c3-ce2a-44de-b0e9-695045812018",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Crank Tools",
                                    categorySlug: "crank-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/749ae446-2238-4276-ac94-87c4a7986715",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Diesel Engine Tools",
                                    categorySlug: "diesel-engine-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7f6c985d-2689-437f-a9ac-408d1f6eeb0f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fiat Tools",
                                    categorySlug: "fiat-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1fb926bb-5d50-4530-bd59-69827ace6299",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Flywheel Positioning Tools",
                                    categorySlug: "flywheel-positioning-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b3de6b8e-5db3-4bcb-b433-aeabaaf5cbc8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ford Tools",
                                    categorySlug: "ford-tool",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/782a4408-059b-438a-87be-8058bf3824f8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "GM Tools",
                                    categorySlug: "gm-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1e9121e3-282d-4de3-a4a3-b17cbb480477",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Honda Tools",
                                    categorySlug: "honda-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4715f15b-0df2-4620-9ed7-546a3b62732a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Land Rover Tools",
                                    categorySlug: "land-rover-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c926a1e7-5022-4944-82ee-8eba18b0123c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Petrol Engine Tools",
                                    categorySlug: "petrol-engine-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a11f9a76-064f-4875-b187-ad30e92cc34c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "PSA Tools",
                                    categorySlug: "psa-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1de9769f-2cb0-46dc-98f7-a484f9387e8a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Renault Tools",
                                    categorySlug: "renault-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5bf3e245-6893-4b2f-8e22-71b26e0cee95",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rover MG Tools",
                                    categorySlug: "rover-mg-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a7d60ffa-8f13-47a1-b54d-35ae38d24771",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Setting Kits",
                                    categorySlug: "setting-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d6687aee-6a4b-439d-be6c-c5b64574f673",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "VAG Tools",
                                    categorySlug: "vag-tools",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/06e6aab0-f172-4bad-90a6-258c4722a8a4",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Setting & Locking Tools",
                            categorySlug: "setting-and-locking-tools",
                            children: [],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/fc14d42e-5aa3-48ca-ae5f-564153ce9c5d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Socketry & Drivers",
                            categorySlug: "socketry-and-drivers",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bffd018f-229c-4bdd-80dd-56e5f0f1fdc0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Impact Sockets",
                                    categorySlug: "imapct-sockets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2f4c0488-271d-4120-be69-3cf37d67ee6c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Specialised Socket Sets",
                                    categorySlug: "specialised-socket-sets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/99d35ba8-8829-4b0e-9025-a0a5ca870c58",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spline Keys",
                                    categorySlug: "spline-keys",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5a4fff99-1aeb-43ed-b5e4-84608de8c400",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Star Keys",
                                    categorySlug: "star-keys",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6fbb29d7-84da-40b1-bc92-1bca79bb58be",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "thread & Bearing Tools",
                                    categorySlug: "thread-and-bearing-tools",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/98c8b79b-b6eb-4486-869f-48771620c4a8",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Trim & Screen Tools",
                            categorySlug: "trim-and-screen-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/61c7f68c-0f94-4b71-a3b6-ed8009149ecc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Knife",
                                    categorySlug: "air-knife",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/19958416-55f4-436b-b4b9-22d3a8403fc3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Door Hinge Pin Tools",
                                    categorySlug: "door-hinge-pin-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/da0ab8a9-07c0-4ced-97c6-63fcb83a1429",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Interior Tools",
                                    categorySlug: "interior-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b9bb6e23-19fd-4c7b-aa1a-bb16c443f954",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Moulding Release Tool",
                                    categorySlug: "moulding-release-tool",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2554da31-e23e-4481-a521-fc12023f1ab1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Panel Removal Tools",
                                    categorySlug: "panel-removal-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c10e1803-f73c-4e01-9d13-06f715e818b1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "trim Tools",
                                    categorySlug: "trim-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1d27d5c7-271a-4a64-ba4c-f033d6f51974",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Windscreen Tools",
                                    categorySlug: "windscreen-tools",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/3e46929d-1a53-4c59-b64a-0e1096f0d9a3",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Tyre & Alignment Tools",
                            categorySlug: "tyre-and-alignment-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5a5da2c4-19b6-4865-bd70-e9479ecc42a0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Line Gauges",
                                    categorySlug: "air-line-gauges",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/178605ec-adec-4a67-80ee-a974953ef7ca",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Alignment Tools",
                                    categorySlug: "alignment-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0fa9eb70-c9f9-4857-818a-f250955fa306",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pressure Gauges",
                                    categorySlug: "pressure-gauges",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7861e8fe-2ae4-4055-a109-7e45a62d6b57",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pumps",
                                    categorySlug: "pumps-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/75984614-f9d2-41bf-8d3f-e7542a7761a1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tyre Changers",
                                    categorySlug: "tyre-changers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c6cfb06e-1d30-42a8-b52d-5f71e211279a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tyre Inflators",
                                    categorySlug: "tyreinflator",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fd840b19-717b-40fb-88bf-694983a7f2be",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wheel & Tyre Tools",
                                    categorySlug: "wheel-and-tyre-tools",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/f0f54082-9ecf-43f8-ae2f-cb5a64e50dc2",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Workshop",
                    categorySlug: "workshop-tools",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/2adb4eda-b178-4642-b31b-0ce24f372c55",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Car Care",
                            categorySlug: "car-care-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/53cb250f-6754-4b71-9f86-d4caa684e3bb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Car Covers",
                                    categorySlug: "car-cover",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/10110c81-5c81-4ff9-b009-171bdf8e9ff8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cleaning Aids",
                                    categorySlug: "cleaning-aids",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/e8a7b3fd-6e11-468d-8762-499683b2e9f3",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Cleaning",
                            categorySlug: "cleaning-tools",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/338d7341-d492-4149-b86a-797a55440ec4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug: "cleaning-accessories-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6c876641-9697-40dd-9da6-17a62a4c2cce",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Domestic Vacuum Cleaners",
                                    categorySlug: "domestic-vacuum-cleaners",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/731b5751-0c21-4c7d-a390-1db94b0d3f4d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Valeting Machines",
                                    categorySlug: "valeting-machines",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/04620a99-3768-4a84-b912-d5c7ddb17426",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Professional Vacuum Cleaners",
                                    categorySlug: "professional-vacuum-cleaner",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c3075c8c-6298-42fc-be07-af3268cdf2e3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Upholstery Cleaner",
                                    categorySlug: "car-upholstery-cleaner",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/8c82638f-e3fa-4f17-8fb8-34b1f53ad33b",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Consumables",
                            categorySlug: "consumables",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7bfa9556-85af-44c5-a663-390ece6dcd20",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cable Ties",
                                    categorySlug: "cable-tie",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f203d776-e0b9-4799-a323-1eaded26143f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Seat Covers",
                                    categorySlug: "seat-cover",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5f96d7f5-be36-40b1-a086-35302a20d426",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Staplers & Nailers",
                                    categorySlug: "staplers-nailers",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/bd5ff78d-818c-4ed3-b9b8-4dd168ed72da",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Garage & Workshop",
                            categorySlug: "garage-and-workshop",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/77a18198-85dd-4d99-b280-8c9b1690ef42",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Presses",
                                    categorySlug: "air-presses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f5b62196-dc07-49bd-b3ef-56d9d2a2239f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Arbor Presses",
                                    categorySlug: "arbor-presses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/48b29f49-28e6-4914-8eb4-4af27b6faafc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Battery Chargers",
                                    categorySlug: "battery-chargers-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ae7cca47-8c4c-4f9a-be49-38521a5587cb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Battery Maintenance",
                                    categorySlug: "battery-maintenance-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4b7c69f9-1400-4f5a-996f-ad1b3ee7c6d8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cleaning Tanks",
                                    categorySlug: "cleaning-tank",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/512a94ab-1a8b-457c-8649-68a3f98cbbe7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Creepers",
                                    categorySlug: "creepers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b9b95c2e-05bd-4d2a-81ec-ab57dee1b558",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuses",
                                    categorySlug: "fuses-tools-513",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/001ff65b-24e1-4d9f-b026-0ba28cef58fc",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hydraulic Presses",
                                    categorySlug: "hydraulic-presses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f8cdf081-1304-4386-8322-baa23415a5b0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Metal Benders",
                                    categorySlug: "metal-benders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7185acbd-67f3-44c2-8f78-0b43cda79976",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pipe Benders",
                                    categorySlug: "pipe-benders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5808f0c2-6d1b-4d2c-a5a5-24e02f3a7442",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Seats",
                                    categorySlug: "seats",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8abd91cd-5544-4749-a5d4-ca0017ab3d23",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Shot Blasting Cabinets",
                                    categorySlug: "shot-blasting-cabinets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/714d6e40-8023-425a-8b36-98b5a09a7efe",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Shot Blasting Consumables",
                                    categorySlug: "shot-blasting-consumables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/329b8ec0-9f38-4aa4-a11b-b4a7d9015572",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Shot Blasting Guns",
                                    categorySlug: "shot-blasting-guns",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e9619955-bea6-4cd0-bd16-8a1cec227ce1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Solar Panels",
                                    categorySlug: "solar-panels",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9baed8a5-234b-488a-aaec-6ce17027e57b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Starters & Chargers",
                                    categorySlug: "starters-and-chargers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fa35a8ac-429c-442d-a82c-829040af98c8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Rails",
                                    categorySlug: "tool-rails",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/49369524-0951-4034-bc00-24b3c95e2d8a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Workshop Lighting & Power",
                            categorySlug: "lighting-and-power",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/51dfe278-0874-42ad-a9df-6b492c6d07bb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "110V Lead Lamps",
                                    categorySlug: "110volt-lead-lamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/57b18c11-e5ff-4840-a2d3-492eb4b552b2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "12V Lead Lamps",
                                    categorySlug: "12volt-lead-lamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/607c5113-3524-4a48-b990-ed748b72d1b3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "230V Lead Lamps",
                                    categorySlug: "230volt-lead-lamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/51bb8275-33bc-4b8c-b69b-7c08f386365c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Accessories",
                                    categorySlug: "lighting-power-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/eb006292-37e1-44e9-b88f-f0f15a78b33e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Batteries",
                                    categorySlug: "battery-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c8cd771f-7913-4584-bb7b-ca9a95e2c2ef",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bulbs",
                                    categorySlug: "bulb",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9ec22b8e-ade6-4ca7-9e7a-5cd6e5e38e2a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cable Reels",
                                    categorySlug: "cable-reels",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2cf21877-3044-4ff9-bfdd-bda8fb6c070d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Extension Leads & Plugs",
                                    categorySlug: "extension-leads-and-plugs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2d5144ef-e65d-4ad2-85a1-2d1bf7e9d38e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Flexi Lights",
                                    categorySlug: "flexi-lights",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/99929775-8d3f-4296-84d4-a971f29c8de8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Floodlight Accessories",
                                    categorySlug: "floodlight-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6cfdcc4c-2a37-4875-b555-947d10c2a0ff",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Floodlights",
                                    categorySlug: "floodlights",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/93ce18aa-1925-4b4b-83bc-15e693a81da9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Generators",
                                    categorySlug: "generators",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/40e019c3-70b9-4cfb-a369-61da661768fe",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Inverters",
                                    categorySlug: "inverters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3ef6a124-659e-4ff4-bde7-c044548e3978",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Lanterns",
                                    categorySlug: "lanterns",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7ed25cbd-b8c7-4203-b76d-dc28445ae5f8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Lead Lamp Accessories",
                                    categorySlug: "lead-lamp-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4b9e3e28-9b21-408a-9509-bd06c6c6c2d0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "LED Lead Lamps",
                                    categorySlug: "led-lead-lamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/10f4cdec-017f-4cd6-9818-265558e37614",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Lighting",
                                    categorySlug: "car-lighting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e04a102f-e717-4fd5-9475-576f5a437896",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Magnetic Lights",
                                    categorySlug: "magnetic-lights",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/686d79ac-77e5-489a-8336-13adaf53f316",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Retractable Lead Lamps",
                                    categorySlug: "retractable-lead-lamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/337f2645-1d06-41ad-8f2a-89707fde7fb8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spotlights",
                                    categorySlug: "spotlights",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fcf959b6-a32c-4798-962d-28ac5b5d59b1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Transformers",
                                    categorySlug: "tool-transformers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4720756d-be02-433f-b325-169c535c9e6f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "torches",
                                    categorySlug: "torches",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/777fff03-01e3-4972-a74a-cc1a13909b4d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Work Lights",
                                    categorySlug: "work-lights-tools",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/79c4e837-5ccf-46ea-b941-50ae417b5234",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Lubrication Accessories",
                            categorySlug: "lubrication",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/442813eb-95e9-4888-9c55-cdfce7f1dc4b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Barrel Bunds",
                                    categorySlug: "barrel-bunds",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/12205406-8683-4bb0-83ae-e3f5e1e3c888",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Diesel Transfer Pumps",
                                    categorySlug: "diesel-transfer-pumps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8f84412b-ba24-4167-809b-11490436bd93",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drain Pans",
                                    categorySlug: "oil-drainer",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a17cb1f4-3247-4a31-8fdf-f2b7a2b2f987",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drainers",
                                    categorySlug: "drainers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3c043c20-3a74-486c-9110-d2eaacf61571",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Emergency Kits",
                                    categorySlug: "emergency-kits",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/84fbb41f-1f98-40f4-82ea-1504f4571b3c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fluid Transfer Pumps",
                                    categorySlug: "fluid-transfer-pumps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fd666b2c-6097-4609-b5e9-4bf98069b38e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuel Cans",
                                    categorySlug: "fuel-can-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/39778408-83df-4474-9f1f-c636c788905d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Funnels",
                                    categorySlug: "funnels",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4c36a5c4-3fde-4dbf-970c-1dc4afc3d1e5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Grease Guns",
                                    categorySlug: "grease-guns",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9e51f38c-0ae8-4761-953f-e7fea5ecfec3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Measuring Jugs",
                                    categorySlug: "measuring-jug",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8679c4f6-68ee-4360-a554-e2ddaaae74e5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Cans",
                                    categorySlug: "oil-cans",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aaae1c1c-fcbc-43a7-88cb-bb0d62e54b9d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil Drainers",
                                    categorySlug: "oil-drainers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/55c5aba3-6d46-4129-96e6-c25ffc0ca509",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spill Mats",
                                    categorySlug: "spill-mats",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f2a28951-5155-4804-bdea-d6ed418704ce",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Water Container",
                                    categorySlug: "water-container",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/9b204b3b-e52e-4081-a063-fe191e7c3545",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Pressure Washers",
                            categorySlug: "pressure-washers1",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e9024450-9df6-46f0-bda0-d843ec061f9d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Pressure Washer Accessories",
                                    categorySlug: "pressure-washer-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/56f25f23-8b82-40ce-9cde-0b640e3b189a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Domestic Pressure Washers",
                                    categorySlug:
                                        "domestic-pressure-washer-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/166b8f58-62f4-40c1-8cc5-526018b76763",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Engine Driven Pressure Washers",
                                    categorySlug:
                                        "engine-driven-pressure-washer",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f5e2a0cf-81f3-4b0a-8607-7fb4f2416e8e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hot Water Pressure Washers",
                                    categorySlug: "hot-water-pressure-washer",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/467962b3-322a-44f0-82c4-86a91b899cea",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Professional Pressure Washers",
                                    categorySlug:
                                        "professional-pressure-washers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/775e6e14-13b7-4b22-8b74-facbb96bc3ab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Steam Cleaners",
                                    categorySlug: "steam-cleaners",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/49fc7c5e-4528-418d-b5d8-1908b5ffeadf",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Pumps",
                            categorySlug: "pumps",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/42614fcd-f670-4dce-a3b1-50c823d020ae",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Submersible Pumps",
                                    categorySlug: "submersible-pumps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ff7635e7-74ab-4b88-a249-0ac6a561a55e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Suction Hoses",
                                    categorySlug: "suction-hoses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/320c5562-1bb3-4a06-8e25-be6df1791819",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Surface Mounted Pumps",
                                    categorySlug: "surface-mounted-pumps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/018d7d10-fc45-4151-80a3-1931b350aa1a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Water Pumps",
                                    categorySlug: "water-pumps",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/57683680-3553-4b24-93c4-4fc2b6bbfcae",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Workwear & PPE",
                            categorySlug: "safety-products",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c5da9f46-31cf-4ee9-a737-95aba70cad83",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Body Protection",
                                    categorySlug: "body-protection-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/49359f4a-bca0-4ad2-ba9f-c64262f30a04",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ear & Head Protection",
                                    categorySlug:
                                        "ear-and-head-protection-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bced9c92-f4b5-4ce8-9e15-3efba8060148",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Eye & Face Protection",
                                    categorySlug:
                                        "eye-and-face-protection-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/086f4373-df1f-4caa-bc22-ae84d81d4845",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fire Protection",
                                    categorySlug: "fire-protection-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/11674be5-f320-4b12-ab5b-9285cbb96cb6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "First Aid",
                                    categorySlug: "first-aid-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b3295b2d-b4a8-419a-b3a3-7d334b252650",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hand Protection",
                                    categorySlug: "hand-protection",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ce1e9951-13fb-4b09-ad8b-67ec391ad2bb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Respiratory Protection",
                                    categorySlug:
                                        "respiratory-protection-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/194ed1b3-88a3-420a-8bd1-74f36466262d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Workwear",
                                    categorySlug: "workwear",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/07bc1de9-8438-4f68-b120-3f99e136b365",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Security",
                            categorySlug: "security1",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/33af0a18-0feb-4d05-a79e-fa3231716db0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Alarms",
                                    categorySlug: "security-alarms",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a3456686-3668-480e-8e73-d32d3fe9b430",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Barriers & Wheel Clamps",
                                    categorySlug: "battery-and-wheel-clamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e2714958-3ed1-42a8-89e8-b170179d6349",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cash Boxes",
                                    categorySlug: "cash-boxes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0ce502b6-a7f1-41b0-b0be-39b4b11978f5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Key Cabinets",
                                    categorySlug: "key-cabinets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/34185f85-5fd8-4f4b-9cdd-555fdade4ac0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Lockers",
                                    categorySlug: "lockers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e87d0c44-285c-4ab0-b421-ed60141c6047",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Padlocks",
                                    categorySlug: "padlock",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/89ba949e-dc3c-4363-a925-9e21ab3eb2db",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Safes",
                                    categorySlug: "safes-tools",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e0b47bf1-7d4e-410e-afc0-602dd1cc153c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Storage Cabinets",
                                    categorySlug: "storage-cabinet",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/4ec1041b-da0c-459c-9eb0-d524980e8f36",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Storage",
                            categorySlug: "storage",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b5ffb10e-153a-44b3-b572-37b7ec855444",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Middle Boxes",
                                    categorySlug: "middle-boxes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7e326d8e-3cfc-4de9-b3c7-1f07ef876a2b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Mobile Workstations",
                                    categorySlug: "mobile-workstations",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/007fbd9c-933c-49c8-938c-fbe7565937dd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Parts Storage",
                                    categorySlug: "parts-storage",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0cd68e56-014b-478b-9406-5d75baf6aaa6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Plastic Toolboxes",
                                    categorySlug: "plastic-toolboxes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f66e7ddb-04cf-4bb4-9dc1-bd6a3f7b7528",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Racking",
                                    categorySlug: "racking",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bd4a34d1-cb30-4211-8d10-94a79af596eb",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rollercabs",
                                    categorySlug: "rollercabs",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/81c6167f-0be3-41bd-9262-0b74b6412355",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Bags",
                                    categorySlug: "tool-bags",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/07387ad4-61cf-4d8c-984d-9d29c66e8e1d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Cabinets",
                                    categorySlug: "tool-cabinets",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/26c0ef5f-2db9-4f9c-83c7-431b111dccd1",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Cases",
                                    categorySlug: "tool-cases",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/940c6f2a-0bd5-42f9-ba39-02c63f9da7a9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Chest Accessories",
                                    categorySlug: "tool-chest-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/53b78773-b0e0-4442-9c87-d5bea5fc619c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Chests",
                                    categorySlug: "tool-chests",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1a0ec2df-073e-41a5-8b7e-bae04ca01320",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Storage Panels",
                                    categorySlug: "tool-storage-panels",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8fc64747-3fa3-4e5c-90c1-703a6ce0bb41",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tool Trays",
                                    categorySlug: "tool-trays",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e3595c5e-69ab-4fbc-b8fe-6f369bdd4eab",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "toolboxes",
                                    categorySlug: "toolboxes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a516d27a-223d-440e-b52e-e1ee286ec391",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "top Chests",
                                    categorySlug: "top-chests",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/98c76015-b916-4d6e-97b1-099a5bec2aae",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Workshop Trolleys",
                                    categorySlug: "workshop-trolleys",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/bdd59207-98e5-4cb8-b922-7e142a85d7ee",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Workshop Environment",
                            categorySlug: "workshop-environment",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9d72a48a-3333-4ddc-9da5-e36c5ec9200c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Bins",
                                    categorySlug: "bins",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b8378d7c-f648-4a55-b13d-589285a0e7a7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Blowers",
                                    categorySlug: "blowers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5f275a54-d546-43d1-9c58-f62a49fb598e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brooms & Squeegee",
                                    categorySlug: "brooms-and-squeegee",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0b8cdb2f-570c-48bb-aa75-e0fae7d34e1f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cycle Products",
                                    categorySlug: "cycle-products",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/66e12c42-f4c5-47c2-974d-33cb23160f3f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Dehumidifiers",
                                    categorySlug: "dehumidifiers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/20863b0a-d3da-4a12-a772-281e19c2dc62",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Desk & Tower Fans",
                                    categorySlug: "desk-and-tower-fans",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b301ee73-2540-4ef4-bf3f-82edf29c221e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Drainage",
                                    categorySlug: "drainage",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/104c0c55-1850-4ec7-a6ca-8772be754add",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Dust Extractors",
                                    categorySlug: "dust-extractors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e6250e4d-17e0-46fb-9c70-19ff6945b8b7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Electric Heaters",
                                    categorySlug: "electric-heaters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ecd78864-8395-4d7d-b1c1-915cc383349a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "High Velocity Fans",
                                    categorySlug: "high-velocity-fans",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6e84ee58-da87-49d5-a432-6ce5f8f4b2ea",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hose Accessories",
                                    categorySlug: "hose-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/83eea31d-1e34-4c5f-9b2f-e33cce52e5e6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Infared Heaters",
                                    categorySlug: "infared-heaters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/17da0200-1740-40db-908f-b20a623aa96a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Kick-Steps",
                                    categorySlug: "kick-steps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fcc7b2bb-7287-4458-bc54-4813fa18acff",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ladders",
                                    categorySlug: "ladders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8604686a-86e5-4c2a-a85b-8ae8b3226c0a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Matting",
                                    categorySlug: "matting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3adaaac7-ea73-403d-aa45-b8c0c134f137",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Paraffin/ Diesel Heaters",
                                    categorySlug: "paraffin-diesel-heaters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ac81c090-1d27-4310-8079-54f59a69f6f2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Platform Ladders",
                                    categorySlug: "platform-ladders",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d935f86b-2f83-426e-83ed-d81bee418141",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Propane Heaters",
                                    categorySlug: "propane-heaters",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d47998ff-17e9-421a-a81c-8fa6a084f6c4",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Safety Steps",
                                    categorySlug: "safety-steps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7daa50db-8ab9-41e4-8712-b8a2b51ff136",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Step Stool",
                                    categorySlug: "step-stool",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/721f498d-46f2-48ef-9802-3fd93c3fc86f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "tarpaulins",
                                    categorySlug: "tarpaulins",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0374e813-a144-434b-8a33-01fa747680ce",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Ventilators",
                                    categorySlug: "ventilators",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/52f96fdd-0bcb-415a-acc4-5f2db0ab80a8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Water Hose",
                                    categorySlug: "water-hose",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/bd5eefef-7342-4e9a-8d7c-231b454f4f9a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Workstations",
                            categorySlug: "workstations",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/69d5afb2-e970-4c71-a602-f8fc32f35918",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Vices",
                                    categorySlug: "vices",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9b827c83-6049-4df3-ae78-54d94204a2ff",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Workbenches",
                                    categorySlug: "workbenches",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            "@id": "/storefront-menu-items-output-dtos/fcc128ea-2d8a-4cd0-8b13-2fce4648199c",
            "@type": "StorefrontMenuItemsOutputDto",
            categoryName: "Workshop Consumables",
            categorySlug: "workshop-consumables",
            children: [
                {
                    "@id": "/storefront-menu-items-output-dtos/5bb409f8-9cb8-41fb-a5e7-241a9880dd1a",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Additives & Cleaners",
                    categorySlug: "additives-and-cleaners",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/cefe254b-cb55-49f5-b0d7-17da60b9986d",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Other Additives",
                            categorySlug: "other-additives",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d380975c-2be1-4d8e-993b-4ebafa505bb5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Specialist Additives",
                                    categorySlug: "specialist-additives",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5fb19e82-e350-471c-b4dd-1ffc02fd5da6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "A/C System",
                                    categorySlug: "ac-system",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e837db13-18fe-41e9-91d8-c9b75eefcc1d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cooling System",
                                    categorySlug: "cooling-system",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7b941b5e-cabd-4755-a63d-99ff9799732a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil & Engine System",
                                    categorySlug: "oil-and-engine-system",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/364feb3a-18ab-45be-a3d1-7b0de037fd32",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Petrol & Diesel Additives",
                            categorySlug: "petrol-and-diesel-additives",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8c7b0c1e-c963-4deb-a971-4a593942117c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Petrol System",
                                    categorySlug: "petrol-system",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c2d99dc0-ee64-47f2-9097-c428c4fa854e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Diesel System",
                                    categorySlug: "diesel-system",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/b8b2d7b8-bac8-4e9f-a783-b232c17aa4d5",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Electrical Components",
                    categorySlug: "electrical-components",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/fae93906-0aad-4cf8-9cb5-97019a7e2e27",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Electrical Consumables",
                            categorySlug: "electrical-consumables",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fefcb3db-424d-43db-b69b-3f44d2bbf71c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Connectors & Terminals",
                                    categorySlug: "connectors-and-terminals",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d8e05e6f-d670-4496-bb8e-13cf056ce1cf",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fuses",
                                    categorySlug: "car-fuses",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/75425cb7-597a-41c3-ac1e-c8b62beabb4f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cable Ties",
                                    categorySlug: "car-cable-ties",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2ac2c9c1-871a-4cff-a04d-5905855600ca",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cable",
                                    categorySlug: "cable",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f07053a4-5f63-4e99-b2e3-96e87610ec6e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Assortment Boxes",
                                    categorySlug: "assortment-boxes",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f3c9ad11-fe0a-49fd-a2e6-b4a8b3def41b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Specialist Electrical",
                                    categorySlug: "specialist-electrical",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/8eff64f6-6366-4dce-b14c-bf3dccaf78fb",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Fluids",
                    categorySlug: "Fluids",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/c4a8ca38-3181-468f-8021-26452c1c2b34",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Fluids",
                            categorySlug: "fluids-workshop",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/62c979be-1901-4d94-a5ef-b5d5d3632003",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Suspension & Steering Fluid",
                                    categorySlug:
                                        "suspension-and-steering-fluid",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/eb6797f6-6fc7-49e9-bd03-d60b197352ac",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Fluid",
                                    categorySlug: "car-brake-fluids",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/a5fabe4f-1251-4a21-a5ac-4088914ec2f7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Screenwash",
                                    categorySlug: "car-screenwash",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9d4ae21c-e6bb-43f6-9dce-2ba085bf5ffe",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Anti-freeze and Coolant",
                                    categorySlug: "anti-freeze-and-coolant",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/fa38cafe-c09c-44b2-90ca-e65d0dc25a4a",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Oil",
                            categorySlug: "oil-grease-and-cleaners",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9a70ee21-b8c0-4690-b9fd-5c3014bf1b87",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Parts Cleaners & Degreaser",
                                    categorySlug:
                                        "parts-cleaners-and-degreaser",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/d5ac7bd2-66d9-42fb-a777-eb116533725d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Grease & Lubrication",
                                    categorySlug: "grease-and-lubrication",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/acdbcf11-5614-460d-981d-2000257214b7",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Adhesives & Glues",
                                    categorySlug: "adhesives-and-glues",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ac367158-accd-4437-868e-0111c4a5a32a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Protectors",
                                    categorySlug: "protectors",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/aa02b53c-3c20-4858-80bc-2ab760c75600",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sealants",
                                    categorySlug: "sealants",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/1216ee44-a357-47e6-8015-ee8515033384",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Maintenance Spray",
                                    categorySlug: "maintenance-spray",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/10efeac4-da0d-4400-a74f-aab838dc3008",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Penetrating Oil",
                                    categorySlug: "penetrating-oil",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f88b55c7-d2be-4fb3-9aba-afdccd90d35c",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Releasers",
                                    categorySlug: "releasers",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/83d84459-c129-475a-b2b9-cf61faed3f12",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Specialist Chemicals",
                            categorySlug: "specialist-chemical-consumables",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c571ed17-390f-42fd-81ec-cf7723f2e23d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Specialist Chemicals",
                                    categorySlug: "specialist-chemicals",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/e9d17ede-d8b0-4332-99c1-d8173dd7491d",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Safety & Protection",
                    categorySlug: "safety-and-protection",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/9199b998-4043-4200-90fa-fdcd98eeb3fa",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Protection",
                            categorySlug: "protection",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ae29e244-b878-49e3-a41e-9cbda576756a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Gloves",
                                    categorySlug: "gloves",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c54c7599-525f-4788-b826-3dd0ca56d151",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Footwear",
                                    categorySlug: "footwear",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/4e33d83f-5304-413c-af95-59282db51976",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Body Protection",
                                    categorySlug: "body-protection",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7308fc00-84f9-4df3-bf64-c33dbfd730b2",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Clothing",
                                    categorySlug: "clothing",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/5f1a27a2-0563-4d71-9b1f-ed21ec4f30cb",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Safety",
                            categorySlug: "safety",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/26ec030a-3c18-487b-a8a5-3ad4f16e12f6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "First Aid",
                                    categorySlug: "first-aid",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/5f1a4f36-0cc9-402c-a5d8-bc4314f9c539",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fire",
                                    categorySlug: "fire",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/ca04f89b-7b9d-4470-93b3-b4b050e19c59",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Screws & Fixings",
                    categorySlug: "screws-and-fixings",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/4e1b461c-058b-4cd4-9f03-f78a4be4be4c",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Screws & Fixings",
                            categorySlug: "screws-fixings",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/9fafbaf5-b13c-4620-adc1-811f5e60df47",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Nuts & Bolts",
                                    categorySlug: "nuts-and-bolts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/98f72533-78aa-4ece-8377-e21f53874d6d",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Screws",
                                    categorySlug: "screws",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0b93916d-fb7c-4c16-acf6-53ffe132d897",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Washers",
                                    categorySlug: "bolt-washers",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6e25b54f-9ef7-4f19-b934-21b6bc3db604",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Fastenings",
                                    categorySlug: "fastenings",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2070166a-4722-4c0c-ba7a-ef53ff42a1f0",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Rivets",
                                    categorySlug: "rivets",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/df3e07cf-af66-4c20-a32f-e0029577a28d",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Vehicle Cleaning & Valeting",
                    categorySlug: "vehicle-cleaning-and-valeting",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/bf96ddf0-de53-4a51-bca0-42aebd169776",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Vehicle Cleaning & Valeting",
                            categorySlug: "vehicle-cleaning-and-valeting-t3",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3ee3f983-7a77-42ef-9ca2-84009bcc2a44",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cleaning",
                                    categorySlug: "car-cleaning",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/7700b0b0-0a11-4a54-91fb-e59d23952f2f",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Valeting",
                                    categorySlug: "valeting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fbeef97d-2dbd-47b8-821e-f6bb36515f0b",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Air Fresheners",
                                    categorySlug: "air-fresheners",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/3b064a6a-45be-404e-847c-951e75655fa8",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cloths & Brushes",
                                    categorySlug: "cloths-and-brushes",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/4f0d7f3f-f166-41c9-9797-e4c5045e7251",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Vehicle Components",
                    categorySlug: "vehicle-components",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/28fcdda7-4b1c-4a17-87d8-e39abb177d03",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Vehicle Components",
                            categorySlug: "vehicle-component",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/16d3cea9-242b-4c12-950a-23fcf77736d3",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Brake Pipe Nuts & Unions",
                                    categorySlug: "brake-pipe-nuts-and-unions",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/b1edba74-0cb8-4aca-af6c-4700f95e8bb5",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cell & Alarm Batteries",
                                    categorySlug: "cell-and-alarm-batteries",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/2047f6ac-13f3-4ea4-88cb-084f892af7fe",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hoses & Hose Clips",
                                    categorySlug: "hoses-and-hose-clips",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/fa59a000-f7ef-41bd-8a7f-6e5376a6a095",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Number Plate Parts",
                                    categorySlug: "number-plate-parts",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ae9b6850-44f6-43aa-b770-08315b9237a6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Sump Plugs & Washers",
                                    categorySlug: "sump-plugs-and-washers",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/4c2b8805-5fb8-46a7-8b37-4aee8f997ee0",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Vehicle Repair",
                    categorySlug: "vehicle-repair",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/78c62427-ca8e-4523-8fb1-d8a7c36f1971",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Vehicle Repair",
                            categorySlug: "vehicle-repair-consumables",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c41a82a6-c959-45e9-a222-8184fb3bb3d6",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cutting & Grinding",
                                    categorySlug: "cutting-and-grinding",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/bfb8357c-d9c4-4bdd-934e-9124187fc1e9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Tape & Abrasives",
                                    categorySlug: "tape-and-abrasives",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/e38275e9-8a47-4672-a4bb-8d6d342db889",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Paste",
                                    categorySlug: "exhaust-paste",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/23277c41-c01a-4e05-aac8-639482082e3a",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Repair",
                                    categorySlug: "exhaust-repair",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/8fb9bc09-5a9c-4193-86b1-539a3c253322",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Exhaust Clamps",
                                    categorySlug: "exhaust-clamps",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f76c9cf1-4e49-4de3-b676-3f6144432e83",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Specialist Repair",
                                    categorySlug: "specialist-repair",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/ebad78c6-cb0f-4d38-86c0-94dd143f93a9",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Welding & Soldiering",
                                    categorySlug: "welding-and-soldiering",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/69337810-b27e-4143-877e-79da809610f0",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Wheel & Tyre",
                    categorySlug: "wheel-and-tyre",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/c56bc12f-2597-417d-aa0d-f536e6888459",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Wheel & Tyre",
                            categorySlug: "wheel-and-tyre-consumables",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f351ea79-b4e6-4fde-a67a-8d7465ad081e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Tyre Consumables",
                                    categorySlug: "tyre-consumables",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6f4b62e2-4b6d-4c11-89f7-df1aa40d9b69",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Tyre Fitting",
                                    categorySlug: "tyre-fitting",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/54d35e48-0dbf-4636-a329-1a69b9df5271",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Wheel Weights",
                                    categorySlug: "wheel-weights",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
                {
                    "@id": "/storefront-menu-items-output-dtos/84f1b8d5-66aa-4569-84da-ea4efe705b6c",
                    "@type": "StorefrontMenuItemsOutputDto",
                    categoryName: "Workshop & Hygiene",
                    categorySlug: "workshop-and-hygiene",
                    children: [
                        {
                            "@id": "/storefront-menu-items-output-dtos/ce82c143-ec67-4264-a761-188ea6d760bd",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Hygiene",
                            categorySlug: "hygiene",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/68c7b143-e1d2-4a82-944a-59623e576347",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Hand Care & Cleaners",
                                    categorySlug: "hand-care-and-cleaners",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f654cc3f-9e36-4670-b5cf-da2e9f944733",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cleaning",
                                    categorySlug:
                                        "Cleaning-wipes-and-paper-roll",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/971b9ac3-b161-4041-8bf6-1bde08843c33",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Spillage & Rags",
                                    categorySlug: "spillage-and-rags",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/c9e3ebfe-6173-4c59-af23-8b5338770147",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Floor mats",
                                    categorySlug: "floor-mats",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/6c5dba07-60cb-4adb-bfb3-da74afa4ec40",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Seat covers",
                                    categorySlug: "seat-covers",
                                    children: [],
                                },
                            ],
                        },
                        {
                            "@id": "/storefront-menu-items-output-dtos/10fae195-8909-48ca-be03-21d9a28b68cc",
                            "@type": "StorefrontMenuItemsOutputDto",
                            categoryName: "Workshop",
                            categorySlug: "workshop",
                            children: [
                                {
                                    "@id": "/storefront-menu-items-output-dtos/91c5a480-58a1-4315-85a1-4970f01ce934",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Snow & Ice",
                                    categorySlug: "snow-and-ice",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/0c6f38dd-2e31-40cf-be94-a709136ace91",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Cleaning Accessories",
                                    categorySlug: "cleaning-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/f76d0871-d253-49cc-b9ba-e5e560a1f160",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Service Essentials",
                                    categorySlug: "service-essentials",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/82f104cd-03f4-4961-9fd5-2f3ca43ce829",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName:
                                        "Lubricant & Fluid Accessories",
                                    categorySlug:
                                        "lubricant-and-fluid-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/17f54b91-9b26-46d9-b89b-2b2b8363a824",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Paint",
                                    categorySlug: "paint",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/abcf7272-c44d-4004-9d36-b23faa5072cd",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Oil & Lubricant Accessories",
                                    categorySlug:
                                        "oil-and-lubricant-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/17ad32e9-c36f-4f3f-9333-54e7add48b92",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Barrel Accessories",
                                    categorySlug: "barrel-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/248213cd-ea5a-4ce1-9144-3bbb26fca96e",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Grease Accessories",
                                    categorySlug: "grease-accessories",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/20f3a61a-e466-46f8-8d27-644c0a54d8ca",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "MOT Essentials",
                                    categorySlug: "mot-essentials",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/26561b21-2e14-4b56-82aa-5dd8734f7529",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Signage",
                                    categorySlug: "signage",
                                    children: [],
                                },
                                {
                                    "@id": "/storefront-menu-items-output-dtos/122d22b2-c3bd-418a-a18f-d72515d8a029",
                                    "@type": "StorefrontMenuItemsOutputDto",
                                    categoryName: "Vehicle Protection",
                                    categorySlug: "vehicle-protection",
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            "@id": "/storefront-menu-items-output-dtos/fc6dc4f0-903f-4af0-bedb-544d73ac92ae",
            "@type": "StorefrontMenuItemsOutputDto",
            categoryName: "Garage Equipment (test)",
            categorySlug: "garage-equipment-(test)",
            children: [],
        },
        {
            "@id": "/storefront-menu-items-output-dtos/4e504d65-c687-46ec-a9d5-84e14d2ed538",
            "@type": "StorefrontMenuItemsOutputDto",
            categoryName: "Omnihub",
            categorySlug: "omnihub",
            children: [],
        },
    ],
    footer: [
        {
            itemType: "branch",
            linkText: "Branch 1",
            path: "menu-branch/Branch-1",
            image: null,
            id: 9,
            filteredChildren: [
                {
                    itemType: "content",
                    linkText: "Style Guide",
                    path: "style-guide",
                    image: null,
                    id: 12,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 2",
                    path: "http://",
                    image: null,
                    id: 13,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 3",
                    path: "http://",
                    image: null,
                    id: 14,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 4",
                    path: "http://",
                    image: null,
                    id: 15,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 5",
                    path: "http://",
                    image: null,
                    id: 16,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 6",
                    path: "http://",
                    image: null,
                    id: 17,
                    filteredChildren: [],
                },
            ],
        },
        {
            itemType: "branch",
            linkText: "Branch 2",
            path: "menu-branch/Branch-2",
            image: null,
            id: 10,
            filteredChildren: [
                {
                    itemType: "directLink",
                    linkText: "Item 1",
                    path: "http://",
                    image: null,
                    id: 18,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 2",
                    path: "http://",
                    image: null,
                    id: 19,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 3",
                    path: "http://item-3",
                    image: null,
                    id: 20,
                    filteredChildren: [],
                },
                {
                    itemType: "branch",
                    linkText: "Item 4",
                    path: "item-4",
                    image: null,
                    id: 21,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 5",
                    path: "http://item-5",
                    image: null,
                    id: 22,
                    filteredChildren: [],
                },
            ],
        },
        {
            itemType: "branch",
            linkText: "Branch 3",
            path: "menu-branch/Branch-3",
            image: null,
            id: 11,
            filteredChildren: [
                {
                    itemType: "directLink",
                    linkText: "Item 1",
                    path: "http://",
                    image: null,
                    id: 23,
                    filteredChildren: [],
                },
                {
                    itemType: "directLink",
                    linkText: "Item 2",
                    path: "http://",
                    image: null,
                    id: 24,
                    filteredChildren: [],
                },
            ],
        },
    ],
};
