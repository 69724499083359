import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faText } from '@fortawesome/pro-regular-svg-icons/faText'

export const defaultTranslations = {
    pluginName: 'Text',
    pluginDescription: 'Edit text using a rich text area.',
}

export const defaultSettings = {
    defaultText: '<p data-testid="text-block">Enter text here.</p>',

    Controls: () => <> Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided </>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faText} size="3x" />,
}
