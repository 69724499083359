import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

import { fonts, colors } from "./tailwind";

// [container, breakpoint]
const sizes = {
    sm: ["100%", "576px"],
    md: ["100%", "768px"],
    lg: ["100%", "992px"],
    xl: ["100%", "1684px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
    },
    fonts: {
        ...theme.fonts,
        heading: "'Figtree', sans-serif",
        body: "'Figtree', sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

export const GlobalStyles = css`
    /* HEADING AND TEXT */
    body {
        font-family: ${fonts.body};
        color: ${colors["brand-black"]};
    }

    /* ADMIN CONTENT BLOCK */
    .ory-row {
        .content__text,
        .ck-content {
            h1 {
                font-family: ${fonts.heading};
                font-weight: bold;
                font-size: 40px;
                line-height: 52px;
            }

            h2 {
                font-family: ${fonts.heading};
                font-weight: bold;
                font-size: 28px;
                line-height: 36px;
            }

            h3 {
                font-family: ${fonts.heading};
                font-weight: bold;
                font-size: 22px;
                line-height: 28px;
            }

            h4 {
                font-family: ${fonts.heading};
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
            }

            h5 {
                font-family: ${fonts.heading};
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
            }

            h6 {
                font-family: ${fonts.heading};
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
            }

            p {
                font-family: ${fonts.body};
                font-size: 16px;
                line-height: 24px;
            }

            .text-small {
                font-family: ${fonts.body};
                font-size: 14px;
                line-height: 20px;
            }

            .text-tiny {
                font-family: ${fonts.body};
                font-size: 12px;
                line-height: 18px;
            }

            blockquote {
                font-size: 18px;
                line-height: 26px;
                padding-left: 30px;
                border-left: 4px solid ${colors["brand-primary"]};
            }

            /* LIST */
            ul:not([class^="DayPicker"]):not(.control-dots):not(.slider) {
                list-style: none;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
                position: relative;

                li {
                    line-height: 30px;
                    padding-left: 20px;
                    position: relative;
                    margin-bottom: 5px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &:before {
                        display: block;
                        position: absolute;
                        left: 0;
                        height: 8px;
                        width: 8px;
                        border-radius: 9999px;
                        background-color: ${colors["brand-grey"]};
                        content: "";
                        top: 12px;
                        margin-right: 10px;
                    }
                }
            }

            ol:not(.breadcrumb) {
                list-style: none;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 20px;
                position: relative;
                padding-left: 0;
                counter-reset: list-number;
                font-size: 16px;
                line-height: 24px;

                li {
                    margin-bottom: 15px;
                    position: relative;
                    display: flex;
                    counter-increment: list-number;
                    &:before {
                        display: block;
                        content: "";
                        margin-right: 5px;
                        content: counter(list-number) ".";
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            /* TABLE */
            .table {
                td {
                    vertical-align: top;
                    padding: 15px;
                }
            }

            .js-focus-visible :focus:not([data-focus-visible-added]) {
                outline: none;
                box-shadow: none;
            }

            a:not([type^="button"]) {
                text-decoration: underline;
                color: ${colors["brand-primary"]};

                &:hover {
                    color: ${colors["brand-primary-darken"]};
                }

                &:visited {
                    color: #3f05c2;
                }
            }

            a[type^="button"] {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        form {
            textarea,
            input {
                margin-bottom: 15px;
            }
        }

        .content-menu-items {
            &__parent-title > p {
                font-size: 22px !important;
                font-weight: 700 !important;
                line-height: 28px !important;
                color: ${colors["brand-primary-600"]} !important;
                margin-bottom: 16px !important;
            }

            &__item {
                margin-bottom: 16px !important;

                &:last-child {
                    margin-bottom: 0 !important;
                }
            }

            &__link-text {
                padding: 12px 16px !important;
                background-color: ${colors["brand-white"]} !important;
                color: ${colors["brand-black"]} !important;
                border-radius: 16px !important;
                margin: 0 !important;
                display: block !important;
                font-weight: 700 !important;
                width: 100% !important;
                height: 100% !important;
                font-size: 16px !important;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                position: relative !important;
                padding-right: 32px !important;

                &::after {
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.5805 11.0058L12.2904 6.71885C11.8904 6.31915 11.8904 5.68961 12.2894 5.2999C12.6794 4.9002 13.3094 4.9002 13.7094 5.2989L19.7094 11.2945C19.9603 11.5388 20.0498 11.8907 19.9778 12.2144C19.9264 12.453 19.789 12.6607 19.6 12.8032L13.6995 18.6992C13.5095 18.8791 13.2495 18.989 12.9895 18.989L12.9995 19C12.7295 19 12.4695 18.8901 12.2895 18.7102C11.8895 18.3205 11.8895 17.681 12.2795 17.2913V17.2813L16.5596 13.0043H5C4.44 13.0043 4 12.5547 4 12.0051C4 11.4455 4.44 11.0058 5 11.0058H16.5805Z' fill='%230033A0'/%3E%3C/svg%3E");
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .content__text,
        .ck-content {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 10px;
                margin-top: 0;
            }
        }

        .content__products {
            .carousel {
                .control-dots,
                .thumbs-wrapper {
                    display: none;
                }
            }
        }

        figure.table {
            width: 100%;

            table {
                margin: 15px 0;
                min-width: 100%;
                font-size: 14px;
                line-height: 20px;

                tbody {
                    > tr {
                        &:first-of-type {
                            font-weight: bold;
                            font-size: 16px;
                            background-color: ${colors[
                                "brand-dark-grey"
                            ]} !important;
                            color: ${colors["brand-white"]};
                            border-radius: 13px 13px 0 0;
                            border-top: none;

                            td {
                                border-top: none;

                                &:first-of-type {
                                    border-top-left-radius: 13px;
                                }

                                &:last-of-type {
                                    border-top-right-radius: 13px;
                                }
                            }
                        }

                        &:last-of-type {
                            border-radius: 0 0 13px 13px;
                            border-bottom: none;

                            td {
                                border-bottom: none;

                                &:first-of-type {
                                    border-bottom-left-radius: 13px;
                                }

                                &:last-of-type {
                                    border-bottom-right-radius: 13px;
                                }
                            }
                        }

                        &:nth-child(odd) {
                            background-color: ${colors["brand-light-grey"]};
                        }

                        &:nth-child(even) {
                            background-color: transparent;
                        }
                    }

                    tr {
                        td {
                            padding: 15px;
                        }
                    }
                }
            }
        }
    }
`;
