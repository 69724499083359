import createPlugin from "./createPlugin";
import BrandsRenderer from "./Renderer/BrandsRenderer";

const BrandsPlugin = (settings) =>
    createPlugin({
        Renderer: BrandsRenderer,
        Controls: null,
        ...settings,
    });

const Brands = BrandsPlugin();
export default Brands;

export { BrandsPlugin, BrandsRenderer };
