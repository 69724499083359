import React from "react";
import { Route, Switch } from "react-router-dom";

import { ApplicationList } from "./ApplicationList/index";
import ApplicationView from "./ApplicationView";

export default () => {
  return {
    routes: [
      {
        path: "/fifm-applications",
        render: ({ match }) => (
          <Switch>
            <Route path={`${match.url}`} exact component={ApplicationList} />
            <Route path={`${match.url}/:id`} exact>
              {(props) => <ApplicationView {...props} />}
            </Route>
          </Switch>
        ),
      },
    ],
  };
};
