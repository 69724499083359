export const brandsData = [
    {
        name: "PAGID",
        image: "/images/brands/pagid.svg",
        link: "/pagid",
    },
    {
        name: "EICHER",
        image: "/images/brands/eicher.png",
        link: "/eicher",
    },
    {
        name: "BREMBO",
        image: "/images/brands/brembo.jpg",
        link: "/brembo",
    },
    {
        name: "CROSSLAND",
        image: "/images/brands/crossland.png",
        link: "/crossland",
    },
    {
        name: "MANN FILTER",
        image: "/images/brands/mann-filter.svg",
        link: "/mann-filter",
    },
    {
        name: "TRIPLE QX",
        image: "/images/brands/tqx.png",
        link: "/triple-qx",
    },
    {
        name: "MPM",
        image: "/images/brands/mpm.jpg",
        link: "/mpm",
    },
    {
        name: "SHELL",
        image: "/images/brands/shell.png",
        link: "/shell",
    },
    {
        name: "STARLINE",
        image: "/images/brands/starline.jpg",
        link: "/starline",
    },
    {
        name: "OPTIMA",
        image: "/images/brands/optima.png",
        link: "/optima",
    },
    {
        name: "SACHS",
        image: "/images/brands/sachs.svg",
        link: "/sachs",
    },
    {
        name: "ERA",
        image: "/images/brands/era.png",
        link: "/era",
    },
    {
        name: "LUCAS",
        image: "/images/brands/lucas.png",
        link: "/lucas",
    },
    {
        name: "LUK",
        image: "/images/brands/luk.svg",
        link: "/luk",
    },
    {
        name: "BOSCH",
        image: "/images/brands/bosch.svg",
        link: "/bosch",
    },
];
