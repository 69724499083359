import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Heading,
    LoadingIcon,
    Select,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    babbageAccountNumber: yup.string().required(),
    babbageBranchId: yup
        .number()
        .required()
        .typeError("Branch ID must be a number"),
    document: yup.string().required(),
    magic: yup.string().required(),
    country: yup.string().required("Trading Country is required."),
});

export const GetDocumentForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/get-document`,
                formData,
                {
                    headers,
                }
            );

            setRequestData(data?.request);
            setResponseData(data?.rawResponse);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    GetDocument | Babbage Soap | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Get Document" />

                <Form
                    autoComplete="on"
                    values={{
                        babbageAccountNumber: "",
                        babbageBranchId: "",
                        document: "",
                        magic: "",
                        country: "gb",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                    enableReinitialize={false}
                >
                    <Group
                        key="get-document"
                        id="get-document"
                        name="Get Document"
                    >
                        <Select
                            name="country"
                            label="Trading Country"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "gb",
                                },
                                { label: "Ireland", value: "ie" },
                            ]}
                            testId="country"
                            required={true}
                        />

                        <Input
                            id="babbageAccountNumber"
                            name="babbageAccountNumber"
                            label="Babbage Account Number"
                            help="Example: A0001"
                            testId="babbageAccountNumber"
                            required
                        />

                        <Input
                            id="babbageBranchId"
                            name="babbageBranchId"
                            label="Branch ID"
                            help="246 for Wembley, 1401 for Dublin"
                            testId="babbageBranchId"
                            required
                        />

                        <Input
                            id="document"
                            name="document"
                            label="Document"
                            help="Example: 08271633"
                            testId="document"
                            required
                        />

                        <Input
                            id="magic"
                            name="magic"
                            label="Magic"
                            help="Example: 337393927"
                            testId="magic"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData?.length > 0 && !loading && (
                        <Group
                            key="request-details"
                            id="request-details"
                            name="Request Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Request to Babbage
                                </h4>{" "}
                                <CopyButton content={requestData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter code={requestData} />
                            </div>
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    EndSession Response
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={responseData}
                                    language="xml"
                                />
                            </div>
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

GetDocumentForm.displayName = "GetDocumentForm";
