import lazyLoad from "@loadable/component";
import createPlugin from "./createPlugin";
import ProductListRenderer from "./Renderer/ProductListRenderer";

const ProductListForm = lazyLoad(() => import("./Form/ProductListForm"));

const ProductsPlugin = (settings) =>
    createPlugin({
        Renderer: ProductListRenderer,
        Controls: ProductListForm,
        ...settings,
    });

const Products = ProductsPlugin();
export default Products;

export { ProductsPlugin, ProductListRenderer };
