import React, { useState, useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Heading } from "@peracto/peracto-ui";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Group, Input, Form } from "@peracto/peracto-ui";
import { GET_LIST, useClient } from "@peracto/client";
import { Checkbox } from "Inputs/components/Checkbox";
import { toast } from "react-toastify";

export interface fifmApplication {
  "@id": string;
  "@type": string;
  status: string;
  accountNumber: string;
  email: string;
  workshopName: string;
  workshopContactNumber: string;
  workshopEmail: string;
  address1: string;
  address2: string;
  address3: any;
  city: string;
  postcode: string;
  labourRate: number;
  openingTimes: OpeningTimes;
  longitude: number;
  latitude: number;
  ecpTermsDocumentVersion: string;
  carsysTermsDocumentVersion: string;
  lkqTermsDocumentVersion: string;
  dpaTermsDocumentVersion: string;
  carsysCompanyUuid: string;
  carsysShopId: string;
  carsysShopOrderAccountUuid: string;
  carsysLabourRateUuid: string;
  signUpUrl: string;
  createdAt: string;
  modifiedAt: string;
  id: string;
}

export interface OpeningTimes {
  fridayClosingTime?: string;
  fridayOpeningTime?: string;
  mondayClosingTime?: string;
  mondayOpeningTime?: string;
  tuesdayClosingTime?: string;
  tuesdayOpeningTime?: string;
  thursdayClosingTime?: string;
  thursdayOpeningTime?: string;
  wednesdayClosingTime?: string;
  wednesdayOpeningTime?: string;
  saturdayClosingTime?: string;
  saturdayOpeningTime?: string;
  sundayClosingTime?: string;
  sundayOpeningTime?: string;
}

const ViewApplicationContainer = ({ children }) => {
  return (
    <div className="">
      <Heading name="View Application">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link className="btn btn-outline-primary" to="/fifm-applications">
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Applications
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const ApplicationView = ({
  match: {
    params: { id },
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [applicationData, setApplicationData] =
    useState<fifmApplication | null>(null);
  const [redirect, setRedirect] = useState<string>();

  const { client } = useClient();
  const weekdays = useMemo(
    () => [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchApplication = async () => {
    setLoading(true);
    try {
      const { data } = await client(GET_LIST, "fifm-applications", {
        id: `fifm-applications`,
        filter: {
          accountNumber: id,
        },
      });

      if (!data[0]) {
        toast.error("There was an error loading this application.");
        setLoading(false);
        setRedirect("/fifm-applications");
      } else {
        setApplicationData(data[0]);
        setLoading(false);
      }
    } catch (e) {
      toast.error("There was an error loading this application.");
      setLoading(false);
      setRedirect("/fifm-applications");
    }
  };

  useEffect(() => {
    fetchApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <ViewApplicationContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </ViewApplicationContainer>
    );
  }

  return (
    <>
      <ViewApplicationContainer>
        {redirect ? (
          <Redirect to={redirect} />
        ) : (
          <>
            <Helmet>
              <title>Fit It For Me | Peracto</title>
            </Helmet>
            <Form
              onSubmit={() => {}}
              autoComplete="off"
              values={{
                accountNumber: applicationData?.accountNumber,
                email: applicationData?.email,
                workshopName: applicationData?.workshopName,
                workshopContactNumber: applicationData?.workshopContactNumber,
                workshopEmail: applicationData?.workshopEmail,
                postcode: applicationData?.postcode,
                address1: applicationData?.address1,
                address2: applicationData?.address2,
                address3: applicationData?.address3,
                city: applicationData?.city,
                labourRate: applicationData?.labourRate,
                openingTimes: {
                  fridayClosingTime:
                    applicationData?.openingTimes?.fridayClosingTime || "17:30",
                  fridayOpeningTime:
                    applicationData?.openingTimes?.fridayOpeningTime || "08:00",
                  mondayClosingTime:
                    applicationData?.openingTimes?.mondayClosingTime || "17:30",
                  mondayOpeningTime:
                    applicationData?.openingTimes?.mondayOpeningTime || "08:00",
                  tuesdayClosingTime:
                    applicationData?.openingTimes?.tuesdayClosingTime ||
                    "17:30",
                  tuesdayOpeningTime:
                    applicationData?.openingTimes?.tuesdayOpeningTime ||
                    "08:00",
                  thursdayClosingTime:
                    applicationData?.openingTimes?.thursdayClosingTime ||
                    "17:30",
                  thursdayOpeningTime:
                    applicationData?.openingTimes?.thursdayOpeningTime ||
                    "08:00",
                  wednesdayClosingTime:
                    applicationData?.openingTimes?.wednesdayClosingTime ||
                    "17:30",
                  wednesdayOpeningTime:
                    applicationData?.openingTimes?.wednesdayOpeningTime ||
                    "08:00",
                  sundayOpeningTime:
                    applicationData?.openingTimes?.sundayOpeningTime || "08:00",
                  sundayClosingTime:
                    applicationData?.openingTimes?.sundayClosingTime || "17:30",
                  saturdayClosingTime:
                    applicationData?.openingTimes?.saturdayClosingTime ||
                    "17:30",
                  saturdayOpeningTime:
                    applicationData?.openingTimes?.saturdayOpeningTime ||
                    "08:00",
                },
              }}
            >
              <Group
                key="accountDetails"
                id="accountDetails"
                name="Account Details"
              >
                <Input
                  name="accountNumber"
                  label="Account Number*"
                  disabled
                  testId="accountNumber"
                  className="hover:cursor-not-allowed"
                />
              </Group>
              <Group
                key="displayDetails"
                id="displayDetails"
                name="Display Details"
              >
                <Input
                  name="email"
                  label="Email address for account creation*"
                  disabled
                  testId="email"
                  className="hover:cursor-not-allowed"
                />
              </Group>
              <Group
                key="addressDetails"
                id="addressDetails"
                name="Address Details"
                className="hover:cursor-not-allowed"
              >
                <Input
                  name="workshopName"
                  label="Workshop Name*"
                  disabled
                  testId="workshopName"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="workshopContactNumber"
                  label="Workshop Contact Number*"
                  disabled
                  testId="workshopContactNumber"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="workshopEmail"
                  label="Workshop Email*"
                  disabled
                  testId="workshopEmail"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="postcode"
                  label="Postcode*"
                  disabled
                  testId="postcode"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="address1"
                  label="Address Line 1*"
                  disabled
                  testId="address1"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="address2"
                  label="Address Line 2"
                  disabled
                  testId="address2"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="address3"
                  label="Address Line 3"
                  disabled
                  testId="address3"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="city"
                  label="City*"
                  disabled
                  testId="city"
                  className="hover:cursor-not-allowed"
                />
                <Input
                  name="labourRate"
                  label="Labour Rate*"
                  disabled
                  testId="labourRate"
                  className="hover:cursor-not-allowed"
                />
                <p className="text-xs italic">(hourly rate VAT inclusive)</p>
              </Group>
              <Group key="openingTimes" id="openingTimes" name="Opening Times">
                <table className="w-full pr-12">
                  <thead>
                    <tr>
                      <th className="p-4"></th>
                      <th className="p-4 text-center">Start Time</th>
                      <th className="p-4 text-center">End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {weekdays.map((day) => {
                      return (
                        <tr key={day}>
                          <td className="pr-36">
                            <Checkbox
                              label={day}
                              checked={applicationData?.openingTimes.hasOwnProperty(
                                `${day.toLowerCase()}OpeningTime`
                              )}
                              disabled
                              className="p-2 bg-opacity-50 hover:cursor-not-allowed"
                            />
                          </td>
                          <td className="p-4 text-center">
                            <select
                              disabled={true}
                              className={`rounded-sm p-2  text-xs bg-brand-grey-100 hover:cursor-not-allowed`}
                            >
                              <option>
                                {applicationData?.openingTimes[
                                  `${day.toLowerCase()}OpeningTime`
                                ] || "08:00"}
                              </option>
                            </select>
                          </td>
                          <td className="p-4 text-center">
                            <select
                              disabled={true}
                              className={`rounded-sm p-2 text-xs bg-brand-grey-100 hover:cursor-not-allowed`}
                            >
                              <option>
                                {applicationData?.openingTimes[
                                  `${day.toLowerCase()}ClosingTime`
                                ] || "17:30"}
                              </option>
                            </select>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Group>
              <Group
                key="terms&conditions"
                id="terms&conditions"
                name="Terms & Conditions"
              >
                <div className="flex flex-col gap-y-2">
                  <p className="">The Terms & Conditions can be viewed below</p>
                  <a
                    className="font-semibold text-brand-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat.omnipart.pub/terms/ecp-terms-and-conditions"
                  >
                    Omnipart (FiFM) Terms & Conditions
                  </a>
                  <a
                    className="font-semibold text-brand-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat.omnipart.pub/terms/carsys-terms-and-conditions"
                  >
                    Carsys Terms & Conditions
                  </a>
                  <a
                    className="font-semibold text-brand-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat.omnipart.pub/terms/privacy-notice"
                  >
                    Trade to Trade Privacy Notice{" "}
                  </a>
                  <a
                    className="font-semibold text-brand-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://uat.omnipart.pub/terms/ecp-terms-and-conditions#dataprocessing"
                  >
                    Data Processing Agreement
                  </a>
                </div>
              </Group>
            </Form>
          </>
        )}
      </ViewApplicationContainer>
    </>
  );
};

export default ApplicationView;
