export const titles = [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Miss", value: "Miss" },
    { label: "Ms", value: "Ms" },
    { label: "Mx", value: "Mx" },
];

export const roles = [
    { label: "Admin", value: "ROLE_ADMIN" },
    { label: "User", value: "ROLE_USER" },
];

export const defaultSelectValue = { label: "Please Select...", value: "" };

export const days = Array.from({ length: 31 }, (_, i) => {
    if (`${i + 1}`.length === 1) {
        return `0${i + 1}`;
    } else {
        return (i + 1).toString();
    }
});
export const months = [
    {
        label: "January",
        value: "01",
    },
    {
        label: "February",
        value: "02",
    },
    {
        label: "March",
        value: "03",
    },
    {
        label: "April",
        value: "04",
    },
    {
        label: "May",
        value: "05",
    },
    {
        label: "June",
        value: "06",
    },
    {
        label: "July",
        value: "07",
    },
    {
        label: "August",
        value: "08",
    },
    {
        label: "September",
        value: "09",
    },
    {
        label: "October",
        value: "10",
    },
    {
        label: "November",
        value: "11",
    },
    {
        label: "December",
        value: "12",
    },
];

function generateArrayOfYears() {
    const max = new Date().getFullYear();
    const min = 1900;
    const years = [];

    for (let i = max; i >= min; i--) {
        years.push(i.toString());
    }
    return years;
}

export const years = generateArrayOfYears();
