/* eslint-disable */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import axios from "axios";
import download from "downloadjs";

import {
  HydraTable,
  Heading,
  TextFilter,
  SelectFilter,
  StyledCheckbox,
} from "@peracto/peracto-ui";
import { GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";

import { inputOptions } from "../util";

const AttributeList = () => {
  const [selectedColumns, onSelectColumn] = useState({});
  const { client } = useClient();

  const config = useConfig();
  const { attributes } = config.get("features", {});
  const API_URL = config.get("api");

  const attributeExports = attributes?.exportAttributeData ?? true;

  const exportAttributeData = async () => {
    let filename = `attribute.csv`;

    try {
      const { data } = await axios.get(`${API_URL}/export/${filename}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      download(data, `attribute_export.csv`);
    } catch (e) {
      console.log("error", e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  const columns = useMemo(() => [
    {
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row }) => (
        <StyledCheckbox
          {...row.getToggleRowSelectedProps()}
          data-testid={`row-${row.index}-checkbox`}
        />
      ),
      selectable: false,
    },
    {
      Header: "Label",
      accessor: "label",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Label" {...props} />,
      Cell: (row) => (
        <Link
          to={row.row.original.id}
          data-testid={`row-${row.row.index}-label`}
        >
          {row.cell.value}
        </Link>
      ),
      selectable: false,
    },
    {
      Header: "Code",
      accessor: "code",
      sortType: "basic",
      Filter: (props) => <TextFilter label="Code" {...props} />,
      selectable: false,
    },
    {
      Header: "Type",
      accessor: "input",
      sortType: "basic",
      Filter: (props) => (
        <SelectFilter
          label="Type"
          options={[
            { label: "Select...", value: "" },
            ...inputOptions.inputTypes,
          ]}
          {...props}
        />
      ),
      Cell: (row) =>
        inputOptions.inputTypes.find((type) => type.value === row.cell.value)
          ?.label || "-",
      selectable: false,
    },
  ]);

  const onFetchData = (options) => client(GET_LIST, "attributes", options);

  return (
    <>
      <Helmet>
        <title>Attributes | Peracto</title>
      </Helmet>
      <Heading name="Attributes">
        <div className="ml-2 d-flex align-items-center">
          {attributeExports === true && (
            <button
              className="mr-2 btn btn-link"
              onClick={() => exportAttributeData()}
              data-testid="export-attribute-data"
            >
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Export Attribute Data
            </button>
          )}
          <Link
            className="mr-2 btn btn-outline-primary"
            to="/attributes/import"
            data-testid="import-attribute-data"
          >
            <FontAwesomeIcon icon={faUpload} className="mr-2" />
            Import Attribute Data
          </Link>

          <Link
            className="btn btn-primary"
            to="/attributes/add"
            data-testid="add-attribute"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Add Attribute
          </Link>
        </div>
      </Heading>

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
                defaultFilters={{
                  label: "",
                  code: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttributeList;
