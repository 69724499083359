import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Select,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    country: yup.string().required(),
    techDocId: yup.string().required(),
});

export const GetMaintenanceSystemsV6Form = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState("");
    const [authenticationData, setAuthenticationData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data: authData } = await axios.get(
                `${API_URL}/test-harness/haynes-pro/get-authentication-vrid`,
                {
                    headers,
                }
            );

            const { data: haynesData } = await axios.post(
                `${API_URL}/test-harness/haynes-pro/get-maintenance-systems`,
                {
                    techDocId: formData?.techDocId,
                    country: formData.country,
                },
                {
                    headers,
                }
            );

            setAuthenticationData(JSON.stringify(authData));
            setResponseData(JSON.stringify(haynesData));
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Haynes Get Times: V6 | Haynes Pro | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Haynes Get Times: V6" />

                <Form
                    autoComplete="on"
                    values={{
                        country: "GB",
                        vrm: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="haynes-get-maintenance-systems-v6"
                        id="haynes-get-maintenance-systems-v6"
                        name="Haynes Get Maintenance Systems V6"
                    >
                        <Select
                            name="country"
                            label="Trading Country"
                            placeholder="Please select..."
                            required={true}
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "GB",
                                },
                                { label: "Ireland", value: "IE" },
                            ]}
                            testId="tradingCountry"
                        />

                        <Input
                            name="techDocId"
                            label="Tech Doc ID"
                            testId="techDocId"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {authenticationData?.length > 0 && !loading && (
                        <Group
                            key="authentication-details"
                            id="authentication-details"
                            name="Authentication Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Haynes Authentication Results
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={authenticationData} />
                                </div>
                            </div>

                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={authenticationData}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    getMaintenanceSystemsV6 Response
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={responseData}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

GetMaintenanceSystemsV6Form.displayName = "GetMaintenanceSystemsV6Form";
