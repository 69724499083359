import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";
import {
    IconArrowRight,
    IconChevronLeft,
    IconChevronRight,
    IconCircleFilled,
} from "@tabler/icons-react";
import { Card } from "components/Card";
import chunk from "lodash/chunk";
import useMedia from "use-media";
import { useConfig } from "@peracto/peracto-config";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faBoxFull} size="6x" />
    </div>
);

const calculateOriginalPrice = (price, discount) => {
    const pc = 100 - discount;
    return (price / pc) * 100;
};

const ProductCard = (props) => {
    const { product } = props;
    const { productName, productImage, discount, price, slug } = product;

    return (
        <Card
            className="flex flex-1 flex-col !py-5 !px-4 gap-y-2 md:max-w-none mr-[16px] md:mr-0"
            shadow={false}
            data-testid="content__products--card"
        >
            <div className="flex justify-center items-start self-stretch relative px-[42px]">
                <img
                    className="aspect-[4/3] object-cover object-center"
                    src={productImage?.location || "/images/placeholder.jpg"}
                    alt={productName}
                    data-testid="content__products--image"
                />
            </div>

            <h3
                className="!text-base font-bold leading-5 text-brand-primary-600 text-left"
                data-testid="content__products--title"
            >
                {productName}
            </h3>

            <hr className="border-t-brand-grey-100" />

            <div className="flex justify-between items-center gap-x-8">
                <p
                    className="text-brand-grey-600 text-base font-normal leading-6 line-through"
                    data-testid="content__products--old-price"
                >
                    £
                    {calculateOriginalPrice(
                        price?.incTax / 100,
                        discount
                    ).toFixed(2)}
                </p>

                <p
                    className="text-brand-red text-2xl font-bold"
                    data-testid="content__products--price"
                >
                    £{(price?.incTax / 100).toFixed(2)}
                </p>
            </div>

            <div className="flex justify-center">
                <a
                    href={`/products/${slug}`}
                    className="text-brand-primary font-bold text-sm py-[10px] flex items-center !no-underline"
                    data-testid="content__products--link"
                >
                    Shop Now
                    <IconArrowRight className="ml-2 w-4 h-4" />
                </a>
            </div>
        </Card>
    );
};

const ProductsComponent = ({ state: { skus = [] } }) => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [currentSlide, setCurrentSlide] = useState(0);
    const [products, setProducts] = useState([]);
    const [loadingError, setLoadingError] = useState(false);

    const isMobile = useMedia({ maxWidth: `768px` });
    const isMd = useMedia({ maxWidth: `992px` });

    const chunkCount = isMobile ? 1 : isMd ? 2 : 3;

    const chunkedProductsToDisplay = chunk(products, chunkCount);

    const loadProductData = async (skus) => {
        setLoadingError(false);

        if (skus.length > 0) {
            try {
                const skuQueryString = skus
                    .map((sku) => `skus[]=${sku}`)
                    .join("&");

                if (skuQueryString?.length > 0) {
                    const response = await fetch(
                        `${API_URL}/storefront/products-by-skus?${skuQueryString}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            credentials: "include",
                        }
                    );

                    const json = await response.json();

                    const apiProducts = json?.["hydra:member"];

                    const productsToShow = [];

                    skus.forEach((sku) => {
                        const apiProduct = apiProducts?.find(
                            (product) => product.sku === sku
                        );

                        if (apiProduct) {
                            return productsToShow.push(apiProduct);
                        }
                    });

                    setProducts(productsToShow);
                }
            } catch (e) {
                setLoadingError(true);
                console.error(e);
            }
        }
    };

    useEffect(() => {
        const filteredSkus = skus.filter((sku) => !!sku);

        loadProductData(filteredSkus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skus]);

    return (
        <div className="py-2">
            {products?.length > 0 && !loadingError ? (
                <div className="flex flex-col justify-start content__products w-full">
                    <Carousel
                        showStatus={false}
                        centerMode={isMobile}
                        centerSlidePercentage={80}
                        renderIndicator={() => null}
                        renderArrowPrev={() => null}
                        renderArrowNext={() => null}
                        selectedItem={currentSlide}
                    >
                        {chunkedProductsToDisplay.map((chunk, chunkIdx) => {
                            return (
                                <div
                                    key={`related-products-chunk-${chunkIdx}`}
                                    className={`w-full grid sm:gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4`}
                                >
                                    {chunk?.map((product, index) => (
                                        <ProductCard
                                            key={`product-${index}`}
                                            product={product}
                                        />
                                    ))}
                                </div>
                            );
                        })}
                    </Carousel>

                    {chunkedProductsToDisplay?.length > 1 && (
                        <div className="flex justify-center items-center">
                            <div className="flex flex-row">
                                <button
                                    onClick={() => {
                                        if (currentSlide === 0) {
                                            setCurrentSlide(
                                                chunkedProductsToDisplay.length -
                                                    1
                                            );
                                        } else {
                                            setCurrentSlide(currentSlide - 1);
                                        }
                                    }}
                                    className="flex items-center justify-center h-8 w-8 lg:h-11 lg:w-11 mr-[16px] lg:mr-[20px]"
                                >
                                    <IconChevronLeft className="text-brand-primary" />
                                </button>

                                <div className="flex flex-row items-center gap-2 md:gap-4">
                                    {chunkedProductsToDisplay.map(
                                        (chunk, idx) => {
                                            return (
                                                <button
                                                    key={`slide-btn-${idx}`}
                                                    onClick={() =>
                                                        setCurrentSlide(idx)
                                                    }
                                                >
                                                    <IconCircleFilled
                                                        className={`h-2 md:h-4 w-2 md:w-4 ${
                                                            currentSlide === idx
                                                                ? "text-brand-primary"
                                                                : "text-brand-grey-200"
                                                        }`}
                                                    />
                                                </button>
                                            );
                                        }
                                    )}
                                </div>

                                <button
                                    onClick={() => {
                                        if (
                                            currentSlide ===
                                            chunkedProductsToDisplay.length - 1
                                        ) {
                                            setCurrentSlide(0);
                                        } else {
                                            setCurrentSlide(currentSlide + 1);
                                        }
                                    }}
                                    className="flex items-center justify-center h-8 w-8 lg:h-11 lg:w-11 ml-[16px] lg:ml-[20px]"
                                >
                                    <IconChevronRight className="text-brand-primary" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Icon />
            )}
        </div>
    );
};

export default ProductsComponent;
