import React, { useState } from "react";
import { Helmet } from "react-helmet";

import { Form, Group, Input, Heading, LoadingIcon } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    productSku: yup.string().required(),
});

export const ApplicationGuideForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const [showRequestData, setShowRequestData] = useState(false);
    const [showResponseData, setShowResponseData] = useState(false);
    const [showTableData, setShowTableData] = useState(false);

    const [tableData, setTableData] = useState([]);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/raindata-app-guide`,
                formData,
                {
                    headers,
                }
            );

            setRequestData(data?.request);
            setResponseData(data?.response);
            setTableData(JSON.parse(data?.response));
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Application Guide | Raindata | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Application Guide" />

                <Form
                    autoComplete="on"
                    values={{
                        productSku: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="application-guide"
                        id="application-guide"
                        name="Application Guide"
                    >
                        <Input
                            name="productSku"
                            label="ECP Part Number"
                            testId="ecpPartNumbner"
                            help="Example: 10111096"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData?.length > 0 && !loading && (
                        <Group
                            key="request-details"
                            id="request-details"
                            name="Request Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Raindata Request
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={requestData} />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowRequestData(
                                                !showRequestData
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showRequestData
                                                    ? faMinus
                                                    : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showRequestData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showRequestData && (
                                <div className="border rounded-[4px] p-2">
                                    <CodeFormatter
                                        code={requestData}
                                        language="json"
                                    />
                                </div>
                            )}
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Raindata Response
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={responseData} />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowResponseData(
                                                !showResponseData
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showResponseData
                                                    ? faMinus
                                                    : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showResponseData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showResponseData && (
                                <div className="border rounded-[4px] p-2">
                                    <CodeFormatter
                                        code={responseData}
                                        language="json"
                                    />
                                </div>
                            )}
                        </Group>
                    )}

                    {tableData?.length > 0 && !loading && (
                        <Group key="results" id="results" name="Results">
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Results
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={responseData} />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTableData(!showTableData);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showTableData ? faMinus : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showTableData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showTableData && (
                                <table className="table table-striped table-sm table-fixed mt-3">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tableData.map((row, idx) => {
                                            const keys = Object.keys(row);

                                            return (
                                                <>
                                                    <tr key={`row-${idx}`}>
                                                        <td colspan={2}>
                                                            <span className="text-lg font-semibold">
                                                                Application{" "}
                                                                {idx}{" "}
                                                            </span>
                                                        </td>
                                                    </tr>

                                                    {keys.map((key, keyIdx) => {
                                                        return (
                                                            <tr
                                                                key={`row-key-${keyIdx}`}
                                                            >
                                                                <td>{key}</td>
                                                                <td>
                                                                    {row[key]}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

ApplicationGuideForm.displayName = "ApplicationGuideForm";
