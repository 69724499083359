import React from "react";
import { Route, Switch } from "react-router-dom";
import { FailedOrdersList } from "./FailedOrders";

export default () => {
    return {
        routes: [
            {
                path: "/failed-orders",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={FailedOrdersList}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
