import * as yup from "yup";

const nullableString = yup.string().nullable();

export default {
    metaDescription: nullableString,
    metaTitle: nullableString,
    line1: yup.string().required(),
    line2: nullableString,
    line3: nullableString,
    website: nullableString,
    specialOpeningHours: nullableString,
    generalInformation: nullableString,
    name: yup.string().required(),
    city: yup.string().required().nullable(),
    county: yup.string().required(),
    postcode: yup.string().required(),
    country: yup.string().required(),
    locationStatus: yup.string().required(),
    latitude: yup.string().required(),
    longitude: yup.string().required(),
    telephone: yup.string().required(),
    ecpBranchId: yup
        .number()
        .required()
        .typeError("Babbage Branch Number must be a number"),
};
