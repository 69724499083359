import {
    CURRENCY_CONDITIONS,
    NUMERIC_CONDITIONS,
} from './PromotionsForm/MinimumRequirements/MinimumRequirementsRow'

export const formatDataForAPI = data => {
    if (data.setEndDate === false) {
        data.endDateTime = null
    }

    if (data.setLimitUses === false) {
        data.maximumUses = null
    }

    if (data.setLimitEmailUses === false) {
        data.maximumUsesPerEmail = null
    }

    if (data.conditions?.length > 0) {
        data.conditions = data.conditions.map(condition => ({
            ...condition,
            value: CURRENCY_CONDITIONS.includes(condition.condition)
                ? Math.round(parseFloat(condition.value) * 100)
                : NUMERIC_CONDITIONS.includes(condition.condition)
                ? parseInt(condition.value)
                : condition.value,
        }))
    }

    if (data.promotionCodes?.length > 0) {
        data.promotionCodes = data.promotionCodes.map(code => ({
            ...code,
            maximumUses: Number(code.maximumUses),
            maximumUsesPerEmail: Number(code.maximumUsesPerEmail),
        }))
    }

    if (data.discounts?.length > 0) {
        data.discounts = data.discounts.map(discount => {
            if (discount?.type !== 'shipping.percentage' && discount.shipping_services) {
                delete discount.shipping_services
            }

            if (discount?.type === 'fixed') {
                discount.value = Math.round(parseFloat(discount.value) * 100)
            }

            if (!['free_product', 'free_product_same_quantity'].includes(discount.type)) {
                delete discount.automatically_add_product
                delete discount.quantity_discount_cap
            } else {
                discount.automatically_add_product =
                    discount.type === 'free_product_same_quantity' ||
                    discount.automatically_add_product
                delete discount.value

                if (discount.type === 'free_product_same_quantity') {
                    delete discount.target_products
                }
            }

            if (discount?.trigger_products?.length === 0) {
                delete discount.trigger_products
            }

            if (discount?.target_products?.length === 0) {
                delete discount.target_products
            }

            return discount
        })
    }

    if (data.promotionCompatibilities?.length > 0) {
        data.promotionCompatibilities = data.promotionCompatibilities.map(compatibility => {
            return { incompatiblePromotion: compatibility?.incompatiblePromotion?.['@id'] }
        })
    }

    return { ...data }
}

export const formatDataForForm = data => {
    const formattedData = { ...data }

    if (formattedData.conditions?.length > 0) {
        formattedData.conditions = formattedData.conditions.map(condition => ({
            ...condition,
            value: CURRENCY_CONDITIONS.includes(condition.condition)
                ? parseInt(condition.value) / 100
                : NUMERIC_CONDITIONS.includes(condition.condition)
                ? parseInt(condition.value)
                : condition.value,
        }))
    }

    if (formattedData.discounts?.length > 0) {
        formattedData.discounts = formattedData.discounts.map(discount => {
            if (discount?.type === 'fixed') {
                discount.value = (discount.value / 100).toFixed(2)
            }

            return discount
        })
    }

    return { ...formattedData }
}
