import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Select,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    babbage_account_number: yup.string().required(),
    babbage_branch_id: yup
        .number()
        .required()
        .typeError("Branch ID must be a number"),
    part_number: yup.string().required(),
    trading_country: yup.string().required(),
});

export const GetPartsDetailForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/get-parts-detail`,
                formData,
                {
                    headers,
                }
            );

            setRequestData(data?.request);
            setResponseData(data?.response);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    GetPartsDetail | Babbage Soap | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Get Parts Detail" />

                <Form
                    autoComplete="on"
                    values={{
                        babbage_account_number: "",
                        babbage_branch_id: "",
                        part_number: "",
                        trading_country: "gb",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="babbage-part-details"
                        id="babbage-part-details"
                        name="Babbage Part Details"
                    >
                        <Input
                            id="babbage_account_number"
                            name="babbage_account_number"
                            label="Babbage Account Number"
                            help="Example: A0001"
                            testId="babbageAccountNumber"
                            required
                        />

                        <Input
                            id="babbage_branch_id"
                            name="babbage_branch_id"
                            label="Branch ID"
                            help="246 for Wembley, 1401 for Dublin"
                            testId="babbageBranchId"
                            required
                        />

                        <Input
                            id="part_number"
                            name="part_number"
                            label="Part Number"
                            help="Example: 104111715"
                            testId="partNumber"
                            required
                        />

                        <Select
                            name="trading_country"
                            label="Trading Country"
                            placeholder="Please select..."
                            help="Country to Query"
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "gb",
                                },
                                { label: "Ireland", value: "ie" },
                            ]}
                            testId="tradingCountry"
                        />
                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData?.length > 0 && !loading && (
                        <Group
                            key="request-details"
                            id="request-details"
                            name="Request Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Request to Babbage
                                </h4>{" "}
                                <CopyButton content={requestData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter code={requestData} />
                            </div>
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Response from Babbage
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter code={responseData} />
                            </div>
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

GetPartsDetailForm.displayName = "GetPartsDetailForm";
