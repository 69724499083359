import React from "react";
import { Route, Switch } from "react-router-dom";
import { BrandList, BrandAdd, BrandEdit, BrandForm } from "./Brand";

export default () => {
    return {
        routes: [
            {
                path: "/brands",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={BrandList}
                        />
                        <Route path={`${match.url}/add`} exact>
                            {(props) => (
                                <BrandAdd {...props} BrandForm={BrandForm} />
                            )}
                        </Route>
                        <Route path={`${match.url}/:id`} exact>
                            {(props) => (
                                <BrandEdit {...props} BrandForm={BrandForm} />
                            )}
                        </Route>
                    </Switch>
                ),
            },
        ],
    };
};
