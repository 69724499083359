import React from 'react'

const TextComponent = props => {
    const { Controls, Renderer, isEditMode } = props

    let editing = isEditMode

    return <div>{!editing ? <Renderer {...props} /> : <Controls {...props} />}</div>
}

export default TextComponent
