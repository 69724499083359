import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Select,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    trading_country: yup.string(),
    vin: yup.string().required(),
});

export const VinLookupForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/vehicle-details`,
                {
                    1: {
                        Name: "VIN",
                        Value: formData.vin,
                    },
                    country: formData.trading_country,
                },
                {
                    headers,
                }
            );

            setRequestData(data?.request);
            setResponseData(data?.response);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>VIN Lookup | Raindata | Web Services | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="VIN Lookup" />

                <Form
                    autoComplete="on"
                    values={{
                        trading_country: "GB",
                        vin: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group key="vin" id="vin" name="VIN">
                        <Select
                            name="trading_country"
                            label="Trading Country"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "GB",
                                },
                                { label: "Ireland", value: "IE" },
                            ]}
                            testId="tradingCountry"
                        />

                        <Input name="vin" label="VIN" testId="vin" required />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData?.length > 0 && !loading && (
                        <Group
                            key="request-details"
                            id="request-details"
                            name="Request Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Raindata Request
                                </h4>{" "}
                                <CopyButton content={requestData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={requestData}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Raindata Response
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={responseData}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

VinLookupForm.displayName = "VinLookupForm";
