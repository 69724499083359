import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Select,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    trading_country: yup.string(),
    vrm: yup.string().required(),
    component_codes: yup.string().required(),
});

export const SearchByComponentTreeForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState("");
    const [responseData, setResponseData] = useState("");

    const [showRequestData, setShowRequestData] = useState(false);
    const [showResponseData, setShowResponseData] = useState(false);
    const [showTableData, setShowTableData] = useState(false);

    const [tableData, setTableData] = useState([]);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/raindata-searchbycomponenttree`,
                {
                    vehicleDetails: {
                        0: { Name: "VRM", Value: formData.vrm },
                    },
                    country: formData.trading_country,
                    components: formData?.component_codes,
                },
                {
                    headers,
                }
            );

            const tableRows =
                JSON.parse(data?.searchByComponentTreeResponse)?.Parts || [];

            setRequestData(data?.searchByComponentTreeRequest);
            setResponseData(data?.searchByComponentTreeResponse);
            setTableData(tableRows);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Search By Component Tree | Raindata | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Search By Component Tree" />

                <Form
                    autoComplete="on"
                    values={{
                        trading_country: "GB",
                        vrm: "",
                        component_codes: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group key="vrm" id="vrm" name="Search By Component Tree">
                        <Select
                            name="trading_country"
                            label="Trading Country"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "GB",
                                },
                                { label: "Ireland", value: "IE" },
                            ]}
                            testId="tradingCountry"
                            required={true}
                        />

                        <Input
                            name="vrm"
                            label="VRM"
                            testId="vrm"
                            help="IE REG: 142D4920, GB REG YR04FWE"
                            required
                        />

                        <Input
                            name="component_codes"
                            label="Component Codes"
                            testId="componentCodes"
                            help="Example component code = 7740 (brake pads), 7659 (air filter)"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {requestData?.length > 0 && !loading && (
                        <Group
                            key="request-details"
                            id="request-details"
                            name="Request Details"
                        >
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Raindata Request
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={requestData} />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowRequestData(
                                                !showRequestData
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showRequestData
                                                    ? faMinus
                                                    : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showRequestData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showRequestData && (
                                <div className="border rounded-[4px] p-2  mt-3">
                                    <CodeFormatter
                                        code={requestData}
                                        language="json"
                                    />
                                </div>
                            )}
                        </Group>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="response-details"
                            id="response-details"
                            name="Response Details"
                        >
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Raindata Response
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={responseData} />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowResponseData(
                                                !showResponseData
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showResponseData
                                                    ? faMinus
                                                    : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showResponseData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showResponseData && (
                                <div className="border rounded-[4px] p-2 mt-3">
                                    <CodeFormatter
                                        code={responseData}
                                        language="json"
                                    />
                                </div>
                            )}
                        </Group>
                    )}

                    {tableData?.length > 0 && !loading && (
                        <Group key="parts" id="parts" name="Parts">
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Parts
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton content={tableData} />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTableData(!showTableData);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showTableData ? faMinus : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showTableData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showTableData && (
                                <table className="table table-sm table-fixed mt-3">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Image (DO NOT USE)</th>
                                            <th>Component Number</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tableData.map((row, idx) => {
                                            return (
                                                <tr
                                                    key={row?.PartNumber || idx}
                                                >
                                                    <td>
                                                        {row?.ComponentName ||
                                                            "-"}
                                                    </td>
                                                    <td>
                                                        <img
                                                            src={
                                                                row?.ImagePath ||
                                                                ""
                                                            }
                                                            alt={
                                                                row?.ComponentName ||
                                                                "-"
                                                            }
                                                            className="max-w-[120px]"
                                                        />
                                                    </td>
                                                    <td>
                                                        {row?.PartNumber || "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

SearchByComponentTreeForm.displayName = "SearchByComponentTreeForm";
