import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { useClient, GET_MANY, DELETE } from "@peracto/client";
import { toast } from "react-toastify";
import { bool } from "prop-types";
import { FormActions, Modal, Form } from "@peracto/peracto-ui";
import { LocationFormContent } from "./LocationFormContent";

const DEFAULT_VALUES = {
    metaDescription: "",
    metaTitle: "",
    website: "",
    telephone: "",
    email: "",
    name: "",
    heading: "",
    identifier: null,
    line1: "",
    line2: "",
    line3: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    locationStatus: "open",
    locationPageStatus: "active",
    slug: "",
    locationTypes: [],
    latitude: "",
    longitude: "",
    specialOpeningHours: "",
    generalInformation: "",
    formLocationFeatures: {},

    // ECP
    seasonalOpeningLabel: "",
    seasonalOpeningDisplayStart: "",
    seasonalOpeningDisplayEnd: "",
    seasonalOpeningTimes: "",
    ecpBranchId: "",
    openDate: "",
    closeDate: "",
    showOnStoreLocator: true,
    straightToPrint: false,
    saturdayHours: "",
    sundayHours: "",
    mondayHours: "",
    tuesdayHours: "",
    wednesdayHours: "",
    thursdayHours: "",
    fridayHours: "",
    buildingName: "",
    streetNo: "",
};

export const LocationsForm = ({
    data,
    modeAdd = false,
    modeEdit = false,
    ...props
}) => {
    const { client } = useClient();
    const [countries, setCountries] = useState();
    const [redirect, setRedirect] = useState();
    const [modalOpen, toggleModal] = useState(false);

    const days = [
        "Saturday",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
    ];

    const fetchCountries = async () => {
        try {
            const { data: countryData, response: countryResponse } =
                await client(GET_MANY, "countries", {
                    id: "countries",
                });

            if (countryResponse.status === 404) {
                setRedirect("/locations");
            }

            setCountries(countryData);
        } catch (e) {
            console.error(e);
            setRedirect("/locations");
        }
    };

    const onDelete = async () => {
        try {
            await client(DELETE, "locations", {
                id: data.id,
            });

            toast.success("Location deleted successfully!");
            setRedirect("/locations");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    useEffect(() => {
        fetchCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            <FormActions
                children={
                    modeEdit ? (
                        <>
                            <button
                                className="btn btn--outline-primary text-danger px-0"
                                onClick={() => toggleModal(!modalOpen)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="mr-2"
                                />
                                Delete Location
                            </button>
                        </>
                    ) : null
                }
            />
            <Form
                autoComplete="false"
                values={{
                    ...DEFAULT_VALUES,
                    ...data,
                }}
                className="location-form"
                {...props}
            >
                <LocationFormContent
                    days={days}
                    countries={countries}
                    modeEdit={modeEdit}
                />
            </Form>

            {modeEdit && (
                <>
                    <Modal
                        isVisible={modalOpen}
                        title="Delete Location"
                        close={() => toggleModal(!modalOpen)}
                        buttons={[
                            {
                                type: "btn-outline-secondary",
                                text: "Close",
                                action: () => toggleModal(!modalOpen),
                            },
                            {
                                type: "btn-danger",
                                text: "Delete Location",
                                action: () => onDelete(),
                            },
                        ]}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="mb-4 d-block"
                        />
                        Are you sure you would like to permanently delete{" "}
                        {data.name}? Deleted locations cannot be recovered.
                    </Modal>
                </>
            )}
        </>
    );
};

LocationsForm.propTypes = {
    modeAdd: bool,
    modeEdit: bool,
};
