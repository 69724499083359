import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import { Helmet } from "react-helmet";
import classNames from "classnames";
import startCase from "lodash/startCase";

import {
    HydraTable,
    Heading,
    TextFilter,
    SelectFilter,
    StyledCheckbox,
} from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";
import { highlightOptions } from "../util";

export const BrandList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        hits: false,
        created: false,
    });

    const { client } = useClient();

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(
        () => [
            {
                id: "selection",
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
                ),
                Cell: ({ row }) => (
                    <StyledCheckbox
                        {...row.getToggleRowSelectedProps()}
                        data-testid={`row-${row.index}-checkbox`}
                    />
                ),
                selectable: false,
            },
            {
                Header: "Display Name",
                accessor: "name",
                sortType: "basic",
                Filter: (props) => (
                    <TextFilter label="Display Name" {...props} />
                ),
                Cell: (row) => (
                    <Link
                        to={row.row.original.id}
                        data-testid={`row-${row.row.index}-display-name`}
                    >
                        {row.cell.value}
                    </Link>
                ),
                selectable: false,
            },
            {
                Header: "Image",
                accessor: "image",
                sortType: "basic",
                Cell: (row) =>
                    row.cell.value ? (
                        <Link
                            to={row.row.original.id}
                            data-testid={`row-${row.row.index}-display-name`}
                        >
                            <img
                                src={row.cell.value}
                                className="max-h-[30px]"
                                alt={row.row.original.name}
                            />
                        </Link>
                    ) : (
                        "No image available"
                    ),
                selectable: false,
                disableSorting: true,
            },
            {
                Header: "Highlight",
                accessor: "highlight",
                sortType: "basic",
                Filter: (props) => (
                    <SelectFilter
                        label="Highlight"
                        options={highlightOptions}
                        isMulti={true}
                        {...props}
                    />
                ),
                Cell: ({ cell: { value } }) => {
                    if (value === "none") {
                        return startCase(value);
                    } else {
                        return (
                            <span className="text-[90%] bg-brand-secondary text-brand-black leading-1 py-[3px] px-[7px] rounded-full">
                                {startCase(value)}
                            </span>
                        );
                    }
                },
                selectable: false,
            },
            {
                Header: "Status",
                accessor: "status",
                sortType: "basic",
                Filter: (props) => <TextFilter label="Status" {...props} />,
                Cell: ({ cell: { value } }) => (
                    <span
                        className={classNames("badge", "badge-pill", {
                            "badge-success": value === "active",
                            "badge-danger": value === "inactive",
                        })}
                    >
                        {startCase(value)}
                    </span>
                ),
                selectable: false,
            },
        ],
        []
    );

    const onFetchData = (options) => client(GET_LIST, "brands", options);

    return (
        <>
            <Helmet>
                <title>Brands | Peracto</title>
            </Helmet>
            <Heading name="Brands">
                <div className="d-flex align-items-center ml-2">
                    <Link
                        className="btn btn-primary"
                        data-testid="add-redirect"
                        to="/brands/add"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Brand
                    </Link>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    name: "",
                                    highlight: "",
                                    status: "",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
