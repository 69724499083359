import React from "react";

import { Modal } from "@peracto/peracto-ui";
import { CopyButton } from "../../../../components/CopyButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons/faExternalLinkAlt";

export const LoginAsUserModal = (props) => {
    const { showModal, closeModal, fields } = props;

    const { email, password } = fields;

    return (
        <Modal
            isVisible={showModal}
            title="Login as User"
            hideCloseButton={true}
            buttons={[
                {
                    type: "btn-outline-secondary ml-auto",
                    text: "Close",
                    action: () => closeModal(),
                },
            ]}
        >
            <div className="w-full text-left">
                {process.env.REACT_APP_STOREFRONT_URL && (
                    <p className="mb-2">
                        To log in as this user, go to{" "}
                        <a
                            className="text-primary underline"
                            href={process.env.REACT_APP_STOREFRONT_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {process.env.REACT_APP_STOREFRONT_URL}
                            <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                className="ml-1"
                            />
                        </a>{" "}
                        and enter the following credentials:
                    </p>
                )}
                <div className="form-group mb-2">
                    <div className="flex justify-between items-center mb-2">
                        <label htmlFor="email" className="mb-0">
                            Email
                        </label>
                        <CopyButton content={email} />
                    </div>

                    <div className="input-group">
                        <input
                            className="form-control"
                            value={email}
                            readOnly
                        />
                    </div>
                </div>

                <div className="form-group mb-2">
                    <div className="flex justify-between items-center mb-2">
                        <label htmlFor="password" className="mb-0">
                            Password
                        </label>
                        <CopyButton content={password} />
                    </div>

                    <div className="input-group">
                        <input
                            className="form-control"
                            value={password}
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
};
