/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Field, FieldArray } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'
import { GET_LIST, useClient } from '@peracto/client'

import MinimumRequirementsRow from './MinimumRequirementsRow'

const MinimumRequirements = ({ name }) => {
    const [customerGroups, setCustomerGroups] = useState([])

    const { client } = useClient()

    const defaultCondition = {
        value: '1',
        condition: 'basket.units',
        operation: '=',
    }

    const fetchCustomerGroups = async () => {
        const { data } = await client(GET_LIST, 'customer-groups', {
            id: 'customer-groups',
            filter: {
                itemsPerPage: 10000,
            },
        })

        const values = data.map(val => ({
            label: val.name,
            value: val.name,
        }))

        setCustomerGroups(values)
    }

    useEffect(() => {
        fetchCustomerGroups()
    }, [])

    return (
        <Field name={name}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => {
                            return (
                                <div className="form-group">
                                    {field.value && field.value.length > 0 ? (
                                        <>
                                            <p className="mt-3">
                                                The following must be true to attempt to apply the
                                                discount.{' '}
                                            </p>

                                            {field.value.map((option, index) => (
                                                <div key={`requirement_${index}`}>
                                                    <MinimumRequirementsRow
                                                        {...{
                                                            index,
                                                            form,
                                                            field,
                                                            remove,
                                                            customerGroups,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <p className="py-3 my-3 text-center">
                                            No requirements have been applied to this promotion
                                        </p>
                                    )}

                                    <div className="mt-4 d-flex align-items-center justify-content-end">
                                        <button
                                            type="button"
                                            className="px-0 mt-2 btn btn-link"
                                            onClick={() => {
                                                push(defaultCondition)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                                            Add New Requirement
                                        </button>
                                    </div>
                                </div>
                            )
                        }}
                    </FieldArray>
                </>
            )}
        </Field>
    )
}

export default MinimumRequirements
