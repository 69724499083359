import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRectanglePortrait } from "@fortawesome/pro-regular-svg-icons/faRectanglePortrait";

export const defaultTranslations = {
    pluginName: "Cards",
    pluginDescription: "Add content cards",
};

export const defaultSettings = {
    Controls: () => <>Controls for this plugin were not provided</>,
    Renderer: () => <>Renderer for this plugin was not provided</>,
    translations: defaultTranslations,
    IconComponent: <FontAwesomeIcon icon={faRectanglePortrait} size="3x" />,
};
