/*
 * This file is a clone of node-renderer-default.js from the 'react-sortable-tree' package
 * which has been brought into the project to allow us to make changes to the default template.
 * https://github.com/frontend-collective/react-sortable-tree/blob/master/src/node-renderer-default.js
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { classnames } from "./utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import classNames from "classnames";

class CategoryTreeRow extends Component {
    render() {
        const {
            scaffoldBlockPxWidth,
            toggleChildrenVisibility,
            connectDragPreview,
            connectDragSource,
            isDragging,
            canDrop,
            canDrag,
            node,
            title,
            linkText,
            subtitle,
            pathName,
            displayFrom,
            displayTo,
            draggedNode,
            path,
            treeIndex,
            isSearchMatch,
            isSearchFocus,
            buttons,
            className,
            style,
            didDrop,
            treeId,
            isOver, // Not needed, but preserved for other renderers
            parentNode, // Needed for dndManager
            rowDirection,
            status,
            ...otherProps
        } = this.props;
        const nodeTitle = title || node.title;
        const nodeSubtitle = pathName || node.pathName;
        const isActive = status === "active" || node.status === "active";
        const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;

        let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
        if (rowDirection === "rtl") {
            buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
        }

        return (
            <div style={{ height: "100%" }} {...otherProps}>
                {toggleChildrenVisibility &&
                    node.children &&
                    (node.children.length > 0 ||
                        typeof node.children === "function") && (
                        <div>
                            <button
                                type="button"
                                aria-label={
                                    node.expanded ? "Collapse" : "Expand"
                                }
                                className={classnames(
                                    node.expanded
                                        ? "rst__collapseButton"
                                        : "rst__expandButton",
                                    rowDirectionClass
                                )}
                                style={{
                                    ...buttonStyle,
                                    backgroundColor: "#ffffff",
                                    color: "#000",
                                    fontSize: "12px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() =>
                                    toggleChildrenVisibility({
                                        node,
                                        path,
                                        treeIndex,
                                    })
                                }
                            >
                                <FontAwesomeIcon
                                    icon={node.expanded ? faMinus : faPlus}
                                />
                            </button>

                            {node.expanded && !isDragging && (
                                <div
                                    style={{ width: scaffoldBlockPxWidth }}
                                    className={classnames(
                                        "rst__lineChildren",
                                        rowDirectionClass
                                    )}
                                />
                            )}
                        </div>
                    )}

                <div
                    className={classnames("rst__rowWrapper", rowDirectionClass)}
                >
                    {/* Set the row preview to be used during drag and drop */}
                    <div
                        className={classnames(
                            "rst__row",
                            isSearchMatch && "rst__rowSearchMatch",
                            isSearchFocus && "rst__rowSearchFocus",
                            rowDirectionClass,
                            className
                        )}
                        style={{
                            ...style,
                        }}
                    >
                        <div
                            className={classnames(
                                "rst__rowContents",
                                "d-flex",
                                "align-items-center",
                                "justify-conetent-between",
                                !canDrag && "rst__rowContentsDragDisabled",
                                rowDirectionClass
                            )}
                            style={{
                                borderLeft: "1px solid #bbb",
                            }}
                        >
                            <div
                                className={classnames(
                                    "rst__rowLabel",
                                    rowDirectionClass
                                )}
                            >
                                <span className={classnames("rst__rowTitle")}>
                                    {typeof nodeTitle === "function"
                                        ? nodeTitle({
                                              node,
                                              path,
                                              treeIndex,
                                          })
                                        : nodeTitle}

                                    {nodeSubtitle && (
                                        <>
                                            <span className="rst__rowSubtitle">
                                                {typeof nodeSubtitle ===
                                                "function"
                                                    ? ` (${nodeSubtitle({
                                                          node,
                                                          path,
                                                          treeIndex,
                                                      })})`
                                                    : ` (${nodeSubtitle})`}
                                            </span>
                                        </>
                                    )}

                                    <span
                                        data-testid="category-row-isActive"
                                        className={classNames(
                                            "badge",
                                            "badge-pill",
                                            "ml-1",
                                            {
                                                "badge-danger": !isActive,
                                                "badge-success": isActive,
                                            }
                                        )}
                                    >
                                        {isActive ? "Active" : "Inactive"}
                                    </span>
                                </span>
                            </div>

                            <div className="rst__rowToolbar">
                                <div>
                                    {buttons.map((btn, index) => (
                                        <div
                                            key={index} // eslint-disable-line react/no-array-index-key
                                            className="rst__toolbarButton"
                                        >
                                            {btn}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CategoryTreeRow.defaultProps = {
    isSearchMatch: false,
    isSearchFocus: false,
    canDrag: false,
    toggleChildrenVisibility: null,
    buttons: [],
    className: "",
    style: {},
    parentNode: null,
    draggedNode: null,
    canDrop: false,
    title: null,
    subtitle: null,
    rowDirection: "ltr",
};

CategoryTreeRow.propTypes = {
    node: PropTypes.shape({}).isRequired,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    path: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ).isRequired,
    treeIndex: PropTypes.number.isRequired,
    treeId: PropTypes.string.isRequired,
    isSearchMatch: PropTypes.bool,
    isSearchFocus: PropTypes.bool,
    canDrag: PropTypes.bool,
    scaffoldBlockPxWidth: PropTypes.number.isRequired,
    toggleChildrenVisibility: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
    style: PropTypes.shape({}),

    // Drag and drop API functions
    // Drag source
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    parentNode: PropTypes.shape({}), // Needed for dndManager
    isDragging: PropTypes.bool.isRequired,
    didDrop: PropTypes.bool.isRequired,
    draggedNode: PropTypes.shape({}),
    // Drop target
    isOver: PropTypes.bool.isRequired,
    canDrop: PropTypes.bool,

    // rtl support
    rowDirection: PropTypes.string,
};

export default CategoryTreeRow;
