import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { HydraTable, Heading, TextFilter } from "@peracto/peracto-ui";
import { GET_LIST, useClient } from "@peracto/client";
import { parseISO } from "date-fns";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const ApplicationList = () => {
  // Default visible columns - key = column accessor
  const [selectedColumns, onSelectColumn] = useState({});

  const { client } = useClient();

  // The 'selectable' property is used to define if the
  // column is available in the 'manage columns' dropdown
  const columns = useMemo(
    () => [
      {
        Header: "User Email",
        accessor: "email",
        Filter: (props) => <TextFilter label="User Email" {...props} />,
        Cell: (row) => <span className="font-normal">{row.cell.value}</span>,
        selectable: false,
        disableSorting: true,
      },
      {
        Header: "Babbage Account",
        accessor: "accountNumber",
        Filter: (props) => <TextFilter label="Babbage Account" {...props} />,
        Cell: (row) => <span className="font-normal">{row.cell.value}</span>,
        selectable: false,
        disableSorting: true,
      },
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: (row) => (
          <span className="font-normal">
            {dayjs(parseISO(row.cell.value)).format("DD MMM YYYY HH:mm:ss")}
          </span>
        ),
        selectable: false,
        disableSorting: true,
      },
      {
        Header: "Action",
        selectable: false,
        Cell: (row) => {
          return (
            <Link to={row.row.original.id}>
              <button className="mr-2 btn btn-primary">View</button>
            </Link>
          );
        },
        disableSorting: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFetchData = (options) =>
    client(GET_LIST, "fifm-applications", options);

  return (
    <>
      <Helmet>
        <title>Fit It For Me | Peracto</title>
      </Helmet>
      <Heading name="Fit It For Me " />

      <div className="row">
        <div className="col">
          <div className="card card--transparent">
            <div className="card-body">
              <HydraTable
                columns={columns}
                selectedColumns={selectedColumns}
                onFetchData={onFetchData}
                onSelectColumn={onSelectColumn}
                defaultFilters={{
                  accountNumber: "",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
