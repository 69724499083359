import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";

import {
    GET_ONE,
    UPDATE,
    useClient,
    getSchemaFromResource,
} from "@peracto/client";

import { MODE_EDIT } from "../BrandForm";

const BrandEditContainer = ({ children }) => {
    return (
        <div className="form-container">
            <Heading name="Edit Brand">
                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                    <Link
                        className="btn btn-outline-primary"
                        data-testid="back-to-brands"
                        to="/brands"
                    >
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            className="mr-2"
                        />
                        Back to Brands
                    </Link>
                </div>
            </Heading>
            {children}
        </div>
    );
};

export const BrandEdit = ({ BrandForm, match: { params } }) => {
    const { client, getResource } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();
    const [brandData, setBrandData] = useState();

    const fetchBrand = async () => {
        try {
            const { data, response } = await client(GET_ONE, "brands", {
                id: `/brands/${params.id}`,
            });

            if (response.status === 404) {
                setRedirect("/brands");
                setLoading(false);
                return;
            }

            setBrandData(data);
            setLoading(false);
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/brands");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBrand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const schema = getSchemaFromResource(getResource("brands")).shape({
        name: yup.string().required(),
        displayOrder: yup.number().required(),
        code: yup.string().required("Brand Code is a required field"),
    });

    const onSubmit = async (data, actions) => {
        try {
            const parsedData = {
                ...data,
                displayOrder: parseInt(data.displayOrder),
            };

            const response = await client(UPDATE, "brands", {
                id: `/brands/${params.id}`,
                data: parsedData,
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            } else {
                actions.setSubmitting(false);
                actions.setSubmitting(false);
                actions.resetForm(data);
                setBrandData(data);

                toast.success("Brand successfully updated!");
            }
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }
            actions.setSubmitting(false);
        }
    };

    if (loading) {
        return (
            <BrandEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </BrandEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/brands"
                message="The brand you're looking for could not be found"
                buttonLabel="Go to Brands"
            />
        );
    }

    return (
        <BrandEditContainer>
            {redirect ? (
                <Redirect to={redirect} />
            ) : (
                <>
                    <Helmet>
                        <title>
                            {brandData?.name || "Brand"} | Edit | Peracto
                        </title>
                    </Helmet>

                    <BrandForm
                        values={brandData}
                        onSubmit={onSubmit}
                        schema={schema}
                        mode={MODE_EDIT}
                    />
                </>
            )}
        </BrandEditContainer>
    );
};
