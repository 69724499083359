/* eslint-disable */

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle'

import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { toast } from 'react-toastify'

import {
    HydraTable,
    Heading,
    TextFilter,
    DateFilter,
    SelectFilter,
    StyledCheckbox,
    TimeAgo,
} from '@peracto/peracto-ui'

import { useConfig } from '@peracto/peracto-config'
import { GET_LIST, CREATE, useClient } from '@peracto/client'

const PromotionsList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        startDateTime: false,
        endDateTime: false,
    })

    const { client } = useClient()

    const config = useConfig()
    const formatDate = config.get('user_format_date', config.get('format_date'))
    const API_URL = config.get('api')

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <StyledCheckbox {...row.getToggleRowSelectedProps()} />,
            selectable: false,
        },

        {
            Header: 'Name',
            accessor: 'name',
            sortType: 'basic',
            Filter: props => <TextFilter label="Name" {...props} />,
            Cell: row => <Link to={row.row.original.id}>{row.cell.value}</Link>,
            selectable: false,
        },
        {
            Header: 'Status',
            accessor: 'status',
            sortType: 'basic',
            Filter: props => (
                <SelectFilter
                    label="Status"
                    options={[
                        { value: 'active', label: 'Active' },
                        { value: 'archived', label: 'Archived' },
                    ]}
                    {...props}
                />
            ),
            Cell: ({ cell: { value } }) => (
                <span
                    className={classNames('badge', 'badge-pill', 'text-capitalize', {
                        'badge-success': value == 'active',
                        'badge-danger': value == 'archived',
                    })}
                >
                    {value}
                </span>
            ),
            selectable: false,
        },
        {
            Header: 'Start Date',
            accessor: 'startDateTime',
            sortType: 'basic',
            Filter: props => <DateFilter label="Start Date" {...props} />,
            Cell: ({ cell: { value } }) =>
                value ? <TimeAgo title={formatDate(new Date(value))} date={value} /> : '-',
            show: selectedColumns['startDateTime'],
            selectable: true,
        },
        {
            Header: 'End Date',
            accessor: 'endDateTime',
            sortType: 'basic',
            Filter: props => <DateFilter label="End Date" {...props} />,
            Cell: ({ cell: { value } }) =>
                value ? <TimeAgo title={formatDate(new Date(value))} date={value} /> : '-',
            show: selectedColumns['endDateTime'],
            selectable: true,
        },
    ]

    const onFetchData = options => client(GET_LIST, 'promotions', options)

    const handleChange = async e => {
        const file = e.currentTarget.files[0]

        if (!file) return

        const reader = new FileReader()

        reader.readAsDataURL(file)

        // Create FormData. API is expecting `{file: file}` as form-data.
        const formData = new FormData()
        formData.append('file', file)
        formData.append('importType', 'promotion_codes')
        formData.append('sourceType', 'csv')

        try {
            const response = await axios.post(`${API_URL}/imports`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            })
            console.log('success:', response)
            toast.success('Promotion codes successfully uploaded!')
        } catch (error) {
            const { response } = error
            console.log('error:', response)
            toast.error(
                error?.error?.body?.hasOwnProperty('hydra:description')
                    ? e.error.body['hydra:description']
                    : 'Whoops, there was a problem...'
            )
        }
    }

    return (
        <>
            <Helmet>
                <title>Promotions | Peracto</title>
            </Helmet>
            <Heading name="Promotions">
                <div className="ml-2 d-flex align-items-center">
                    <div className="overflow-hidden position-relative">
                        <input
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                opacity: 0,
                                cursor: 'pointer',
                            }}
                            type="file"
                            id="promoUpload"
                            onChange={evt => {
                                handleChange(evt)
                            }}
                            accept=".csv"
                        />
                        <button className="mr-2 btn btn-outline-primary">
                            <FontAwesomeIcon icon={faUpload} className="mr-2" />
                            Upload Promotion Codes
                        </button>
                    </div>

                    <Link
                        className="btn btn-primary"
                        to="/promotions/add"
                        data-testid="add-promotion"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                        Add Promotion
                    </Link>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    name: '',
                                    status: 'active',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PromotionsList
