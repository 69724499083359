/* eslint-disable */
import React, { useState, useMemo } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons/faExternalLink";
import { faFileCsv } from "@fortawesome/pro-regular-svg-icons/faFileCsv";
import { faUpload } from "@fortawesome/pro-regular-svg-icons/faUpload";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";

import { Helmet } from "react-helmet";
import { Field, getIn } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import isString from "lodash/isString";

import {
  Heading,
  Group,
  Form,
  Checkbox,
  Select,
  Error,
} from "@peracto/peracto-ui";

import { useConfig } from "@peracto/peracto-config";

import AttributesImportList from "./AttributesImportList";

const AttributesImport = () => {
  const config = useConfig();
  const API_URL = config.get("api");
  const [selectedFile, setSelectedFile] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showImportHistory, setShowImportHistory] = useState(false);
  const [missingProperties, setMissingProperties] = useState();
  const [unrecognisedColumns, setUnrecognisedColumns] = useState();
  const [redirect, setRedirect] = useState();

  const handleSubmit = async ({ file }, actions) => {
    if (!file) return;

    setIsSubmitting(true);
    setMissingProperties();
    setUnrecognisedColumns();

    const reader = new FileReader();

    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("importType", "attribute");
    formData.append("sourceType", "csv");

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    try {
      const response = await axios.post(`${API_URL}/imports`, formData, {
        headers,
      });

      if (response?.data?.errors?.length > 0) {
        console.error("error:", response);
        const errorMessages = response?.data?.errors.map((err) => err.message);
        toast.error(errorMessages.join("\n"));

        const invalidColumnData = response.data.errors.find(
          (err) => err.type === "invalid_columns"
        );

        if (invalidColumnData?.extra_data?.missing_columns?.length > 0) {
          setMissingProperties(
            invalidColumnData.extra_data.missing_columns.join(", ")
          );
        }

        if (invalidColumnData?.extra_data?.unrecognised_columns?.length > 0) {
          setUnrecognisedColumns(
            invalidColumnData.extra_data.unrecognised_columns.join(", ")
          );
        }
      } else {
        console.log("success:", response);
        toast.success(
          "Attribute data successfully uploaded, the import will run shortly."
        );
        setRedirect(`/attributes/import/${response.data.id}`);
      }
    } catch (e) {
      const { response } = e;
      console.error("error:", response);
      if (response?.data?.violations?.length > 0) {
        // Display errors for invalid fields
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Helmet>
        <title>Import Attribute Data | Peracto</title>
      </Helmet>
      <div className="form-container">
        <Heading name="Import Attribute Data">
          <div className="ml-2 d-flex align-items-center">
            <Link
              className="btn btn-outline-primary"
              to="/Attributes"
              data-testid="back-to-attributes"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
              Back to Attributes
            </Link>
          </div>
        </Heading>

        <Group key="import-data" id="import-data" name="Import Data">
          <Form
            values={{
              file: "",
            }}
            showActionPanel={false}
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12">
                <ol className="py-2">
                  <li>
                    <label htmlFor="importType" className="mb-2">
                      Which CSV file are you uploading?
                    </label>

                    <Field name="file">
                      {({ field, form }) => {
                        let error = getIn(form.errors, field.name);

                        return (
                          <div className="mb-3 d-flex flex-column align-items-start">
                            <div className="overflow-hidden position-relative">
                              <input
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  opacity: 0,
                                  cursor: "pointer",
                                }}
                                name={field.name}
                                required
                                onChange={(e) => {
                                  if (e?.currentTarget?.files) {
                                    setSelectedFile(
                                      e.currentTarget.files[0].name
                                    );
                                    form.setFieldValue(
                                      field.name,
                                      e.currentTarget.files[0]
                                    );
                                  }
                                }}
                                type="file"
                                id="fileUpload"
                                accept=".csv"
                              />
                              <div className="d-flex align-items-center">
                                <button className="mr-2 btn btn-outline-primary">
                                  <FontAwesomeIcon
                                    icon={faFileCsv}
                                    className="mr-2"
                                  />
                                  Choose File
                                </button>

                                <span>
                                  {selectedFile || "No File Selected"}
                                </span>
                              </div>
                            </div>

                            {error && isString(error) && (
                              <div className="mb-2">
                                <Error error={error} name={field.name} />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Field>

                    <a
                      className="px-0 my-2 btn btn-link"
                      href="https://9xbagency.atlassian.net/wiki/spaces/PER/pages/243367938/Importing+and+Bulk+Managing+Product+Data"
                      target="_blank"
                    >
                      Learn more about Bulk Management{" "}
                      <FontAwesomeIcon icon={faExternalLink} className="mr-2" />
                    </a>
                  </li>

                  <li>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      <FontAwesomeIcon icon={faUpload} className="mr-2" />
                      Upload File
                    </button>

                    {missingProperties && (
                      <p className="mt-2 text-danger">
                        Missing properties: {missingProperties}
                      </p>
                    )}

                    {unrecognisedColumns && (
                      <p className="mt-2 text-danger">
                        Unrecognised columns: {unrecognisedColumns}
                      </p>
                    )}
                  </li>
                </ol>
              </div>
            </div>
          </Form>
        </Group>

        <div className="mb-3 d-flex justify-content-center">
          <button
            className="btn btn-link"
            onClick={() => setShowImportHistory(!showImportHistory)}
          >
            <FontAwesomeIcon
              icon={showImportHistory ? faTimes : faHistory}
              className="mr-2"
            />
            {showImportHistory ? "Hide" : "Show"} Import History
          </button>
        </div>

        <ImportHistory showImportHistory={showImportHistory} />
      </div>
    </>
  );
};

const ImportHistory = ({ showImportHistory }) => {
  return useMemo(
    () => (
      <>
        {showImportHistory && (
          <Group key="import-history" id="import-history" name="Import History">
            <AttributesImportList />
          </Group>
        )}
      </>
    ),
    [showImportHistory]
  );
};

export default AttributesImport;
