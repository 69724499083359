import React, { useState } from "react";
import { CREATE, useClient, getSchemaFromResource } from "@peracto/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { Heading } from "@peracto/peracto-ui";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import sharedYupFields from "../sharedYupFields";
import { formatDataForApi } from "../util";

export const LocationsAdd = ({ LocationsForm }) => {
    const { client, getResource } = useClient();
    const [redirect, setRedirect] = useState("");

    const schema = getSchemaFromResource(getResource("locations")).shape(
        sharedYupFields
    );

    const onSubmit = async (data, actions) => {
        try {
            const response = await client(CREATE, "locations", {
                data: formatDataForApi(data),
            });

            setRedirect(response.data.id);
            toast.success("Location successfully added!");
            actions.setSubmitting(false);
        } catch (e) {
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>Locations | Add | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Add Location">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link
                            className="btn btn-outline-primary"
                            to="/locations"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Locations
                        </Link>
                    </div>
                </Heading>
                <LocationsForm modeAdd onSubmit={onSubmit} schema={schema} />
            </div>
        </>
    );
};
