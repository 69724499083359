import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Heading,
    Select,
    LoadingIcon,
    DateInput,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

import dayjs from "dayjs";

const schema = yup.object().shape({
    accountNumber: yup.string().required(),
    status: yup.string().required(),
    limit: yup
        .number()
        .required()
        .typeError("Number of orders to be returned must be a number"),
    customerRef: yup.string(),
    vrm: yup.string(),
    date: yup.string(),
});

export const WismoAccountRequestForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState("");
    const [notFound, setNotFound] = useState(false);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);
        setNotFound(false);
        setResponseData("");

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/wismo-order-list`,
                {
                    ...formData,
                    limit: parseInt(formData.limit),
                    date: formData.date
                        ? dayjs(formData.date).format("YYYY-MM-DD")
                        : "",
                },
                {
                    headers,
                }
            );

            setResponseData(JSON.stringify(data));
        } catch (e) {
            console.error(e);
            setNotFound(true);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Account Request | Wismo | Web Services | Peracto</title>
            </Helmet>
            <div className="form-container">
                <Heading name="Wismo Account Request" />

                <Form
                    autoComplete="on"
                    values={{
                        accountNumber: "",
                        status: "OPEN",
                        limit: 10,
                        customerRef: "",
                        vrm: "",
                        date: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="babbage-part-details"
                        id="babbage-part-details"
                        name="Babbage Part Details"
                    >
                        <Input
                            id="babbage_account_number"
                            name="accountNumber"
                            label="Babbage Account Number"
                            help="Example: A0001"
                            testId="babbageAccountNumber"
                            required
                        />

                        <Input
                            id="limit"
                            name="limit"
                            label="Number Of Orders To Be Returned"
                            testId="limit"
                            type="number"
                            required
                        />

                        <Select
                            name="status"
                            label="Select Open/Closed Orders"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "Open",
                                    value: "OPEN",
                                },
                                { label: "Closed", value: "COMPLETE" },
                            ]}
                            testId="status"
                        />

                        <Input
                            id="vrm"
                            name="vrm"
                            label="Vehicle Registration (VRM)"
                            testId="vrm"
                        />

                        <Input
                            id="customerRef"
                            name="customerRef"
                            label="Order Reference"
                            testId="customerRef"
                        />

                        <DateInput
                            id="date"
                            name="date"
                            label="Date"
                            testId="date"
                            allowClear={true}
                            fullWidth={true}
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {responseData?.length > 0 && !loading && (
                        <Group
                            key="account-details"
                            id="account-details"
                            name="Account Details"
                        >
                            <div className="flex items-center justify-between mt-2 mb-3">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Account Details
                                </h4>{" "}
                                <CopyButton content={responseData} />
                            </div>
                            <div className="border rounded-[4px] p-2">
                                <CodeFormatter
                                    code={responseData}
                                    language="json"
                                />
                            </div>
                        </Group>
                    )}

                    {notFound && !loading && (
                        <p className="text-center py-4">No order data found</p>
                    )}
                </Form>
            </div>
        </>
    );
};

WismoAccountRequestForm.displayName = "WismoAccountRequestForm";
