import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Select,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    trading_country: yup.string().required(),
    vrm: yup.string().required(),
    exhaust_system_id: yup.string().required(),
});

export const ExhaustLookupForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState({
        part: "",
        exhaust: "",
    });
    const [responseData, setResponseData] = useState({
        part: "",
        exhaust: "",
    });

    const [showRequestData, setShowRequestData] = useState({
        part: false,
        exhaust: false,
    });
    const [showResponseData, setShowResponseData] = useState({
        part: false,
        exhaust: false,
    });
    const [showTableData, setShowTableData] = useState(false);

    const [tableData, setTableData] = useState([]);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/raindata-get-exhaust-system-children`,
                {
                    vehicleDetails: {
                        0: { Name: "VRM", Value: formData.vrm },
                    },
                    country: formData.trading_country,
                    exhaustSystemId: formData.exhaust_system_id,
                },
                {
                    headers,
                }
            );

            const parsedData = data?.GetSystemChildrenResponse
                ? JSON.parse(data?.GetSystemChildrenResponse)
                : "";

            const tableRows = parsedData?.MapInstanceRegions
                ? parsedData.MapInstanceRegions
                : [];

            setRequestData({
                part: data?.searchByComponentTreeRequest,
                exhaust: data?.GetSystemChildrenRequest,
            });
            setResponseData({
                part: data?.searchByComponentTreeResponse,
                exhaust: data?.GetSystemChildrenResponse,
            });
            setTableData(tableRows);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Exhaust Lookup | Raindata | Web Services | Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Exhaust Lookup" />

                <Form
                    autoComplete="on"
                    values={{
                        trading_country: "GB",
                        vrm: "",
                        exhaust_system_id: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="part-details"
                        id="part-details"
                        name="Part Details"
                    >
                        <Select
                            name="trading_country"
                            label="Trading Country"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "GB",
                                },
                                { label: "Ireland", value: "IE" },
                            ]}
                            testId="tradingCountry"
                            required={true}
                        />

                        <Input
                            name="vrm"
                            label="VRM"
                            testId="vrm"
                            help="IE REG: 142D4920, GB REG YR04FWE"
                            required
                        />

                        <Input
                            name="exhaust_system_id"
                            label="Exhaust System ID"
                            testId="exhaust_system_id"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {(requestData?.part?.length > 0 ||
                        requestData?.exhaust?.length > 0) &&
                        !loading && (
                            <Group
                                key="request-details"
                                id="request-details"
                                name="Request Details"
                            >
                                {requestData?.part?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Part Request
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={requestData.part}
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowRequestData({
                                                            ...showRequestData,
                                                            part: !showRequestData.part,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showRequestData?.part
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showRequestData?.part
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showRequestData?.part && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={requestData?.part}
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                {requestData?.exhaust?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2 pt-2 border-t">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Exhaust Request
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={
                                                        requestData.exhaust
                                                    }
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowRequestData({
                                                            ...showRequestData,
                                                            exhaust:
                                                                !showRequestData.exhaust,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showRequestData?.exhaust
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showRequestData?.exhaust
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showRequestData?.exhaust && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={requestData?.exhaust}
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Group>
                        )}

                    {(responseData?.part?.length > 0 ||
                        responseData?.exhaust?.length > 0) &&
                        !loading && (
                            <Group
                                key="response-details"
                                id="response-details"
                                name="Response Details"
                            >
                                {responseData?.part?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Part Response
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={responseData.part}
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowResponseData({
                                                            ...showResponseData,
                                                            part: !showResponseData.part,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showResponseData?.part
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showResponseData?.part
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showResponseData?.part && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={responseData?.part}
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                {responseData?.exhaust?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2 pt-2 border-t">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Exhaust Response
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={
                                                        responseData.exhaust
                                                    }
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowResponseData({
                                                            ...showResponseData,
                                                            exhaust:
                                                                !showResponseData.exhaust,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showResponseData?.exhaust
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showResponseData?.exhaust
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showResponseData?.exhaust && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={responseData?.exhaust}
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Group>
                        )}

                    {tableData?.length > 0 && !loading && (
                        <Group
                            key="formatted-results"
                            id="formatted-results"
                            name="Results"
                        >
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Formatted Results
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton
                                        content={JSON.stringify(tableData)}
                                    />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTableData(!showTableData);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showTableData ? faMinus : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showTableData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showTableData && (
                                <table className="table table-striped table-sm table-fixed mt-3">
                                    <thead>
                                        <tr>
                                            <th>Key</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tableData.map((row, idx) => {
                                            return (
                                                <tr
                                                    key={
                                                        row?.SupplierPartNumber ||
                                                        idx
                                                    }
                                                >
                                                    <td>
                                                        {row?.SupplierPartNumber ||
                                                            "-"}
                                                    </td>
                                                    <td>
                                                        {row?.Component || "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

ExhaustLookupForm.displayName = "ExhaustLookupForm";
