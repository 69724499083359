export const formatDataForAPI = (data) => {
    const babbageAccounts = [];

    if (data?.babbageAccounts?.length > 0) {
        data.babbageAccounts.forEach((account) => {
            if (account?.babbageAccount?.["@id"]) {
                babbageAccounts.push({
                    "@id": account.babbageAccount["@id"],
                    isDefault: !!account.isDefault,
                });
            } else {
                babbageAccounts.push({
                    accountNumber: account.babbageAccount.accountNumber,
                    branch: `/locations/${account.babbageAccount.branch.id}`,
                    isDefault: !!account.isDefault,
                });
            }
        });
    }

    return {
        ...data,
        babbageAccounts,
        defaultBranch: data?.defaultBranch || null,
        enabled: data?.status === "active",
    };
};

export const highlightOptions = [
    { label: "None", value: "none" },
    { label: "Sale", value: "sale" },
    { label: "New", value: "new" },
    { label: "Recommended", value: "recommended" },
    { label: "Performance", value: "performance" },
    { label: "Best Seller", value: "bestseller" },
];
