import React, { useState } from "react";

import { Field, FieldArray } from "formik";
import { IconStarFilled } from "@tabler/icons-react";

import { StyledCheckbox } from "@peracto/peracto-ui";

import { AddBabbageAccount } from "./AddBabbageAccount";

export const accountTypes = {
    A: "Credit",
    C: "Cash",
};

export const BabbageAccounts = () => {
    const [selectedRows, setSelectedRows] = useState([]);

    return (
        <Field name={`babbageAccounts`}>
            {({ field, form }) => (
                <>
                    <FieldArray name={field.name}>
                        {({ push, remove }) => {
                            return (
                                <>
                                    <div
                                        className="form-group"
                                        data-testid={`babbage-accounts`}
                                    >
                                        {field?.value?.length > 0 ? (
                                            <div>
                                                <div className="flex flex-row items-center mb-2">
                                                    <div className="ml-[5px]">
                                                        <StyledCheckbox
                                                            data-testid="babbage-accounts__select-all"
                                                            checked={
                                                                field?.value
                                                                    ?.length ===
                                                                selectedRows.length
                                                            }
                                                            onChange={(e) => {
                                                                if (
                                                                    e.target
                                                                        .checked
                                                                ) {
                                                                    const allRows =
                                                                        Array.from(
                                                                            Array(
                                                                                field
                                                                                    .value
                                                                                    .length
                                                                            ).keys()
                                                                        );

                                                                    setSelectedRows(
                                                                        allRows
                                                                    );
                                                                } else {
                                                                    setSelectedRows(
                                                                        []
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <button
                                                        data-testid="babbage-accounts__remove-rows"
                                                        className="btn btn-sm btn-link ml-2 p-0 !text-[14px]"
                                                        disabled={
                                                            selectedRows?.length ===
                                                                0 ||
                                                            field?.value
                                                                ?.length ===
                                                                selectedRows.length
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            if (
                                                                selectedRows?.length >
                                                                    0 &&
                                                                field?.value
                                                                    ?.length !==
                                                                    selectedRows.length
                                                            ) {
                                                                const sortedRows =
                                                                    selectedRows
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) => {
                                                                                return (
                                                                                    a -
                                                                                    b
                                                                                );
                                                                            }
                                                                        )
                                                                        .reverse();
                                                                sortedRows.forEach(
                                                                    (row) => {
                                                                        remove(
                                                                            row
                                                                        );
                                                                    }
                                                                );

                                                                setSelectedRows(
                                                                    []
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Remove
                                                    </button>
                                                    <button
                                                        data-testid="babbage-accounts__set-default"
                                                        className="btn btn-sm btn-link ml-2 p-0 !text-[14px]"
                                                        disabled={
                                                            selectedRows?.length !==
                                                                1 ||
                                                            form.values
                                                                .babbageAccounts?.[
                                                                selectedRows[0]
                                                            ]?.isDefault
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (
                                                                selectedRows?.length ===
                                                                    1 ||
                                                                form.values
                                                                    .babbageAccounts?.[
                                                                    selectedRows[0]
                                                                ]?.isDefault
                                                            ) {
                                                                // eslint-disable-next-line no-unused-expressions
                                                                form?.values?.babbageAccounts?.forEach(
                                                                    (
                                                                        _val,
                                                                        idx
                                                                    ) => {
                                                                        form.setFieldValue(
                                                                            `${field.name}.${idx}.isDefault`,
                                                                            false
                                                                        );
                                                                    }
                                                                );

                                                                form.setFieldValue(
                                                                    `${field.name}.${selectedRows[0]}.isDefault`,
                                                                    true
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Set as Default
                                                    </button>
                                                </div>
                                                <div className="overflow-x-scroll overflow-y-auto">
                                                    <table className="table table-sm mb-1">
                                                        <thead>
                                                            <tr className="whitespace-nowrap">
                                                                <th></th>
                                                                <th></th>
                                                                <th>
                                                                    Account No
                                                                </th>
                                                                <th>Branch</th>
                                                                <th>
                                                                    A/C Level
                                                                </th>
                                                                <th>
                                                                    Main A/C
                                                                    Number
                                                                </th>
                                                                <th>
                                                                    Invoice A/C
                                                                    Number
                                                                </th>
                                                                <th>
                                                                    Statement
                                                                    A/C Number
                                                                </th>
                                                                <th>
                                                                    Customer
                                                                    Name
                                                                </th>
                                                                <th>
                                                                    Account Type
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {field.value.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                        data-id={
                                                                            index
                                                                        }
                                                                        data-testid={`babbage-accounts__row-${index}`}
                                                                    >
                                                                        <td>
                                                                            <StyledCheckbox
                                                                                data-testid={`babbage-accounts__row-${index}--checkbox`}
                                                                                checked={selectedRows.includes(
                                                                                    index
                                                                                )}
                                                                                onChange={() => {
                                                                                    if (
                                                                                        selectedRows.includes(
                                                                                            index
                                                                                        )
                                                                                    ) {
                                                                                        setSelectedRows(
                                                                                            selectedRows.filter(
                                                                                                (
                                                                                                    row
                                                                                                ) =>
                                                                                                    row !==
                                                                                                    index
                                                                                            )
                                                                                        );
                                                                                    } else {
                                                                                        setSelectedRows(
                                                                                            [
                                                                                                ...selectedRows,
                                                                                                index,
                                                                                            ]
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            data-testid={`babbage-accounts__row-${index}--isDefault`}
                                                                        >
                                                                            {item.isDefault ? (
                                                                                <span title="Default Branch">
                                                                                    <IconStarFilled className="text-warning h-[20px] w-[20px]" />
                                                                                </span>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--accountNumber`}
                                                                        >
                                                                            {
                                                                                item
                                                                                    ?.babbageAccount
                                                                                    ?.accountNumber
                                                                            }{" "}
                                                                        </td>

                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--branch`}
                                                                        >
                                                                            {
                                                                                item
                                                                                    ?.babbageAccount
                                                                                    ?.branch
                                                                                    ?.name
                                                                            }{" "}
                                                                            -{" "}
                                                                            {
                                                                                item
                                                                                    ?.babbageAccount
                                                                                    ?.branch
                                                                                    ?.ecpBranchId
                                                                            }
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--accountLevelType`}
                                                                        >
                                                                            {item
                                                                                ?.babbageAccount
                                                                                ?.accountLevelType ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--mainAccountNumber`}
                                                                        >
                                                                            {item
                                                                                ?.babbageAccount
                                                                                ?.mainAccountNumber ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--invoiceAccountNumber`}
                                                                        >
                                                                            {item
                                                                                ?.babbageAccount
                                                                                ?.invoiceAccountNumber ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--statementAccountNumber`}
                                                                        >
                                                                            {item
                                                                                ?.babbageAccount
                                                                                ?.statementAccountNumber ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--customerName`}
                                                                        >
                                                                            {item
                                                                                ?.babbageAccount
                                                                                ?.customerName ||
                                                                                "N/A"}
                                                                        </td>
                                                                        <td
                                                                            className="whitespace-nowrap"
                                                                            data-testid={`babbage-accounts__row-${index}--accountType`}
                                                                        >
                                                                            {item
                                                                                ?.babbageAccount
                                                                                ?.accountType
                                                                                ? accountTypes[
                                                                                      item
                                                                                          .babbageAccount
                                                                                          .accountType
                                                                                  ]
                                                                                : "N/A"}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="text-center pt-6 pb-2">
                                                No Babbage accounts are
                                                associated with this user
                                            </div>
                                        )}
                                    </div>
                                    <AddBabbageAccount
                                        push={push}
                                        field={field}
                                    />

                                    {form.errors[field.name] && (
                                        <p className="text-danger mt-2 mb-0">
                                            {form.errors[field.name]}
                                        </p>
                                    )}
                                </>
                            );
                        }}
                    </FieldArray>
                </>
            )}
        </Field>
    );
};
