import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
    Heading,
    NotFoundMessage,
    UnauthorisedMessage,
} from "@peracto/peracto-ui";
import { GET_LIST, UPDATE, useClient } from "@peracto/client";
import { convertToTreeData, convertToAPIData } from "../util.js";

import CategoryTreeForm from "../CategoryTreeForm";

const CategoryEditContainer = ({ name, children }) => {
    return (
        <div className="form-container">
            <Heading name={name ? name : "Loading..."} />
            {children}
        </div>
    );
};

const CategoryEdit = ({
    location: { pathname },
    match: {
        params: { menuCode },
    },
}) => {
    const { client } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [categoryData, setCategoryData] = useState();
    const [savingOrder, setSavingOrder] = useState();

    const fetchCategories = async () => {
        try {
            const { data } = await client(GET_LIST, "categories", {
                pagination: {
                    page: 1,
                    perPage: 10000,
                },
            });

            const formattedData = convertToTreeData(data);

            setCategoryData(formattedData);
            setLoading(false);
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSaveOrder = async (data) => {
        setSavingOrder(true);
        const menuItems = convertToAPIData(data);
        try {
            await client(UPDATE, "menus", {
                id: pathname,
                data: {
                    menuItems,
                },
            });

            toast.success("Display order saved!");
            setSavingOrder(false);
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            setSavingOrder(false);
        }
    };

    if (loading) {
        return (
            <CategoryEditContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </CategoryEditContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/menus"
                message="The menu item you're looking for could not be found"
                buttonLabel="Go to Category"
            />
        );
    }

    return (
        <CategoryEditContainer name="Categories">
            <Helmet>
                <title>Categories | Peracto</title>
            </Helmet>
            <CategoryTreeForm
                values={categoryData}
                menuCode={menuCode}
                onSaveOrder={onSaveOrder}
                savingOrder={savingOrder}
            />
        </CategoryEditContainer>
    );
};

export default CategoryEdit;
