import React from "react";
import { ThemeProvider } from "styled-components";

import Peracto from "@peracto/peracto";
import Config from "@peracto/peracto-config";
import PeractoUser from "@peracto/peracto-user";
import PeractoCatalogue from "@peracto/peracto-catalogue";
import PeractoCommon from "@peracto/peracto-common";
import PeractoContent from "@peracto/peracto-content";
import PeractoLocations from "@peracto/peracto-locations";
import PeractoPromotions from "@peracto/peracto-promotions";

import {
    CategoryForm,
    CategoryList,
    CategoryAdd,
    CategoryEdit,
} from "./packages/peracto-catalogue";

import {
    UserAdd,
    UserEdit,
    UserExport,
    UserForm,
    UserList,
} from "./packages/peracto-user";
import {
    LocationsForm,
    LocationsList,
    LocationsAdd,
    LocationsEdit,
} from "./packages/peracto-locations";
import {
    PromotionsForm,
    PromotionsList,
    PromotionsAdd,
    PromotionsEdit,
} from "./packages/peracto-promotions";
import {
    AttributeForm,
    AttributeList,
    AttributeAdd,
    AttributeEdit,
    AttributeImport,
    AttributeImportStatus,
    OrdersList,
    OrdersView,
} from "./packages/peracto-catalogue";

import Brands from "./packages/brands";
import WebServices from "./packages/web-services";
import BabbageAccounts from "./packages/babbage-accounts";
import FifmApplications from "./packages/fifm-applications";
import UserApplications from "./packages/user-applications";
import BranchReassignment from "./packages/branch-reassignment";
import FailedOrders from "./packages/failed-orders";

import { EditorTheme, AdminTheme, GlobalStyles } from "./theme";

import blocks from "./blocks";
import { menu } from "./menu";

import compact from "lodash/compact";
import startCase from "lodash/startCase";

import * as buttonVariants from "./theme/buttonVariants";
import { colors, fonts } from "./theme/tailwind";

import {
    Input,
    Textarea,
    Select,
    Radio,
    Checkbox,
    CheckboxGroup,
    File,
} from "./Inputs";

const config = new Config({
    api: process.env.REACT_APP_API,
    algolia: {
        applicationId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
    },
    modules: [
        PeractoUser({ UserAdd, UserEdit, UserExport, UserForm, UserList }),
        PeractoCommon(),
        PeractoCatalogue({
            CategoryList,
            CategoryForm,
            CategoryAdd,
            CategoryEdit,
            AttributeForm,
            AttributeList,
            AttributeAdd,
            AttributeEdit,
            AttributeImport,
            AttributeImportStatus,
            OrdersList,
            OrdersView,
        }),
        PeractoPromotions({
            PromotionsForm,
            PromotionsList,
            PromotionsAdd,
            PromotionsEdit,
        }),
        PeractoContent(),
        PeractoLocations({
            LocationsForm,
            LocationsList,
            LocationsAdd,
            LocationsEdit,
        }),
        WebServices(),
        BabbageAccounts(),
        Brands(),
        UserApplications(),
        BranchReassignment(),
        FailedOrders(),
        FifmApplications(),
    ],

    editorConfig: {
        isTailwind: true,
        theme: EditorTheme,
        colorOptions: compact(
            Object.entries(colors).map(([label, value]) => {
                return {
                    label: startCase(label.replaceAll("brand-", "")),
                    value,
                };
            })
        ),
        fonts,
        locales: ["en_GB"],
        blocks,
        buttonVariants,
        inputTypes: {
            Input,
            Textarea,
            Select,
            Radio,
            Checkbox,
            CheckboxGroup,
            File,
        },
        stylesheets: [
            "https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap",
        ],
        globalStyles: GlobalStyles,
        screens: {
            sm: "100%",
            md: "100%",
            lg: "100%",
            xl: "1684px",
        },
        restrictedCountries: [
            {
                label: "UK",
                value: "uk",
            },
            {
                label: "Ireland",
                value: "ie",
            },
        ],
    },

    features: {
        shippingServices: {
            deliveryDays: true,
            conditions: true,
        },
        products: {
            exportProductData: true,
            productVariants: true,
        },
        content: {
            restrictByAccountNumber: true,
            restrictByUserGroup: true,
        },
    },
    menu,
});

const App = () => (
    <ThemeProvider theme={AdminTheme}>
        <Peracto config={config} />
    </ThemeProvider>
);

export default App;
