import React from "react";

import { twMerge } from "tailwind-merge";

export const Price = (props) => {
    const {
        price,
        rrpPrice,
        ecpPrice,
        surcharge,
        unit,
        className,
        incVat,
        ...restProps
    } = props;

    return (
        <div
            className={twMerge(`flex flex-col`, className)}
            {...restProps}
            data-testid="product-card__price"
        >
            <div className="flex gap-2">
                {rrpPrice?.excTax > 0 && (
                    <p
                        className="font-normal text-sm text-brand-grey-600 mb-0"
                        data-testid="product-card__price--rrp-price"
                    >
                        RRP{" "}
                        <span className="font-bold">
                            £{(rrpPrice.excTax / 100).toFixed(2)}{" "}
                        </span>
                    </p>
                )}
                {ecpPrice?.excTax > 0 && (
                    <p
                        className="font-normal text-sm text-brand-grey-600 mb-0"
                        data-testid="product-card__price--ecp-price"
                    >
                        ECP Retail{" "}
                        <span className="font-bold">
                            £{(ecpPrice.excTax / 100).toFixed(2)}
                        </span>
                    </p>
                )}
            </div>
            <p
                className="text-[22px] leading-[28px] text-brand-red font-bold"
                data-testid="product-card__price--price"
            >
                £{(price?.excTax / 100).toFixed(2)}{" "}
                <span className="text-base leading-5 font-bold">
                    <span className="font-semibold">
                        {incVat ? "inc" : "ex"}
                    </span>{" "}
                    VAT
                </span>
                {unit && unit !== "each" && (
                    <>
                        {" "}
                        <span className="font-normal text-xs text-brand-grey-400">
                            (priced per {unit})
                        </span>
                    </>
                )}
            </p>
        </div>
    );
};
