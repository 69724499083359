import React from "react";
import { Route, Switch } from "react-router-dom";
import {
    BranchReassignmentList,
    BranchReassignmentForm,
} from "./BranchReassignment";

export default () => {
    return {
        routes: [
            {
                path: "/branch-reassignment",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={BranchReassignmentList}
                        />
                        <Route path={`${match.url}/add`} exact>
                            {(props) => <BranchReassignmentForm {...props} />}
                        </Route>
                    </Switch>
                ),
            },
        ],
    };
};
