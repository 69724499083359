import React, { useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faRedo } from "@fortawesome/pro-regular-svg-icons/faRedo";

import { Helmet } from "react-helmet";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import * as qs from "querystring";

import {
    Heading,
    Group,
    FormActions,
    NotFoundMessage,
    UnauthorisedMessage,
    Modal,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";

import { GET_ONE, UPDATE, useClient } from "@peracto/client";
import * as S from "../styled";
import isEmpty from "lodash/isEmpty";

const currencySymbols = {
    GBP: "£",
    USD: "$",
    EUR: "€",
};

export const formatAsCurrency = (price = 0, currency = "GBP") => {
    return `${currencySymbols[currency]}${(price / 100).toFixed(2)}`;
};

const OrdersViewContainer = ({
    status,
    onReprocessOrder,
    isReprocessing,
    showReprocessModal,
    setShowReprocessModal,
    children,
}) => {
    const query = useLocation();
    const queryParams = query.search ? qs.parse(query.search.slice(1)) : null;

    return (
        <>
            <div className="form-container">
                <Heading name="View Order">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        {status?.toLowerCase() === "error" && (
                            <button
                                className="btn btn-success mr-2"
                                onClick={() => {
                                    setShowReprocessModal(true);
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faRedo}
                                    className="mr-2"
                                />
                                Reprocess Order
                            </button>
                        )}
                        <Link
                            className="btn btn-outline-primary"
                            to={
                                queryParams?.to === "failed"
                                    ? `/failed-orders`
                                    : `/orders`
                            }
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Orders
                        </Link>
                    </div>
                </Heading>
                {children}
            </div>

            <Modal
                isVisible={showReprocessModal}
                title="Reprocess Order"
                close={() => setShowReprocessModal(false)}
                buttons={[
                    {
                        type: "btn-outline-secondary",
                        text: "Close",
                        action: () => setShowReprocessModal(false),
                    },
                    {
                        type: "btn-success",
                        text: "Reprocess Order",
                        disabled: isReprocessing,
                        action: () => {
                            onReprocessOrder();
                        },
                    },
                ]}
            >
                {isReprocessing ? (
                    <>
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            size="4x"
                            spin
                            className="d-block mb-4"
                        />
                        Reprocessing.....
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="d-block mb-4"
                        />
                        <div data-testid={`delete-warning`}>
                            Would you like to reprocess this order?
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};

const OrderAddress = ({ address, title = "" }) => (
    <S.OrderAddress>
        <p className="address-title">{title}</p>
        {(address?.forename || address?.surname) && (
            <p>
                {address?.forename} {address?.surname}
            </p>
        )}
        {address?.company && <p>{address?.company}</p>}
        {address?.line1 && <p>{address?.line1}</p>}
        {address?.line2 && <p>{address?.line2}</p>}
        {address?.line3 && <p>{address?.line3}</p>}
        {address?.line4 && <p>{address?.line4}</p>}
        {address?.postalCode && <p>{address?.postalCode}</p>}
        {address?.county && <p>{address?.county}</p>}
        {address?.country && <p>{address?.country}</p>}
        {address?.telephone && <p>{address?.telephone}</p>}
    </S.OrderAddress>
);

export const OrdersView = ({ location: { pathname } }) => {
    const { client } = useClient();
    const [loading, setLoading] = useState(true);
    const [unauthorised, setUnauthorised] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [redirect, setRedirect] = useState();
    const [orderData, setOrderData] = useState();

    const [showReprocessModal, setShowReprocessModal] = useState(false);
    const [isReprocessing, setIsReprocessing] = useState(false);

    const config = useConfig();
    const { orders } = config.get("features", {});

    // show prices as tax feature flag - default: true
    const includeTax =
        orders && orders.hasOwnProperty("includeTax")
            ? orders.includeTax === true
            : true;

    const fetchOrder = async () => {
        try {
            const { data, response } = await client(GET_ONE, "orders", {
                id: pathname,
            });

            if (response.status === 404) {
                setRedirect("/orders");
                setLoading(false);
                return;
            }

            setOrderData(data);
            setLoading(false);
        } catch (e) {
            console.error(e);

            if (e.status === 403) {
                setUnauthorised(true);
            }

            if (e.status === 404) {
                setNotFound(true);
            }

            setRedirect("/orders");
            setLoading(false);
        }
    };

    const onReprocessOrder = async () => {
        setIsReprocessing(true);

        try {
            await client(UPDATE, `orders`, {
                id: `${orderData?.id}/process-xml`,
                data: {},
            });
            setShowReprocessModal(false);
            setIsReprocessing(false);
            toast.success("Order is reprocessing");
            fetchOrder();
        } catch (e) {
            if (process.env.NODE_ENV === "development") console.error(e);

            setIsReprocessing(false);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    useEffect(() => {
        fetchOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return (
            <OrdersViewContainer>
                <div className="card">
                    <div className="card-body">Loading...</div>
                </div>
            </OrdersViewContainer>
        );
    }

    if (unauthorised) {
        return <UnauthorisedMessage />;
    }

    if (notFound) {
        return (
            <NotFoundMessage
                url="/orders"
                message="The order you're looking for could not be found"
                buttonLabel="Go to Orders"
            />
        );
    }

    return (
        <OrdersViewContainer
            orderTransferred={!!orderData?.transferred}
            status={orderData?.status}
            onReprocessOrder={onReprocessOrder}
            orderConfirmationEmailCanBeResent={
                orderData?.orderConfirmationEmailCanBeResent
            }
            isReprocessing={isReprocessing}
            showReprocessModal={showReprocessModal}
            setShowReprocessModal={setShowReprocessModal}
        >
            {redirect ? (
                <Redirect to={redirect} />
            ) : (
                <>
                    <Helmet>
                        <title>
                            {orderData.reference
                                ? `Order #: ${orderData.reference}`
                                : "Order"}{" "}
                            | View | Peracto
                        </title>
                    </Helmet>
                    {
                        <>
                            <FormActions>
                                <span className="mr-3">
                                    Order ID:{" "}
                                    <span className="font-weight-bold">
                                        {orderData.reference}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Customer Reference:{" "}
                                    <span className="font-weight-bold">
                                        {orderData.customerReference}
                                    </span>
                                </span>

                                <span className="mr-3">
                                    Status:{" "}
                                    <span className="font-weight-bold text-capitalize">
                                        {orderData.status}
                                    </span>
                                </span>

                                {orderData?.purchaseOrder && (
                                    <span className="mr-3">
                                        Purchase Order:{" "}
                                        <span className="font-weight-bold">
                                            {orderData.purchaseOrder}
                                        </span>
                                    </span>
                                )}

                                <span className="mr-3">
                                    Created:{" "}
                                    <span className="font-weight-bold text-capitalize">
                                        {dayjs(
                                            new Date(orderData.createdAt)
                                        ).format("YYYY-MM-DD HH:mm:ss")}
                                    </span>
                                </span>

                                {orderData?.kerridgeOrderNumber && (
                                    <span className="mr-3">
                                        Kerridge Order Number:{" "}
                                        <span className="font-weight-bold">
                                            {orderData.kerridgeOrderNumber}
                                        </span>
                                    </span>
                                )}
                                {orderData?.transferred && (
                                    <span className="mr-3">
                                        Transferred:{" "}
                                        <span className="font-weight-bold text-capitalize">
                                            {dayjs(
                                                new Date(orderData.transferred)
                                            ).format("YYYY-MM-DD HH:mm:ss")}
                                        </span>
                                    </span>
                                )}
                            </FormActions>
                            <Group key="items" id="items" name="Items">
                                <table className="table">
                                    <colgroup>
                                        <col width="55%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <thead>
                                        <tr>
                                            <th className="border-top-0 pl-1"></th>
                                            <th className="text-right border-top-0">
                                                Quantity
                                            </th>
                                            <th className="text-right border-top-0">
                                                Total Discount
                                            </th>
                                            <th className="text-right border-top-0 pr-1">
                                                Line Total
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {orderData?.lines?.map((line, idx) => (
                                            <tr key={`product_${idx}`}>
                                                <td className="overflow-anywhere pl-1">
                                                    <p className="mb-0 font-weight-bold">
                                                        {line.item?.name}
                                                    </p>
                                                    {line.item?.sku && (
                                                        <p className="mb-0">
                                                            {line.item?.sku}
                                                        </p>
                                                    )}
                                                    {!isEmpty(
                                                        line.item
                                                            ?.variantOptions
                                                    ) && (
                                                        <p className="mb-2">
                                                            {Object.values(
                                                                line.item
                                                                    .variantOptions
                                                            ).map((v) => (
                                                                <>
                                                                    <span className="font-weight-bold">
                                                                        {v.name}
                                                                        :
                                                                    </span>{" "}
                                                                    {
                                                                        v.displayValue
                                                                    }{" "}
                                                                </>
                                                            ))}
                                                        </p>
                                                    )}
                                                    {line.line && (
                                                        <p className="mb-0">
                                                            {formatAsCurrency(
                                                                includeTax
                                                                    ? line.priceIncTaxBeforeDiscount
                                                                    : line.priceExcTaxBeforeDiscount,
                                                                orderData?.currency
                                                            )}
                                                        </p>
                                                    )}
                                                </td>
                                                <td className="text-right">
                                                    {line.quantity}
                                                </td>
                                                <td className="text-right">
                                                    <p className="mb-0">
                                                        {line?.promotions
                                                            ? formatAsCurrency(
                                                                  line.promotions?.reduce(
                                                                      (
                                                                          acc,
                                                                          curVal
                                                                      ) => {
                                                                          return (
                                                                              acc +
                                                                              curVal
                                                                                  ?.discount
                                                                                  ?.excTax
                                                                          );
                                                                      },
                                                                      0
                                                                  ),
                                                                  orderData?.currency
                                                              )
                                                            : formatAsCurrency(
                                                                  0,
                                                                  orderData?.currency
                                                              )}
                                                    </p>
                                                </td>
                                                <td className="text-right pr-1">
                                                    {formatAsCurrency(
                                                        includeTax
                                                            ? line
                                                                  ?.linePriceTotal
                                                                  ?.incTax
                                                            : line
                                                                  ?.linePriceTotal
                                                                  ?.excTax,
                                                        orderData?.currency
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <hr />

                                <table className="table table-sm table-borderless">
                                    <colgroup>
                                        <col width="15%" />
                                        <col width="70%" />
                                        <col width="15%" />
                                    </colgroup>

                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">
                                                Subtotal
                                            </td>
                                            <td>
                                                {orderData?.lines?.length} item
                                                {orderData?.lines?.length !== 1
                                                    ? "s"
                                                    : ""}
                                            </td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    includeTax
                                                        ? orderData?.totalIncTaxBeforeDiscount
                                                        : orderData?.totalExcTaxBeforeDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Total Discount</td>
                                            <td></td>
                                            <td className="text-right">
                                                {formatAsCurrency(
                                                    orderData?.totalDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>

                                        {includeTax && (
                                            <tr>
                                                <td>Tax</td>
                                                <td></td>
                                                <td className="text-right">
                                                    {formatAsCurrency(
                                                        orderData?.totalTaxAfterDiscount,
                                                        orderData?.currency
                                                    )}
                                                </td>
                                            </tr>
                                        )}

                                        <tr className="border-top">
                                            <td className="font-weight-bold">
                                                Paid by customer
                                            </td>
                                            <td></td>
                                            <td className="text-right font-weight-bold">
                                                {formatAsCurrency(
                                                    includeTax
                                                        ? orderData?.totalIncTaxAfterDiscount
                                                        : orderData?.totalExcTaxAfterDiscount,
                                                    orderData?.currency
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Group>
                            <Group key="customer" id="customer" name="Customer">
                                <S.OrderAddress>
                                    {(orderData?.user?.firstName ||
                                        orderData?.user?.lastName) && (
                                        <p className="address-title">{`${orderData?.user?.firstName} ${orderData?.user?.lastName}`}</p>
                                    )}
                                    {(orderData?.email ||
                                        orderData?.telephone) && (
                                        <p>
                                            {orderData?.email
                                                ? orderData?.email
                                                : ""}
                                            {orderData?.email &&
                                                orderData?.telephone &&
                                                " / "}
                                            {orderData?.telephone
                                                ? orderData?.telephone
                                                : ""}
                                        </p>
                                    )}
                                    {orderData?.user?.defaultBranch?.name && (
                                        <p>{`${orderData?.user?.defaultBranch?.name}`}</p>
                                    )}
                                </S.OrderAddress>

                                {orderData?.deliveryAddress && (
                                    <>
                                        <hr />

                                        <OrderAddress
                                            address={orderData.deliveryAddress}
                                            title="Shipping Address"
                                        />
                                    </>
                                )}

                                {orderData?.deliveryAddress && (
                                    <>
                                        <hr />

                                        <OrderAddress
                                            address={orderData.billingAddress}
                                            title="Billing Address"
                                        />
                                    </>
                                )}
                            </Group>

                            <Group
                                key="order-transfer-issues"
                                id="order-transfer-issues"
                                name="Order Transfer Issues"
                            >
                                {orderData?.history?.length > 0 ? (
                                    <table className="table table-sm table-borderless">
                                        <colgroup>
                                            <col width="20%" />
                                            <col width="80%" />
                                        </colgroup>
                                        <tbody>
                                            {orderData?.history?.map(
                                                (historyItem) => (
                                                    <tr
                                                        key={historyItem["@id"]}
                                                    >
                                                        <td className="font-weight-bold">
                                                            {historyItem?.createdAt
                                                                ? dayjs(
                                                                      historyItem.createdAt
                                                                  ).format(
                                                                      "YYYY-MM-DD HH:mm:ss"
                                                                  )
                                                                : "-"}
                                                        </td>
                                                        <td>
                                                            {historyItem?.detail ||
                                                                "-"}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="py-4 m-0 text-center">
                                        No Order Transfer Issues
                                    </p>
                                )}
                            </Group>
                        </>
                    }
                </>
            )}
        </OrdersViewContainer>
    );
};

export default OrdersView;
