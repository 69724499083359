import React from "react";
import { Card } from "components/Card";
import styled from "@emotion/styled";
import { Heading } from "./components";
import { Details } from "./layout/Details";
import { twMerge } from "tailwind-merge";

const ProductCardGrid = styled.div`
    &.product-card__wide {
        @media all and (min-width: 768px) {
            display: grid;
            grid-template-columns: 300px auto 280px;
            grid-template-areas: "image heading brand-picker";
        }
    }
`;

export const ProductCard = (props) => {
    const {
        product,
        productAddingToBasket,
        orientation = "vertical",
        showAddToFavourites,
        showAddToFavouritesMessage = true,
        onAddToFavourites,
        onAddToBasket,
        onAddPartToWorkOrder,
        canAddToWorkOrder = false,
        stock,
        showVehicleDetails,
        className = "",
        addToBasketButtonText,
        addToBasketButtonIcon,
        pdpHref,
    } = props;

    const wide = orientation === "horizontal";
    const isMobile = false;

    return (
        <Card
            padding={false}
            data-testid={props?.["data-testid"] || "product-card"}
            className={twMerge(
                "shadow-[0px_4px_8px_0px_rgba(48,49,51,0.10),0px_0px_1px_0px_rgba(48,49,51,0.05)]",
                className
            )}
        >
            <ProductCardGrid
                className={`px-4 py-5 ${
                    wide
                        ? "product-card__wide md:gap-x-4 md:items-start"
                        : "h-full flex flex-col"
                }`}
                data-testid="product-card__content"
            >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img
                    src={
                        product?.image ||
                        product?.imageBySku ||
                        "/images/placeholder.svg"
                    }
                    title={product?.productName}
                    className="mb-4 w-full aspect-square"
                />
                <Heading
                    showAddToFavourites={showAddToFavourites}
                    onAddToFavourites={onAddToFavourites}
                    showAddToFavouritesMessage={showAddToFavouritesMessage}
                    className="flex-grow mb-4"
                    wide={wide}
                    isMobile={isMobile}
                    stock={stock}
                    product={product}
                    onAddToBasket={onAddToBasket}
                    onAddPartToWorkOrder={onAddPartToWorkOrder}
                    canAddToWorkOrder={canAddToWorkOrder}
                    canAddToBasket={product.canAddToBasket}
                    productAddingToBasket={productAddingToBasket}
                    addToBasketButtonIcon={addToBasketButtonIcon}
                    addToBasketButtonText={addToBasketButtonText}
                    pdpHref={pdpHref}
                />
                {!wide || (wide && isMobile) ? (
                    <Details
                        stock={stock}
                        selectedProduct={product}
                        showVehicleDetails={showVehicleDetails}
                    />
                ) : null}
            </ProductCardGrid>
        </Card>
    );
};
