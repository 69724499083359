import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { Field } from "formik";

import {
    Form,
    Group,
    Input,
    RichText,
    File,
    Select,
    Modal,
    Checkbox,
    FormActions,
} from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { toast } from "react-toastify";

import { DELETE, useClient } from "@peracto/client";

import { highlightOptions } from "../util";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

export const BrandForm = ({ mode = MODE_EDIT, values, ...props }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [redirect, setRedirect] = useState();

    const { client } = useClient();

    const statusOptions = [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
    ];

    const onDelete = async () => {
        try {
            await client(DELETE, "brands", {
                id: values.id,
            });

            toast.success("Brand deleted successfully!");
            setRedirect("/brands");
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            {mode === MODE_EDIT && (
                <FormActions>
                    <button
                        className="btn text-danger px-0"
                        data-testId="deleteBrand"
                        onClick={() => setShowDialog(true)}
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        Delete Brand
                    </button>
                </FormActions>
            )}

            <Form
                autoComplete="off"
                values={{
                    name: "",
                    description: "",
                    status: "active",
                    image: "",
                    highlight: "none",
                    displayOrder: 0,
                    code: "",
                    allowPdfDownload: false,
                    ...values,
                }}
                {...props}
            >
                <Group key="core" id="core" name="Core">
                    <Input
                        name="name"
                        label="Display Name"
                        required
                        testId="name"
                    />

                    <Input
                        name="code"
                        label="Brand Code"
                        required
                        testId="brandCode"
                    />

                    <RichText
                        name="description"
                        label="Description"
                        required
                        testId="description"
                    />

                    <File name="image" label="Image" testId="image" />

                    <Field name="image">
                        {({ field, form }) => {
                            return (
                                field?.value?.length > 0 && (
                                    <>
                                        <img
                                            src={field.value}
                                            className="mb-2"
                                            alt={form.values?.name || ""}
                                        />
                                    </>
                                )
                            );
                        }}
                    </Field>

                    <Select
                        name="highlight"
                        label="Highlight"
                        placeholder="Please select..."
                        options={highlightOptions}
                        testId="highlight"
                    />

                    <Input
                        name="displayOrder"
                        label="Display Order"
                        required
                        testId="displayOrder"
                    />

                    <Select
                        name="status"
                        label="Status"
                        placeholder="Please select..."
                        options={statusOptions}
                        testId="status"
                    />

                    <Checkbox
                        name="allowPdfDownload"
                        label="Allow PDF Download"
                    />
                </Group>
            </Form>

            {mode === MODE_EDIT && (
                <Modal
                    isVisible={showDialog}
                    title="Delete Brand"
                    close={() => setShowDialog(false)}
                    buttons={[
                        {
                            type: "btn-outline-secondary",
                            text: "Close",
                            action: () => setShowDialog(false),
                        },
                        {
                            type: "btn-danger",
                            text: "Delete Brand",
                            action: () => onDelete(),
                        },
                    ]}
                >
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        size="4x"
                        className="d-block mb-4"
                    />
                    Are you sure you would like to permanently delete this
                    brand? Deleted brands cannot be recovered.
                </Modal>
            )}
        </>
    );
};

BrandForm.displayName = "BrandForm";

BrandForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
};
