/* eslint-disable */
export const inputOptions = {
    filteringOperators: [
        { label: "OR", value: "or" },
        { label: "AND", value: "and" },
    ],
    attributeSortingOrder: [
        { label: "None", value: "" },
        { label: "Alphanumeric", value: "alphanumeric" },
        { label: "Numeric", value: "numeric" },
        { label: "Use manually set display order", value: "manual" },
    ],
    inputTypes: [
        { label: "Text", value: "text" },
        { label: "Textarea", value: "textarea" },
        { label: "Integer", value: "integer" },
        { label: "Single Select", value: "select" },
        { label: "Multi-Select", value: "multiselect" },
        { label: "Yes/No", value: "yesno" },
        { label: "Rich Text Editor", value: "textarea_rte" },
        { label: "Swatch", value: "swatch" },
    ],
};

export const formatDataForAPI = (data) => {
    if (data.productSpecSortOrder) {
        data.productSpecSortOrder = parseInt(data.productSpecSortOrder);
    } else {
        delete data.productSpecSortOrder;
    }

    const options = [];

    if (data?.options?.length > 0) {
        data.options.forEach((opt) => {
            if (opt.label && opt.value) {
                options.push({
                    ...opt,
                    display_order: parseInt(opt.display_order),
                });
            }
        });
    }

    data.options = options;

    if (!data.filterable) {
        data.filteringOperator = "or";
    }

    data.active = data.active == "true";
    data.partialMatch = data.partialMatch == "true";
    data.dataType = "string";
    data.locale = data.locale || "en_GB";

    return { ...data };
};
