import React, { HTMLAttributes } from "react";
import { Field } from "formik";

import styled from "@emotion/styled";

type RadioProps = HTMLAttributes<HTMLInputElement> & {
    options: {
        value: string;
        label: string;
        selected: boolean;
        disabled: boolean;
    }[];
    name: string;
    label?: string;
    required?: boolean;
    help?: string;
    hasError?: boolean;
    errorMessage?: string;
};

const RadioStyles = styled.div`
    &:hover {
        input {
            border-color: #78be20;
        }
    }
`;

const Radio = (props: RadioProps) => {
    const { label, errorMessage, required, hasError, ...restProps } = props;

    return (
        <>
            {label?.length > 0 && (
                <label
                    htmlFor={restProps?.name || restProps?.id}
                    className={`mb-[10px] text-sm block font-bold`}
                >
                    {label}{" "}
                    {required ? (
                        <span className="text-brand-danger">*</span>
                    ) : (
                        <span className="italic text-[12px] font-normal text-brand-dark-grey">
                            {" "}
                            &#8212; optional
                        </span>
                    )}
                </label>
            )}
            {props?.options?.length > 0 && (
                <Field name={props.name}>
                    {({ field, form }) => (
                        <div className="mb-[15px]">
                            {props.options.map((option, idx) => (
                                <RadioStyles
                                    className={`flex items-center cursor-pointer ${
                                        idx > 0 ? "mt-[10px]" : "mt-0"
                                    }`}
                                    key={option.value}
                                >
                                    <input
                                        type="radio"
                                        id={option.value.replaceAll(" ", "-")}
                                        {...field}
                                        value={option.value}
                                        disabled={form.isSubmitting}
                                        checked={field.value === option.value}
                                        onChange={(e) =>
                                            form.setFieldValue(
                                                field.name,
                                                e.target.value
                                            )
                                        }
                                        style={{
                                            boxShadow:
                                                field.value === option.value
                                                    ? "inset 0 0 0px 2px #fff"
                                                    : "none",
                                        }}
                                        class="appearance-none w-4 h-4 !border rounded-full checked:border-brand-primary checked:bg-brand-primary !mb-0 mr-[10px]"
                                    />

                                    {option.label.length > 0 && (
                                        <label
                                            className="mb-0 text-[16px] leading-[24px] cursor-pointer"
                                            htmlFor={option.value.replaceAll(
                                                " ",
                                                "-"
                                            )}
                                        >
                                            {option.label}
                                        </label>
                                    )}
                                </RadioStyles>
                            ))}
                        </div>
                    )}
                </Field>
            )}
            {props?.help?.length > 0 && (
                <p className="text-brand-dark-grey text-[12px] mb-[15px] md:mb-[30px]">
                    {props.help}
                </p>
            )}
        </>
    );
};

export default Radio;
