import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";

export const CategoryTreeEmptyMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;

    p {
        font-size: 1.1rem;
    }
`;

const CategoryTreeEmpty = () => (
    <CategoryTreeEmptyMessage>
        <FontAwesomeIcon icon={faInfoCircle} size="6x" className="mb-3" />
        <p>No categories have been crrated</p>
    </CategoryTreeEmptyMessage>
);

export default CategoryTreeEmpty;
