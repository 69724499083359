import React, { useState } from "react";

import { Helmet } from "react-helmet";
import * as yup from "yup";
import { toast } from "react-toastify";

import { Heading, Input, Form, Group, LoadingIcon } from "@peracto/peracto-ui";
import { Toggle } from "components/Toggle";

import { GET_LIST, UPDATE, useClient } from "@peracto/client";

const schema = yup.object().shape({
    mainAccountNumber: yup.string().required(),
});

export const AccountsList = () => {
    const { client } = useClient();

    const [loading, setLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [babbageAccounts, setBabbageAccounts] = useState([]);

    const [mainAccountNumber, setMainAccountNumber] = useState("");

    const onUpdateValue = async ({ id, key, value }) => {
        setIsUpdating(true);

        try {
            await client(UPDATE, "babbage-accounts", {
                id: `/babbage-accounts/${mainAccountNumber}`,
                data: {
                    type: key,
                    value,
                },
            });

            const { data } = await client(GET_LIST, "babbage-accounts", {
                id: "babbage-accounts",
                filter: {
                    mainAccountNumber,
                    itemsPerPage: 10000,
                },
            });

            setBabbageAccounts(data);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error updating this value."
            );
        } finally {
            setIsUpdating(false);
        }
    };

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);
        setNoResults(false);
        setMainAccountNumber(formData.mainAccountNumber);

        try {
            const { data } = await client(GET_LIST, "babbage-accounts", {
                id: "babbage-accounts",
                filter: {
                    mainAccountNumber: formData.mainAccountNumber,
                    itemsPerPage: 10000,
                },
            });

            setNoResults(data.length === 0);
            setBabbageAccounts(data);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Babbage Accounts | Peracto</title>
            </Helmet>
            <Heading name="Babbage Accounts" />

            <div className="row">
                <div className="col">
                    <Form
                        autoComplete="on"
                        values={{
                            mainAccountNumber: "",
                        }}
                        schema={schema}
                        showActionPanel={false}
                        onSubmit={onSubmitForm}
                    >
                        <Group
                            key="babbage-account-number"
                            id="babbage-account-number"
                            name="Babbage Account Number"
                        >
                            <Input
                                name="mainAccountNumber"
                                label="Main Account Number"
                                testId="mainAccountNumber"
                                required
                            />

                            <button
                                className="btn btn-primary"
                                type="submit"
                                data-testid="submit"
                            >
                                Submit
                            </button>
                        </Group>
                    </Form>

                    {loading ? (
                        <div className="flex py-20 items-center justify-center">
                            <LoadingIcon />
                        </div>
                    ) : babbageAccounts.length > 0 ? (
                        <Group
                            key="babbage-accounts"
                            id="babbage-accounts"
                            name="Babbage Accounts"
                        >
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="!border-t-0">
                                            Babbage Account
                                        </th>
                                        <th className="!border-t-0">
                                            Restrict Haynes Pro access?
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {babbageAccounts.map((account) => {
                                        return (
                                            <tr key={account.mainAccountNumber}>
                                                <td>
                                                    {account.mainAccountNumber}
                                                </td>
                                                <td>
                                                    <Toggle
                                                        isOn={
                                                            account.hasHaynesProRevoked
                                                        }
                                                        setIsOn={() =>
                                                            onUpdateValue({
                                                                mainAccountNumber:
                                                                    account.mainAccountNumber,
                                                                key: "restrict_haynes_pro_access",
                                                                value: `${!account.hasHaynesProRevoked}`,
                                                            })
                                                        }
                                                        disabled={isUpdating}

                                                        // data-testid={`babbage-accounts--row-${row.row.index}__toggle--hasFifm`}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Group>
                    ) : noResults ? (
                        <div className="py-20 text-center">
                            No Babbage Accounts found for "{mainAccountNumber}"
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};
