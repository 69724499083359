/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, FieldArray } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import { Checkbox, File } from "@peracto/peracto-ui";

const AttributeOptionList = ({ name, inputType }) => {
  const [isSwatch, setIsSwatch] = useState(false);

  useEffect(() => {
    setIsSwatch(inputType === "swatch");
  }, [inputType]);

  const row = {
    default_value: false,
    display_order: 10,
    label: "",
    value: "",
  };

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FieldArray name={field.name}>
          {({ push, remove }) => (
            <div className="d-flex flex-column">
              <table className="table">
                <thead>
                  <tr>
                    <th>Label</th>
                    {isSwatch && (
                      <th>
                        Swatch Value{" "}
                        <span
                          data-tooltip="Please choose a file or enter a HEX colour code (eg: #bababa)"
                          className="tooltip-wrap"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                      </th>
                    )}
                    <th>Display Order</th>
                    <th className="text-nowrap">Default Value</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {field.value &&
                    field.value.length > 0 &&
                    field.value.map((option, index) => (
                      <tr key={`${name}-${index}`}>
                        <td>
                          <Field
                            name={`${name}.${index}.label`}
                            className="form-control form-control-sm"
                            data-testid={`option-${index}-label`}
                            onChange={(e) => {
                              form.setFieldValue(
                                `${name}.${index}.label`,
                                e.target.value
                              );

                              if (!isSwatch) {
                                form.setFieldValue(
                                  `${name}.${index}.value`,
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </td>
                        {isSwatch && (
                          <td>
                            <File
                              name={`${name}.${index}.value`}
                              testId={`option-${index}-swatch-value`}
                            />
                          </td>
                        )}
                        <td>
                          <Field
                            name={`${name}.${index}.display_order`}
                            className="form-control form-control-sm"
                            data-testid={`option-${index}-display-order`}
                          />
                        </td>
                        <td>
                          <Checkbox
                            name={`${name}.${index}.default_value`}
                            label=""
                            onClick={(e) => {
                              const values = [...field.value];
                              values.forEach((val, idx) => {
                                form.setFieldValue(
                                  `${name}.${idx}.default_value`,
                                  false
                                );
                              });
                              form.setFieldValue(
                                `${name}.${index}.default_value`,
                                !e.target.value
                              );
                            }}
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            onClick={() => remove(index)}
                            data-testid={`remove-option-${index}`}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <button
                type="button"
                role="button"
                className="mb-2 btn btn-primary align-self-end"
                onClick={() => push(row)}
                data-testid="add-an-option"
              >
                <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                Add an Option
              </button>
            </div>
          )}
        </FieldArray>
      )}
    </Field>
  );
};

AttributeOptionList.displayName = "AttributeOptionList";

AttributeOptionList.propTypes = {
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
};

export default AttributeOptionList;
