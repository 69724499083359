/* eslint-disable */
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Field, FieldArray } from "formik";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

import {
  Form,
  Group,
  Input,
  Select,
  DateInput,
  Modal,
  Checkbox,
  FormActions,
} from "@peracto/peracto-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faEyeSlash } from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";

import { toast } from "react-toastify";

import PromotionCodes from "./PromotionCodes";
import MinimumRequirements from "./MinimumRequirements";
import Discounts from "./Discounts";

import { DELETE, GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

const PromotionsForm = ({
  mode = MODE_EDIT,
  onDuplicate = () => {},
  values,
  ...props
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [redirect, setRedirect] = useState();
  const [promotions, setPromotions] = useState([]);
  const [showPromoCodeForm, setShowPromoCodeForm] = useState(false);

  const { client } = useClient();
  const config = useConfig();
  const { promotions: promotionsConfig } = config.get("features", {});

  const fetchPromotions = async (inputValue, fieldVal) => {
    const { data } = await client(GET_LIST, "promotions", {
      id: "promotions",
      name: inputValue,
    });

    setPromotions(data);

    const selectedIds = fieldVal
      ? fieldVal.map((value) => {
          return value.originId ? value.originId : value.id;
        })
      : [];

    const values = data
      .filter((attr) => !selectedIds.includes(attr.originId))
      .map((attr) => ({
        label: attr.name,
        value: attr.id,
      }));

    return values;
  };

  const debouncedFetchPromotions = debounce(fetchPromotions, 200);

  const onDelete = async () => {
    try {
      await client(DELETE, "promotions", {
        id: values.id,
      });

      toast.success("Promotion deleted successfully!");
      setRedirect("/promotions");
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  const COUNTRY_TYPES = [
    {
      label: "United Kingdom",
      value: "gb",
    },
    {
      label: "Ireland",
      value: "ie",
    },
  ];

  return (
    <>
      {redirect && <Redirect to={redirect} />}

      {mode === MODE_EDIT && (
        <FormActions>
          <a onClick={() => setShowDuplicateDialog(true)}>
            <FontAwesomeIcon icon={faCopy} className="mr-2" />
            Duplicate
          </a>

          <a className="text-danger" onClick={() => setShowDialog(true)}>
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            Delete Promotion
          </a>
        </FormActions>
      )}

      <Form autoComplete="off" values={values} {...props}>
        <Group key="core" id="core" name="Core">
          <Select
            label="Country"
            name={"countryCode"}
            options={COUNTRY_TYPES}
            required
          />
          <Field name="name">
            {({ field, form }) => {
              return (
                <Input
                  name={field.name}
                  label="Promotion Name"
                  required
                  help={`Displayed when the promotion is successfully applied.`}
                  onBlur={() => {
                    if (field?.value?.length > 0) {
                      if (
                        !form.values.identifier ||
                        form.values.identifier.length === 0
                      ) {
                        form.setFieldValue(
                          "identifier",
                          encodeURI(
                            `${form.values.name
                              .split(" ")
                              .join("-")
                              .replace(
                                /[`~!@#$£€%^&*_|+=?;:'",.()<>\{\}\[\]\\\/]/gi,
                                ""
                              )
                              .replace(/-+/g, "-")
                              .toLowerCase()}`
                          )
                        );
                      }
                    }
                  }}
                  testId="promotion-name"
                />
              );
            }}
          </Field>

          <Input
            name="identifier"
            testId="promotion-identifier"
            label="Promotion Identifier"
            required
            help="Used by Peracto to uniquely identify the promotion."
          />

          <Input
            name="priority"
            testId="promotion-priority"
            type="number"
            label="Priority"
            required
            help={
              "Promotions are applied in ascending order according to their priority."
            }
          />

          <Input
            name="applicationSuccessMessage"
            testId="promotion-success-message"
            label="Promotion Success Message"
            help="Set your own custom success message when this promotion is applied"
          />

          {promotionsConfig?.orderTransferCode && (
            <Input
              name="orderTransferCode"
              label="Order Transfer Code"
              required
            />
          )}
        </Group>
        <Group
          key="promotion-codes"
          id="promotion-codes"
          name="Promotion Codes"
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="mt-3">
              To limit use, create one or many promotion codes.
            </p>

            <button
              type="button"
              testId="promotion-manage-codes"
              onClick={(e) => setShowPromoCodeForm(!showPromoCodeForm)}
              className="px-0 btn btn-link"
            >
              {showPromoCodeForm ? (
                <>
                  <FontAwesomeIcon icon={faEyeSlash} className="mr-2" />
                  Close
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faEye} className="mr-2" />
                  Manage Codes
                </>
              )}
            </button>
          </div>

          {!showPromoCodeForm && (
            <Field name="promotionCodes">
              {({ field }) => (
                <>
                  {!field.value ||
                    (field?.value?.length === 0 ? (
                      <p className="my-4 mb-0 text-center">
                        No codes have been assigned to this promotion
                      </p>
                    ) : (
                      <p className="my-4 mb-0 text-center">
                        {field.value.length}{" "}
                        {field?.value?.length === 1 ? "code has" : "codes have"}{" "}
                        been assigned to this promotion
                      </p>
                    ))}
                </>
              )}
            </Field>
          )}

          {showPromoCodeForm && <PromotionCodes name="promotionCodes" />}
        </Group>
        <Group
          key="minimum-requirements"
          id="minimum-requirements"
          name="Minimum Requirements"
        >
          <MinimumRequirements name="conditions" />
        </Group>
        <Group key="discount" id="discount" name="Discount">
          <Discounts name="discounts" />
        </Group>
        <Group key="availability" id="availability" name="Availability">
          <Select
            name="status"
            label="Status"
            options={[
              { value: "active", label: "Active" },
              { value: "archived", label: "Archived" },
            ]}
          />

          <div className="row">
            <div className="col-6">
              <DateInput
                name="startDateTime"
                label="Start Date"
                includeTime={true}
              />
            </div>
          </div>

          <Checkbox name="setEndDate" label="Set End Date" />

          <Field name="endDateTime">
            {({ field, form }) => (
              <>
                {form.values.setEndDate === true && (
                  <div className="row">
                    <div className="col-6">
                      <DateInput
                        name={field.name}
                        label="End Date"
                        includeTime={true}
                        minDate={form.values.startDateTime}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Field>
        </Group>

        <Field name="promotionCodes">
          {({ field }) => (
            <>
              {field.value.length === 0 && (
                <Group key="usage-limits" id="usage-limits" name="Usage Limits">
                  <div className="row">
                    <div className="col-6">
                      <Checkbox name="setLimitUses" label="Limit Total Uses" />

                      <Field name="maximumUses">
                        {({ field, form }) => (
                          <>
                            {form.values.setLimitUses === true && (
                              <div className="row">
                                <div className="col-6">
                                  <Input
                                    type="number"
                                    testId="promotion-maximum-uses"
                                    min={1}
                                    name={field.name}
                                    disabled={
                                      form?.values?.discountCodes?.length > 0
                                    }
                                    label="Maximum Uses"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Field>

                      <Checkbox
                        name="setLimitEmailUses"
                        label="Limit Uses Per Email Address"
                      />

                      <Field name="maximumUsesPerEmail">
                        {({ field, form }) => (
                          <>
                            {form.values.setLimitEmailUses === true && (
                              <div className="row">
                                <div className="col-6">
                                  <Input
                                    type="number"
                                    data-testid="promotion-maximum-uses-email   "
                                    min={1}
                                    name={field.name}
                                    disabled={
                                      form?.values?.discountCodes?.length > 0
                                    }
                                    label="Maximum Uses Per Email"
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </div>
                  </div>
                </Group>
              )}
            </>
          )}
        </Field>

        <Group
          key="promotion-compatibility"
          id="promotion-compatibility"
          name="Promotion Compatibility"
        >
          <p className="mt-3">
            By default, all promotions will work in conjunction with each other.
            To prevent this promotion being applied with specific others set
            compatibility exclusions.
          </p>

          <Field name="promotionCompatibilities">
            {({ field, form }) => (
              <>
                <FieldArray name={field.name}>
                  {() => (
                    <table className="table table-sm">
                      <colgroup>
                        <col width="85%" />
                        <col width="15%" />
                      </colgroup>

                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="text-right">Remove</th>
                        </tr>
                      </thead>

                      <tbody>
                        {field?.value?.length > 0 &&
                          field?.value?.map((item, index) => (
                            <tr
                              key={`${field?.incompatiblePromotion?.name}_${item.id}`}
                              data-id={item.id}
                            >
                              <td>{item?.incompatiblePromotion?.name}</td>
                              <td className="text-right">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  onClick={() => {
                                    const values = [...field.value];
                                    values.splice(index, 1);
                                    form.setFieldValue(field.name, [...values]);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </FieldArray>
                <div className="row">
                  <div className="col-12">
                    <AsyncSelect
                      classNamePrefix="list"
                      loadOptions={(input) =>
                        debouncedFetchPromotions(input, field.value)
                      }
                      onChange={(option) => {
                        const selectedValue = promotions.filter(
                          (attr) => attr.id == option.value
                        );
                        const fieldValues = field.value ? [...field.value] : [];

                        fieldValues.push({
                          incompatiblePromotion: selectedValue[0],
                        });

                        form.setFieldValue(field.name, [...fieldValues]);
                      }}
                      value={null}
                      placeholder="Search for Promotions to add..."
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length > 0) {
                          return `No results found for '${inputValue}'.`;
                        } else {
                          return "Enter text to begin searching.";
                        }
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </Field>
        </Group>
      </Form>

      {mode === MODE_EDIT && (
        <>
          <Modal
            isVisible={showDialog}
            title="Delete Promotion"
            close={() => setShowDialog(false)}
            buttons={[
              {
                type: "btn-outline-secondary",
                text: "Close",
                action: () => setShowDialog(false),
              },
              {
                type: "btn-danger",
                text: "Delete Promotion",
                action: () => onDelete(),
              },
            ]}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              size="4x"
              className="mb-4 d-block"
            />
            Are you sure you would like to permanently delete this promotion?
            Deleted promotions cannot be recovered.
          </Modal>

          <Modal
            isVisible={showDuplicateDialog}
            title="Duplicate Promotion"
            close={() => setShowDuplicateDialog(false)}
            buttons={[
              {
                type: "btn-outline-secondary",
                text: "Close",
                action: () => setShowDuplicateDialog(false),
              },
              {
                type: "btn-success",
                text: "Duplicate Promotion",
                action: () => {
                  onDuplicate(values);
                  setShowDuplicateDialog(false);
                },
              },
            ]}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              size="4x"
              className="mb-4 d-block"
            />
            Are you sure you would like to duplicate this promotion?
          </Modal>
        </>
      )}
    </>
  );
};

PromotionsForm.displayName = "PromotionsForm";

PromotionsForm.propTypes = {
  values: PropTypes.object,
  mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
  schema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PromotionsForm;
