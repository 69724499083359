import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Group, Heading, LoadingIcon } from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";
import ReactSelect from "react-select";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

export const GetIDByTecdocMVLForm = () => {
  const config = useConfig();
  const API_URL = config.get("api");

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState("");
  const [authenticationData, setAuthenticationData] = useState("");

  const [vehicleManufacturers, setVehicleManufacturers] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleYears, setVehicleYears] = useState([]);
  const [vehicleEngines, setVehicleEngines] = useState([]);
  const [vehicleFuels, setVehicleFuels] = useState([]);
  const [errors, setErrors] = useState([]);
  const [mvlSearchLoading, setMvlSearchLoading] = useState(false);

  const [mvl, setMvl] = useState({
    make: "",
    model: "",
    vehicleYear: "",
    engine: "",
    fuel: "",
  });

  const schema = yup.object().shape({
    make: yup.string().required(),
    model: yup.string().required(),
    vehicleYear: yup.string().required(),
    engine: yup.string().required(),
    fuel: yup.string().required(),
  });

  useEffect(() => {
    const getVehicleManufacturers = async () => {
      const { data } = await axios.get(
        `${API_URL}/storefront/vehicle-search/manufacturers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const manufacturerList = Object.values(data.manufacturers).map((make) => {
        return { label: make.Name, value: make.Name };
      });

      setVehicleManufacturers(manufacturerList);
    };
    setLoading(true);
    getVehicleManufacturers();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const getVehicleModels = async (make) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${API_URL}/storefront/vehicle-search/models`,
        {
          manufacturer: make,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const modelList = Object.values(data.models["hydra:member"]).map(
        (model) => {
          return { label: model.Value, value: model.Value };
        }
      );

      setVehicleModels(modelList);
    } catch (e) {
      console.error(e);

      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "There was an error fetching vehicle models."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMvlLookup = async (make, model, vehicleYear, engine) => {
    const body = {
      make,
      model,
      performFinalSearch: false,
      ...(vehicleYear && { vehicleYear }),
      ...(engine && { engine }),
    };

    setLoading(true);

    try {
      const { data } = await axios.post(
        `${API_URL}/storefront/vehicle-search/mvl`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (body.hasOwnProperty("engine")) {
        const fuels = data.mvlVehicleDetails
          .filter((item) => item.Name === "Fuel")
          .map((item) => {
            return { label: item.Value, value: item.Value };
          });
        setVehicleFuels(fuels);
      } else if (body.hasOwnProperty("vehicleYear")) {
        const engines = data.mvlVehicleDetails
          .filter((item) => item.Name === "EngineSizeDescription")
          .map((item) => {
            return { label: item.Value, value: item.Value };
          });
        setVehicleEngines(engines);
      } else {
        const years = data.mvlVehicleDetails
          .filter((item) => item.Name === "VehicleYear")
          .map((item) => {
            return { label: item.Value, value: item.Value };
          })
          .reverse();

        setVehicleYears(years);
      }
    } catch (e) {
      console.error(e);

      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "There was an error fetching vehicle details."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMvlSearchLoading(true);

    try {
      await schema.validate(mvl, { abortEarly: false });
    } catch (error) {
      const fieldErrors = error.inner.map((item) => {
        return item.params.path;
      });
      setErrors(fieldErrors);
      setLoading(false);
      setMvlSearchLoading(false);

      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    try {
      const { data: authData } = await axios.get(
        `${API_URL}/test-harness/haynes-pro/get-authentication-vrid`,
        {
          headers,
        }
      );

      const { data: haynesData } = await axios.post(
        `${API_URL}/test-harness/haynes-pro/get-identification-by-tecdoc-number/by-mvl`,
        {
          ...mvl,
        },
        {
          headers,
        }
      );

      setAuthenticationData(JSON.stringify(authData));
      setResponseData(JSON.stringify(haynesData));
    } catch (e) {
      console.error(e);

      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "There was an error submitting this form."
      );
    } finally {
      setMvlSearchLoading(false);

      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Haynes Pro Tech Doc Lookup: MVL | Raindata | Web Services | Peracto
        </title>
      </Helmet>
      <div className="form-container">
        <Heading name="Haynes Pro Tech Doc Lookup: MVL" />

        <div className="mb-4 card card--shadow">
          <div className="card-body">
            <h3 className="text-[#c8c8c8] text-[0.8rem] border-b border-b-[#f0f0f0] pb-2 mb-2 uppercase font-medium">
              MVL
            </h3>
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
              <div>
                <label htmlFor="make">Make</label>
                <ReactSelect
                  id="make"
                  options={vehicleManufacturers}
                  value={
                    mvl.make
                      ? vehicleManufacturers.find(
                          (make) => make.value === mvl.make
                        )
                      : null
                  }
                  onChange={async (e) => {
                    setMvl({
                      ...mvl,
                      make: e.value,
                      model: "",
                      vehicleYear: "",
                      engine: "",
                      fuel: "",
                    });
                    setErrors((prevErrors) => {
                      return prevErrors.filter((error) => error !== "make");
                    });
                    await getVehicleModels(e.value);
                  }}
                  placeholder={"Select vehicle make"}
                  isDisabled={loading}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: errors.includes("make") && "1px solid #E23D56",
                    }),
                  }}
                />
                {errors.includes("make") && (
                  <span className={"text-brand-red italic text-xs"}>
                    {"Vehicle make is a required field"}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="model">Model</label>
                <ReactSelect
                  id="model"
                  options={vehicleModels}
                  value={
                    mvl.model
                      ? vehicleModels.find((model) => model.value === mvl.make)
                      : null
                  }
                  onChange={(e) => {
                    setMvl({
                      ...mvl,
                      model: e.value,
                      vehicleYear: "",
                      engine: "",
                      fuel: "",
                    });
                    setErrors((prevErrors) => {
                      return prevErrors.filter((error) => error !== "model");
                    });
                    handleMvlLookup(mvl.make, e.value);
                  }}
                  placeholder="Select vehicle model"
                  isDisabled={!mvl.make || !vehicleModels || loading}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border:
                        errors.includes("model") &&
                        (mvl.make || vehicleModels) &&
                        "1px solid #E23D56",
                    }),
                  }}
                />
                {errors.includes("model") && (
                  <span className={"text-brand-red italic text-xs"}>
                    {"Vehicle model is a required field"}
                  </span>
                )}
              </div>
              <div>
                <label htmlFor="vehicleYear">Vehicle Year</label>
                <ReactSelect
                  id="vehicleYear"
                  options={vehicleYears}
                  value={
                    mvl.vehicleYear
                      ? vehicleYears.find((year) => year.value === mvl.year)
                      : null
                  }
                  onChange={(e) => {
                    setMvl({
                      ...mvl,
                      vehicleYear: e.value,
                      engine: "",
                      fuel: "",
                    });
                    setErrors((prevErrors) => {
                      return prevErrors.filter(
                        (error) => error !== "vehicleYear"
                      );
                    });
                    handleMvlLookup(mvl.make, mvl.model, e.value);
                  }}
                  placeholder="Select vehicle year"
                  isDisabled={
                    !mvl.make || !mvl.model || !vehicleYears || loading
                  }
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border:
                        errors.includes("vehicleYear") && "1px solid #E23D56",
                    }),
                  }}
                />
                {errors.includes("vehicleYear") && (
                  <span className={"text-brand-red italic text-xs"}>
                    {"Vehicle year is a required field"}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="vehicleEngine">Engine size</label>
                <ReactSelect
                  id="vehicleEngine"
                  options={vehicleEngines}
                  value={
                    mvl.engine
                      ? vehicleEngines.find(
                          (engine) => engine.value === mvl.engine
                        )
                      : null
                  }
                  onChange={(e) => {
                    setMvl({
                      ...mvl,
                      engine: e.value,
                      fuel: "",
                    });
                    setErrors((prevErrors) => {
                      return prevErrors.filter((error) => error !== "engine");
                    });
                    handleMvlLookup(
                      mvl.make,
                      mvl.model,
                      mvl.vehicleYear,
                      e.value
                    );
                  }}
                  placeholder="Select engine size"
                  isDisabled={
                    !mvl.make ||
                    !mvl.model ||
                    !mvl.vehicleYear ||
                    !vehicleEngines ||
                    loading
                  }
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: errors.includes("engine") && "1px solid #E23D56",
                    }),
                  }}
                />
                {errors.includes("engine") && (
                  <span className={"text-brand-red italic text-xs"}>
                    {"Engine size is a required field"}
                  </span>
                )}
              </div>

              <div>
                <label htmlFor="fuel">Fuel</label>
                <ReactSelect
                  id="fuel"
                  options={vehicleFuels}
                  value={
                    mvl.fuel
                      ? vehicleFuels.find((fuel) => fuel.value === mvl.fuel)
                      : null
                  }
                  onChange={(e) => {
                    setMvl({
                      ...mvl,
                      fuel: e.value,
                    });
                    setErrors((prevErrors) => {
                      return prevErrors.filter((error) => error !== "fuel");
                    });
                  }}
                  placeholder="Select fuel type"
                  isDisabled={
                    !mvl.make ||
                    !mvl.model ||
                    !mvl.vehicleYear ||
                    !mvl.engine ||
                    !vehicleEngines ||
                    loading
                  }
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: errors.includes("fuel") && "1px solid #E23D56",
                    }),
                  }}
                />
                {errors.includes("fuel") && (
                  <span className={"text-brand-red italic text-xs"}>
                    {"Vehicle fuel is a required field"}
                  </span>
                )}
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  type="submit"
                  data-testid="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        {mvlSearchLoading && (
          <div className="flex items-center justify-center py-5">
            <LoadingIcon />
          </div>
        )}

        {authenticationData?.length > 0 && !loading && (
          <Group
            key="authentication-details"
            id="authentication-details"
            name="Authentication Details"
          >
            <div className="flex items-center justify-between mt-2 mb-3">
              <h4 className="text-[18px] font-semibold mr-2 mb-0">
                Haynes Authentication Results
              </h4>{" "}
              <div className="flex flex-row">
                <CopyButton content={authenticationData} />
              </div>
            </div>

            <div className="border rounded-[4px] p-2">
              <CodeFormatter code={authenticationData} language="json" />
            </div>
          </Group>
        )}

        {responseData?.length > 0 && !loading && (
          <Group
            key="response-details"
            id="response-details"
            name="Response Details"
          >
            <div className="flex items-center justify-between mt-2 mb-3">
              <h4 className="text-[18px] font-semibold mr-2 mb-0">
                Haynes Vehicle Response
              </h4>{" "}
              <CopyButton content={responseData} />
            </div>
            <div className="border rounded-[4px] p-2">
              <CodeFormatter code={responseData} language="json" />
            </div>
          </Group>
        )}
      </div>
    </>
  );
};

GetIDByTecdocMVLForm.displayName = "GetIDByTecdocMVLForm";
