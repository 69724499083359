import createPlugin from "./createPlugin";
import DepartmentsRenderer from "./Renderer/DepartmentsRenderer";

const DepartmentsPlugin = (settings) =>
    createPlugin({
        Renderer: DepartmentsRenderer,
        Controls: null,
        ...settings,
    });

const Departments = DepartmentsPlugin();
export default Departments;

export { DepartmentsPlugin, DepartmentsRenderer };
