import React from "react";
import type { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

type DepartmentCardProps = HTMLAttributes<HTMLDivElement> & {
    departmentName: string;
    departmentSlug: string;
    departmentImage: string;
};

export const DepartmentCard = (props: DepartmentCardProps) => {
    const {
        departmentName,
        departmentSlug,
        departmentImage,
        className = "",
    } = props;

    return (
        <a
            href={`/${departmentSlug}`}
            className={twMerge(
                "px-[16px] py-3 rounded-2xl gap-x-3 flex items-center justify-between bg-brand-white",
                className
            )}
            style={{
                boxShadow:
                    "0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 4px 4px 0px rgba(0, 0, 0, 0.05)",
            }}
        >
            <span className="flex-[1_0_0] font-bold">{departmentName}</span>
            <span className="relative w-[70px] h-[52px]">
                <img
                    src={departmentImage}
                    alt={departmentName}
                    className="absolute h-full w-full inset-0 object-cover transparent"
                />
            </span>
        </a>
    );
};
