import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";

import { GET_LIST, useClient } from "@peracto/client";
import { Link } from "react-router-dom";
import {
    HydraTable,
    Heading,
    TextFilter,
    StyledCheckbox,
} from "@peracto/peracto-ui";
import { Helmet } from "react-helmet";

export const LocationsList = () => {
    const { client } = useClient();
    const [selectedColumns, onSelectColumn] = useState({});

    const fields = [
        {
            header: "Postal Code",
            accessor: "postcode",
            show: true,
            selectable: false,
        },
        {
            header: "Email",
            accessor: "email",
            show: true,
            selectable: false,
            Cell: (row) => (
                <a href={`mailto:${row.cell.value}`}>{row.cell.value}</a>
            ),
        },
        {
            header: "Telephone",
            accessor: "telephone",
            show: true,
            selectable: false,
        },
    ];

    const columns = [
        {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
                <StyledCheckbox {...row.getToggleRowSelectedProps()} />
            ),
            selectable: false,
        },
        {
            Cell: (row) => (
                <Link to={row.row.original.id}>{row.cell.value}</Link>
            ),
            Filter: (props) => <TextFilter label="Store Name" {...props} />,
            sortType: "basic",
            selectable: false,
            accessor: "name",
            Header: "Store Name",
        },
    ];

    fields.forEach(
        ({ header, accessor, selectable, show, id = null, Cell, Filter }) =>
            columns.push({
                Header: header,
                accessor: accessor,
                sortType: "basic",
                Filter: Filter ? Filter : null,
                Cell: Cell
                    ? Cell
                    : ({ cell: { value } }) => (value ? value : "-"),
                selectable: selectable,
                show: show,
                id,
            })
    );

    const onFetchData = (options) => client(GET_LIST, "locations", options);

    return (
        <>
            <Helmet>
                <title>Locations | Peracto</title>
            </Helmet>
            <Heading name="Locations">
                <div className="d-flex align-items-center ml-2">
                    <span
                        className="tooltip-wrap tooltip-below"
                        data-tooltip="New locations must be created by the 9xb development team. Please raise a Jira ticket"
                    >
                        <Link
                            className="btn btn-primary opacity-30 cursor-not-allowed"
                            data-testid="add-location"
                            to="#"
                            onClick={(e) => e.preventDefault()}
                        >
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="mr-2"
                            />
                            Add Location
                        </Link>
                    </span>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
