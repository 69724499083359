import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons/faRedo";
import { UPDATE, useClient } from "@peracto/client";
import { toast } from "react-toastify";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons/faCircleNotch";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";

import { Modal } from "@peracto/peracto-ui";

export const ReprocessOrders = ({ selectedRows, instance, reloadData }) => {
    const [errors, setErrors] = useState([]);
    const [showReprocessModal, setShowReprocessModal] = useState(false);

    const [isReprocessing, setIsReprocessing] = useState(false);
    const { client } = useClient();

    const onReprocessOrders = async () => {
        setIsReprocessing(true);

        let ids = [];
        selectedRows.forEach((row) => {
            ids.push(
                client(UPDATE, `orders`, {
                    id: `/orders/${row}/process-xml`,
                    data: {},
                })
            );
        });

        try {
            await Promise.all(ids);
            setShowReprocessModal(false);
            setIsReprocessing(false);
            instance.toggleRowSelectedAll(false);
            reloadData();
            toast.success("The selected orders are reprocessing");
        } catch (e) {
            if (process.env.NODE_ENV === "development") console.error(e);
            setErrors([
                ...errors,
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem...",
            ]);
            setIsReprocessing(false);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
        }
    };

    return (
        <div className="flex-col w-full">
            <div className="flex flex-row justify-between items-center w-full">
                <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faRedo} size="1x" className="mr-2" />
                    <p>
                        Reprocess {selectedRows.length} Order
                        {selectedRows.length !== 1 && `s`}.
                    </p>
                </div>

                <div>
                    <button
                        className="btn btn-outline-secondary mr-2 btn-cancel-delete"
                        onClick={() => instance.toggleRowSelectedAll(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="btn btn-success"
                        onClick={() => setShowReprocessModal(true)}
                    >
                        Reprocess
                    </button>
                </div>
            </div>

            <Modal
                isVisible={showReprocessModal}
                title="Reprocess Orders"
                close={() => setShowReprocessModal(false)}
                buttons={[
                    {
                        type: "btn-outline-secondary",
                        text: "Close",
                        action: () => setShowReprocessModal(false),
                    },
                    {
                        type: "btn-success",
                        text: "Reprocess Orders",
                        disabled: isReprocessing,
                        action: () => {
                            onReprocessOrders();
                        },
                    },
                ]}
            >
                {isReprocessing ? (
                    <>
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            size="4x"
                            spin
                            className="d-block mb-4"
                        />
                        Reprocessing.....
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="4x"
                            className="d-block mb-4"
                        />
                        <div data-testid={`delete-warning`}>
                            Would you like to reprocess the selected orders?
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};
