import React, { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Field } from "formik";
import { toast } from "react-toastify";
import { Modal, Select } from "@peracto/peracto-ui";
import axios from "axios";

import { useConfig } from "@peracto/peracto-config";
import { CREATE, useClient } from "@peracto/client";
import { formatDataForAPI } from "../../util";

const REJECTION_REASONS = [
    "Payment Arrears",
    "Customer Unreachable",
    "Customer Is Not Placing Orders",
    "Competitor",
    "Other",
];

export const ManageApplication = (props) => {
    const { isPending, canRejectApplications } = props;
    const config = useConfig();

    const API_URL = config.get("api");

    const { id } = useParams();

    const [redirect, setRedirect] = useState();

    const [showRejectionModal, setShowRejectionModal] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [rejectionReasonMessage, setRejectionReasonMessage] = useState("");

    const { client } = useClient();

    const onAcceptApplication = async (data, actions) => {
        const formattedData = formatDataForAPI(data);
        try {
            const response = await client(CREATE, "users", {
                data: formattedData,
            });

            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(
                        `${error.propertyPath}`,
                        error.message
                    );
                });
            } else {
                setRedirect(response.data.id);
                toast.success("User application accepted!");
                actions.setSubmitting(false);
            }
        } catch (e) {
            console.error(e);

            // eslint-disable-next-line no-unused-expressions
            e?.error?.body?.violations?.forEach((error) => {
                actions.setFieldError(`${error.propertyPath}`, error.message);
            });

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            actions.setSubmitting(false);
        }
    };

    const onRejectApplication = async () => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            await axios.put(
                `${API_URL}/user-applications/reject/${id}`,
                {
                    reason:
                        rejectionReason === "Other"
                            ? rejectionReasonMessage
                            : rejectionReason,
                },
                {
                    headers,
                }
            );

            setRedirect("/user-applications");
            toast.success("User application rejected!");
        } catch (e) {
            console.error(e);

            toast.error("Whoops, there was a problem...");
        }
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return (
        <>
            <div className="flex flex-row">
                <Field name="name">
                    {({ form }) => {
                        return (
                            <div>
                                <div>
                                    {form.values?.babbageAccounts?.length ===
                                    0 ? (
                                        <p className="mb-2 font-semibold text-danger">
                                            At least one Babbage account must be
                                            assigned before the application can
                                            be accepted.
                                        </p>
                                    ) : (
                                        ""
                                    )}

                                    <button
                                        className="btn btn-success mr-2"
                                        disabled={
                                            form.values?.babbageAccounts
                                                ?.length === 0
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                                form.values?.babbageAccounts
                                                    ?.length > 0
                                            ) {
                                                const { values, ...actions } =
                                                    form;

                                                onAcceptApplication(
                                                    values,
                                                    actions
                                                );
                                            }
                                        }}
                                    >
                                        Accept Application
                                    </button>
                                    {isPending && canRejectApplications && (
                                        <button
                                            className="btn btn-danger"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowRejectionModal(true);
                                            }}
                                        >
                                            Reject Application
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    }}
                </Field>
            </div>

            <Modal
                isVisible={showRejectionModal}
                title="Reject Application"
                close={() => setShowRejectionModal(false)}
                buttons={[
                    {
                        type: "btn-outline-secondary",
                        text: "Cancel",
                        action: () => setShowRejectionModal(false),
                    },
                    {
                        type: "btn-success",
                        text: "Reject Application",
                        action: () => onRejectApplication(),
                        disabled:
                            !rejectionReason ||
                            (rejectionReason === "Other" &&
                                !rejectionReasonMessage),
                    },
                ]}
            >
                <p className="text-left">
                    Please select a reason for rejecting this application.
                </p>

                <Select
                    name="reason"
                    className="w-full text-left"
                    options={REJECTION_REASONS.map((reason) => {
                        return {
                            label: reason,
                            value: reason,
                        };
                    })}
                    required
                    placeholder="Select Rejection Reason"
                    testId="rejection-reason"
                    testIdItems="rejection-reason__item"
                    testIdIndex={0}
                    handleChange={(reason) => {
                        setRejectionReason(reason);
                    }}
                />

                {rejectionReason === "Other" && (
                    <textarea
                        className="w-full form-control"
                        value={rejectionReasonMessage}
                        onChange={(e) =>
                            setRejectionReasonMessage(e.target.value)
                        }
                    />
                )}
            </Modal>
        </>
    );
};
