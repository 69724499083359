/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

import { DepartmentCard } from "./components";
import { TabNavigation } from "components/TabNavigation";

import { menuData } from "./fakeData";

export const Departments = (props) => {
    const { className = "" } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const filteredMenuData = menuData?.header?.filter(
        (item) => item?.children?.length > 0
    );

    return (
        <>
            <section
                className={twMerge(`relative bg-brand-white py-8`, className)}
            >
                <img
                    src="/images/decorative/lkq-lines-1.png"
                    className="absolute inset-0 w-full h-full pointer-events-none object-cover object-center"
                />
                <div className="w-full px-[40px]">
                    <h2 className="mb-8 text-[40px] leading-[52px] text-center text-brand-primary font-bold">
                        Departments
                    </h2>
                    <TabNavigation
                        tabStyle="tab"
                        tabs={filteredMenuData?.map((item, index) => ({
                            label: item?.categoryName,
                            value: index,
                        }))}
                        onTabClick={(value) => setSelectedTab(value)}
                        className="w-full mb-12 text-xl"
                    />
                    <div className="relative z-10 grid grid-cols-2 gap-4 md:grid-cols-4 xl:grid-cols-6">
                        {filteredMenuData?.[selectedTab]?.children?.map(
                            (item, index) => (
                                <DepartmentCard
                                    key={index}
                                    departmentName={item?.categoryName}
                                    departmentSlug={item?.categorySlug}
                                    departmentImage={
                                        item?.categoryImage ||
                                        "/images/placeholder.jpg"
                                    }
                                />
                            )
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};
