/* eslint-disable no-unused-expressions */
export const DEFAULT_LOCATION_TYPES = [
    { label: "Branch", value: "branch" },
    { label: "Stockist", value: "stockist" },
    { label: "Warehouse", value: "warehouse" },
    { label: "Office", value: "office" },
    { label: "Click And Collect", value: "click_and_collect" },
];

export const LOCATION_TYPE_OPTIONS = [
    { label: "Locations", value: "location" },
    { label: "Location Types", value: "location_type" },
    { label: "Location Features", value: "location_feature" },
];

export const formatDataForForm = (data) => {
    const types =
        data?.locationTypes?.reduce((d, t) => [...d, t.type], []) || [];

    const formLocationFeatures = {};

    if (data?.locationFeatures?.length > 0) {
        data?.locationFeatures?.forEach((feature) => {
            !!formLocationFeatures[feature["feature"]];
        });
    }

    return {
        ...data,
        locationTypes: types,
        formLocationFeatures,
    };
};

export const formatDataForApi = (data) => {
    const types =
        data?.locationTypes?.reduce((d, t) => [...d, { type: t }], []) || [];
    const features = [];

    if (data?.formLocationFeatures) {
        for (const [key, val] of Object.entries(data.formLocationFeatures)) {
            if (val === true) {
                features.push({ feature: key });
            }
        }

        delete data.formLocationFeatures;
    }

    if (data?.ibtConfig) {
        delete data?.ibtConfig;
    }

    const dataClone = {
        ...data,
        locationTypes: types,
        locationFeatures: features,
        locationPageStatus: data.locationPageStatus || "active",
        longitude: parseFloat(data.longitude),
        latitude: parseFloat(data.latitude),
        ecpBranchId: parseInt(data.ecpBranchId),
    };

    if (data?.locationImages?.length > 0) {
        dataClone.locationImages = data.locationImages.map((image, idx) => {
            return {
                ...image,
                sortOrder: idx + 1,
            };
        });
    }

    if (!!data?.email === false) {
        dataClone.email = null;
    }

    return dataClone;
};
