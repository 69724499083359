export const formatDataForAPI = (data) => {
    const babbageAccounts = [];

    if (data?.babbageAccounts?.length > 0) {
        data.babbageAccounts.forEach((account) => {
            if (account?.babbageAccount?.["@id"]) {
                babbageAccounts.push({
                    "@id": account.babbageAccount["@id"],
                    isDefault: !!account.isDefault,
                });
            } else {
                babbageAccounts.push({
                    accountNumber: account.babbageAccount.accountNumber,
                    branch: `/locations/${account.babbageAccount.branch.id}`,
                    isDefault: !!account.isDefault,
                });
            }
        });
    }

    delete data["@id"];
    delete data["@type"];

    return {
        ...data,
        babbageAccounts,
        defaultBranch: data?.defaultBranch || null,
        enabled: true,
    };
};
