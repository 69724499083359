import React from "react";
import { Route, Switch } from "react-router-dom";
import {
    UserApplicationList,
    UserApplicationEdit,
    UserApplicationForm,
} from "./UserApplication";

export default () => {
    return {
        routes: [
            {
                path: "/user-applications",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}`}
                            exact
                            component={UserApplicationList}
                        />
                        <Route path={`${match.url}/:id`} exact>
                            {(props) => (
                                <UserApplicationEdit
                                    {...props}
                                    UserApplicationForm={UserApplicationForm}
                                />
                            )}
                        </Route>
                    </Switch>
                ),
            },
        ],
    };
};
