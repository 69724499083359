/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";

import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import * as yup from "yup";
import { useConfig } from "@peracto/peracto-config";
import cloneDeep from "lodash/cloneDeep";

import { formatDataForAPI, formatDataForForm } from "../util";

import {
  Heading,
  NotFoundMessage,
  UnauthorisedMessage,
} from "@peracto/peracto-ui";

import {
  GET_ONE,
  CREATE,
  UPDATE,
  useClient,
  getSchemaFromResource,
} from "@peracto/client";

import { MODE_EDIT } from "../PromotionsForm";

const PromotionsEditContainer = ({ children }) => {
  return (
    <div className="form-container">
      <Heading name="Edit Promotion">
        <div className="flex-grow-1 d-flex align-items-center justify-content-end">
          <Link className="btn btn-outline-primary" to="/promotions">
            <FontAwesomeIcon icon={faArrowCircleLeft} className="mr-2" />
            Back to Promotions
          </Link>
        </div>
      </Heading>
      {children}
    </div>
  );
};

const PromotionsEdit = ({ PromotionsForm, location: { pathname } }) => {
  const { client, getResource } = useClient();
  const [loading, setLoading] = useState(true);
  const [unauthorised, setUnauthorised] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [redirect, setRedirect] = useState();
  const [promotionData, setPromotionData] = useState();

  const fetchPromotion = async () => {
    try {
      const { data, response } = await client(GET_ONE, "promotions", {
        id: pathname,
      });

      if (response.status === 404) {
        setRedirect("/promotions");
        setLoading(false);
        return;
      }

      const formattedData = formatDataForForm(data);

      setPromotionData(formattedData);
      setLoading(false);
    } catch (e) {
      console.error(e);

      if (e.status === 403) {
        setUnauthorised(true);
      }

      if (e.status === 404) {
        setNotFound(true);
      }

      setLoading(false);
      setRedirect("/promotions");
    }
  };

  useEffect(() => {
    fetchPromotion();
  }, []);

  const config = useConfig();
  const { promotions: promotionsConfig } = config.get("features", {});

  const schema = getSchemaFromResource(getResource("promotions")).shape({
    name: yup.string().required(),
    identifier: yup.string().required(),
    countryCode: yup
      .string()
      .matches(/^(gb|ie)$/)
      .required(),
    ...(promotionsConfig?.orderTransferCode && {
      orderTransferCode: yup.string().required(),
    }),
  });

  const onSubmit = async (data, actions) => {
    const dataClone = cloneDeep(data);

    try {
      const response = await client(UPDATE, "promotions", {
        id: pathname,
        data: formatDataForAPI(dataClone),
      });

      if (response.data.violations && response.data.violations.length > 0) {
        // Display errors for invalid fields
        actions.setSubmitting(false);
        response.data.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      } else {
        actions.setSubmitting(false);
        toast.success("Promotion successfully updated!");
        const formattedData = formatDataForForm(response.data);
        setPromotionData(formattedData);
      }
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
      if (e?.error?.body?.violations?.length > 0) {
        // Display errors for invalid fields
        e.error.body.violations.map((error) => {
          actions.setFieldError(error.propertyPath, error.message);
        });
      }
      actions.setSubmitting(false);
    }
  };

  const onDuplicate = async (data) => {
    const formattedData = formatDataForAPI(data);
    delete formattedData["@id"];
    delete formattedData["@context"];
    delete formattedData["@type"];
    delete formattedData.id;
    formattedData.name = formattedData.name + " Copy";
    formattedData.identifier = formattedData.identifier + "-copy";
    delete formattedData.promotionCodes;

    try {
      const response = await client(CREATE, "promotions", {
        data: formattedData,
      });

      setRedirect(`/promotions`);
      toast.success("Promotion successfully duplicated!");
    } catch (e) {
      console.error(e);
      toast.error(
        e?.error?.body?.hasOwnProperty("hydra:description")
          ? e.error.body["hydra:description"]
          : "Whoops, there was a problem..."
      );
    }
  };

  if (loading) {
    return (
      <PromotionsEditContainer>
        <div className="card">
          <div className="card-body">Loading...</div>
        </div>
      </PromotionsEditContainer>
    );
  }

  if (unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (notFound) {
    return (
      <NotFoundMessage
        url="/promotions"
        message="The promotion you're looking for could not be found"
        buttonLabel="Go to Promotions"
      />
    );
  }

  const defaultFormData = {
    setEndDate:
      promotionData.endDateTime !== "" &&
      promotionData.endDateTime !== null &&
      typeof promotionData.endDateTime !== "undefined",
    setLimitUses: promotionData.maximumUses > 0,
    setLimitEmailUses: promotionData.maximumUsesPerEmail > 0,
  };

  return (
    <PromotionsEditContainer>
      {redirect ? (
        <Redirect to={redirect} />
      ) : (
        <>
          <Helmet>
            <title>{promotionData.name || "Promotion"} | Edit | Peracto</title>
          </Helmet>
          <PromotionsForm
            values={{
              ...promotionData,
              ...defaultFormData,
            }}
            onDuplicate={onDuplicate}
            onSubmit={onSubmit}
            schema={schema}
            mode={MODE_EDIT}
          />
        </>
      )}
    </PromotionsEditContainer>
  );
};

export default PromotionsEdit;
