import React from "react";
import PropTypes from "prop-types";

import CategoryTree from "./CategoryTree";
const CategoryTreeForm = ({ ...props }) => {
    return <CategoryTree {...props} />;
};

CategoryTreeForm.displayName = "CategoryTreeForm";

CategoryTreeForm.propTypes = {
    values: PropTypes.array,
    onSaveOrder: PropTypes.func.isRequired,
};

export default CategoryTreeForm;
