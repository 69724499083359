import React from 'react'
import styled from '@emotion/styled'

import { InnerHtml } from '@peracto/peracto-editor-ui'

const Text = styled(InnerHtml)`
    overflow: auto;
    padding: 0 0.6rem;
    border: 0;
    max-width: ${props => `${props.maxwidth}%`};
    margin: ${props => {
        switch (props.blockalignment) {
            case 'center':
                return `0 auto`
            case 'right':
                return `0 0 0 auto`
            default:
                return `${props => (props.maxwidth < 100 ? `0 auto 0 0` : `0 auto`)}`
        }
    }};

    > :first-child {
        margin-top: 1.5rem;
    }

    > :last-child {
        margin-bottom: 1.5rem;
    }

    figure.table {
        overflow-y: visible;
        overflow-x: scroll;

        td {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
`

const TextRenderer = ({ state: { text, maxWidth = 100, blockAlignment = 'left' } }) => {
    return (
        <Text
            content={text}
            maxwidth={maxWidth}
            blockalignment={blockAlignment}
            className="content__text"
        />
    )
}

export default TextRenderer
