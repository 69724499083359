import React, { HTMLAttributes } from "react";

import useEmblaCarousel from "embla-carousel-react";
import { twMerge } from "tailwind-merge";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useMedia } from "use-media";

export type BrandsProps = HTMLAttributes<HTMLDivElement> & {
    brands: {
        name: string;
        image: any;
        link: string;
    }[];
};

export const Brands = (props: BrandsProps) => {
    const { brands, className = "" } = props;

    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: "start",
        dragFree: true,
    });

    const isMobile = useMedia({ maxWidth: `991px` });

    const scrollNext = () => {
        emblaApi?.scrollNext();
    };

    const scrollPrev = () => {
        emblaApi?.scrollPrev();
    };

    return (
        <section className={twMerge("py-8", className)}>
            <h2 className="text-brand-primary font-bold mb-6 lg:mb-8 text-2xl lg:text-[40px] lg:leading-[52px] text-center">
                Stocking the Best Brands{" "}
                <span className="block">in Car and Van Parts</span>
            </h2>
            <div className="relative w-full">
                <div className="px-[40px]">
                    <div ref={emblaRef} className="overflow-hidden">
                        <div className="flex gap-x-2 lg:gap-x-4">
                            {brands?.map((brand, index) => (
                                <a
                                    key={index}
                                    href={brand.link}
                                    className="min-w-0 flex-[0_0_calc(50%-4px)] md:flex-[0_0_calc(33.33%-4px)] lg:flex-[0_0_calc(14.28%-13.71px)] bg-brand-white p-[8px]"
                                >
                                    <div className="relative h-12 flex items-center justify-center">
                                        <img
                                            src={brand.image}
                                            alt={brand.name}
                                            title={brand.name}
                                            className="h-full"
                                        />
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
                <button
                    onClick={scrollPrev}
                    className="absolute -translate-y-1/2 left-0 top-1/2"
                >
                    <IconChevronLeft
                        size={isMobile ? 24 : 40}
                        className="text-brand-black"
                    />
                </button>
                <button
                    onClick={scrollNext}
                    className="absolute -translate-y-1/2 right-0 top-1/2"
                >
                    <IconChevronRight
                        size={isMobile ? 24 : 40}
                        className="text-brand-black"
                    />
                </button>
            </div>
        </section>
    );
};
