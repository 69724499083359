export type tabStyleProps = 'tab' | 'pill'

export const tabStyleDict: Record<tabStyleProps, string> = {
    tab: 'relative flex flex-col gap-2 justify-center items-center w-full pb-4 -mb-4 text-brand-black',
    pill: 'py-2 px-4 rounded-[32px]',
}

export const tabActiveStyleDict: Record<tabStyleProps, string> = {
    tab: 'font-bold',
    pill: 'bg-brand-black text-brand-white font-semibold',
}

export const tabInactiveStyleDict: Record<tabStyleProps, string> = {
    tab: 'font-normal text-brand-grey',
    pill: 'bg-brand-grey-100 font-light text-brand-grey',
}
