import React, { useState, useEffect } from "react";
// import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Form,
    Group,
    Input,
    Tags,
    Select,
    Checkbox,
} from "@peracto/peracto-ui";
import { Field } from "formik";

import { GET_LIST, useClient } from "@peracto/client";
import { useConfig } from "@peracto/peracto-config";
import { useAuth } from "@peracto/peracto-auth";

import { roles, titles } from "./util";
import { ManageApplication } from "./ManageApplication";
import { BabbageAccounts } from "../../../peracto-user/user/UserForm/BabbageAccounts";
import { ReassignApplication } from "./ReassignApplication";

export const MODE_ADD = "add";
export const MODE_EDIT = "edit";

export const UserApplicationForm = ({
    mode = MODE_EDIT,
    values,
    setFormData = () => {},
    onAcceptApplication,
    testId,
    ...props
}) => {
    const [branches, setBranches] = useState([]);
    const [userGroups, setUserGroups] = useState([]);

    // const [redirect, setRedirect] = useState();
    const { client } = useClient();

    const { user: adminUser } = useAuth();

    const config = useConfig();
    const { user } = config.get("features", {});

    const { user_group_permissions } = adminUser?.profile;
    const {
        ug_manage_pending_user_applications_reassign_branch: canReassignBranch,
        ug_manage_pending_user_applications_reassign_to_customer_service,
        ug_manage_pending_user_applications_reject: canRejectApplications,
    } = user_group_permissions;

    const canReassignToCustomerService =
        ug_manage_pending_user_applications_reassign_to_customer_service &&
        values?.applicationHolderType !== "customer_service";

    const fetchUserGroups = async (inputValue = "") => {
        const { data } = await client(GET_LIST, "user-groups", {
            id: "user-groups",
            label: inputValue,
        });

        const values = data.map((val) => ({
            label: val.name,
            value: val.identifier,
        }));

        setUserGroups(values);

        return values;
    };

    const fetchBranches = async (inputValue = "") => {
        const { data } = await client(GET_LIST, "locations", {
            id: "locations",
            label: inputValue,
            filter: {
                itemsPerPage: 10000,
            },
        });

        const values = data.map((val) => ({
            label: `${val.name} - ${val.ecpBranchId}`,
            value: `${val.ecpBranchId}`,
        }));

        setBranches(values);

        return values;
    };

    useEffect(() => {
        fetchUserGroups();
        fetchBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <>
            <div data-testid={testId}>
                {/* {redirect && <Redirect to={redirect} />} */}

                <Form
                    autoComplete="off"
                    values={{
                        reason: "",
                        ...values,
                    }}
                    showActionPanel={false}
                    {...props}
                >
                    <Group
                        key="account-information"
                        id="account-information"
                        name="Account Information"
                    >
                        <Input
                            name="email"
                            label="Email"
                            required
                            autoComplete="email"
                            testId="email"
                            help="The email address is used to login and recover their account if they forget their password. Order confirmation emails will be sent to this email address."
                        />
                    </Group>
                    <Group
                        key="personal-information"
                        id="personal-information"
                        name="Personal Information"
                    >
                        <Select
                            name="title"
                            label="Title"
                            options={titles}
                            required
                            placeholder="Select a title"
                            testId="title"
                            testIdItems="title__item"
                            testIdIndex={0}
                        />
                        <Input
                            name="firstName"
                            label="First name"
                            required
                            autoComplete="first-name"
                            testId="firstname"
                        />
                        <Input
                            name="lastName"
                            label="Last name"
                            required
                            autoComplete="last-name"
                            testId="lastname"
                        />
                    </Group>

                    <Group
                        key="contact-information"
                        id="contact-information"
                        name="Contact Information"
                    >
                        <Input
                            name="telephone"
                            label="Telephone"
                            testId="telephone"
                            required
                        />
                    </Group>

                    <Group
                        key="user-settings"
                        id="user-settings"
                        name="User Settings"
                    >
                        <Tags
                            name="roles"
                            label="Roles"
                            options={roles}
                            testId="roles"
                            testIdItems="roles__item"
                            help="The user's role will determine their level of access."
                        />

                        <Field name="userGroup">
                            {({ field, form }) => (
                                <div className="form-group">
                                    <label>User Group</label>
                                    <Select
                                        name={field.name}
                                        className="w-100"
                                        isSearchable={true}
                                        onChange={(option) => {
                                            form.setFieldValue(
                                                field.name,
                                                option.value
                                            );
                                        }}
                                        options={userGroups}
                                        defaultValue={{
                                            label: "Search for User Groups...",
                                            value: null,
                                        }}
                                        placeholder="Search for User Groups..."
                                        testId="user-groups"
                                        testIdItems="user-groups__item"
                                        testIdIndex={1}
                                        allowClear={true}
                                    />
                                </div>
                            )}
                        </Field>

                        <Checkbox
                            name="isExcludedFromOmnirewards"
                            label="Exclude user from Omnirewards?"
                        />

                        <Checkbox
                            name="hasWismo"
                            label="Set Wismo access at user level"
                        />

                        <Checkbox
                            name="excludedFromMaintenanceMode"
                            label="Bypass maintenance mode?"
                        />
                    </Group>

                    <Group
                        key="tws-user-settings"
                        id="tws-user-settings"
                        name="TWS User Settings"
                    >
                        <Select
                            name="tradingCountry"
                            label="Trading Country"
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "gb",
                                },
                                {
                                    label: "Ireland",
                                    value: "ie",
                                },
                            ]}
                            placeholder="Select a trading country"
                            testId="tradingCountry"
                            testIdItems="tradingCountry__item"
                            testIdIndex={0}
                            required
                        />

                        <Checkbox
                            name="hasCreditTerms"
                            label="User has credit terms?"
                        />

                        <Checkbox
                            name="canShowPrices"
                            label="Show prices on website front-end?"
                            help="If unchecked, prices will be hidden from the user"
                        />

                        <Checkbox
                            name="canEditAccount"
                            label="Allow user to edit account on the front-end?"
                            help="If unchecked, user will be able to see account page but not edit the details"
                        />

                        <Checkbox
                            name="canManagePrice"
                            label="Allow price management"
                            help="If checked, this Price Management option will allow the user to also display RRP or ECP price or show their price plus a percentage"
                        />

                        <Checkbox
                            name="allowOrdersStraightToPrint"
                            label="Allow orders straight to print"
                        />

                        <Checkbox
                            name="allowAlternateDeliveryAddress"
                            label="Allow delivery to alternate delivery locations"
                        />
                    </Group>

                    <Group
                        key="babbage-accounts"
                        id="babbage-accounts"
                        name="Babbage Accounts"
                    >
                        <table className="table table-sm table-fixed">
                            <p className="mb-2">
                                Account and branch information provided in
                                application:
                            </p>
                            <Field name="accountNumber">
                                {({ field }) =>
                                    field.value ? (
                                        <tr>
                                            <td>
                                                <strong>
                                                    Babbage Account:
                                                </strong>
                                            </td>
                                            <td>{field.value}</td>
                                        </tr>
                                    ) : null
                                }
                            </Field>

                            <Field name="branch">
                                {({ field }) =>
                                    field.value ? (
                                        <tr>
                                            <td>
                                                <strong>Branch:</strong>
                                            </td>
                                            <td>
                                                {branches.find(
                                                    (branch) =>
                                                        branch.value ===
                                                        field.value
                                                )?.label || "-"}
                                            </td>
                                        </tr>
                                    ) : null
                                }
                            </Field>
                        </table>

                        <BabbageAccounts />
                    </Group>

                    <Group
                        key="haynes-pro-settings"
                        id="haynes-pro-settings"
                        name="Haynes Pro Settings"
                    >
                        <Checkbox
                            name="hasHaynesPro"
                            label="Set Haynes Pro access at user level"
                            {...(mode === MODE_EDIT && {
                                help: !!values?.haynesProAgreedAt
                                    ? "EULA has been accepted by user"
                                    : "EULA not yet accepted by user",
                            })}
                        />
                    </Group>

                    <Group
                        key="fifm-settings"
                        id="fifm-settings"
                        name="FiFM Settings"
                    >
                        <Checkbox
                            name="hasFifm"
                            label="Set FiFM Access At User Level"
                        />
                    </Group>

                    {(canReassignBranch || canReassignToCustomerService) && (
                        <Group
                            key="reassign-application"
                            id="reassign-application"
                            name="Reassign Application"
                        >
                            <ReassignApplication
                                canReassignBranch={canReassignBranch}
                                canReassignToCustomerService={
                                    canReassignToCustomerService
                                }
                                branches={branches}
                            />
                        </Group>
                    )}

                    <Group
                        key="process-new-application"
                        id="process-new-application"
                        name="Process New Application"
                    >
                        <Field name="status">
                            {({ field, form }) => (
                                <>
                                    {field.value === "rejected" && (
                                        <p className="my-2">
                                            <strong>
                                                Application Rejected:
                                            </strong>{" "}
                                            {
                                                form?.values?.logs?.find(
                                                    (log) =>
                                                        log.type === "rejected"
                                                )?.message
                                            }
                                        </p>
                                    )}

                                    <ManageApplication
                                        isPending={field.value === "pending"}
                                        canRejectApplications={
                                            canRejectApplications
                                        }
                                    />
                                </>
                            )}
                        </Field>
                    </Group>
                </Form>
            </div>
        </>
    );
};

UserApplicationForm.displayName = "UserApplicationForm";
UserApplicationForm.propTypes = {
    values: PropTypes.object,
    mode: PropTypes.oneOf([MODE_ADD, MODE_EDIT]),
    schema: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    setFormData: PropTypes.func,
};
