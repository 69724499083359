import React, { useState, useEffect } from "react";

import { useClient, GET_LIST, UPDATE } from "@peracto/client";
import { toast } from "react-toastify";
import { Select, Form, Heading, Group } from "@peracto/peracto-ui";
import { Helmet } from "react-helmet";

import { Field } from "formik";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft } from "@fortawesome/pro-regular-svg-icons/faArrowCircleLeft";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";

export const BranchReassignmentForm = ({ ...props }) => {
    const { client } = useClient();
    const [branches, setBranches] = useState([]);
    const [redirect, setRedirect] = useState();

    const fetchBranches = async () => {
        const { data } = await client(GET_LIST, "locations", {
            id: "locations",
            filter: {
                itemsPerPage: 10000,
            },
        });

        const values = data.map((location) => ({
            label: `${location.name} - ${location.ecpBranchId}`,
            value: location.id.toString(),
        }));

        setBranches(values);
    };

    useEffect(() => {
        fetchBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data, actions) => {
        const { branchFrom, branchTo } = data;

        try {
            const response = await client(UPDATE, "locations", {
                id: branchFrom,
                data: {
                    branchOverride: branchTo,
                },
            });
            if (
                response.data.violations &&
                response.data.violations.length > 0
            ) {
                // Display errors for invalid fields
                actions.setSubmitting(false);
                response.data.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            } else {
                actions.setSubmitting(false);
                actions.setSubmitting(false);
                actions.resetForm(data);
                toast.success("Branch reassignment successfully added!");
                setRedirect("/branch-reassignment");
            }
        } catch (e) {
            console.error(e);
            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "Whoops, there was a problem..."
            );
            if (e?.error?.body?.violations?.length > 0) {
                // Display errors for invalid fields
                e.error.body.violations.forEach((error) => {
                    actions.setFieldError(error.propertyPath, error.message);
                });
            }
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            {redirect && <Redirect to={redirect} />}

            <Helmet>
                <title>Add Branch Reassignment | Edit | Peracto</title>
            </Helmet>

            <div className="form-container">
                <Heading name="Add Reassignment">
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Link
                            className="btn btn-outline-primary"
                            data-testid="back-to-reassignments"
                            to="/branch-reassignment"
                        >
                            <FontAwesomeIcon
                                icon={faArrowCircleLeft}
                                className="mr-2"
                            />
                            Back to Reassignments
                        </Link>
                    </div>
                </Heading>
                <Form
                    autoComplete="false"
                    values={{ branchFrom: "", branchTo: "" }}
                    className="reassignment-form"
                    onSubmit={onSubmit}
                    showActionPanel={false}
                    {...props}
                >
                    <Group
                        key="resassignment"
                        id="resassignment"
                        name="Resassignment"
                    >
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <Select
                                    placeholder="Reassign From"
                                    name="branchFrom"
                                    testId="branch-from"
                                    required
                                    options={branches}
                                    label="Reassign From"
                                />
                            </div>

                            <div className="hidden lg:flex justify-center items-center col-lg-2">
                                <FontAwesomeIcon icon={faArrowRight} />
                            </div>

                            <div className="col-12 col-lg-5">
                                <Select
                                    placeholder="Reassign To"
                                    name="branchTo"
                                    testId="branch-to"
                                    required
                                    options={branches}
                                    label="Reassign To"
                                />
                            </div>
                        </div>

                        <div className="flex justify-end items-center ">
                            <Field name="branchFrom">
                                {({ form }) => {
                                    const branchesMatch =
                                        form?.values?.branchFrom ===
                                        form?.values?.branchTo;
                                    const branchNotSelected =
                                        !form?.values?.branchFrom ||
                                        !form?.values?.branchTo;

                                    const disabled =
                                        branchesMatch || branchNotSelected;

                                    return (
                                        <>
                                            {!branchNotSelected &&
                                                branchesMatch && (
                                                    <p className="mb-0 mr-2 text-brand-red">
                                                        Branches cannot match
                                                    </p>
                                                )}
                                            <button
                                                className="btn btn-success"
                                                disabled={disabled}
                                                type="submit"
                                            >
                                                Reassign Users
                                            </button>
                                        </>
                                    );
                                }}
                            </Field>
                        </div>
                    </Group>
                </Form>
            </div>
        </>
    );
};
