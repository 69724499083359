import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import {
    HydraTable,
    Heading,
    TextFilter,
    SelectFilter,
    LoadingIcon,
} from "@peracto/peracto-ui";

import { GET_LIST, useClient } from "@peracto/client";

import startCase from "lodash/startCase";

const statusOptions = [
    { label: "Pending", value: "pending" },
    { label: "Rejected", value: "rejected" },
];

const applicationHolderTypeOptions = [
    { label: "Branch", value: "branch" },
    { label: "Customer Service", value: "customer_service" },
];

export const UserApplicationList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState({
        status: true,
        accountNumber: true,
        applicationHolderType: true,
        branch: true,
    });

    const { client } = useClient();

    const [branches, setBranches] = useState([]);

    const fetchBranches = async () => {
        const { data } = await client(GET_LIST, "locations", {
            id: "locations",
            filter: {
                itemsPerPage: 10000,
            },
        });

        const values = data.map((location) => ({
            label: `${location.name} - ${location.ecpBranchId}`,
            value: location.ecpBranchId.toString(),
        }));

        setBranches(values);
    };

    useEffect(() => {
        fetchBranches();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = [
        {
            Header: "First Name",
            accessor: "firstName",
            sortType: "basic",
            Filter: (props) => <TextFilter label="First Name" {...props} />,
            Cell: (row) => (
                <Link to={row.row.original.id}>{row.cell.value}</Link>
            ),
            selectable: false,
        },
        {
            Header: "Last Name",
            accessor: "lastName",
            sortType: "basic",
            Filter: (props) => <TextFilter label="Last Name" {...props} />,
            Cell: (row) => (
                <Link to={row.row.original.id}>{row.cell.value} </Link>
            ),
            selectable: false,
        },
        {
            Header: "Email",
            accessor: "email",
            sortType: "basic",
            Filter: (props) => (
                <TextFilter label="Email" type="email" {...props} />
            ),
            selectable: false,
        },
        {
            Header: "Branch",
            accessor: "branch",
            sortType: "basic",
            Cell: ({ cell: { value } }) =>
                value
                    ? branches.find((branch) => value === branch.value)
                          ?.label || "-"
                    : "-",
            Filter: (props) => (
                <SelectFilter label="Branch" options={branches} {...props} />
            ),
            selectable: true,
            show: selectedColumns["branch"],
            disableSorting: true,
        },
        {
            Header: "Account Number(s) Assigned",
            accessor: "accountNumber",
            sortType: "basic",
            Cell: ({ cell: { value } }) => value || "-",
            Filter: (props) => (
                <TextFilter label="Account Number(s) Assigned" {...props} />
            ),
            selectable: true,
            show: selectedColumns["accountNumber"],
        },

        {
            Header: "Application Holder Type",
            accessor: "applicationHolderType",
            sortType: "basic",
            Cell: ({ cell: { value } }) => startCase(value || "-"),
            Filter: (props) => (
                <SelectFilter
                    label="Application Holder Type"
                    options={applicationHolderTypeOptions}
                    {...props}
                />
            ),
            selectable: true,
            show: selectedColumns["applicationHolderType"],
        },
        {
            Header: "Status",
            accessor: "status",
            sortType: "basic",
            Cell: ({ cell: { value } }) => startCase(value) || "-",
            Filter: (props) => (
                <SelectFilter
                    label="Status"
                    options={statusOptions}
                    {...props}
                />
            ),
            selectable: true,
            show: selectedColumns["status"],
        },
    ];

    const onFetchData = (options) =>
        client(GET_LIST, "user-applications", options);

    return branches?.length > 0 ? (
        <>
            <Helmet>
                <title>User Applications | Peracto</title>
            </Helmet>
            <Heading name="User Applications" />

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    firstName: "",
                                    lastName: "",
                                    email: "",
                                    status: "",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="d-flex flex-grow-1 align-items-center flex-column justify-content-center py-5 h-100">
            <LoadingIcon />
        </div>
    );
};
