import React from "react";
import { Card } from "components/Card";

export const CardHorizontal = (props) => {
    const { heading, content, url, imageUrl, openInNewTab } = props;
    return (
        <Card className="flex flex-row flex-nowrap">
            <a
                href={url}
                {...(openInNewTab && {
                    target: "_blank",
                    rel: "noindex nofollow",
                })}
            >
                <img
                    src={imageUrl}
                    alt={heading}
                    className="aspect-square object-cover object-center rounded-l-[16px] flex-[1] w-[173px] lg:w-[157px]"
                />
            </a>

            <div className="p-4 lg:p-6 flex flex-col items-start flex-[3]">
                <h3 className="!text-[28px] !leading-[36px] font-bold mb-4">
                    {heading}
                </h3>

                <p className="text-sm lg:text-base">{content}</p>
            </div>
        </Card>
    );
};
