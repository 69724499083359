import React from "react";

import { twMerge } from "tailwind-merge";

import { Details } from "components/ProductCard/layout/Details";

export const Heading = (props) => {
    const {
        wide,
        isMobile,
        stock,
        product,
        isBasket,

        className,

        pdpHref,
    } = props;

    const pdpSlug = pdpHref ? pdpHref : "/product";

    return (
        <div
            style={{
                gridArea: "heading",
            }}
        >
            <div
                className={twMerge(
                    `flex justify-between gap-2 w-full items-start`,
                    className
                )}
            >
                <div>
                    <a
                        href={`${pdpSlug}/${product?.slug}`}
                        className="block mb-1 text-base font-bold leading-5 text-brand-primary"
                        data-testid="product-card__heading--title"
                    >
                        {product?.productName || ""}
                    </a>
                    <div className="flex justify-between">
                        <p
                            className="text-sm font-normal text-brand-grey"
                            data-testid="product-card__heading--sku"
                        >
                            {product?.sku}
                        </p>
                    </div>
                </div>

                <div className="flex flex-col items-end">
                    {!wide && product?.brandImage?.length > 0 && (
                        <div className="flex justify-end w-20">
                            <img
                                src={product?.brandImage}
                                alt={product?.brand}
                                className="max-h-12"
                                data-testid="product-card__brand-image"
                            />
                        </div>
                    )}
                </div>
            </div>

            {!isBasket && wide && !isMobile ? (
                <>
                    <Details
                        stock={stock}
                        selectedProduct={product}
                        isWide={true}
                        showVehicleDetails={false}
                    />
                    {/* <Footer
                        partDetails={product?.partDetails}
                        wide={wide}
                        productId={product.productId}
                        onAddToBasket={onAddToBasket}
                        product={product}
                        canAddToBasket={canAddToBasket}
                        onAddPartToWorkOrder={onAddPartToWorkOrder}
                        canAddToWorkOrder={canAddToWorkOrder}
                        productAddingToBasket={productAddingToBasket}
                        showComparison={false}
                        addToBasketButtonIcon={addToBasketButtonIcon}
                        addToBasketButtonText={addToBasketButtonText}
                    /> */}
                </>
            ) : null}
        </div>
    );
};
