import React from "react";

import { Field, FieldArray } from "formik";

export const LocationIBTConfig = () => {
    return (
        <Field name={`ibtConfig`}>
            {({ field }) => (
                <>
                    <FieldArray name={field.name}>
                        {() => (
                            <div className="form-group">
                                {field?.value?.length > 0 ? (
                                    <table className="table table-sm mb-1 align-top">
                                        <colgroup>
                                            <col width="30%" />
                                            <col width="70%" />
                                        </colgroup>

                                        <tbody>
                                            {field.value.map((item, index) => (
                                                <tr key={index} data-id={index}>
                                                    <td className="align-top">
                                                        <p>
                                                            Time Range Start:{" "}
                                                            <span className="font-bold">
                                                                {
                                                                    item.timeRangeStart
                                                                }
                                                            </span>
                                                        </p>
                                                        <p>
                                                            Time Range Stop:{" "}
                                                            <span className="font-bold">
                                                                {
                                                                    item.timeRangeEnd
                                                                }
                                                            </span>
                                                        </p>
                                                        <p>
                                                            Days of Week:{" "}
                                                            <span className="font-bold">
                                                                {
                                                                    item.daysOfWeek
                                                                }
                                                            </span>
                                                        </p>
                                                    </td>
                                                    <td className="align-top">
                                                        {
                                                            item.ibtConfig
                                                                .nodeSequence
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="py-8 mb-0 text-center">
                                        There are no IBT config sequences set up
                                        for this location.
                                    </p>
                                )}
                            </div>
                        )}
                    </FieldArray>
                </>
            )}
        </Field>
    );
};
