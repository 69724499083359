import React from "react";
import { Price } from "../components/Price";

export const Details = (props) => {
    const { showVehicleDetails, stock, selectedProduct } = props;

    const productStock = selectedProduct?.stock || stock;

    const vrm = selectedProduct?.vehicleOptions?.VRM;
    const vin = selectedProduct?.vehicleOptions?.VIN;

    const vehicleDetails = [];

    if (selectedProduct?.vehicleOptions?.make) {
        vehicleDetails.push(selectedProduct?.vehicleOptions?.make);
    }

    if (selectedProduct?.vehicleOptions?.model) {
        vehicleDetails.push(selectedProduct?.vehicleOptions?.model);
    }

    if (selectedProduct?.vehicleOptions?.vehicleYear) {
        vehicleDetails.push(selectedProduct?.vehicleOptions?.vehicleYear);
    }

    if (selectedProduct?.vehicleOptions?.engineSizeDescription) {
        vehicleDetails.push(
            selectedProduct?.vehicleOptions?.engineSizeDescription
        );
    }

    return (
        <div
            style={{
                gridArea: "details",
            }}
            className="flex flex-col justify-end flex-grow"
        >
            {productStock?.length > 0 && (
                <div>
                    <hr className="mb-4 border-t-brand-grey-100" />

                    {showVehicleDetails && (
                        <div className="mb-2">
                            {(vrm || vin) && (
                                <p
                                    className="flex flex-col mb-1 text-sm font-bold lg:flex-row"
                                    data-testid="product-card--vrm"
                                >
                                    {vrm}
                                    {vin && (
                                        <span
                                            className="lg:border-l lg:border-l-brand-grey-100 lg:ml-1 lg:pl-1"
                                            data-testid="product-card--vin"
                                        >
                                            {vin}
                                        </span>
                                    )}
                                </p>
                            )}

                            {vehicleDetails.length > 0 && (
                                <p
                                    className="text-sm font-bold"
                                    data-testid="product-card--vehicle"
                                >
                                    {vehicleDetails.join(" ")}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            )}

            <div>
                <hr className="mb-4 border-t-brand-grey-100" />

                <Price
                    price={selectedProduct?.prices?.price}
                    rrpPrice={selectedProduct?.prices?.wasPrice}
                    ecpPrice={selectedProduct?.prices?.wasPriceEcp}
                    surcharge={selectedProduct?.prices?.surcharge}
                    unit={selectedProduct?.unit}
                    incVat={false}
                    className="mb-4"
                />
            </div>
        </div>
    );
};
