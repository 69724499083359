import React, { useState, useEffect } from "react";
import type { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

import { TabNavigationItem } from "./TabNavigationItem";
import { tabStyleProps } from "./variants";

export type TabNavigationProps = HTMLAttributes<HTMLUListElement> & {
    tabs: {
        label: string;
        value: number;
    }[];
    tabStyle?: tabStyleProps;
    showTabIcon?: boolean;
    onTabClick?: (value: number) => void;
};

export const TabNavigation = (props: TabNavigationProps) => {
    const {
        tabs = [],
        tabStyle = "tab",
        showTabIcon = false,
        onTabClick,
        className = "",
        ...restProps
    } = props;

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (onTabClick) {
            onTabClick(tabs[activeTab]?.value);
        }
    }, [onTabClick, tabs, activeTab]);

    return (
        <ul
            className={twMerge(
                `flex flex-wrap items-end list-none ${
                    tabStyle === "pill"
                        ? "gap-4 text-xs"
                        : "gap-y-4 md:gap-y-0 text-sm"
                }`,
                className
            )}
            {...restProps}
        >
            {tabs.map((tab, index) => (
                <TabNavigationItem
                    key={index}
                    tabStyle={tabStyle}
                    showTabIcon={showTabIcon}
                    active={activeTab === index}
                    onClick={() => {
                        setActiveTab(index);
                    }}
                    className="!outline-none"
                    {...tab}
                />
            ))}
        </ul>
    );
};
