import React from 'react'
import Select from '@peracto/peracto-editor-ui/dist/Select'
import Checkbox from '@peracto/peracto-editor-ui/dist/Checkbox'

const Form = ({
    onChange,
    state: {
        containerSize = 'full',
        containerType = 'fluid',
        aspectRatio = '',
        alignContent = 'center',
        matchContentHeight,
        mobileTwoColumnLayout,
    },
}) => {
    const containerSizeOptions = [
        { label: 'Full Width', value: 'full' },
        { label: 'Wide', value: 'wide' },
        { label: 'Standard', value: 'standard' },
        { label: 'Narrow', value: 'narrow' },
    ]

    const containerTypeOptions = [
        { label: 'Fluid', value: 'fluid' },
        { label: 'Fixed', value: 'fixed' },
    ]

    const aspectRatioOptions = [
        { label: 'Dynamic', value: '' },
        { label: '1:1', value: '1:1' },
        { label: '16:9', value: '16:9' },
        { label: '4:3', value: '4:3' },
        { label: '2:1', value: '2:1' },
    ]

    const alignmentOptions = [
        { label: 'Top', value: 'flex-start' },
        { label: 'Centre', value: 'center' },
        { label: 'Bottom', value: 'flex-end' },
    ]

    return (
        <>
            <Select
                label="Container Size"
                value={containerSize}
                onChange={e => onChange({ containerSize: e.value })}
                options={containerSizeOptions}
            />

            <Select
                label="Container Type"
                value={containerType}
                onChange={e => onChange({ containerType: e.value })}
                options={containerTypeOptions}
            />

            <Select
                label="Container Aspect Ratio"
                value={aspectRatio}
                onChange={e => onChange({ aspectRatio: e.value })}
                options={aspectRatioOptions}
            />

            <Select
                label="Vertically Align Content"
                value={alignContent}
                onChange={e => onChange({ alignContent: e.value })}
                options={alignmentOptions}
            />

            <Checkbox
                label="Match Content Height"
                checked={matchContentHeight}
                onChange={e => onChange({ matchContentHeight: e.target.checked })}
            />

            <Checkbox
                label="Two Column Layout on Mobile"
                checked={mobileTwoColumnLayout}
                onChange={e => onChange({ mobileTwoColumnLayout: e.target.checked })}
            />
        </>
    )
}

export default Form
