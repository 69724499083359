import React from "react";
import type { ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { IconSquare } from "@tabler/icons-react";

import {
    tabStyleProps,
    tabStyleDict,
    tabActiveStyleDict,
    tabInactiveStyleDict,
} from "./variants";

export type TabNavigationItemProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    tabStyle?: tabStyleProps;
    showTabIcon?: boolean;
    label: string;
    value: number;
    active: boolean;
};

export const TabNavigationItem = (props: TabNavigationItemProps) => {
    const {
        tabStyle = "tab",
        showTabIcon,
        label,
        value,
        active,
        className = "",
        ...restProps
    } = props;

    return (
        <li
            className={`list-none ${
                tabStyle === "tab"
                    ? "flex-[0_0_50%] md:flex-[0_0_25%] lg:flex-1"
                    : ""
            }`}
        >
            <button
                className={twMerge(
                    `${tabStyleDict[tabStyle]} ${
                        active
                            ? `${tabActiveStyleDict[tabStyle]}`
                            : `${tabInactiveStyleDict[tabStyle]}`
                    }`,
                    className
                )}
                {...restProps}
            >
                {tabStyle === "tab" && showTabIcon && <IconSquare />}
                {label}
                {tabStyle === "tab" && active && (
                    <div className="absolute bottom-0 w-full h-1 rounded-lg bg-brand-primary" />
                )}
                {tabStyle === "tab" && !active && (
                    <div className="absolute bottom-0 w-full h-1 rounded-lg bg-brand-grey-100" />
                )}
            </button>
        </li>
    );
};
