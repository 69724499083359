const colors = {
    // Base colors
    "brand-primary-50": "#E6EBF6",
    "brand-primary-100": "#CCD6EC",
    "brand-primary-200": "#99ADD9",
    "brand-primary-300": "#6685C6",
    "brand-primary-400": "#335CB3",
    "brand-primary": "#0033A0",
    "brand-primary-600": "#002980",
    "brand-primary-700": "#001F60",
    "brand-primary-800": "#001440",
    "brand-primary-900": "#000A20",

    "brand-secondary-50": "#FFFBE9",
    "brand-secondary-100": "#FFF8D3",
    "brand-secondary-200": "#FEF0A7",
    "brand-secondary-300": "#FEE97C",
    "brand-secondary-400": "#FDE150",
    "brand-secondary": "#FDDA24",
    "brand-secondary-600": "#CAAE1D",
    "brand-secondary-700": "#988316",
    "brand-secondary-800": "#65570E",
    "brand-secondary-900": "#332C07",

    "brand-tertiary-50": "#E6E9EF",
    "brand-tertiary-100": "#CCD2DF",
    "brand-tertiary-200": "#99A5BF",
    "brand-tertiary-300": "#6678A0",
    "brand-tertiary-400": "#334B80",
    "brand-tertiary": "#001E60",
    "brand-tertiary-600": "#00184D",
    "brand-tertiary-700": "#00123A",
    "brand-tertiary-800": "#000C26",
    "brand-tertiary-900": "#000613",

    "brand-red-50": "#FAE9E9",
    "brand-red-100": "#F5D2D3",
    "brand-red-200": "#EBA5A8",
    "brand-red-300": "#E1797C",
    "brand-red-400": "#D74C51",
    "brand-red": "#CD1F25",
    "brand-red-600": "#A4191E",
    "brand-red-700": "#7B1316",
    "brand-red-800": "#520C0F",
    "brand-red-900": "#290607",

    "brand-black": "#131313",
    "brand-white": "#FFFFFF",

    // Functional colors
    "brand-grey-50": "#F5F6F6",
    "brand-grey-100": "#F0F0F0",
    "brand-grey-200": "#D8DADA",
    "brand-grey-300": "#B2B5B5",
    "brand-grey-400": "#656B6B",
    "brand-grey": "#3E4646",
    "brand-grey-600": "#323838",
    "brand-grey-700": "#252A2A",
    "brand-grey-800": "#191C1C",
    "brand-grey-900": "#131515",

    "brand-success-50": "#EDF5E9",
    "brand-success-100": "#DBECD3",
    "brand-success-200": "#B7D8A7",
    "brand-success-300": "#93C57C",
    "brand-success-400": "#6FB150",
    "brand-success": "#4B9E24",
    "brand-success-600": "#3C7E1D",
    "brand-success-700": "#2D5F16",
    "brand-success-800": "#1E3F0E",
    "brand-success-900": "#172F0B",

    "brand-warning-50": "#FDF1E7",
    "brand-warning-100": "#FAE3CE",
    "brand-warning-200": "#F5C79D",
    "brand-warning-300": "#F0AC6C",
    "brand-warning-400": "#EB903B",
    "brand-warning": "#E6740A",
    "brand-warning-600": "#B85D08",
    "brand-warning-700": "#8A4606",
    "brand-warning-800": "#5C2E04",
    "brand-warning-900": "#2E1702",

    "brand-error-50": "#FEF2F2",
    "brand-error-100": "#FEE2E2",
    "brand-error-200": "#FECACA",
    "brand-error-300": "#FCA5A5",
    "brand-error-400": "#F87171",
    "brand-error": "#EF4444",
    "brand-error-600": "#DC2626",
    "brand-error-700": "#B91C1C",
    "brand-error-800": "#991B1B",
    "brand-error-900": "#7F1D1D",

    "brand-info-50": "#F3F5FE",
    "brand-info-100": "#E6EBFD",
    "brand-info-200": "#C9D6FB",
    "brand-info-300": "#A8BEFA",
    "brand-info-400": "#7EA3F8",
    "brand-info": "#3B82F6",
    "brand-info-600": "#3574DC",
    "brand-info-700": "#2E65BF",
    "brand-info-800": "#25529C",
    "brand-info-900": "#1A3A6E",

    "brand-input-blue-light": "#BFDBFE",
    "brand-input-blue": "#93C5FD",

    "brand-tooltip": "rgba(62, 70, 70, 0.64)",
};

const fonts = {
    heading: ["'Figtree', sans-serif"],
    body: ["'Figtree', sans-serif"],
};

module.exports = { colors, fonts };
