import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
    Form,
    Group,
    Input,
    Select,
    Heading,
    LoadingIcon,
} from "@peracto/peracto-ui";
import { useConfig } from "@peracto/peracto-config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-regular-svg-icons/faMinus";

import * as yup from "yup";
import { toast } from "react-toastify";

import { CodeFormatter } from "components/CodeFormatter";
import { CopyButton } from "components/CopyButton";

const schema = yup.object().shape({
    trading_country: yup.string().required(),
    vrm: yup.string().required(),
});

export const ComponentsByVehicleAttributesForm = () => {
    const config = useConfig();
    const API_URL = config.get("api");

    const [loading, setLoading] = useState(false);
    const [requestData, setRequestData] = useState({
        component: "",
        vehicle: "",
    });
    const [responseData, setResponseData] = useState({
        component: "",
        vehicle: "",
    });

    const [showRequestData, setShowRequestData] = useState({
        component: false,
        vehicle: false,
    });
    const [showResponseData, setShowResponseData] = useState({
        component: false,
        vehicle: false,
    });
    const [showTableData, setShowTableData] = useState(false);

    const [tableData, setTableData] = useState([]);

    const onSubmitForm = async (formData, actions) => {
        setLoading(true);

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        };

        try {
            const { data } = await axios.post(
                `${API_URL}/test-harness/raindata-components-by-vehicle-attributes`,
                {
                    vehicleDetails: {
                        0: { Name: "VRM", Value: formData.vrm },
                    },
                    country: formData.trading_country,
                },
                {
                    headers,
                }
            );

            const tableRows =
                JSON.parse(data?.ComponentsByVehicleAttributesResponse) || [];

            setRequestData({
                component: data?.ComponentsByVehicleAttributesRequest,
                vehicle: data?.vehicleDetailsRequest,
            });
            setResponseData({
                component: data?.ComponentsByVehicleAttributesResponse,
                vehicle: data?.vehicleDetailsResponse,
            });
            setTableData(tableRows);
        } catch (e) {
            console.error(e);

            toast.error(
                e?.error?.body?.hasOwnProperty("hydra:description")
                    ? e.error.body["hydra:description"]
                    : "There was an error submitting this form."
            );
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>
                    Components By Vehicle Attributes | Raindata | Web Services |
                    Peracto
                </title>
            </Helmet>
            <div className="form-container">
                <Heading name="Components By Vehicle Attributes" />

                <Form
                    autoComplete="on"
                    values={{
                        trading_country: "GB",
                        vrm: "",
                    }}
                    schema={schema}
                    showActionPanel={false}
                    onSubmit={onSubmitForm}
                >
                    <Group
                        key="part-details"
                        id="part-details"
                        name="Part Details"
                    >
                        <Select
                            name="trading_country"
                            label="Trading Country"
                            placeholder="Please select..."
                            options={[
                                {
                                    label: "United Kingdom",
                                    value: "GB",
                                },
                                { label: "Ireland", value: "IE" },
                            ]}
                            testId="tradingCountry"
                            required={true}
                        />

                        <Input
                            name="vrm"
                            label="VRM"
                            testId="vrm"
                            help="IE REG: 142D4920, GB REG YR04FWE"
                            required
                        />

                        <button
                            className="btn btn-primary"
                            type="submit"
                            data-testid="submit"
                        >
                            Submit
                        </button>
                    </Group>

                    {loading && (
                        <div className="flex justify-center items-center py-5">
                            <LoadingIcon />
                        </div>
                    )}

                    {(requestData?.vehicle?.length > 0 ||
                        requestData?.component?.length > 0) &&
                        !loading && (
                            <Group
                                key="request-details"
                                id="request-details"
                                name="Request Details"
                            >
                                {requestData?.component?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Vehicle Request
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={
                                                        requestData.vehicle
                                                    }
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowRequestData({
                                                            ...showRequestData,
                                                            vehicle:
                                                                !showRequestData.vehicle,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showRequestData?.vehicle
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showRequestData?.vehicle
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showRequestData?.vehicle && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={requestData?.vehicle}
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                {requestData?.component?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2 pt-2 border-t">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Component Request
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={
                                                        requestData.component
                                                    }
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowRequestData({
                                                            ...showRequestData,
                                                            component:
                                                                !showRequestData.component,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showRequestData?.component
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showRequestData?.component
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showRequestData?.component && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={
                                                        requestData?.component
                                                    }
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Group>
                        )}

                    {(responseData?.vehicle?.length > 0 ||
                        responseData?.component?.length > 0) &&
                        !loading && (
                            <Group
                                key="response-details"
                                id="response-details"
                                name="Response Details"
                            >
                                {responseData?.component?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Vehicle Response
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={
                                                        responseData.vehicle
                                                    }
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowResponseData({
                                                            ...showResponseData,
                                                            vehicle:
                                                                !showResponseData.vehicle,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showResponseData?.vehicle
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showResponseData?.vehicle
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showResponseData?.vehicle && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={responseData?.vehicle}
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}

                                {responseData?.component?.length > 0 && (
                                    <>
                                        <div className="flex items-center justify-between mt-2 pt-2 border-t">
                                            <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                                Raindata Component Response
                                            </h4>{" "}
                                            <div className="flex flex-row">
                                                <CopyButton
                                                    content={
                                                        responseData.component
                                                    }
                                                />
                                                <button
                                                    className="btn btn-primary btn-sm ml-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowResponseData({
                                                            ...showResponseData,
                                                            component:
                                                                !showResponseData.component,
                                                        });
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            showResponseData?.component
                                                                ? faMinus
                                                                : faPlus
                                                        }
                                                        className="mr-1"
                                                    />
                                                    {showResponseData?.component
                                                        ? "Hide"
                                                        : "Show"}
                                                </button>
                                            </div>
                                        </div>
                                        {showResponseData?.component && (
                                            <div className="border rounded-[4px] p-2  mt-3">
                                                <CodeFormatter
                                                    code={
                                                        responseData?.component
                                                    }
                                                    language="json"
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </Group>
                        )}

                    {tableData?.length > 0 && !loading && (
                        <Group
                            key="components-results"
                            id="components-results"
                            name="Components Results"
                        >
                            <div className="flex items-center justify-between mt-2">
                                <h4 className="text-[18px] font-semibold mr-2 mb-0">
                                    Components Results
                                </h4>{" "}
                                <div className="flex flex-row">
                                    <CopyButton
                                        content={responseData?.component}
                                    />
                                    <button
                                        className="btn btn-primary btn-sm ml-2"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowTableData(!showTableData);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={
                                                showTableData ? faMinus : faPlus
                                            }
                                            className="mr-1"
                                        />
                                        {showTableData ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            {showTableData && (
                                <table className="table table-striped table-sm table-fixed mt-3">
                                    <thead>
                                        <tr>
                                            <th>Number</th>
                                            <th>Name</th>
                                            <th>Source</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {tableData.map((row, idx) => {
                                            return (
                                                <tr
                                                    key={
                                                        row?.ComponentNumber ||
                                                        idx
                                                    }
                                                >
                                                    <td>
                                                        {row?.ComponentNumber ||
                                                            "-"}
                                                    </td>
                                                    <td>
                                                        {row?.ComponentName ||
                                                            "-"}
                                                    </td>
                                                    <td>
                                                        {row?.ComponentSource ||
                                                            "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </Group>
                    )}
                </Form>
            </div>
        </>
    );
};

ComponentsByVehicleAttributesForm.displayName =
    "ComponentsByVehicleAttributesForm";
