import React from "react";
import { Card } from "components/Card";

export const CardVertical = (props) => {
    const { heading, content, buttonText, url, imageUrl, openInNewTab } = props;
    return (
        <Card className="flex flex-col h-full">
            <img
                src={imageUrl}
                alt={heading}
                className="aspect-video object-cover object-center rounded-t-[16px]"
            />

            <div className="p-4 lg:p-6 flex flex-col items-start h-full">
                <h3 className="!text-[28px] !leading-[36px] font-bold mb-4">
                    {heading}
                </h3>

                <p className="text-sm lg:text-base mb-6 lg:mb-4 flex-grow">
                    {content}
                </p>

                {buttonText?.length > 0 && (
                    <a
                        href={url}
                        {...(openInNewTab && {
                            target: "_blank",
                            rel: "noindex nofollow",
                        })}
                        className="inline-block transition-all bg-brand-primary !text-brand-white !no-underline font-bold text-sm px-[18px] py-[10px] rounded-[32px] hover:bg-brand-primary-600 focus:bg-brand-primary focus:ring-brand-primary-300 focus:ring active:bg-brand-primary-700 active:ring"
                    >
                        {buttonText}
                    </a>
                )}
            </div>
        </Card>
    );
};
