import React from "react";

import { Textarea, Select } from "@peracto/peracto-ui";

export const SUSPENSION_REASONS = [
    "Incorrect Branch ID",
    "Unable To Verify Customer",
    "Payment Arrears",
    "Customer Unreachable",
    "Customer Is Not Placing Orders",
    "Competitor",
];

export const SuspendUser = (props) => {
    const { form } = props;
    const { values } = form;

    const activeStatusLogs = values?.userStatusLogs?.filter(
        (log) => log.type === "active"
    );
    const suspendedStatusLogs = values?.userStatusLogs?.filter(
        (log) => log.type === "suspended"
    );

    return values?.status === "suspended" ? (
        <>
            {" "}
            {suspendedStatusLogs.length <= activeStatusLogs.length ? (
                <>
                    <Select
                        name="status_reason"
                        className="w-full text-left"
                        options={[...SUSPENSION_REASONS, "Other"].map(
                            (reason) => {
                                return {
                                    label: reason,
                                    value: reason,
                                };
                            }
                        )}
                        required
                        label="Suspension Reason"
                        placeholder="Select Suspension Reason"
                        testId="suspension-reason"
                        testIdItems="suspension-reason__item"
                        testIdIndex={0}
                    />

                    {values?.status_reason === "Other" && (
                        <Textarea
                            name="status_reason_other"
                            label="Explain Suspension Reason"
                            className="w-full form-control"
                            required={true}
                        />
                    )}
                </>
            ) : null}
        </>
    ) : null;
};
