import React from "react";
import type { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

export type CardProps = HTMLAttributes<HTMLDivElement> & {
    shadow?: boolean;
    padding?: boolean;
    large?: boolean;
};

export const Card = (props: CardProps) => {
    const {
        shadow = true,
        padding = true,
        large = false,
        className,
        style,
        children,
        ...restProps
    } = props;

    return (
        <div
            className={twMerge(
                `overflow-hidden rounded-2xl bg-brand-white ${
                    padding
                        ? large
                            ? "px-4 py-6 lg:px-8 lg:py-10"
                            : "px-[11px] py-[19px] lg:px-[19px] border-[5px] border-transparent"
                        : "p-0"
                } ${
                    shadow
                        ? large
                            ? "shadow-brand-light-300"
                            : "shadow-brand-light-600"
                        : ""
                }`,
                className
            )}
            {...(style && { style })}
            data-testid={props?.["data-testid"] || "card"}
            {...restProps}
        >
            {children}
        </div>
    );
};
